import { Schedule } from 'protos/pb/v1alpha1/schedule';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';
import { storageService } from './StorageService';
import {
  CreateScheduleRequest,
  ListSchedulesRequest,
  ListSchedulesResponse,
  DeleteScheduleRequest,
  SchedulesClientImpl,
  GetScheduleRequest,
  UpdateScheduleRequest,
} from 'protos/pb/v1alpha1/schedule_service';

export class WorkflowSchedulerService {
  private static instance: WorkflowSchedulerService;
  private static client = new SchedulesClientImpl(rpcWithErrorHandling);

  public static getInstance(): WorkflowSchedulerService {
    if (!this.instance) {
      this.instance = new WorkflowSchedulerService();
    }
    return this.instance;
  }

  async getSchedules(
    req: ListSchedulesRequest,
  ): Promise<{ response?: ListSchedulesResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await WorkflowSchedulerService.client.ListSchedules(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
  async createSchedule(
    req: CreateScheduleRequest,
    refreshResults?: () => void,
  ): Promise<{ response?: Schedule; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await WorkflowSchedulerService.client.CreateSchedule(
        req,
        getMetaData({ authorization }),
      );
      await refreshResults?.();
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
  async deleteSchedule(
    req: DeleteScheduleRequest,
    refreshResults?: () => void,
  ): Promise<{ response?: any; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await WorkflowSchedulerService.client.DeleteSchedule(
        req,
        getMetaData({ authorization }),
      );
      await refreshResults?.();
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getSchedule(
    req: GetScheduleRequest,
  ): Promise<{ response?: Schedule; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await WorkflowSchedulerService.client.GetSchedule(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateSchedule(
    req: UpdateScheduleRequest,
    refreshResults?: () => void,
  ): Promise<{ response?: Schedule; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await WorkflowSchedulerService.client.UpdateSchedule(
        req,
        getMetaData({ authorization }),
      );
      await refreshResults?.();
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const workflowSchedulerService = WorkflowSchedulerService.getInstance();
