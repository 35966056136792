import {
  GetWorkflowTaskRequest,
  GetWorkflowTemplateRequest,
  ListWorkflowTemplatesRequest,
} from 'protos/pb/v1alpha1/orbot_service';
import { WorkflowTaskActionType } from './actions.constants';
import { WorkflowWithTask } from '../reducers/workflow_task.reducer';
import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';

export const getWorkflowTaskSuccess = (response: any) => ({
  type: WorkflowTaskActionType.GET_WORKFLOW_TASK_SUCCESS,
  payload: response,
});

export const getWorkflowTaskFailure = (error: Error) => ({
  type: WorkflowTaskActionType.GET_WORKFLOW_TASK_FAILURE,
  payload: error,
});

export const getWorkflowByIdSuccess = (response: any) => ({
  type: WorkflowTaskActionType.GET_WORKFLOW_BY_ID,
  payload: response,
});

export const getWorkflowWithTask = (
  req: GetWorkflowTaskRequest,
  refresh = false,
) => ({
  type: WorkflowTaskActionType.GET_WORKFLOW_WITH_TASK,
  payload: { req, refresh },
});

export const updateLinkedTasks = (req: WorkflowWithTask[]) => ({
  type: WorkflowTaskActionType.UPDATE_WORKFLOW_LINKED_TASKS,
  payload: req,
});

export const clearWorkflowTask = () => ({
  type: WorkflowTaskActionType.CLEAR_WORKFLOW_WITH_TASK,
});

// list templates workflows orbot
export const listWorkflowsTemplateErrorAction = (error?: Error) => ({
  type: WorkflowTaskActionType.LIST_WORKFLOWS_TEMPLATE_ERROR_ORBOT,
  payload: error,
});

export const listOrbotWorkflowsTemplateAction = (
  req: ListWorkflowTemplatesRequest,
  refresh = false,
) => ({
  type: WorkflowTaskActionType.LIST_WORKFLOWS_TEMPLATE_ORBOT,
  req,
  refresh,
});

export const listWorkflowsTemplateCompletedAction = (
  workflows: Workflow[],
  totalSize: number,
) => ({
  type: WorkflowTaskActionType.LIST_WORKFLOWS_TEMPLATE_COMPLETED_ORBOT,
  payload: workflows,
  totalSize,
});

export const getOrbotWorkflowTemplateErrorAction = (error?: Error) => ({
  type: WorkflowTaskActionType.GET_WORKFLOW_TEMPLATE_ERROR_ORBOT,
  payload: error,
});

export const getOrbotWorkflowTemplateAction = (
  req: GetWorkflowTemplateRequest,
) => ({
  type: WorkflowTaskActionType.GET_WORKFLOW_TEMPLATE_ORBOT,
  req,
});

export const getOrbotWorkflowTemplateCompletedAction = (
  workflow: Workflow,
) => ({
  type: WorkflowTaskActionType.GET_WORKFLOW_TEMPLATE_COMPLETED_ORBOT,
  payload: workflow,
});
