import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';
import RootReducer from './reducers';
import rootSaga from './sagas';

/* Root Saga Middleware */
const sagaMiddleware = createSagaMiddleware();

const middlewares = applyMiddleware(sagaMiddleware);

// if (ENV_CONFIG.APP_ENV === 'development') {
//   middlewares = applyMiddleware(sagaMiddleware, logger);
// }

/* Root Store with all the combined reducers */
const store = createStore(RootReducer, composeWithDevTools(middlewares));

/* Run the sagas */
if (process.env.NODE_ENV !== 'test') {
  // Run sagas only when not in 'test' environment
  sagaMiddleware.run(rootSaga);
}
export default store;
