import { eraseCookie, getCookie, parseCookie } from '../utils/cookie';
import { removeQuotes } from '../utils/helpers';
import { httpApiService } from './HTTPApiService';

class SAMLService {
  private static instance: SAMLService;
  // private static client = new OrganizationsClientImpl(rpcWithErrorHandling);

  public static getInstance() {
    if (!this.instance) {
      this.instance = new SAMLService();
    }
    return this.instance;
  }

  // HTTP Request
  async login(email: string) {
    const response = await httpApiService.post('saml/auth/login', {
      email,
    });
    // since response will be HTML form we auto submit that HTML form
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = response;
    // Find the form element
    const form = tempDiv.querySelector('form');
    // Append the form to the body (or another container)
    document.body.appendChild(form!);
    // Automatically submit the form
    form!.submit(); // This will trigger a POST request to the SAML endpoint
    // Handle the response data as needed
  }

  async verifyConfiguration(orgResourceName: string) {
    const response = await httpApiService.post(
      'saml/login/test',
      { org_resource_name: orgResourceName },
      true,
    );
    // since response will be HTML form we auto submit that HTML form
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = response;
    // Find the form element
    const form = tempDiv.querySelector('form');
    // Append the form to the body (or another container)
    document.body.appendChild(form!);
    // Automatically submit the form
    form!.submit(); // This will trigger a POST request to the SAML endpoint
    // Handle the response data as needed
  }

  async checkForSamlResponse(error: string): Promise<
    | {
        access_token: string;
        session_id: string;
      }
    | { error: string }
    | null
  > {
    return new Promise((resolve, reject) => {
      try {
        let samlResponse = getCookie('orby_saml_response');
        if (samlResponse) {
          // remove starting and ending quotes
          samlResponse = removeQuotes(samlResponse);

          // parse cookie into object
          const parsedResponse:
            | { access_token: string; session_id: string }
            | { error: string } = parseCookie(samlResponse) as any;

          // clear the cookie
          eraseCookie('orby_saml_response');
          resolve(parsedResponse);
        }
        reject(error);
      } catch (e) {
        reject(e);
      }
    });
  }
}

export const samlService = SAMLService.getInstance();
