import {
  DocumentEntity,
  DocumentEntityNormalizedValue,
  DocumentPageAnchor,
  DocumentPageAnchorPageRef,
  DocumentTextAnchor,
  DocumentTextAnchorTextSegment,
} from 'protos/google/cloud/documentai/v1/document';
import {
  EntityInfo,
  SelectedParentEntity,
  TextSegmentInfo,
} from '../redux/reducers/review_task.reducer';
import { tokenValuesUtil } from './TokenValuesUtil';
import { boxPositionValuesUtilV2 } from './BoxPositionValuesUtilV2';
import {
  IdleSession,
  Task,
  TaskSTATUS,
} from 'protos/pb/v1alpha2/tasks_service';
import {
  BoundingPoly,
  NormalizedVertex,
  Vertex,
} from 'protos/google/cloud/documentai/v1/geometry';
import { TEXT_ANCHOR_SEPARATOR, checkIfNotesEntityType } from './entities';
import { boxPositionValuesUtil } from './BoxPositionValuesUtil';
import {
  EntityDataType,
  EntityDetails,
  EntityTypeSchema,
} from 'protos/pb/v1alpha2/workflow_steps_params';
import { DateMessage } from 'protos/google/type/date';
import {
  moneyToText,
  processCurrency,
  processDate,
  processNumber,
  processText,
} from './normalization';
import { Money } from 'protos/google/type/money';
import {
  DEFAULT_BOX_HEIGHT,
  DEFAULT_BOX_WIDTH,
  DEFAULT_CONFIDENCE_SCORE,
  EntityFilter,
  FEATURE_FLAGS,
  PADDING_BW_PDF_PAGES,
  REVIEW_PAGE_TOP_BAR_HEIGHT,
  REVIEW_PAGE_TOP_MARGIN,
  TimeRange,
} from './constants';
import {
  setSelectedEntityIdAction,
  setSelectedParentEntityTypeAction,
  updateSelectedReviewFilterSection,
  updateTaskEntityInfoAction,
} from '../redux/actions/review_task.action';
import store from '../redux/store';
import { getSelectedTaskDocument } from './helpers';
import { WorkflowMode } from 'protos/pb/v1alpha2/workflows_service';
import { resetFloatingModalStyle } from './UnsavedChangesUtils';
import { uniqueId } from 'lodash';
import { isFeatureFlagEnabled } from '../pages/FeatureFlags/FeatureFlagUtils';
import { sentryService } from '../services/SentryService';

// FUNCTION TO ADD INFO OF ENTITY
export const getEntityInfoForEntity = ({
  entity,
  documentText,
  isNestedEntity,
  parentEntity,
  task,
  entityDetails,
  isInDoc,
}: {
  entity: DocumentEntity;
  documentText: string;
  isNestedEntity: boolean;
  parentEntity?: DocumentEntity;
  entityDetails: EntityDetails[];
  task: Task;
  isInDoc: boolean;
}): EntityInfo => {
  // Nested entities with prefix "*" are not treated as Notes entities rather they are normal entities.
  const isNotesEntity = checkIfNotesEntityType(entity.type as string);
  const normalizedEntityType = getNormalizedEntityTypeValue(
    entity,
    entityDetails,
    parentEntity?.type,
  );
  const textSegments = getTextSegmentInfoForEntity(entity, documentText, task);
  return {
    id: entity.id as string,
    isReviewed:
      task.status === TaskSTATUS.COMPLETED && !task?.tags?.includes('auto'),
    isModified: false,
    textSegments,
    entityText: entity.mentionText ?? getTextFromTextSegments(textSegments),
    normalizedValue:
      getNormalizedValueForEntity(entity, normalizedEntityType) ??
      ({} as DocumentEntityNormalizedValue),
    isNormalizedValueModified: false,
    type: entity.type as string,
    isNestedEntity,
    parentEntityId: parentEntity?.id,
    parentEntityType: parentEntity?.type,
    confidenceScore: entity.confidence,
    isInDoc: isInDoc,
    isConfirmed: false,
    isDeclined: false,
    normalizedEntityType: normalizedEntityType,
    normalizedInputValue: getNormalizedInputValue(entity, normalizedEntityType),
    isExtra: isNotesEntity,
    extraEntityText: entity.mentionText,
    // Adding this confidence score to check if this entity needs attention of reviewer
    // If the entity's confidence is less than this, then we show it in Need Attention Tab on review page
    needAttentionConfidenceScore:
      task.workflowModeWhenCreated === WorkflowMode.DEFAULT
        ? (task.needAttentionThresholdDefaultMode as number)
        : DEFAULT_CONFIDENCE_SCORE,
  };
};

/**
 *
 * @param entity - Entity for which normalization type is to be found
 * @param entityDetails - Array of all the entities with their normalization type info
 * @param parentEntityType - Name of the parent entity (if entity is nested)
 * @returns
 */
export const getNormalizedEntityTypeValue = (
  entity: DocumentEntity,
  entityDetails: EntityDetails[],
  parentEntityType?: string,
) => {
  let normalizedEntityType = EntityDataType.ENTITY_TYPE_TEXT;
  entityDetails.forEach((entityDetail) => {
    if (!parentEntityType && entityDetail.entityType === entity.type) {
      // This means that the entity is not a nested entity
      normalizedEntityType = entityDetail.normalizationType as EntityDataType;
      return;
    } else if (
      entityDetail?.properties?.length &&
      parentEntityType === entityDetail.entityType &&
      entityDetail?.properties?.length > 0
    ) {
      // This means that the entity is a nested entity
      entityDetail.properties.forEach((property) => {
        if (property.entityType === entity.type) {
          normalizedEntityType = property.normalizationType as EntityDataType;
          return;
        }
      });
    }
  });
  return normalizedEntityType;
};

export const getDefaultTextSegment = (entityId: string) => {
  const textSegment: { [id: string]: TextSegmentInfo } = {};
  textSegment[0] = {
    id: `${0}`,
    vertices: [],
    pageCorrespondingVertices: [],
    normalizedVertices: [],
    page: 0,
    modifiedPage: 0,
    text: '',
    textFromTokens: '',
    entityId: entityId as string,
    startIndex: 0,
    endIndex: 0,
  };
  return textSegment;
};

export const getNormalizedInputValue = (
  entity: DocumentEntity,
  normalizedEntityType: EntityDataType,
) => {
  switch (normalizedEntityType) {
    case EntityDataType.ENTITY_TYPE_MONEY:
      return moneyToText(
        entity?.normalizedValue?.moneyValue?.units ?? 0,
        entity?.normalizedValue?.moneyValue?.nanos ?? 0,
      );
    case EntityDataType.ENTITY_TYPE_FLOAT:
      return entity.normalizedValue?.floatValue?.toString() ?? '';
    case EntityDataType.ENTITY_TYPE_INTEGER:
      return entity.normalizedValue?.integerValue?.toString() ?? '';
    default:
      return entity.normalizedValue?.text;
  }
};

export const getNormalizedValueForEntity = (
  entity: DocumentEntity,
  normalizedEntityType: EntityDataType,
) => {
  if (normalizedEntityType) {
    // Check if the normalized value is already present
    // If not then create a new normalized value
    if (!entity.normalizedValue) {
      return getBlankNormalizedValue(normalizedEntityType);
    }
    return entity.normalizedValue;
  }
  return undefined;
};

export const getBlankNormalizedValue = (normalizationType?: EntityDataType) => {
  const normalizedValue: DocumentEntityNormalizedValue = {};
  if (normalizationType === EntityDataType.ENTITY_TYPE_DATE) {
    normalizedValue.dateValue = DateMessage.create();
  } else if (normalizationType === EntityDataType.ENTITY_TYPE_MONEY) {
    normalizedValue.moneyValue = Money.create();
  } else if (normalizationType === EntityDataType.ENTITY_TYPE_INTEGER) {
    normalizedValue.integerValue = 0;
  } else if (normalizationType === EntityDataType.ENTITY_TYPE_FLOAT) {
    normalizedValue.floatValue = 0;
  } else if (normalizationType === EntityDataType.ENTITY_TYPE_TEXT) {
    normalizedValue.text = '';
  }
  return normalizedValue;
};

// FUNCTION TO ADD INFO OF TEXT SEGMENTS IN AN ENTITY
export const getTextSegmentInfoForEntity = (
  entity: DocumentEntity,
  documentText: string,
  task: Task,
) => {
  let textSegmentsInfo: { [id: string]: TextSegmentInfo } = {};
  if (
    entity.textAnchor?.textSegments?.length &&
    entity.pageAnchor?.pageRefs?.length
  ) {
    let index = 0;
    for (const textSegment of entity.textAnchor.textSegments) {
      textSegmentsInfo[index] = {
        id: `${index}`,
        vertices: entity.pageAnchor.pageRefs?.[index]?.boundingPoly
          ?.vertices as Vertex[],
        pageCorrespondingVertices: entity.pageAnchor.pageRefs?.[index]
          ?.boundingPoly?.vertices as Vertex[],
        normalizedVertices: entity.pageAnchor.pageRefs?.[index]?.boundingPoly
          ?.normalizedVertices as Vertex[],
        page: entity.pageAnchor.pageRefs?.[index]?.page as number,
        modifiedPage: entity.pageAnchor.pageRefs?.[index]?.page as number,
        text: getTextValue(
          task,
          textSegment,
          documentText,
          entity,
          index,
        ) as string,
        textFromTokens: getTextValue(
          task,
          textSegment,
          documentText,
          entity,
          index,
        ) as string,
        entityId: entity.id as string,
        startIndex: textSegment.startIndex as number,
        endIndex: textSegment.endIndex as number,
      };
      index++;
    }
  } else if (entity.mentionText) {
    // In case when mention text is present but text segment is not
    textSegmentsInfo[0] = {
      id: `${0}`,
      vertices: [],
      pageCorrespondingVertices: [],
      normalizedVertices: [],
      page: 0,
      modifiedPage: 0,
      text: entity.mentionText,
      textFromTokens: entity.mentionText,
      entityId: entity.id as string,
      startIndex: 0,
      endIndex: 0,
    };
  }
  if (Object.keys(textSegmentsInfo).length === 0) {
    textSegmentsInfo = getDefaultTextSegment(entity.id as string);
  }
  return textSegmentsInfo;
};

const getTextValue = (
  task: Task,
  textSegment: DocumentTextAnchorTextSegment,
  documentText: string,
  entity: DocumentEntity,
  index: number,
) => {
  if (task.status === TaskSTATUS.READY) {
    if (
      entity.textAnchor?.textSegments &&
      entity.textAnchor.textSegments.length === 1
    ) {
      return entity.mentionText;
    }
    return tokenValuesUtil.getSubStringFromTextSegment(
      textSegment,
      documentText,
    );
  } else {
    /**
     * In case of completed and declined tasks
     * If the entity has only one text segment, then we can directly use the mention text
     */
    if (
      entity.textAnchor?.textSegments &&
      entity.textAnchor.textSegments.length === 1
    ) {
      return entity.mentionText;
    } else {
      /**
       * If the task is modified then we use the text anchor content to get the text value
       */
      if (task && task?.tags?.includes('modified')) {
        const parsedTextContent = parseTextAnchorContent(
          entity?.textAnchor?.content as string,
        );
        return parsedTextContent[index];
      } else {
        /**
         * If the task is not modified then we use the text segment to get the text value
         */
        return tokenValuesUtil.getSubStringFromTextSegment(
          textSegment,
          documentText,
        );
      }
    }
  }
};

const parseTextAnchorContent = (textAnchorContent: string) => {
  return textAnchorContent?.split(TEXT_ANCHOR_SEPARATOR);
};

// THIS APPENDS ALL THE TEXT PRESENT IN TEXT SEGMENT MAP OF ANY ENTITY
// The text segments will be appended in the order of text index
export const getTextFromTextSegments = (textSegments: {
  [id: string]: TextSegmentInfo;
}) => {
  const textSegmentArray = Object.values(textSegments || {});
  // Join the sorted text segments
  const text = textSegmentArray
    .map((segment) => segment.text)
    .join(' ')
    .trim();
  return text;
};

// We need this to display entities on review side drawer. this returns parent-child entity
// mapping. need this since we store each child entity as a normal entity in our entity info map
// e.g if there are 5 entities in which one entity contains 3 child entities, the our map has
// 4 + 3 = 7 entity info values stored to their corresponding entity id
// this returns those 7 as 5 again back in original form
export const getOldEntitiesList = (entityInfoObject: {
  [id: string]: EntityInfo;
}) => {
  const entitiesList: any[] = [];
  for (const entityId in entityInfoObject) {
    if (entityInfoObject[entityId].isNestedEntity) {
      const nestedEntityIndex = entitiesList.findIndex(
        (entity) =>
          entity.parentEntityId === entityInfoObject[entityId].parentEntityId,
      );
      if (nestedEntityIndex !== -1) {
        entitiesList[nestedEntityIndex]?.entities?.push(
          entityInfoObject[entityId],
        );
      } else {
        entitiesList.push({
          parentEntityId: entityInfoObject[entityId].parentEntityId,
          parentEntityType: entityInfoObject[entityId].parentEntityType,
          entities: [entityInfoObject[entityId]],
        });
      }
    } else {
      entitiesList.push(entityInfoObject[entityId]);
    }
  }
  return entitiesList;
};

// This will return all normal entities along with the first child entity of a parent entity that is used to
// display parent ui in the list. E.g. If there are 4 normal entities and 7 child(nested) entities (4 of parent 1
// and 3 of parent 2), then the list will return 4 normal entities, 1 child entity of parent 1 and 1 child entity
// of parent 2, 6 in total
export const getEntitiesList = (
  entityInfoObject: {
    [id: string]: EntityInfo;
  },
  originalEntityInfoObject: {
    [id: string]: EntityInfo;
  },
) => {
  const entitiesList: EntityInfo[] = [];
  // The reason we are using originalEntityInfoObject for looping,
  // is to preserve the original entity display order
  for (const entityId in originalEntityInfoObject) {
    const entity = originalEntityInfoObject[entityId];
    if (entity.isNestedEntity) {
      if (
        !entitiesList.find(
          (e) => e.parentEntityType === entity.parentEntityType,
        )
      ) {
        // While preserving the the original order we want to make sure to always
        // display updated entity which is present in entityInfoObject
        const updatedEntity = Object.values(entityInfoObject).find(
          (e) => e.parentEntityType === entity?.parentEntityType,
        );
        if (updatedEntity) {
          entitiesList.push(updatedEntity);
        }
      }
    } else {
      const updatedEntity = entityInfoObject[entity.id];
      if (updatedEntity) {
        entitiesList.push(updatedEntity);
      }
    }
  }
  return entitiesList;
};

// THIS BASICALLY DO THE SAME THING AS ABOVE. THE ONLY DIFFERENCE IS IT RETURNS IT AS TYPE - DocumentEntity
// THIS RETURNS THE UPDATED VALUES FROM ENTITY INFO MAP FOR EACH ENTITY
export const getDocumentEntitiesFromMap = (
  entityInfoObject: { [id: string]: EntityInfo },
  increaseConfidence?: boolean,
) => {
  const entitiesList: DocumentEntity[] = [];
  for (const entityId in entityInfoObject) {
    if (entityInfoObject[entityId].isNestedEntity) {
      const nestedEntityIndex = entitiesList.findIndex(
        (entity) => entity.id === entityInfoObject[entityId].parentEntityId,
      );
      if (nestedEntityIndex !== -1) {
        const entity = createDocumentEntityFromEntityInfo(
          entityInfoObject[entityId],
          increaseConfidence,
        );
        entitiesList[nestedEntityIndex]?.properties?.push(entity);
      } else {
        const parentEntity = createParentEntityFromChildEntityInfo(
          entityInfoObject[entityId],
        );
        const childEntity = createDocumentEntityFromEntityInfo(
          entityInfoObject[entityId],
          increaseConfidence,
        );
        parentEntity.properties?.push(childEntity);
        entitiesList.push(parentEntity);
      }
    } else {
      const entity = createDocumentEntityFromEntityInfo(
        entityInfoObject[entityId],
        increaseConfidence,
      );
      entitiesList.push(entity);
    }
  }
  return entitiesList;
};

// THIS RETURNS DocumentEntity FROM EntityInfo MAP
export const createDocumentEntityFromEntityInfo = (
  entityInfo: EntityInfo,
  increaseConfidence?: boolean,
): DocumentEntity => {
  const entity: DocumentEntity = DocumentEntity.create({});
  entity.id = entityInfo.id;
  entity.type = entityInfo.type;
  entity.confidence = increaseConfidence ? 1 : entityInfo.confidenceScore;
  entity.mentionText = entityInfo.isExtra
    ? entityInfo.extraEntityText
    : entityInfo.entityText;
  entity.normalizedValue = entityInfo.normalizedValue;
  entity.pageAnchor = getPageAnchor(Object.values(entityInfo.textSegments));
  entity.textAnchor = getTextAnchor(entityInfo);
  return entity;
};

export const createParentEntityFromChildEntityInfo = (
  entityInfo: EntityInfo,
): DocumentEntity => {
  const entity: DocumentEntity = {};
  entity.id = entityInfo.parentEntityId ?? '';
  entity.type = entityInfo.parentEntityType ?? '';
  entity.properties = [];
  return entity;
};

// THIS RETURNS THE TEXT SEGMENT WHOSE Y COORD IS THE MOST OUT OF ALL TEXT SEGMENTS
export const getBottomTextSegment = (
  textSegments: TextSegmentInfo[],
): TextSegmentInfo | undefined => {
  let textSegmentInfo: TextSegmentInfo | undefined = undefined;
  for (const textSegment of textSegments) {
    if (textSegment && textSegment?.vertices?.length) {
      if (!textSegmentInfo) {
        textSegmentInfo = textSegment;
      }
      const bottomRightVertex =
        boxPositionValuesUtilV2.getBottomRightCornerVertices(
          textSegment.vertices,
        );
      const prevBottomRightVertex =
        boxPositionValuesUtilV2.getBottomRightCornerVertices(
          textSegmentInfo.vertices,
        );
      if (
        (bottomRightVertex.y as number) > (prevBottomRightVertex.y as number)
      ) {
        textSegmentInfo = textSegment;
      }
    } else {
      if (!textSegmentInfo) {
        textSegmentInfo = textSegment;
      }
    }
  }
  return textSegmentInfo;
};

/**
 * This function returns the entity keys based on the selected review filter
 * @param {EntityInfo} entityInfo
 * @param {EntityFilter} selectedReviewFilterSection
 * @returns
 */
const isNextKeyBasedOnReviewFilter = (
  entityInfo: EntityInfo,
  selectedReviewFilterSection: EntityFilter,
) => {
  switch (selectedReviewFilterSection) {
    case EntityFilter.NEED_ATTENTION:
      return isEntityNeedAttention(entityInfo);
    case EntityFilter.REVIEWED:
      return isEntityReviewed(entityInfo);
    case EntityFilter.PREDICTED:
      return isEntityPredicted(entityInfo);
    default:
      return true;
  }
};

/**
 * This function check if the entity is the last key in the entities list based on the selected review filter
 * @param {{ [id: string]: EntityInfo; }} entitiesInfoMap
 * @param {EntityInfo} entityInfo
 * @param {EntityFilter} selectedReviewFilterSection
 * @returns
 */
const isEntityLastInSelectedFilter = (
  entitiesInfoMap: { [id: string]: EntityInfo },
  entityInfo: EntityInfo,
  selectedReviewFilterSection?: EntityFilter,
) => {
  switch (selectedReviewFilterSection) {
    case EntityFilter.NEED_ATTENTION: {
      const lastNeedAttentionEntityId =
        getLastNeedAttentionEntityId(entitiesInfoMap);
      return lastNeedAttentionEntityId === entityInfo.id;
    }
    case EntityFilter.PREDICTED: {
      const lastPredictedEntityId = getLastPredictedEntityId(entitiesInfoMap);
      return lastPredictedEntityId === entityInfo.id;
    }
    case EntityFilter.REVIEWED: {
      const lastReviewedEntityId = getLastReviewedEntityId(entitiesInfoMap);
      return lastReviewedEntityId === entityInfo.id;
    }
    default: {
      const entities = Object.keys(entitiesInfoMap);
      return entities[entities.length - 1] === entityInfo?.id;
    }
  }
};

/**
 * This function gives the next available entity id
 */
export const findNextEntityKey = (
  selectedEntityId: string,
  entitiesInfoMap: { [id: string]: EntityInfo },
  selectedReviewFilterSection?: EntityFilter,
) => {
  const entityKeys = Object.keys(entitiesInfoMap); // Create an array of entity keys
  // Find the index of the selected entity in the array
  const selectedEntityIndex = entityKeys.findIndex(
    (key) => key === selectedEntityId,
  );
  /**
   * If this key is the last key in the selected review filter
   * The below block is used to handle the transitions between the review filter sections
   * REF - https://orby-ai.atlassian.net/browse/OA-2132
   */
  const isEntityTheLastKeyInSelectedFilter = isEntityLastInSelectedFilter(
    entitiesInfoMap,
    entitiesInfoMap[selectedEntityId],
    selectedReviewFilterSection,
  );
  // If the selected entity is the last key in the entities list based on the selected filter
  if (isEntityTheLastKeyInSelectedFilter) {
    switch (selectedReviewFilterSection) {
      case EntityFilter.NEED_ATTENTION: {
        // Check if there are more than one entities that need attention
        // The reason for using 1 is because the selected entity could be the last key in the entities list
        if (getNeedAttentionEntitiesCount(entitiesInfoMap) > 1) {
          // If yes, set the review filter section to 'NEED_ATTENTION'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.NEED_ATTENTION),
          );
          return getFirstNeedAttentionEntityId(entitiesInfoMap); // Return the first need attention entity id
        } else if (getPredictedEntitiesCount(entitiesInfoMap) > 0) {
          // If yes, set the review filter section to 'PREDICTED'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.PREDICTED),
          );
          return getFirstPredictedEntityId(entitiesInfoMap); // Return the ID of the first predicted entity
        } else {
          // If no entities need attention and no predicted entities exist, set the review filter section to 'REVIEWED'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.REVIEWED),
          );
          return null; // Return null when tab changes to REVIEWED
        }
      }
      case EntityFilter.PREDICTED: {
        if (getNeedAttentionEntitiesCount(entitiesInfoMap) > 0) {
          // If yes, set the review filter section to 'NEED_ATTENTION'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.NEED_ATTENTION),
          );
          return getFirstNeedAttentionEntityId(entitiesInfoMap); // Return the first need attention entity id
        } else {
          // If no entities need attention, set the review filter section to 'REVIEWED'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.REVIEWED),
          );
          return null; // Return null when tab changes to REVIEWED
        }
      }
      case EntityFilter.REVIEWED: {
        if (getNeedAttentionEntitiesCount(entitiesInfoMap) > 0) {
          // If yes, set the review filter section to 'NEED_ATTENTION'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.NEED_ATTENTION),
          );
          return getFirstNeedAttentionEntityId(entitiesInfoMap); // Return the first need attention entity id
        } else if (getPredictedEntitiesCount(entitiesInfoMap) > 0) {
          // If yes, set the review filter section to 'PREDICTED'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.PREDICTED),
          );
          return getFirstPredictedEntityId(entitiesInfoMap); // Return the ID of the first predicted entity
        }
        return null; // Return null since there are no entities to review
      }
      default:
        return null; // Return null since there are no entities to review
    }
  } else {
    // If the selected entity is not the last key in the entities list based on the selected filter
    // Iterate through the entity keys, starting from the key after the selected entity
    for (let i = 1; i < entityKeys.length; i++) {
      // Calculate the index in a circular manner to loop back to the start of the array
      const index = (selectedEntityIndex + i) % entityKeys.length;
      const key = entityKeys[index];
      // Skip the currently selected key
      if (key === selectedEntityId) {
        continue;
      }

      // Check if the current key is the next un-reviewed key based on the selected review filter section
      if (
        isNextKeyBasedOnReviewFilter(
          entitiesInfoMap[key],
          selectedReviewFilterSection as EntityFilter,
        )
      ) {
        return key; // Found the next un reviewed key
      }
    }
    // If no key is found, return null
    return null;
  }
};

// THIS RETURNS DocumentPageAnchor INFO FOR ANY TEXT SEGMENT
export const getPageAnchor = (
  textSegments: TextSegmentInfo[],
): DocumentPageAnchor => {
  const pageAnchor = DocumentPageAnchor.create({});
  if (textSegments.length) {
    for (const textSegment of textSegments) {
      if (textSegment?.pageCorrespondingVertices) {
        // This is to handle the case when there is a not in doc entity so it won't have page corresponding vertices if no modifications are made
        pageAnchor?.pageRefs?.push(
          DocumentPageAnchorPageRef.create({
            page: textSegment.modifiedPage,
            boundingPoly: BoundingPoly.create({
              vertices: textSegment.pageCorrespondingVertices.map((v) => {
                return Vertex.create({
                  x: Math.round(v.x as number),
                  y: Math.round(v.y as number),
                });
              }),
              normalizedVertices: textSegment.normalizedVertices,
            }),
          }),
        );
      }
    }
  }
  return pageAnchor;
};

// THIS RETURNS DocumentTextAnchor INFO FOR ANY TEXT SEGMENT
export const getTextAnchor = (entityInfo: EntityInfo): DocumentTextAnchor => {
  const textAnchor = DocumentTextAnchor.create({
    content: entityInfo.isExtra
      ? entityInfo.extraEntityText
      : Object.values(entityInfo.textSegments)
          .map((t) => t.text.trim())
          .join(TEXT_ANCHOR_SEPARATOR),
  });

  const textSegments: TextSegmentInfo[] = Object.values(
    entityInfo.textSegments,
  );
  if (textSegments.length) {
    for (const textSegment of textSegments) {
      textAnchor?.textSegments?.push(
        DocumentTextAnchorTextSegment.create({
          startIndex: textSegment.startIndex,
          endIndex: textSegment.endIndex,
        }),
      );
    }
  } else {
    textAnchor?.textSegments?.push(DocumentTextAnchorTextSegment.create({}));
  }
  return textAnchor;
};

/**
 * The purpose of this function is to normalize the coordinates of these vertices relative to the
 * dimensions of the PDF box, and it returns an array of NormalizedVertex objects.
 */
export const getNormalizedVertices = (
  pdfBoxHeight: number,
  pdfBoxWidth: number,
  vertices: Vertex[],
): NormalizedVertex[] => {
  const { top, left, width, height } =
    boxPositionValuesUtil.getBoxPositionValues(
      pdfBoxWidth,
      pdfBoxHeight,
      vertices,
    );
  const right = left + width;
  const bottom = top + height;

  const normalisedVertex: NormalizedVertex[] = [
    { x: left / 100, y: top / 100 },
    { x: right / 100, y: top / 100 },
    { x: right / 100, y: bottom / 100 },
    { x: left / 100, y: bottom / 100 },
  ];
  return normalisedVertex;
};

export const isElementInViewport = (element: HTMLElement): boolean => {
  const rect = element.getBoundingClientRect();
  const documentView = document.getElementById('pdf-panel-wrapper');
  if (!documentView) return false;

  const documentRect = documentView.getBoundingClientRect();
  const table = document.getElementById('review-page-floating-table-modal');

  const inDocumentView =
    rect.top >= documentRect.top &&
    rect.left >= documentRect.left &&
    rect.bottom <= documentView.clientHeight &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth);

  if (!table) {
    return inDocumentView;
  }

  const tableRect = table.getBoundingClientRect();

  // Check if the element is obscured by the table
  const isObscuredByTable =
    rect.bottom > tableRect.top &&
    rect.top < tableRect.bottom &&
    rect.right > tableRect.left &&
    rect.left < tableRect.right;

  return inDocumentView && !isObscuredByTable;
};

export const goToVisibleElement = (id: string): void => {
  const element = document.getElementById(id);
  // do not scroll if element is not found or if element is already in viewport
  if (!element || isElementInViewport(element)) return;

  element.style.scrollMarginTop = '20px';
  const table = document.getElementById('review-page-floating-table-modal');
  const documentView = document.getElementById('pdf-panel-wrapper');
  // scroll to default if table
  if (!table || !documentView) {
    element.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'start',
    });
    return;
  }

  const tableRect = table.getBoundingClientRect();
  const documentRect = documentView.getBoundingClientRect();
  const spaceAvailableOnTop = tableRect.top - documentRect.top;
  const spaceAvailableOnBottom = documentRect.bottom - tableRect.bottom;

  if (spaceAvailableOnTop >= 40) {
    element.style.scrollMarginTop = '20px';
    element.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'start',
    });
  } else if (spaceAvailableOnBottom >= 40) {
    element.style.scrollMarginBottom = '40px';
    element.scrollIntoView({
      behavior: 'auto',
      block: 'end',
      inline: 'start',
    });
  } else {
    element.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'start',
    });
  }
};

/**
 * This function is used to identify if the normalized value should be shown or not
 * @param entityType
 */
export const isShowNormalizedValue = (entityType: EntityDataType) => {
  return [
    EntityDataType.ENTITY_TYPE_DATE,
    EntityDataType.ENTITY_TYPE_FLOAT,
    EntityDataType.ENTITY_TYPE_INTEGER,
    EntityDataType.ENTITY_TYPE_MONEY,
    EntityDataType.ENTITY_TYPE_TEXT,
  ].includes(entityType);
};

/**
 * Confirms the review of an entity, updates its state, and shifts to the next entity if applicable.
 *
 * @param {EntityInfo} entity - The entity being reviewed.
 * @param {Task} task - The current task.
 * @param {{ [id: string]: EntityInfo }} entitiesInfoMap - Map of entity IDs to their information.
 * @param {EntityFilter} selectedReviewFilterSection - The selected review filter section.
 * @param {boolean} [selectNextEntityIfAvailable=true] - Flag to determine if the next entity should be selected if available.
 */
export const confirmReview = (
  entity: EntityInfo,
  task: Task,
  entitiesInfoMap: { [id: string]: EntityInfo },
  selectedReviewFilterSection: EntityFilter,
  selectNextEntityIfAvailable = true,
) => {
  if (!entity.error) {
    resetFloatingModalStyle(); // this will remove warning state from modal
    let textSegments = { ...entity.textSegments };
    const textSegmentsList = Object.values(textSegments);
    for (const segment of textSegmentsList) {
      if (isTextSegmentEmpty(segment)) {
        delete textSegments[segment.id];
      }
    }
    let normalizedInputValue = entity.normalizedInputValue;
    if (Object.values(textSegments).length === 0) {
      textSegments = getDefaultTextSegment(entity.id);
      normalizedInputValue = '';
    }
    const newEntityInfo = { ...entity, textSegments };
    newEntityInfo.isNormalizationFailed = false;
    newEntityInfo.normalizedInputValue = normalizedInputValue;
    newEntityInfo.isReviewed = true;
    newEntityInfo.isInDoc = !(
      textSegmentsList.length === 0 || checkIfNotesEntityType(entity.type)
    );
    newEntityInfo.confidenceScore = 1;
    newEntityInfo.isConfirmed = true;
    newEntityInfo.isDeclined = false;
    store.dispatch(updateTaskEntityInfoAction(entity.id, newEntityInfo));
    const featureFlags =
      store.getState().featureFlags.featureFlagsForOrgAndUser;
    const showNewNestedUI = isFeatureFlagEnabled(
      FEATURE_FLAGS.NESTED_HITL,
      featureFlags,
    );
    shiftToNextEntity(
      entity,
      entitiesInfoMap,
      selectedReviewFilterSection,
      selectNextEntityIfAvailable,
      // prevent shifting the focus onto next entity if Floating Modal is open from within table modal, instead just open the table modal itself
      !!entity?.parentEntityId && !!entity?.parentEntityType && showNewNestedUI,
    );
  }
};

/**
 * Closes the floating modal and handles special cases for nested UI.
 *
 * @param {EntityInfo} entity - The entity for which the floating modal is being closed.
 */
export const closeFloatingModal = (entity: EntityInfo) => {
  resetFloatingModalStyle();
  store.dispatch(setSelectedEntityIdAction(null));
  const featureFlags = store.getState().featureFlags.featureFlagsForOrgAndUser;
  const showNewNestedUI = isFeatureFlagEnabled(
    FEATURE_FLAGS.NESTED_HITL,
    featureFlags,
  );
  // if that entity happens to be child entity reopen the table modal upon close
  if (entity.parentEntityId && entity.parentEntityType && showNewNestedUI) {
    store.dispatch(
      setSelectedParentEntityTypeAction({
        id: entity.parentEntityId,
        type: entity.parentEntityType,
      }),
    );
  }
};

export const getLastEntityForParent = (
  entitiesInfoMap: { [id: string]: EntityInfo },
  parentType: string,
) => {
  const currentTableEntities = Object.values(entitiesInfoMap).filter(
    (e) => e.parentEntityType === parentType,
  );
  return currentTableEntities.pop();
};

export const groupEntitiesByParentType = (entities: EntityInfo[]) => {
  const groupedEntities: EntityInfo[] = [];
  const typeAlreadyAdded = new Set();

  entities.forEach((entity) => {
    const type = entity.parentEntityType;
    if (!type) {
      groupedEntities.push(entity);
    } else {
      if (!typeAlreadyAdded.has(type)) {
        typeAlreadyAdded.add(type);
        groupedEntities.push(
          ...entities.filter((e) => e.parentEntityType === type),
        );
      }
    }
  });

  return groupedEntities;
};

/**
 * Shifts the selection to the next entity based on the current entity and specified conditions.
 *
 * @param {EntityInfo} entity - The current entity information.
 * @param {{ [id: string]: EntityInfo }} entitiesInfoMap - Map of entity IDs to their information.
 * @param {EntityFilter} selectedReviewFilterSection - The selected review filter section.
 * @param {boolean} [selectNextEntityIfAvailable=true] - Flag to determine if the next entity should be selected if available.
 * @param {boolean} [reselectSameEntity=false] - Flag to determine if the same entity should be reselected.
 * @returns {string | undefined} - The key of the next entity, or undefined if not applicable.
 */
export const shiftToNextEntity = (
  entity: EntityInfo,
  entitiesInfoMap: { [id: string]: EntityInfo },
  selectedReviewFilterSection: EntityFilter,
  selectNextEntityIfAvailable = true,
  reselectSameEntity = false,
) => {
  const featureFlags =
    store?.getState?.()?.featureFlags?.featureFlagsForOrgAndUser;
  const showNewNestedUI = isFeatureFlagEnabled(
    FEATURE_FLAGS.NESTED_HITL,
    featureFlags,
  );

  let sortedEntitiesInfoMap = entitiesInfoMap;
  if (showNewNestedUI) {
    const sortedEntities = groupEntitiesByParentType(
      Object.values(entitiesInfoMap),
    );
    sortedEntitiesInfoMap = {};
    sortedEntities.forEach((e) => {
      sortedEntitiesInfoMap[e.id] = e;
    });
  }
  const nextEntityKey = selectNextEntityIfAvailable
    ? reselectSameEntity
      ? entity.id
      : findNextEntityKey(
          entity.id,
          sortedEntitiesInfoMap,
          selectedReviewFilterSection,
        )
    : undefined;
  const parentId = reselectSameEntity
    ? entity?.parentEntityId
    : nextEntityKey && entitiesInfoMap[nextEntityKey]?.parentEntityId;
  const parentType = reselectSameEntity
    ? entity?.parentEntityType
    : nextEntityKey && entitiesInfoMap[nextEntityKey]?.parentEntityType;

  // if the next entity happens to be a child entity then
  // select the parent entity to which that child belongs (opens table modal)
  if (parentId && parentType && showNewNestedUI) {
    store.dispatch(
      setSelectedParentEntityTypeAction({ id: parentId, type: parentType }),
    );
    store.dispatch(setSelectedEntityIdAction(null));
  } else {
    store.dispatch(setSelectedEntityIdAction(nextEntityKey));
  }
  // We do not use the return value of this function in actual FE code (We can use if need be, but not currently)
  // we are only retuning the value to facilitate proper testing of this function
  return nextEntityKey;
};

/**
 * Function to process the Date Entity Type
 */
export const processDateEntity = ({
  entityInfo,
  originalNormalizedValue,
  text,
  originalText,
}: {
  entityInfo: EntityInfo;
  originalNormalizedValue?: DocumentEntityNormalizedValue;
  text: string;
  originalText?: string;
}) => {
  // Check if the normalized value is already present
  // If not then create a new normalized value
  if (!entityInfo.normalizedValue) {
    entityInfo.normalizedValue = DocumentEntityNormalizedValue.create({});
    entityInfo.normalizedValue.dateValue = DateMessage.create({});
  }
  // If the text is same as original text then use the original normalized value
  if (text === originalText && entityInfo.normalizedValue?.dateValue) {
    entityInfo.normalizedValue.text = originalNormalizedValue?.text;
    entityInfo.normalizedValue.dateValue.year =
      originalNormalizedValue?.dateValue?.year;
    entityInfo.normalizedValue.dateValue.month =
      originalNormalizedValue?.dateValue?.month;
    entityInfo.normalizedValue.dateValue.day =
      originalNormalizedValue?.dateValue?.day;
  } else {
    // Else process the date and get the normalized date value
    const normalizedDateValue = processDate(text);
    if (normalizedDateValue && entityInfo.normalizedValue?.dateValue) {
      entityInfo.normalizedValue.text = normalizedDateValue.text;
      entityInfo.normalizedValue.dateValue.year =
        normalizedDateValue.dateValue.year;
      entityInfo.normalizedValue.dateValue.month =
        normalizedDateValue.dateValue.month;
      entityInfo.normalizedValue.dateValue.day =
        normalizedDateValue.dateValue.day;
      entityInfo.error = null;
    } else {
      entityInfo.normalizedValue = DocumentEntityNormalizedValue.create({});
      entityInfo.normalizedValue.dateValue = DateMessage.create({});
      entityInfo.isNormalizationFailed = !!text;
    }
  }
  entityInfo.normalizedInputValue = entityInfo.normalizedValue.text;
};

/**
 * Function to process the Money Entity Type
 */
export const processMoneyEntity = ({
  entityInfo,
  originalNormalizedValue,
  text,
  originalText,
}: {
  entityInfo: EntityInfo;
  originalNormalizedValue?: DocumentEntityNormalizedValue;
  text: string;
  originalText?: string;
}) => {
  // Check if the normalized value is already present
  // If not then create a new normalized value
  if (!entityInfo.normalizedValue) {
    entityInfo.normalizedValue = DocumentEntityNormalizedValue.create({});
    entityInfo.normalizedValue.moneyValue = Money.create({
      units: 0,
      nanos: 0,
      currencyCode: '',
    });
  }
  // If the text is same as original text then use the original normalized value
  if (text === originalText && entityInfo.normalizedValue.moneyValue) {
    entityInfo.normalizedValue.text = originalNormalizedValue?.text;
    entityInfo.normalizedValue.moneyValue.units =
      originalNormalizedValue?.moneyValue?.units;
    entityInfo.normalizedValue.moneyValue.nanos =
      originalNormalizedValue?.moneyValue?.nanos;
    entityInfo.normalizedValue.moneyValue.currencyCode =
      originalNormalizedValue?.moneyValue?.currencyCode;
  } else {
    // Else process the money and get the normalized money value
    // Also here we are replacing any . at the end of the text (REF - OA-2043)
    const normalizedMoneyValue = processCurrency(
      text.replace(/\.*$/, '').trim(),
    );
    if (normalizedMoneyValue && entityInfo.normalizedValue.moneyValue) {
      entityInfo.normalizedValue.text = normalizedMoneyValue.text;
      entityInfo.normalizedValue.moneyValue.units =
        normalizedMoneyValue.moneyValue.units;
      entityInfo.normalizedValue.moneyValue.nanos =
        normalizedMoneyValue.moneyValue.nanos;
      entityInfo.normalizedValue.moneyValue.currencyCode =
        normalizedMoneyValue.moneyValue.currencyCode;
      entityInfo.error = null;
    } else {
      entityInfo.normalizedValue = DocumentEntityNormalizedValue.create({});
      entityInfo.normalizedValue.moneyValue = Money.create({
        units: 0,
        nanos: 0,
        currencyCode: '',
      });
      entityInfo.isNormalizationFailed = !!text;
    }
  }
  entityInfo.normalizedInputValue = text
    ? moneyToText(
        entityInfo.normalizedValue.moneyValue.units ?? 0,
        entityInfo.normalizedValue.moneyValue.nanos ?? 0,
      )
    : '';
};

/**
 * Function to process the Integer Entity Type
 */
export const processIntegerEntity = ({
  entityInfo,
  originalNormalizedValue,
  text,
  originalText,
}: {
  entityInfo: EntityInfo;
  originalNormalizedValue?: DocumentEntityNormalizedValue;
  text: string;
  originalText?: string;
}) => {
  // Check if the normalized value is already present
  // If not then create a new normalized value
  if (!entityInfo.normalizedValue) {
    entityInfo.normalizedValue = DocumentEntityNormalizedValue.create({
      integerValue: 0,
    });
  }
  // If the text is same as original text then use the original normalized value
  if (text === originalText) {
    entityInfo.normalizedValue.integerValue =
      originalNormalizedValue?.integerValue;
  } else {
    // Else process the number and get the normalized integer value
    const normalizedIntegerValue = processNumber(text) as {
      integerValue: number;
    };
    if (normalizedIntegerValue && normalizedIntegerValue.integerValue) {
      entityInfo.normalizedValue.integerValue =
        normalizedIntegerValue?.integerValue;
      entityInfo.error = null;
    } else {
      entityInfo.normalizedValue = DocumentEntityNormalizedValue.create({
        integerValue: 0,
      });
      entityInfo.isNormalizationFailed = !!text;
    }
  }
  entityInfo.normalizedInputValue = text
    ? entityInfo.normalizedValue.integerValue?.toString() ?? ''
    : '';
};

/**
 * Function to process the Float Entity Type
 */
export const processFloatEntity = ({
  entityInfo,
  originalNormalizedValue,
  text,
  originalText,
}: {
  entityInfo: EntityInfo;
  originalNormalizedValue?: DocumentEntityNormalizedValue;
  text: string;
  originalText?: string;
}) => {
  // Check if the normalized value is already present
  // If not then create a new normalized value
  if (!entityInfo.normalizedValue) {
    entityInfo.normalizedValue = DocumentEntityNormalizedValue.create({
      floatValue: 0,
    });
  }
  // If the text is same as original text then use the original normalized value
  if (text === originalText) {
    entityInfo.normalizedValue.floatValue = originalNormalizedValue?.floatValue;
  } else {
    // Else process the number and get the normalized float value
    const normalizedFloatValue = processNumber(text) as { floatValue: number };
    if (normalizedFloatValue && normalizedFloatValue.floatValue) {
      entityInfo.normalizedValue.floatValue = normalizedFloatValue.floatValue;
      entityInfo.error = null;
    } else {
      entityInfo.normalizedValue = DocumentEntityNormalizedValue.create({
        floatValue: 0,
      });
      entityInfo.isNormalizationFailed = !!text;
    }
  }
  entityInfo.normalizedInputValue = text
    ? entityInfo.normalizedValue.floatValue?.toString() ?? ''
    : '';
};

/**
 * Function to process the Text Entity Type
 */
export const processTextEntity = ({
  entityInfo,
  originalNormalizedValue,
  text,
  originalText,
}: {
  entityInfo: EntityInfo;
  originalNormalizedValue?: DocumentEntityNormalizedValue;
  text: string;
  originalText?: string;
}) => {
  // Check if the normalized value is already present
  // If not then create a new normalized value
  if (!entityInfo.normalizedValue) {
    entityInfo.normalizedValue = {} as DocumentEntityNormalizedValue;
  }
  // If the text is same as original text then use the original normalized value
  if (text === originalText) {
    entityInfo.normalizedValue.text = originalNormalizedValue?.text;
  } else {
    // Else process the number and get the normalized text value
    const normalizedTextValue = processText(text);
    entityInfo.normalizedValue.text = normalizedTextValue;
  }
  entityInfo.normalizedInputValue = entityInfo.normalizedValue.text ?? '';
};

/**
 * The below function is used to get the next available text segment id
 * when we add a new text segment using the floating modal
 * @param {{ [id: string]: TextSegmentInfo }} textSegments
 * @returns
 */
export const getNewTextSegmentId = () => {
  return `${uniqueId()}`;
};

/**
 * Inserts a copied entity below the selected entity in the left sidebar ordering.
 *
 * @param {{ [x: string]: EntityInfo }} entityInfoMap - The ordering object on the left sidebar.
 * @param {string} selectedEntityId - The identifier of the selected entity.
 * @param {string} entityCopyId - The identifier of the copied entity.
 * @param {EntityInfo} copiedEntity - The copied entity object to insert.
 * @returns {{ [x: string]: EntityInfo }} - A new ordering object with the copied entity inserted below the selected entity.
 */
export const insertEntityBelowSelectedEntity = (
  entityInfoMap: { [x: string]: EntityInfo },
  selectedEntityId: string,
  entityCopyId: string,
  copiedEntity: EntityInfo,
) => {
  const entityInfoObject: { [x: string]: EntityInfo } = {};
  for (const key in entityInfoMap) {
    entityInfoObject[key] = entityInfoMap[key];
    if (key === selectedEntityId) {
      entityInfoObject[entityCopyId] = copiedEntity;
    }
  }
  return entityInfoObject;
};

/**
 * This functions returns the entities that needs attention by the reviewer
 * The entities which have not been reviewed and ( are not in the document or have a low confidence score )
 * will come under this category
 * @param {EntityInfo} entity
 * @returns
 */
export const isEntityNeedAttention = (entity: EntityInfo) => {
  return (
    !entity.isReviewed &&
    (!entity.isInDoc ||
      (entity.isInDoc &&
        (entity.confidenceScore ?? 0) < entity.needAttentionConfidenceScore))
  );
};

/**
 * This functions returns the entities that have been reviewed by the reviewer
 * @param {EntityInfo} entity
 * @returns
 */
export const isEntityReviewed = (entity: EntityInfo) => {
  return entity.isReviewed;
};

/**
 * This functions returns the entities that have been predicted by the ML
 * The entities which have not been reviewed and are in doc and have confidence score greater than or equal to 85%
 * @param {EntityInfo} entity
 * @returns
 */
export const isEntityPredicted = (entity: EntityInfo) => {
  return (
    !entity.isReviewed &&
    entity.isInDoc &&
    (entity?.confidenceScore ?? 0) >= entity.needAttentionConfidenceScore
  );
};

/**
 * Get the label for floating modal
 * @param {EntityInfo} entity
 * @returns
 */
export const getFloatingModalLabel = (entity: EntityInfo) => {
  if (
    !entity.isInDoc ||
    !Object.values(entity.textSegments)?.[0]?.vertices?.length
  ) {
    return 'Not found';
  }
  return ''; // If Entity is found return empty string as an indicator not to show label
};

/**
 * This functions returns the id of the first entity in the need attention entities list
 */
export const getFirstNeedAttentionEntityId = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  const needAttentionEntities = Object.values(entityInfo).filter(
    (entityDetail) => {
      return isEntityNeedAttention(entityDetail);
    },
  );
  return needAttentionEntities.length ? needAttentionEntities[0].id : null;
};

/**
 * This functions returns the id of the first entity in the predicted entities list
 */
export const getFirstPredictedEntityId = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  const predictedEntities = Object.values(entityInfo).filter((entityDetail) => {
    return isEntityPredicted(entityDetail);
  });
  return predictedEntities.length ? predictedEntities[0].id : null;
};

/**
 * This functions returns the id of the last entity in the need attention entities list
 */
export const getLastNeedAttentionEntityId = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  const needAttentionEntities = Object.values(entityInfo).filter(
    (entityDetail) => {
      return isEntityNeedAttention(entityDetail);
    },
  );
  return needAttentionEntities.length
    ? needAttentionEntities[needAttentionEntities.length - 1].id
    : null;
};

/**
 * This functions returns the id of the last entity in the predicted entities list
 */
export const getLastPredictedEntityId = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  const predictedEntities = Object.values(entityInfo).filter((entityDetail) => {
    return isEntityPredicted(entityDetail);
  });
  return predictedEntities.length
    ? predictedEntities[predictedEntities.length - 1].id
    : null;
};

/**
 * This functions returns the id of the last entity in the predicted entities list
 */
export const getLastReviewedEntityId = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  const reviewedEntities = Object.values(entityInfo).filter((entityDetail) => {
    return isEntityReviewed(entityDetail);
  });
  return reviewedEntities.length
    ? reviewedEntities[reviewedEntities.length - 1].id
    : null;
};

/**
 * This function returns the count for entities which needs attention
 */
export const getNeedAttentionEntitiesCount = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  return Object.values(entityInfo).filter((entityDetail) => {
    return isEntityNeedAttention(entityDetail);
  }).length;
};

/**
 * This function returns the count for entities which are predicted
 */
export const getPredictedEntitiesCount = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  return Object.values(entityInfo).filter((entityDetail) => {
    return isEntityPredicted(entityDetail);
  }).length;
};

/**
 * This function returns the count for entities which are reviewed
 */
export const getReviewedEntitiesCount = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  return Object.values(entityInfo).filter((entityDetail) => {
    return isEntityReviewed(entityDetail);
  }).length;
};

/**
 * Function to find the center of the screen to show a bounding box at that position
 * This is used when text segment info is not available and user adds a new text segment.
 *
 * @param {number} scale
 * @param {Task} task
 */
export const getCenterBoundingBoxVertices = (scale: number, task: Task) => {
  // COMPLETE COMPONENT THAT CONTAINS ALL THE PAGES
  const elementWrapper = document.getElementById(
    'pdf-panel-wrapper',
  ) as HTMLElement;
  // TO SHOW EXACTLY IN CENTER WE NEED HALF OF SCREEN HEIGHT
  const halfViewportHeight =
    window.innerHeight / scale / 2 -
    (REVIEW_PAGE_TOP_BAR_HEIGHT + REVIEW_PAGE_TOP_MARGIN);
  // HOW MUCH THE USER HAS SCROLLED PDF
  const elemScrollTop = elementWrapper.scrollTop / scale;
  const elemScrollLeft = elementWrapper.scrollLeft / scale;
  // WIDTH OF THE PDF
  const elemWidth = elementWrapper.clientWidth / scale;
  // DEFAULT BOX HEIGHT AND WIDTH
  const boxWidth = DEFAULT_BOX_WIDTH;
  const boxHeight = DEFAULT_BOX_HEIGHT;

  const top = elemScrollTop + halfViewportHeight;
  const left = elemScrollLeft + (elemWidth / 2 - boxWidth / 2);

  const vertices = [
    { x: left, y: top },
    { x: left + boxWidth, y: top },
    { x: left + boxWidth, y: top + boxHeight },
    { x: left, y: top + boxHeight },
  ];

  const selectedTaskDocument = getSelectedTaskDocument(task)?.documents?.[0];
  const pdfBoxHeight = selectedTaskDocument?.pages?.[0]?.image?.height;
  // total padding will be CURRENT PADDING / scale
  const padding = PADDING_BW_PDF_PAGES / scale;
  const pageCorrespondingPdfHeight =
    ((pdfBoxHeight as number) + padding) * (getDefaultPDFPage(scale, task) + 1);

  // To get the vertices according to the page number on which it lies
  return vertices.map((v) => {
    const ver = v as Vertex;
    // Ensure the box doesn't span across two pages
    if (
      vertices[0].y < pageCorrespondingPdfHeight &&
      vertices[2].y > pageCorrespondingPdfHeight
    ) {
      (ver.y as number) -= boxHeight;
    }
    // Adjust vertices to fit within the page height
    (ver.y as number) %= (pdfBoxHeight as number) + padding;
    return ver;
  });
};

export const getDefaultPDFPage = (scale: number, task: Task) => {
  // Complete component that contains all the pages
  const elementWrapper = document.getElementById(
    'pdf-panel-wrapper',
  ) as HTMLElement;
  // To show exactly in center we need half of screen height
  const halfViewportHeight =
    window.innerHeight / scale / 2 -
    (REVIEW_PAGE_TOP_BAR_HEIGHT + REVIEW_PAGE_TOP_MARGIN);
  // How much the user has scrolled PDF
  const elemScrollTop = elementWrapper.scrollTop / scale;
  // Top position of the bounding box
  const boxTop = elemScrollTop + halfViewportHeight;

  const selectedTaskDocument = getSelectedTaskDocument(task)?.documents?.[0];
  const pdfBoxHeight = selectedTaskDocument?.pages?.[0].image?.height;
  // Total padding will be CURRENT PADDING / scale
  const padding = PADDING_BW_PDF_PAGES / scale;
  return Math.floor(boxTop / ((pdfBoxHeight as number) + padding));
};

// Function to check whether any segment text contains empty text
export const isTextSegmentEmpty = (textSegment?: TextSegmentInfo) => {
  if (!textSegment || !textSegment.text || textSegment.text.trim() === '') {
    return true;
  }
  return false;
};

/**
 * TODO: Can we removed once the migration has been done on the backend
 * This function is used to convert entityTypeSchemaMapping to entityDetails
 * This is done to provide a fallback till the migration on the backend is not done
 * So the old tasks can still work with the new changes
 * @param {{ [id: string]: EntityTypeSchema }} entityTypeSchemaMapping
 * @returns
 */
export const getEntityDetailsFromEntityTypeSchemaMapping = ({
  entities,
  entityTypeSchemaMapping,
}: {
  entities: string[];
  entityTypeSchemaMapping: Map<string, EntityTypeSchema>;
}) => {
  // Array to store the resulting entityDetails
  const entityDetails: EntityDetails[] = [];
  // Iterating through each entity in the input array
  entities.forEach((entity) => {
    // Destructuring the result of splitting the entity string into parent and child
    const [parent, child] = entity.split('/');
    // Finding an existing parent entity in the entityDetails array
    const parentEntity = entityDetails.find((e) => e.entityType === parent);
    // Checking if there is no existing parent entity
    if (!parentEntity) {
      // Creating a new entity object for the parent or entity without a parent
      // if parent, setting normalizationType to UNSPECIFIED
      const newEntity: EntityDetails = {
        entityType: parent || entity,
        normalizationType: parent
          ? EntityDataType.ENTITY_TYPE_NESTED
          : entityTypeSchemaMapping?.get(entity)?.normalizationType,
        properties: [],
      };
      // Checking if there is a child entity
      if (child) {
        // Adding a child entity to the properties array of the new entity
        newEntity?.properties?.push({
          entityType: child,
          normalizationType:
            entityTypeSchemaMapping?.get(entity)?.normalizationType,
          properties: [],
        });
      }
      // Adding the new entity to the entityDetails array
      entityDetails.push(newEntity);
    } else if (child) {
      // If there is an existing parent entity, adding the child entity to its properties array
      parentEntity?.properties?.push({
        entityType: child,
        normalizationType:
          entityTypeSchemaMapping?.get(entity)?.normalizationType,
        properties: [],
      });
    }
  });
  // Returning the resulting entityDetails array
  return entityDetails;
};

export const processNormalizedValue = (
  entityInfo: EntityInfo,
  text: string,
  originalNormalizedValue?: DocumentEntityNormalizedValue,
  originalText?: string,
) => {
  const data = {
    entityInfo: entityInfo,
    originalNormalizedValue,
    text,
    originalText,
  };

  switch (entityInfo.normalizedEntityType) {
    case EntityDataType.ENTITY_TYPE_MONEY:
      processMoneyEntity(data);
      break;
    case EntityDataType.ENTITY_TYPE_DATE:
      processDateEntity(data);
      break;
    case EntityDataType.ENTITY_TYPE_INTEGER:
      processIntegerEntity(data);
      break;
    case EntityDataType.ENTITY_TYPE_FLOAT:
      processFloatEntity(data);
      break;
    case EntityDataType.ENTITY_TYPE_TEXT:
      processTextEntity(data);
      break;
  }
};

export const getTitleForTableModal = (
  selectedParentEntityInfo: SelectedParentEntity,
  selectedEntityIdsForAnnotation: string[],
  selectedTableEntitiesInfoMap: Record<string, EntityInfo>,
) => {
  // If no cell are selected we show initial title (parent entity name)
  if (!selectedEntityIdsForAnnotation?.length) {
    return selectedParentEntityInfo?.type;
  }
  const selectedEntity =
    selectedTableEntitiesInfoMap[selectedEntityIdsForAnnotation[0]];
  if (selectedEntityIdsForAnnotation.length === 1) {
    // If selected cell text is empty we show column name otherwise selected cell text
    return selectedEntity?.entityText || selectedEntity?.type;
  }

  // if more than one entity is selected we return column name
  if (selectedEntityIdsForAnnotation.length > 1) {
    return selectedEntity?.type;
  }
};

export const getRowOrderInfoForTableEntities = (
  selectedTableEntitiesInfo: EntityInfo[],
  selectedParentEntityInfoType: string | undefined,
) => {
  const parentIds: Set<string> = new Set();
  selectedTableEntitiesInfo?.forEach((e) => {
    if (
      e.parentEntityType === selectedParentEntityInfoType &&
      !parentIds.has(e.parentEntityId || '')
    ) {
      parentIds.add(e.parentEntityId || '');
    }
  });
  return [...parentIds];
};

export const getBackgroundColorForTableRows = (entity: EntityInfo) => {
  if (entity.isReviewed) {
    return '#F6FEF9';
  } else if (isEntityPredicted(entity)) {
    return 'rgb(246, 248, 252)';
  }
  return '#FCF6F7';
};

export const checkIfMultipleCellsSelected = (
  selectedEntityIdsForAnnotation: string[],
  selectedTableEntitiesInfo: Record<string, EntityInfo>,
) => {
  const isNotesEntitySelected = selectedEntityIdsForAnnotation.some((id) => {
    const entity = selectedTableEntitiesInfo[id];
    return entity && entity.isExtra;
  });
  return selectedEntityIdsForAnnotation.length > 1 && !isNotesEntitySelected;
};

export const formatIdleSessionsForBackend = (
  idleSessions: TimeRange[],
): IdleSession[] => {
  const validSessions: IdleSession[] = [];

  idleSessions.forEach(({ start, end }) => {
    // Log an error to Sentry if either the start or end time is missing.
    // This check is expected to rarely trigger, but if it does, it will handle the error
    // gracefully and log it to Sentry for developers to address.
    if (!start || !end) {
      sentryService.error('Start or End Time not found', { idleSessions });
    } else {
      const startTime = new Date(start);
      const duration = end - start; // duration in ms
      validSessions.push(
        IdleSession.create({
          startTime: startTime,
          durationMsec: duration,
        }),
      );
    }
  });

  return validSessions;
};
