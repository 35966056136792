import React, { FC, memo } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import '../../styles/global.css';
import OrbyTypography from '../typography/OrbyTypography';
import {
  getBadgeFontSize,
  getBadgePaddingX,
  getBadgePaddingY,
} from './badge-utils';

export interface OrbyBadgeProps {
  /**
   * The name of the badge
   */
  badgeName?: string;

  /**
   * Set the start icon on the button
   */
  startIcon?: any;

  /**
   * Set the start icon on the button
   */
  endIcon?: any;

  /**
   * The size of the button
   * Defines the height
   */
  size: 'small' | 'medium' | 'large';

  /**
   * Badge Text Color
   */
  textColor: string;

  /**
   * Badge Background Color
   */
  backgroundColor: string;

  /**
   * Box sx
   */
  boxSx?: SxProps<Theme>;

  /**
   * Typography sx
   */
  textSx?: SxProps<Theme>;
}

const OrbyBadge: FC<OrbyBadgeProps> = (props) => {
  const {
    badgeName,
    startIcon,
    endIcon,
    size,
    textColor,
    backgroundColor,
    boxSx,
    textSx,
  } = props;

  return (
    <Box
      title={badgeName}
      display={'flex'}
      gap={'4px'}
      alignItems={'center'}
      sx={{
        paddingX: getBadgePaddingX(size),
        paddingY: getBadgePaddingY(size),
        backgroundColor: backgroundColor,
        borderRadius: '16px',
        width: 'max-content',
        ...boxSx,
      }}
    >
      {startIcon}
      {badgeName && (
        <OrbyTypography
          size={getBadgeFontSize(size)}
          weight='medium'
          color={textColor}
          sx={{
            textWrap: 'nowrap',
            ...textSx,
          }}
        >
          {badgeName}
        </OrbyTypography>
      )}
      {endIcon}
    </Box>
  );
};

export default memo(OrbyBadge);
