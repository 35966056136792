import { SuggestionActionType } from './actions.constants';
import {
  BatchUpdateSuggestionsRequest,
  ListSuggestionsRequest,
  SummarizeSuggestionsRequest,
  SummarizeSuggestionsResponse,
} from 'protos/pb/v1alpha1/suggestions_service';
import { Suggestion } from 'protos/pb/v1alpha1/suggestion';
import { DocumentEntity } from 'protos/google/cloud/documentai/v1/document';
import { SuggestionStepOption } from 'protos/pb/v1alpha1/suggestion_step';
export const listReadySuggestionAction = (
  req: ListSuggestionsRequest,
  fetchRegularly = false,
  fetchOnStart = true,
) => ({
  type: SuggestionActionType.LIST_READY_SUGGESTIONS,
  payload: req,
  fetchRegularly,
  fetchOnStart,
});

export const listReadySuggestionCompletedAction = (
  suggestions: Suggestion[],
  nextPageToken: string,
  totalSize: number,
) => ({
  type: SuggestionActionType.LIST_READY_SUGGESTIONS_COMPLETED,
  payload: suggestions,
  nextPageToken,
  totalSize,
});

export const listReadySuggestionErrorAction = (error: string) => ({
  type: SuggestionActionType.LIST_READY_SUGGESTIONS_ERROR,
  payload: error,
});

export const listCompletedSuggestionAction = (req: ListSuggestionsRequest) => ({
  type: SuggestionActionType.LIST_COMPLETED_SUGGESTIONS,
  payload: req,
});

export const listCompletedSuggestionCompletedAction = (
  suggestions: Suggestion[],
  nextPageToken: string,
  totalSize: number,
) => ({
  type: SuggestionActionType.LIST_COMPLETED_SUGGESTIONS_COMPLETED,
  payload: suggestions,
  nextPageToken,
  totalSize,
});

export const listCompletedSuggestionErrorAction = (error: string) => ({
  type: SuggestionActionType.LIST_COMPLETED_SUGGESTIONS_ERROR,
  payload: error,
});

export const setSelectedSuggestionAction = (suggestion: Suggestion) => ({
  type: SuggestionActionType.SET_SELECTED_SUGGESTION,
  payload: suggestion,
});

export const updateSuggestionAction = (
  suggestionName: string,
  data: unknown,
  params?: unknown,
) => ({
  type: SuggestionActionType.UPDATE_SUGGESTION,
  suggestionName,
  payload: data,
  params,
});

export const updateSuggestionCompletedAction = (suggestion: Suggestion) => ({
  type: SuggestionActionType.UPDATE_SUGGESTION_COMPLETED,
  payload: suggestion,
});

export const updateSuggestionErrorAction = (error: string) => ({
  type: SuggestionActionType.UPDATE_SUGGESTION_ERROR,
  payload: error,
});

export const batchUpdateSuggestionAction = (
  req: BatchUpdateSuggestionsRequest,
) => ({
  type: SuggestionActionType.BATCH_UPDATE_SUGGESTION,
  payload: req,
});

export const batchUpdateSuggestionCompletedAction = (
  suggestions: Suggestion[],
) => ({
  type: SuggestionActionType.BATCH_UPDATE_SUGGESTION_COMPLETED,
  payload: suggestions,
});

export const batchUpdateSuggestionErrorAction = (error: string) => ({
  type: SuggestionActionType.BATCH_UPDATE_SUGGESTION_ERROR,
  payload: error,
});

export const getSuggestionSummaryAction = (
  req: SummarizeSuggestionsRequest,
) => ({
  type: SuggestionActionType.GET_SUGGESTION_SUMMARY,
  payload: req,
});

export const getSuggestionSummaryCompletedAction = (
  summary: SummarizeSuggestionsResponse,
) => ({
  type: SuggestionActionType.GET_SUGGESTION_SUMMARY_COMPLETED,
  payload: summary,
});

export const getSuggestionSummaryErrorAction = (error: string) => ({
  type: SuggestionActionType.GET_SUGGESTION_SUMMARY_ERROR,
  payload: error,
});

export const getNextStepAction = (suggestion: Suggestion) => ({
  type: SuggestionActionType.GET_NEXT_STEP,
  suggestion: suggestion,
});

export const getNextStepCompletedAction = (suggestion: Suggestion) => ({
  type: SuggestionActionType.GET_NEXT_STEP_COMPLETED,
  suggestion: suggestion,
});

export const getNextStepErrorAction = (error: string) => ({
  type: SuggestionActionType.GET_NEXT_STEP_ERROR,
  payload: error,
});

export const getSuggestionByNameAction = (
  suggestionName: string,
  parent: string,
  loadList = false,
) => ({
  type: SuggestionActionType.GET_SUGGESTION_BY_NAME,
  suggestionName: suggestionName,
  parent: parent,
  loadList,
});

export const getSuggestionByNameCompletedAction = (suggestion: Suggestion) => ({
  type: SuggestionActionType.GET_SUGGESTION_BY_NAME_COMPLETED,
  payload: suggestion,
});

export const getSuggestionByNameErrorAction = (error: string) => ({
  type: SuggestionActionType.GET_SUGGESTION_BY_NAME_ERROR,
  payload: error,
});

export const localSetSuggestionByNameAction = (suggestion: Suggestion) => ({
  type: SuggestionActionType.LOCAL_SET_SUGGESTION_BY_NAME,
  payload: suggestion,
});

export const executeSuggestionStepAction = (suggestion: Suggestion) => ({
  type: SuggestionActionType.EXECUTE_SUGGESTION_STEP,
  payload: suggestion,
});

export const executeSuggestionStepCompletedAction = () => ({
  type: SuggestionActionType.EXECUTE_SUGGESTION_STEP_COMPLETED,
});

export const executeSuggestionStepErrorAction = (error: string) => ({
  type: SuggestionActionType.EXECUTE_SUGGESTION_STEP_ERROR,
  payload: error,
});

export const editDocumentEntitiesAction = (
  entity: DocumentEntity,
  suggestionName: string,
) => ({
  type: SuggestionActionType.EDIT_DOCUMENT_ENTITIES,
  payload: entity,
  suggestionName,
});

export const deleteDocumentEntitiesAction = (
  entity: DocumentEntity,
  suggestionName: string,
) => ({
  type: SuggestionActionType.DELETE_DOCUMENT_ENTITIES,
  payload: entity,
  suggestionName,
});

export const copyDocumentEntitiesAction = (
  entity: DocumentEntity,
  suggestionName: string,
) => ({
  type: SuggestionActionType.COPY_DOCUMENT_ENTITIES,
  payload: entity,
  suggestionName,
});

export const editSheetDataAction = (
  suggestionStepOption: SuggestionStepOption,
  suggestionName: string,
) => ({
  type: SuggestionActionType.EDIT_SHEET_DATA,
  payload: suggestionStepOption,
  suggestionName,
});

export const setSelectedStatusAction = (type?: string) => ({
  type: SuggestionActionType.SET_SELECTED_STATUS,
  payload: type,
});
