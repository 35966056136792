export class ColorValuesUtil {
  private static instance: ColorValuesUtil;

  public static getInstance(): ColorValuesUtil {
    if (!this.instance) {
      this.instance = new ColorValuesUtil();
    }
    return this.instance;
  }

  hashCode(str: string) {
    // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i: number) {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();

    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }

  getColorList(strs: string[]) {
    return strs.map((str) => {
      return this.intToRGB(this.hashCode(str));
    });
  }

  getColor(str: string) {
    return this.intToRGB(this.hashCode(str));
  }
}

export const colorValuesUtil = ColorValuesUtil.getInstance();
