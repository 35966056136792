import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import './base.css';
import AuthPage from '../AuthPage';

import {
  fetchingLoggedInUserSelector,
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';
import { fetchLoggedInUserAction } from '../../redux/actions/user.action';
import { storageService, Token } from '../../services/StorageService';
import { Box, CircularProgress } from '@mui/material';
import PolicyTermsPage from '../../pages/PolicyTermsPage';
import { sendTokenToExtension } from '../../utils/extension';
import { isEmptyTokenAsPerCookieFeatureFlag } from '../../utils/Auth';
import { getFeatureFlagsForOrgAndUserLoadingSelector } from '../../redux/selectors/feature_flags.selectors';
import { useFetchFeatureFlagsForOrgAndUser } from '../../hooks/useFetchFeatureFlagsForOrgAndUser';
import { updateFeatureFlagsLoaderForOrgAndUserErrorAction } from '../../redux/actions/feature_flags.action';
import { useFetchOrbotWorkflows } from '../../hooks/useFetchOrbotWorkflows';
import { unauthenticatedErrorSelector } from '../../redux/selectors/auth.selectors';
import useLogoutHandler from '../../hooks/useLogoutHandler';
import useIsFeatureEnabled from '../../hooks/useIsFeatureEnabled';
import { FEATURE_FLAGS } from '../../utils/constants';
import ProtectedRoutes from '../ProtectedRoutes/ProtectedRoutes';
import { APP_ROUTES } from '../ProtectedRoutes/Routes';

const OptionsApp: React.FC = () => {
  const dispatch = useDispatch();
  const [loadingToken, setLoadingToken] = useState(false);
  const [didComponentMount, setDidComponentMount] = useState(false);
  const loading: boolean = useSelector(fetchingLoggedInUserSelector);
  const unauthError = useSelector(unauthenticatedErrorSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const user = useSelector(loggedInUserSelector);
  const featureFlagsLoading = useSelector(
    getFeatureFlagsForOrgAndUserLoadingSelector,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { handleLogout } = useLogoutHandler();

  useFetchFeatureFlagsForOrgAndUser(selectedOrgInfo?.orgResourceName);
  const { orbotWorkflowLength, orbotWorkflowsLoading } = useFetchOrbotWorkflows(
    selectedOrgInfo?.orgResourceName,
  );

  useEffect(() => {
    dispatch(updateFeatureFlagsLoaderForOrgAndUserErrorAction(true));
    loadToken();
  }, [user, unauthError]);

  useEffect(() => {
    setDidComponentMount(true);
  }, []);

  const isLoginCookieEnabled = useIsFeatureEnabled(FEATURE_FLAGS.COOKIE);

  const loadToken = async () => {
    setLoadingToken(true);
    const token: Token | undefined = await storageService.getStoredToken();
    const isTokenEmpty = isEmptyTokenAsPerCookieFeatureFlag(
      token,
      isLoginCookieEnabled,
    );

    // We intend to call the Get User API
    // This is so because in the first render cycle we will have user as undefined and
    // also the isTokenEmpty comes out to be false as it depends on the user's feature flag
    // So, if the API returns an error, we fetch that from unauthError and logout the user
    if (!didComponentMount || (user === undefined && !isTokenEmpty)) {
      dispatch(fetchLoggedInUserAction());
    } else if (user === undefined || unauthError) {
      dispatch(updateFeatureFlagsLoaderForOrgAndUserErrorAction(false));
      const currentPath = window.location.pathname;
      if (currentPath && !currentPath.includes('/login')) {
        navigate('/login?redirectUrl=' + currentPath);
      } else if (!currentPath.includes('/login')) {
        navigate('/login');
      }
    } else if (
      !isTokenEmpty &&
      location.pathname === '/login' &&
      location.search === '?redirectUrl=extension'
    ) {
      // if user is already authenticated, we would normally be redirected to the
      // dashboard page. However, if this is a login request coming from our Chrome
      // extension, we need to send the token to our the extension. Our extension
      // would navigate to the extension's dashboard after receiving the token.

      sendTokenToExtension(!isLoginCookieEnabled ? token : undefined);
    }
    setLoadingToken(false);
  };

  if (loadingToken || loading || featureFlagsLoading || orbotWorkflowsLoading)
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100%'}
      >
        <CircularProgress />
      </Box>
    );

  if (localStorage.getItem('reauthenticate')) {
    handleLogout();
    return;
  }

  return (
    <>
      {user && selectedOrgInfo ? (
        <Routes>
          <Route
            path='/login'
            element={<Navigate to={APP_ROUTES.DASHBOARD} />}
          />
          <Route
            path='*'
            element={
              user.prerequisite?.policiesToReview ? (
                <PolicyTermsPage />
              ) : (
                <ProtectedRoutes orbotWorkflowLength={orbotWorkflowLength} />
              )
            }
          />
        </Routes>
      ) : (
        <Routes>
          <Route path='*' element={<AuthPage />} />
        </Routes>
      )}
    </>
  );
};

export default OptionsApp;
