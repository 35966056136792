import { AutomationMiningClientImpl } from 'protos/pb/v1alpha1/automation_mining_service';
import { storageService } from './StorageService';
import { GenerateActivityGraphRequest } from 'protos/pb/v1alpha1/automation_mining_service';
import { rpcWithErrorHandling } from '../utils/RpcUtills';
class AutomationMiningService {
  private static instance: AutomationMiningService;
  private static client = new AutomationMiningClientImpl(rpcWithErrorHandling);
  public static getInstance(): AutomationMiningService {
    if (!this.instance) {
      this.instance = new AutomationMiningService();
    }
    return this.instance;
  }

  async generateActivityGraph(
    req: GenerateActivityGraphRequest,
  ): Promise<{ activityGraph: string; mimeType: string }> {
    const authorization = await storageService.getAuthorizationHeader();
    return new Promise<{ activityGraph: string; mimeType: string }>(
      (resolve, reject) => {
        AutomationMiningService.client
          .GenerateActivityGraph(req, { authorization } as any)
          .then((response: any) => response.toObject())
          .catch((error: any) => reject(error));
      },
    );
  }
}

export const automationMiningService = AutomationMiningService.getInstance();
