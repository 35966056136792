import { DocumentPageToken } from 'protos/google/cloud/documentai/v1/document';
import { Vertex } from 'protos/google/cloud/documentai/v1/geometry';
import { TextSegmentInfo } from '../redux/reducers/review_task.reducer';
import { PADDING_BW_PDF_PAGES } from './constants';

export interface BoxPositionValuesV2 {
  top: number;
  left: number;
  width: number;
  height: number;
}

export class BoxPositionValuesUtilV2 {
  private static instance: BoxPositionValuesUtilV2;

  public static getInstance(): BoxPositionValuesUtilV2 {
    if (!this.instance) {
      this.instance = new BoxPositionValuesUtilV2();
    }
    return this.instance;
  }

  getTopLeftCornerVertices(vertices: Vertex[]): Vertex {
    if (!vertices?.length) return Vertex.create({ x: 0, y: 0 });

    let leftX: number | undefined;
    let topY: number | undefined;
    vertices.forEach((v) => {
      if (leftX === undefined || (v.x as number) < leftX) leftX = v.x;
      if (topY === undefined || (v.y as number) < topY) topY = v.y;
    });
    return Vertex.create({ x: leftX, y: topY });
  }

  getTopRightCornerVertices(vertices: Vertex[]): Vertex {
    if (!vertices?.length) return Vertex.create({ x: 0, y: 0 });

    let rightX: number | undefined;
    let topY: number | undefined;
    vertices.forEach((v) => {
      if (rightX === undefined || (v.x as number) > rightX) rightX = v.x;
      if (topY === undefined || (v.y as number) < topY) topY = v.y;
    });
    return Vertex.create({ x: rightX, y: topY });
  }

  getBottomRightCornerVertices(vertices: Vertex[]): Vertex {
    if (!vertices?.length) return Vertex.create({ x: 0, y: 0 });

    let rightX: number | undefined;
    let bottomY: number | undefined;
    vertices.forEach((v) => {
      if (rightX === undefined || (v.x as number) > rightX) rightX = v.x;
      if (bottomY === undefined || (v.y as number) > bottomY) bottomY = v.y;
    });
    return Vertex.create({ x: rightX, y: bottomY });
  }

  getBottomLeftCornerVertices(vertices: Vertex[]): Vertex {
    if (!vertices?.length) return Vertex.create({ x: 0, y: 0 });

    let leftX: number | undefined;
    let bottomY: number | undefined;
    vertices.forEach((v) => {
      if (leftX === undefined || (v.x as number) < leftX) leftX = v.x;
      if (bottomY === undefined || (v.y as number) > bottomY) bottomY = v.y;
    });
    return Vertex.create({ x: leftX, y: bottomY });
  }

  getDefaultBoxPosition() {
    return {
      top: -60,
      left: -130,
      width: 120,
      height: 50,
    };
  }

  getBoxPositionValues(
    vertices: Vertex[] | undefined,
    scale: number,
    topAdjustment?: number, // There might be some adjustement needed for the top position of any box which can be done using this param
    adjustBeforeScale?: boolean, // The adjustment may be done before/after the scale adjustement. This is to specify the case
  ): BoxPositionValuesV2 {
    if (!vertices || !vertices?.length) {
      return this.getDefaultBoxPosition();
    }

    const topLeft = boxPositionValuesUtilV2.getTopLeftCornerVertices(vertices);
    const topRight =
      boxPositionValuesUtilV2.getTopRightCornerVertices(vertices);
    const bottomRight =
      boxPositionValuesUtilV2.getBottomRightCornerVertices(vertices);

    return {
      top:
        ((topLeft?.y as number) -
          (adjustBeforeScale ? topAdjustment || 0 : 0)) *
          scale -
        (adjustBeforeScale ? 0 : topAdjustment || 0),
      left: (topLeft?.x as number) * scale,
      width: ((topRight?.x as number) - (topLeft?.x as number)) * scale,
      height: ((bottomRight?.y as number) - (topLeft?.y as number)) * scale,
    };
  }

  hasSameBoxPositionValues(
    boxPositionValues1: BoxPositionValuesV2,
    boxPositionValues2: BoxPositionValuesV2,
  ) {
    return (
      boxPositionValues1.top === boxPositionValues2.top &&
      boxPositionValues1.left === boxPositionValues2.left &&
      boxPositionValues1.width === boxPositionValues2.width &&
      boxPositionValues1.height === boxPositionValues2.height
    );
  }

  getAdjustedScaleVertices(vertices: Vertex[], scale: number): Vertex[] {
    return vertices.map((v) => {
      return Vertex.create({
        x: (v.x as number) * scale,
        y: (v.y as number) * scale,
      });
    });
  }

  isBoxContainingTheCenterOfAnotherBox(
    box1Vertices: Vertex[],
    box2Vertices: Vertex[],
  ) {
    const box1TopLeft = this.getTopLeftCornerVertices(box1Vertices);
    const box1TopRight = this.getTopRightCornerVertices(box1Vertices);
    const box1BottomLeft = this.getBottomLeftCornerVertices(box1Vertices);
    const box2TopLeft = this.getTopLeftCornerVertices(box2Vertices);
    const box2TopRight = this.getTopRightCornerVertices(box2Vertices);
    const box2BottomLeft = this.getBottomLeftCornerVertices(box2Vertices);
    const centerX =
      ((box2TopLeft.x as number) + (box2TopRight.x as number)) / 2;
    const centerY =
      ((box2TopLeft.y as number) + (box2BottomLeft.y as number)) / 2;

    const isInsideX =
      (box1TopLeft?.x as number) < centerX &&
      centerX < (box1TopRight?.x as number);
    const isInsideY =
      (box1TopLeft?.y as number) < centerY &&
      centerY < (box1BottomLeft?.y as number);

    return isInsideX && isInsideY;
  }

  // This will find the cell of the row whose value has to be added for entity
  // which lies in the same column of the table
  // Check if box1 covers the center x coordinate of box2
  // Use case: find columns related to box2 for table auto annotation
  isBox1CoverBox2CenterX(box1Vertices: Vertex[], box2Vertices: Vertex[]) {
    const box1TopLeft = this.getTopLeftCornerVertices(box1Vertices);
    const box1TopRight = this.getTopRightCornerVertices(box1Vertices);

    const box2TopLeft = this.getTopLeftCornerVertices(box2Vertices);
    const box2TopRight = this.getTopRightCornerVertices(box2Vertices);

    const box2CenterX =
      ((box2TopLeft.x as number) + (box2TopRight.x as number)) / 2;

    return (
      (box1TopLeft.x as number) < box2CenterX &&
      box2CenterX < (box1TopRight.x as number)
    );
  }

  getCollidingTokens = (tokens: DocumentPageToken[], vertices: Vertex[]) => {
    const collidingTokens: DocumentPageToken[] = [];
    tokens.map((t) => {
      const colliding =
        boxPositionValuesUtilV2.isBoxContainingTheCenterOfAnotherBox(
          vertices,
          t?.layout?.boundingPoly?.vertices as Vertex[],
        );
      if (colliding) {
        collidingTokens.push(t);
      }
    });
    return collidingTokens;
  };

  // THIS FUNCTION RETURNS THE ADJUSTED PADDING DIFFERENCE BETWEEN DIFFERENT SCALES
  // e.g. If we adjusted the bounding box when scale = 0.4, it calculates padding as PADDING / scale (0.4)
  // But If we increase the scale to say 0.6, it should reduce the padding accordingly, which does not happen automatically
  // For this we are manually reducing the difference of pixels by calculating using this function
  // Also if it is moved to a different page, it also multiplies it with the no of pages the bounding box is moved by
  getAdjustedPadding = (textSegmentInfo: TextSegmentInfo, scale: number) => {
    if (textSegmentInfo?.lastAdjustedPadding) {
      const pageDifference =
        textSegmentInfo.modifiedPage - textSegmentInfo.page;

      const newPaddingAsPerScale = PADDING_BW_PDF_PAGES / scale;

      // This is difference of padding that needs to be adjusted for each page
      // e.g if lastAdjustedPaddding was 20, and after changing the scale, new padding comes out to be 8
      // then the difference will be 20 - 8 = 12
      // if lastAdjustedPaddding was 8, and after changing the scale, new padding comes out to be 20
      // then difference will be 8 - 20 = -12, will be 0 if no zoom in/out
      const paddingDifference =
        textSegmentInfo.lastAdjustedPadding - newPaddingAsPerScale;

      return paddingDifference * pageDifference;
    }
    return 0;
  };

  /**
   * This function is used to get the vertices of a newly added segment which is positioned
   * relative to the previous segment that already exists.
   *
   * @param {Vertex[]} vertices - The vertices of the new segment.
   * @param {number} pageHeight - The height of the page.
   * @param {number} pageWidth - The width of the page.
   * @returns {Vertex[]} The updated vertices of the new segment.
   */
  getNewTextSegmentVertices(
    vertices: Vertex[],
    pageHeight: number,
    pageWidth: number,
  ): Vertex[] {
    const updatedVertices = vertices.map((v) => Vertex.create(v));

    const topLeft =
      boxPositionValuesUtilV2.getTopLeftCornerVertices(updatedVertices);
    const topRight =
      boxPositionValuesUtilV2.getTopRightCornerVertices(updatedVertices);
    const bottomRight =
      boxPositionValuesUtilV2.getBottomRightCornerVertices(updatedVertices);

    // THE DIFFERENCE IN BOXES THAT HAS TO BE ADDED/SUBTRACTED
    const widthToAdd = ((topRight.x as number) - (topLeft.x as number)) / 2;
    const heightToAdd = ((bottomRight.y as number) - (topLeft.y as number)) / 2;

    const newPageWidth = pageWidth - widthToAdd;
    const newPageHeight = pageHeight - heightToAdd;

    // CHECKING FOR CORNER CASES IF THE BOX IS ON THE CORNER/BOTTOM OF THE PAGE
    const bottomRightExceedingWidth = (bottomRight.x as number) > newPageWidth;
    const bottomRightExceedingHeight =
      (bottomRight.y as number) > newPageHeight;

    updatedVertices.forEach((ver) => {
      // If box is to extreme bottom with no space, then we subtract height otherwise add height
      if (bottomRightExceedingHeight) {
        (ver.y as number) -= heightToAdd;
      } else {
        (ver.y as number) += heightToAdd;
      }

      // If box is to extreme right with no space, then we subtract width otherwise add width
      if (bottomRightExceedingWidth) {
        (ver.x as number) -= widthToAdd;
      } else {
        (ver.x as number) += widthToAdd;
      }
    });

    return updatedVertices;
  }

  /**
   * Splits a bounding box into equal parts based on the number of split times.
   *
   * @param vertices - Array of Vertex objects representing the bounding box.
   * @param splitTimes - Number of times to split the bounding box vertically.
   * @returns Array of Vertex objects representing the new bounding boxes.
   */
  splitVerticesIntoEqualParts(
    vertices: Vertex[],
    splitTimes: number,
  ): Vertex[] {
    if (splitTimes <= 0) {
      return [
        Vertex.create({ x: 0, y: 0 }),
        Vertex.create({ x: 0, y: 0 }),
        Vertex.create({ x: 0, y: 0 }),
        Vertex.create({ x: 0, y: 0 }),
      ];
    }

    if (splitTimes === 1) return vertices;

    const topLeft = this.getTopLeftCornerVertices(vertices);
    const topRight = this.getTopRightCornerVertices(vertices);
    const bottomLeft = this.getBottomLeftCornerVertices(vertices);
    const bottomRight = this.getBottomRightCornerVertices(vertices);

    const height = (bottomLeft.y! - topLeft.y!) / splitTimes;

    const boxes: Vertex[] = [];

    for (let i = 0; i < splitTimes; i++) {
      // Calculate the new y-coordinates
      const newY1 = topLeft.y! + i * height;
      const newY2 = newY1 + height;
      // Create a new bounding box with the calculated coordinates
      const box = [
        Vertex.create({ x: topLeft.x, y: newY1 }),
        Vertex.create({ x: topRight.x, y: newY1 }),
        Vertex.create({ x: bottomRight.x, y: newY2 }),
        Vertex.create({ x: bottomLeft.x, y: newY2 }),
      ];
      boxes.push(...box);
    }
    return boxes;
  }

  addPaddingToBoxPositionValues(
    padding: number,
    boxPositionValues: BoxPositionValuesV2,
  ) {
    const top =
      boxPositionValues.top > padding ? boxPositionValues.top - padding : 0;
    const left =
      boxPositionValues.left > padding ? boxPositionValues.left - padding : 0;
    const width =
      boxPositionValues.left > padding
        ? boxPositionValues.width + padding * 2
        : boxPositionValues.width + boxPositionValues.left * 2;
    const height =
      boxPositionValues.top > padding
        ? boxPositionValues.height + padding * 2
        : boxPositionValues.height + boxPositionValues.top * 2;

    return {
      top,
      left,
      width,
      height,
    };
  }
}

export const boxPositionValuesUtilV2 = BoxPositionValuesUtilV2.getInstance();
