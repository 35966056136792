import { Box, SxProps, Theme } from '@mui/material';
import React, { memo } from 'react';
import OrbyTypography from '../../../typography/OrbyTypography';
import OrbyColorPalette from '../../../colors/ColorPalette';

interface OrbyHeaderLabelProps {
  label: string;
  maxWidth?: string;
  sx?: SxProps<Theme>;
}

const OrbyHeaderLabel: React.FC<OrbyHeaderLabelProps> = ({ label, sx }) => {
  return (
    <Box
      title={label}
      display={'flex'}
      alignItems={'center'}
      gap={1}
      sx={{ ...sx }}
    >
      <OrbyTypography
        size='xs'
        weight='medium'
        color={OrbyColorPalette['grey-500']}
      >
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {label}
        </span>
      </OrbyTypography>
    </Box>
  );
};

export default memo(OrbyHeaderLabel);
