import { createSelector } from 'reselect';
import { connectorSelector } from './app.selectors';

export const connectorListSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.connectors,
);

export const connectorTotalSizeSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.totalSize,
);

export const connectorNextPageTokenSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.nextPageToken,
);

export const connectorListLoadingSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.loadingConnectorsList,
);

export const processConnectorErrorSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.processConnectorError,
);

export const processingConnectorSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.processingConnector,
);

export const createdConnectorSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.createdConnector,
);

export const updatedConnectorSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.updatedConnector,
);

export const selectedConnectorSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.selectedConnector,
);

export const selectedConnectorLoadingSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.selectedConnectorLoading,
);

export const selectedConnectorErrorSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.selectedConnectorError,
);
