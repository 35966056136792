/**
 * REFERENCE URL
 * https://github.com/arshadkazmi42/currency-symbols/blob/master/currency_symbols/_constants.py
 */

export const CURRENCY_CODES = [
  'AFN',
  'EUR',
  'ALL',
  'DZD',
  'USD',
  'AOA',
  'XCD',
  'ARS',
  'AMD',
  'AWG',
  'AUD',
  'AZN',
  'BSD',
  'BHD',
  'BDT',
  'BBD',
  'BYN',
  'BZD',
  'XOF',
  'BMD',
  'INR',
  'BTN',
  'BOB',
  'BOV',
  'BAM',
  'BWP',
  'NOK',
  'BRL',
  'BND',
  'BGN',
  'BIF',
  'CVE',
  'KHR',
  'XAF',
  'CAD',
  'KYD',
  'CLP',
  'CLF',
  'CNY',
  'COP',
  'COU',
  'KMF',
  'CDF',
  'NZD',
  'CRC',
  'HRK',
  'CUP',
  'CUC',
  'ANG',
  'CZK',
  'DKK',
  'DJF',
  'DOP',
  'EGP',
  'SVC',
  'ERN',
  'SZL',
  'ETB',
  'FKP',
  'FJD',
  'XPF',
  'GMD',
  'GEL',
  'GHS',
  'GIP',
  'GTQ',
  'GBP',
  'GNF',
  'GYD',
  'HTG',
  'HNL',
  'HKD',
  'HUF',
  'ISK',
  'IDR',
  'XDR',
  'IRR',
  'IQD',
  'ILS',
  'JMD',
  'JPY',
  'JOD',
  'KZT',
  'KES',
  'KPW',
  'KRW',
  'KWD',
  'KGS',
  'LAK',
  'LBP',
  'LSL',
  'ZAR',
  'LRD',
  'LYD',
  'CHF',
  'MOP',
  'MKD',
  'MGA',
  'MWK',
  'MYR',
  'MVR',
  'MRU',
  'MUR',
  'XUA',
  'MXN',
  'MXV',
  'MDL',
  'MNT',
  'MAD',
  'MZN',
  'MMK',
  'NAD',
  'NPR',
  'NIO',
  'NGN',
  'OMR',
  'PKR',
  'PAB',
  'PGK',
  'PYG',
  'PEN',
  'PHP',
  'PLN',
  'QAR',
  'RON',
  'RUB',
  'RWF',
  'SHP',
  'WST',
  'STN',
  'SAR',
  'RSD',
  'SCR',
  'SLL',
  'SLE',
  'SGD',
  'XSU',
  'SBD',
  'SOS',
  'SSP',
  'LKR',
  'SDG',
  'SRD',
  'SEK',
  'CHE',
  'CHW',
  'SYP',
  'TWD',
  'TJS',
  'TZS',
  'THB',
  'TOP',
  'TTD',
  'TND',
  'TRY',
  'TMT',
  'UGX',
  'UAH',
  'AED',
  'USN',
  'UYU',
  'UYI',
  'UYW',
  'UZS',
  'VUV',
  'VES',
  'VED',
  'VND',
  'YER',
  'ZMW',
  'ZWL',
  'XBA',
  'XBB',
  'XBC',
  'XBD',
  'XTS',
  'XXX',
  'XAU',
  'XPD',
  'XPT',
  'XAG',
];

export const CURRENCY_SYMBOLS_SET = [
  'USh',
  '£',
  '₽',
  '₱',
  '₫',
  '៛',
  '֏',
  'R',
  'C\\$',
  'GH₵',
  'лв',
  'RD\\$',
  'Kz',
  'Дин\\.',
  '₭',
  'M',
  'L',
  'د\\.ت',
  'UM',
  '₣',
  '\\.د\\.ب',
  'P',
  'د\\.إ',
  '₴',
  'S',
  'zł',
  'B/\\.',
  'ден',
  'T\\$',
  '\\$U',
  'Ar',
  'J\\$',
  '₩',
  '₹',
  'R₣',
  'FG',
  'TT\\$',
  'دج',
  'kn',
  '₨',
  'FBu',
  'MOP\\$',
  'S/\\.',
  'MT',
  'Nfk',
  '\\$',
  'Fdj',
  'ج\\.س\\.',
  'WS\\$',
  'D',
  '₼',
  'E',
  'MAD',
  '₡',
  'Rf',
  '€',
  'Gs',
  'ƒ',
  'R\\$',
  'Db',
  'KD',
  'lei',
  'SM',
  '৳',
  'Rp',
  '₮',
  '₸',
  'TSh',
  'LD',
  '₾',
  'Br',
  '₺',
  'Le',
  'CFA',
  'KM',
  '₪',
  'KSh',
  'Ft',
  '﷼',
  'Nu\\.',
  '¥',
  'T',
  'ع\\.د',
  '\\$b',
  'RM',
  '₦',
  'BZ\\$',
  'Q',
  'FCFA',
  'kr',
  '฿',
  'FC',
  'NT\\$',
  'MK',
  'VT',
  'CHF',
  '؋',
  'CF',
  'G',
  'Kč',
  'JD',
  'K',
];

export const CURRENCY_SYMBOLS_SET_MAP = {
  AFN: '؋',
  EUR: '€',
  ALL: 'L',
  DZD: 'دج',
  USD: '$',
  AOA: 'Kz',
  XCD: '$',
  ARS: '$',
  AMD: '֏',
  AWG: 'ƒ',
  AUD: '$',
  AZN: '₼',
  BSD: '$',
  BHD: '.د.ب',
  BDT: '৳',
  BBD: '$',
  BYN: 'Br',
  BZD: 'BZ$',
  XOF: 'CFA',
  BMD: '$',
  INR: '₹',
  BTN: 'Nu.',
  BOB: '$b',
  BAM: 'KM',
  BWP: 'P',
  NOK: 'kr',
  BRL: 'R$',
  BND: '$',
  BGN: 'лв',
  BIF: 'FBu',
  CVE: '$',
  KHR: '៛',
  XAF: 'FCFA',
  CAD: '$',
  KYD: '$',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  KMF: 'CF',
  CDF: 'FC',
  NZD: '$',
  CRC: '₡',
  HRK: 'kn',
  CUP: '₱',
  CUC: '$',
  ANG: 'ƒ',
  CZK: 'Kč',
  DKK: 'kr',
  DJF: 'Fdj',
  DOP: 'RD$',
  EGP: '£',
  SVC: '$',
  ERN: 'Nfk',
  SZL: 'E',
  ETB: 'Br',
  FKP: '£',
  FJD: '$',
  XPF: '₣',
  GMD: 'D',
  GEL: '₾',
  GHS: 'GH₵',
  GIP: '£',
  GTQ: 'Q',
  GBP: '£',
  GNF: 'FG',
  GYD: '$',
  HTG: 'G',
  HNL: 'L',
  HKD: '$',
  HUF: 'Ft',
  ISK: 'kr',
  IDR: 'Rp',
  IRR: '﷼',
  IQD: 'ع.د',
  ILS: '₪',
  JMD: 'J$',
  JPY: '¥',
  JOD: 'JD',
  KZT: '₸',
  KES: 'KSh',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KGS: 'лв',
  LAK: '₭',
  LBP: '£',
  LSL: 'M',
  ZAR: 'R',
  LRD: '$',
  LYD: 'LD',
  CHF: 'CHF',
  MOP: 'MOP$',
  MKD: 'ден',
  MGA: 'Ar',
  MWK: 'MK',
  MYR: 'RM',
  MVR: 'Rf',
  MRU: 'UM',
  MUR: '₨',
  MXN: '$',
  MDL: 'lei',
  MNT: '₮',
  MAD: 'MAD',
  MZN: 'MT',
  MMK: 'K',
  NAD: '$',
  NPR: '₨',
  NIO: 'C$',
  NGN: '₦',
  OMR: '﷼',
  PKR: '₨',
  PAB: 'B/.',
  PGK: 'K',
  PYG: 'Gs',
  PEN: 'S/.',
  PHP: '₱',
  PLN: 'zł',
  QAR: '﷼',
  RON: 'lei',
  RUB: '₽',
  RWF: 'R₣',
  SHP: '£',
  WST: 'WS$',
  STN: 'Db',
  SAR: '﷼',
  RSD: 'Дин.',
  SCR: '₨',
  SLL: 'Le',
  SGD: '$',
  SBD: '$',
  SOS: 'S',
  SSP: '£',
  LKR: '₨',
  SDG: 'ج.س.',
  SRD: '$',
  SEK: 'kr',
  SYP: '£',
  TWD: 'NT$',
  TJS: 'SM',
  TZS: 'TSh',
  THB: '฿',
  TOP: 'T$',
  TTD: 'TT$',
  TND: 'د.ت',
  TRY: '₺',
  TMT: 'T',
  UGX: 'USh',
  UAH: '₴',
  AED: 'د.إ',
  UYU: '$U',
  UZS: 'лв',
  VUV: 'VT',
  VND: '₫',
  YER: '﷼',
};

// Format : Month Day Year
const DATE_FORMATS_MDY = [
  'M D YY',
  'M DD YY',
  'M D YYYY',
  'M DD YYYY',
  'MM D YY',
  'MM DD YY',
  'MM D YYYY',
  'MM DD YYYY',
  'MMM D YY',
  'MMM DD YY',
  'MMM D YYYY',
  'MMM DD YYYY',
  'MMMM D YY',
  'MMMM DD YY',
  'MMMM D YYYY',
  'MMMM DD YYYY',
];

// Format : Day Month Year
const DATE_FORMATS_DMY = [
  'D M YY',
  'DD M YY',
  'D M YYYY',
  'DD M YYYY',
  'D MM YY',
  'DD MM YY',
  'D MM YYYY',
  'DD MM YYYY',
  'D MMM YY',
  'DD MMM YY',
  'D MMM YYYY',
  'DD MMM YYYY',
  'D MMMM YY',
  'DD MMMM YY',
  'D MMMM YYYY',
  'DD MMMM YYYY',
];

// Format : Year Month Day
const DATE_FORMATS_YMD = [
  'YY M D',
  'YY M DD',
  'YYYY M D',
  'YYYY M DD',
  'YY MM D',
  'YY MM DD',
  'YYYY MM D',
  'YYYY MM DD',
  'YY MMM D',
  'YY MMM DD',
  'YYYY MMM D',
  'YYYY MMM DD',
  'YY MMMM D',
  'YY MMMM DD',
  'YYYY MMMM D',
  'YYYY MMMM DD',
];

// Format : Month Year Day
const DATE_FORMATS_MYD = [
  'M YY D',
  'M YY DD',
  'M YYYY D',
  'M YYYY DD',
  'MM YY D',
  'MM YY DD',
  'MM YYYY D',
  'MM YYYY DD',
  'MMM YY D',
  'MMM YY DD',
  'MMM YYYY D',
  'MMM YYYY DD',
  'MMMM YY D',
  'MMMM YY DD',
  'MMMM YYYY D',
  'MMMM YYYY DD',
];

// Format : Day Year Month
const DATE_FORMATS_DYM = [
  'D YY M',
  'DD YY M',
  'D YYYY M',
  'DD YYYY M',
  'D YY MM',
  'DD YY MM',
  'D YYYY MM',
  'DD YYYY MM',
  'D YY MMM',
  'DD YY MMM',
  'D YYYY MMM',
  'DD YYYY MMM',
  'D YY MMMM',
  'DD YY MMMM',
  'D YYYY MMMM',
  'DD YYYY MMMM',
];

// Format : Year Day Month
const DATE_FORMATS_YDM = [
  'YY D M',
  'YY DD M',
  'YYYY D M',
  'YYYY DD M',
  'YY D MM',
  'YY DD MM',
  'YYYY D MM',
  'YYYY DD MM',
  'YY D MMM',
  'YY DD MMM',
  'YYYY D MMM',
  'YYYY DD MMM',
  'YY D MMMM',
  'YY DD MMMM',
  'YYYY D MMMM',
  'YYYY DD MMMM',
];

const expandSpaceDelimitedFormats = (formats: string[]) => {
  const separators = ['/', '-', '.']; // space is not included since we already have all format with space as delimiter
  const allFormats: string[] = [...formats]; // all space format
  separators.forEach((separator) => {
    allFormats.push(
      ...formats.map((format) => format.replaceAll(' ', separator)),
    );
  });
  return allFormats;
};

// Note: Don't change order here as certain formats takes precedence over another
export const DATE_FORMATS = [
  ...expandSpaceDelimitedFormats(DATE_FORMATS_MDY),
  ...expandSpaceDelimitedFormats(DATE_FORMATS_DMY),
  ...expandSpaceDelimitedFormats(DATE_FORMATS_YMD),
  ...expandSpaceDelimitedFormats(DATE_FORMATS_MYD),
  ...expandSpaceDelimitedFormats(DATE_FORMATS_DYM),
  ...expandSpaceDelimitedFormats(DATE_FORMATS_YDM),
];
