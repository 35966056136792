import { storageService } from './StorageService';

class HTTPApiService {
  private static instance: HTTPApiService;
  private baseUrl = process.env.REACT_APP_GRPC_URI as string;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new HTTPApiService();
    }
    return this.instance;
  }

  async post(endpoint: string, data: any, isEndPointProtected = false) {
    const url = `${this.baseUrl}/${endpoint}`;

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };

    if (isEndPointProtected) {
      headers['Authorization'] = await storageService.getAuthorizationHeader();
    }

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const error = await response.json();
      throw error;
    }

    const contentType = response.headers.get('content-type');
    if (contentType?.includes('application/json')) {
      return response.json();
    } else {
      return response.text();
    }
  }
}

export const httpApiService = HTTPApiService.getInstance();
