import produce from 'immer';
import { FeatureFlag } from 'protos/pb/v1alpha2/feature_flag_service';
import { Reducer } from 'redux';
import { FeatureFlagActionType } from '../actions/actions.constants';

export interface FeatureFlagsState {
  featureFlags: FeatureFlag[];
  totalSize?: number;
  loadingFeatureFlagsList: boolean;
  listFeatureFlagsError?: Error;

  createdFeatureFlag?: FeatureFlag;
  updatedFeatureFlag?: FeatureFlag;

  processingFeatureFlag: boolean;
  processFeatureFlagError?: Error;

  selectedFeatureFlag?: FeatureFlag;
  selectedFeatureFlagLoading: boolean;
  selectedFeatureFlagError?: Error;

  featureFlagsForOrgAndUser?: { [name: string]: boolean };
  featureFlagsForOrgAndUserLoading: boolean;
  featureFlagsForOrgAndUserError?: Error;
}

const initialState: FeatureFlagsState = {
  featureFlags: [],
  loadingFeatureFlagsList: false,
  processingFeatureFlag: false,
  selectedFeatureFlagLoading: false,
  featureFlagsForOrgAndUserLoading: true,
};

export const featureFlagsReducer: Reducer<FeatureFlagsState> = (
  state: FeatureFlagsState = initialState,
  action: any,
) =>
  produce(state, (draft: FeatureFlagsState) => {
    switch (action.type) {
      case FeatureFlagActionType.LIST_FEATURE_FLAGS:
        draft.loadingFeatureFlagsList = true;
        break;
      case FeatureFlagActionType.LIST_FEATURE_FLAGS_COMPLETED: {
        if (action.refresh) {
          draft.featureFlags = action.payload;
        } else {
          draft.featureFlags.push(...action.payload);
        }
        draft.totalSize = action.totalSize;
        draft.loadingFeatureFlagsList = false;
        break;
      }
      case FeatureFlagActionType.LIST_FEATURE_FLAGS_ERROR: {
        draft.loadingFeatureFlagsList = false;
        draft.listFeatureFlagsError = action.payload;
        break;
      }
      case FeatureFlagActionType.CREATE_FEATURE_FLAG:
        draft.processingFeatureFlag = true;
        break;
      case FeatureFlagActionType.CREATE_FEATURE_FLAG_COMPLETED: {
        draft.createdFeatureFlag = action.payload;
        draft.processingFeatureFlag = false;
        break;
      }
      case FeatureFlagActionType.CREATE_FEATURE_FLAG_ERROR: {
        draft.processingFeatureFlag = false;
        draft.processFeatureFlagError = action.payload;
        break;
      }
      case FeatureFlagActionType.UPDATE_FEATURE_FLAG:
        draft.processingFeatureFlag = true;
        break;
      case FeatureFlagActionType.UPDATE_FEATURE_FLAG_COMPLETED: {
        draft.updatedFeatureFlag = action.payload;
        draft.processingFeatureFlag = false;
        break;
      }
      case FeatureFlagActionType.UPDATE_FEATURE_FLAG_ERROR: {
        draft.processingFeatureFlag = false;
        draft.processFeatureFlagError = action.payload;
        break;
      }
      case FeatureFlagActionType.DELETE_FEATURE_FLAG:
        draft.processingFeatureFlag = true;
        break;
      case FeatureFlagActionType.DELETE_FEATURE_FLAG_COMPLETED: {
        draft.featureFlags = draft.featureFlags.filter(
          (ff) => ff.id !== action.payload,
        );
        draft.processingFeatureFlag = false;
        break;
      }
      case FeatureFlagActionType.DELETE_FEATURE_FLAG_ERROR: {
        draft.processingFeatureFlag = false;
        draft.processFeatureFlagError = action.payload;
        break;
      }
      case FeatureFlagActionType.GET_FEATURE_FLAG:
        draft.selectedFeatureFlagLoading = true;
        break;
      case FeatureFlagActionType.GET_FEATURE_FLAG_COMPLETED: {
        draft.selectedFeatureFlag = action.payload;
        draft.selectedFeatureFlagLoading = false;
        break;
      }
      case FeatureFlagActionType.GET_FEATURE_FLAG_ERROR: {
        draft.selectedFeatureFlagLoading = false;
        draft.selectedFeatureFlagError = action.payload;
        break;
      }
      case FeatureFlagActionType.SET_SELECTED_FEATURE_FLAG: {
        draft.selectedFeatureFlag = action.payload;
        break;
      }
      case FeatureFlagActionType.CLEAR: {
        draft.selectedFeatureFlag = undefined;
        draft.selectedFeatureFlagError = undefined;
        break;
      }
      case FeatureFlagActionType.GET_FEATURE_FLAGS_FOR_ORG_AND_USER:
        draft.featureFlagsForOrgAndUserError = undefined;
        draft.featureFlagsForOrgAndUserLoading = true;
        break;
      case FeatureFlagActionType.GET_FEATURE_FLAGS_FOR_ORG_AND_USER_COMPLETED: {
        draft.featureFlagsForOrgAndUser = action.payload;
        draft.featureFlagsForOrgAndUserLoading = false;
        break;
      }
      case FeatureFlagActionType.GET_FEATURE_FLAGS_FOR_ORG_AND_USER_ERROR: {
        draft.featureFlagsForOrgAndUserLoading = false;
        draft.featureFlagsForOrgAndUserError = action.payload;
        break;
      }
      case FeatureFlagActionType.UPDATE_FEATURE_FLAGS_LOADER_FOR_ORG_AND_USER: {
        draft.featureFlagsForOrgAndUserLoading = action.payload;
        break;
      }
      default:
        break;
    }
  });
