import _ from 'lodash';
import {
  EntityInfo,
  SelectedParentEntity,
} from '../redux/reducers/review_task.reducer';
import { deepCloneEntityInfoObject } from './cloneObjectUtils';
import { getRowOrderInfoForTableEntities } from './ReviewTaskUtils';

// This function take entities info map, and from it takes out nested entities
// and group them to be used in table Modal for each parent type. Initially the
// order is assigned based on the order received from BE. columns are ordered and
// rows are ordered based on BE entity list ordering. Later this order can
// be changed via table modal UI.
export const setRowAndColumnOrderForTask = (
  selectedTaskEntityInfo: { [id: string]: EntityInfo },
  columnOrderInfoForTableEntities: { [id: string]: EntityInfo[] },
  rowOrderInfoForTableEntities: { [id: string]: string[] },
) => {
  const uniquesEntities = [] as EntityInfo[];
  const allParentTypes = [] as string[];
  Object.values(selectedTaskEntityInfo).forEach((e) => {
    if (e.parentEntityType && !allParentTypes.includes(e.parentEntityType)) {
      allParentTypes.push(e.parentEntityType);
    }
    if (
      e.parentEntityId &&
      !uniquesEntities.some(
        (ue) =>
          ue.type === e.type && ue.parentEntityType === e.parentEntityType,
      )
    ) {
      uniquesEntities.push(e);
    }
  });
  uniquesEntities.forEach((e: EntityInfo) => {
    if (columnOrderInfoForTableEntities[e.parentEntityType || '']) {
      columnOrderInfoForTableEntities[e.parentEntityType || ''].push(e);
    } else {
      columnOrderInfoForTableEntities[e.parentEntityType || ''] = [e];
    }
  });
  allParentTypes.forEach((parentType) => {
    const selectedTableEntitiesInfo: EntityInfo[] = [];
    Object.values(selectedTaskEntityInfo)
      .filter((e) => e.parentEntityType === parentType)
      .forEach((e: EntityInfo) => {
        selectedTableEntitiesInfo?.push(deepCloneEntityInfoObject(e));
      });
    rowOrderInfoForTableEntities[parentType] = getRowOrderInfoForTableEntities(
      selectedTableEntitiesInfo,
      parentType,
    );
  });
};

// This function helps delete row from row order when any row is deleted from table modal
export const removeRowFromRowOrderInfo = (
  rowOrderInfoForTableEntities: { [id: string]: string[] },
  parentId: string,
  selectedParentEntityInfo?: SelectedParentEntity,
) => {
  rowOrderInfoForTableEntities[selectedParentEntityInfo?.type || ''] =
    rowOrderInfoForTableEntities[selectedParentEntityInfo?.type || ''].filter(
      (id) => id !== parentId,
    );
};

// This function is to handle a case where if we add some rows and arrange order for few rows
// but do not confirm it, the rows actually does not get added in Task Entity Info and hence
// should be removed when setting the rowOrder again
export const removeRowsNotPresentInEntityInfoFromRowOrder = (
  rowOrderInfoForTableEntities: { [id: string]: string[] },
  selectedTaskEntityInfo: { [id: string]: EntityInfo },
  selectedTableEntitiesInfo: EntityInfo[],
  selectedParentEntityInfo: SelectedParentEntity,
) => {
  const parentIdMap = _.keyBy(
    Object.values(selectedTaskEntityInfo),
    'parentEntityId',
  );
  rowOrderInfoForTableEntities[selectedParentEntityInfo.type] =
    rowOrderInfoForTableEntities[selectedParentEntityInfo.type].filter((e) => {
      return parentIdMap[e];
    });
  const parentIds = getRowOrderInfoForTableEntities(
    selectedTableEntitiesInfo,
    selectedParentEntityInfo?.type,
  );
  const remainingDeletedIds: string[] = [];
  const alreadyPresentIds =
    rowOrderInfoForTableEntities[selectedParentEntityInfo.type];
  parentIds.forEach((id) => {
    const existingList = [...alreadyPresentIds, ...remainingDeletedIds];
    if (!existingList.includes(id)) {
      remainingDeletedIds.push(id);
    }
  });
  rowOrderInfoForTableEntities[selectedParentEntityInfo.type].push(
    ...remainingDeletedIds,
  );
};
