import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box } from '@mui/material';
import CustomTypography from '../../components/CustomTypography';
import CustomFilterSelect from '../../components/generic/CustomFilterSelect';
import React from 'react';
import { ROWS_PER_PAGE } from '../../utils/constants';

interface Props {
  rowsPerPage: number;
  totalSize?: number;
  page: number;
  rows?: number[];
  justifyContent?: 'center' | 'end';
  onRowsPerPageChange?: (rows: number) => void;
  onPageChange: (page: number) => void;
  ariaLabel?: string;
  isShowSelectFilter?: boolean;
}

interface PageBoxProps {
  title: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  role?: React.AriaRole;
  ariaLabel?: string;
  component?: React.ElementType;
}

const PageBox: React.FC<PageBoxProps> = ({
  title,
  selected = false,
  onClick,
  disabled,
  role,
  ariaLabel,
  component,
}) => {
  return (
    <Box
      height={'32px'}
      component={component}
      role={role}
      aria-label={ariaLabel}
      width={'32px'}
      border={`1.5px solid ${selected ? '#1669F7' : '#757575'}`}
      borderRadius={'4px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      marginX={'4px'}
      // The reason for doing e.preventDefault is to disable the default behavior of the button
      // When formik is used, the default behavior of the button is to submit the form unless the type is set to button
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (disabled) {
          e.preventDefault();
          return;
        }
        onClick?.(e);
      }}
    >
      <Box aria-hidden={true}>
        <CustomTypography
          component={'span'}
          color={selected ? '#1669F7' : disabled ? '#757575' : '#545454'}
        >
          {title}
        </CustomTypography>
      </Box>
    </Box>
  );
};

const CustomPagination: React.FC<Props> = ({
  rowsPerPage,
  totalSize = 0,
  page,
  rows,
  justifyContent = 'end',
  onRowsPerPageChange,
  onPageChange,
  ariaLabel,
  isShowSelectFilter = true,
}) => {
  const total = Math.ceil(totalSize / rowsPerPage);
  const firstTaskIndex = page * rowsPerPage + 1;
  const lastTaskIndex = (page + 1) * rowsPerPage;

  return (
    <Box
      display={'flex'}
      component={'nav'}
      role='navigation'
      aria-label='Pagination Navigation'
      justifyContent={justifyContent}
      alignItems={'center'}
      marginTop={'16px'}
    >
      {totalSize > rowsPerPage && (
        <>
          <Box
            role='region'
            aria-label={`You’re currently on page ${
              page + 1
            } out of total ${total} page`}
          >
            <Box aria-hidden={true}>
              <CustomTypography
                sx={{
                  marginRight: '12px',
                }}
                component={'span'}
                color='#545454'
              >
                {firstTaskIndex}-
                {lastTaskIndex > totalSize
                  ? totalSize || rowsPerPage
                  : lastTaskIndex}
                /{totalSize || rowsPerPage}
              </CustomTypography>
            </Box>
          </Box>
          <PageBox
            ariaLabel='Click to navigate to previous page.'
            component={'button'}
            title={
              <KeyboardArrowLeft
                sx={{
                  marginTop: '2px',
                  cursor: page === 0 ? 'not-allowed' : 'pointer',
                }}
              />
            }
            disabled={page === 0}
            onClick={(e) => {
              // The reason for doing e.preventDefault is to disable the default behavior of the button
              // When formik is used, the default behavior of the button is to submit the form unless the type is set to button
              e.preventDefault();
              onPageChange(page - 1);
            }}
          />
          <PageBox
            ariaLabel={`Page ${page + 1}`}
            role='region'
            title={page + 1}
            selected
          />
          <PageBox
            component={'button'}
            ariaLabel='Click to navigate to next page.'
            title={
              <KeyboardArrowRight
                sx={{
                  marginTop: '2px',
                  cursor:
                    total === 0 || page === total - 1
                      ? 'not-allowed'
                      : 'pointer',
                }}
              />
            }
            disabled={total === 0 || page === total - 1}
            onClick={(e) => {
              // The reason for doing e.preventDefault is to disable the default behavior of the button
              // When formik is used, the default behavior of the button is to submit the form unless the type is set to button
              e.preventDefault();
              onPageChange(page + 1);
            }}
          />
        </>
      )}
      {isShowSelectFilter && (
        <CustomFilterSelect
          height={'34px'}
          width={'130px'}
          left={'15px'}
          onChange={(rows) => onRowsPerPageChange?.(rows as number)}
          filters={(rows ? rows : ROWS_PER_PAGE).map((r) => ({
            value: r,
            label: `${r} rows/page`,
          }))}
          value={rowsPerPage}
          ariaLabel={ariaLabel ?? 'Select rows per page.'}
        />
      )}
    </Box>
  );
};

export default React.memo(CustomPagination);
