import { Box, MenuItem, TextField } from '@mui/material';
import React, { FC, memo, useEffect, useRef, useState } from 'react';

interface SearchFieldProps {
  selectedValues: any;
  searchList: any;
  handleAdd: any;
  searchStyle: any;
  className: string;
  ariaDescribeBy: string;
  ariaLabel: string;
  placeholder: string;
  disabled?: boolean;
  autoFocus?: boolean;
  dropdownWidth: string;
  height: string;
  inputProps?: any;
}

const SearchField: FC<SearchFieldProps> = (props) => {
  const {
    selectedValues,
    searchList,
    handleAdd,
    searchStyle,
    className,
    ariaLabel,
    ariaDescribeBy,
    placeholder,
    disabled,
    autoFocus,
    dropdownWidth,
    height,
    inputProps,
  } = props;
  const searchFieldRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchListState, setSearchListState] = useState(searchList.sort());
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        searchFieldRef.current &&
        !searchFieldRef.current.contains(event.target as Node)
      ) {
        setSearchValue('');
        setShowSuggestions(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  /**
   * The following code is to prevent the enter key from
   * submitting the form and also prevent default enter key behavior
   * when the user is typing in the search field and hits enter.
   */
  useEffect(() => {
    function handleEnterKey(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        if (showSuggestions) {
          event.preventDefault();
        }
      }
    }
    document.addEventListener('keydown', handleEnterKey);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', handleEnterKey);
    };
  });

  useEffect(() => {
    /**
     * Selected values is an array of strings which contains the values of users that are already selected
     * Search list is an array of strings which contains the values of users that are not selected
     * The following code is to filter out the users that are already selected from the search list
     * and set the filtered list to the search list state.
     * Prevent removing parent entities with remaining child entities in nonSelectedEntities.
     */
    const nonSelectedEntities = searchList?.filter(
      (val: string) => !selectedValues.includes(val),
    );
    const finalEntities = searchList.filter(
      (val: string) =>
        !selectedValues.includes(val) ||
        (selectedValues.includes(val) &&
          nonSelectedEntities.some(
            (e: string) => e.split('/').length > 1 && e.split('/')[0] === val,
          )),
    );
    setSearchListState(finalEntities);
  }, [selectedValues]);

  return (
    <Box>
      <Box position={'relative'}>
        <TextField
          className={className}
          aria-label={ariaLabel}
          aria-describedby={ariaDescribeBy}
          style={{ ...searchStyle }}
          value={searchValue}
          placeholder={placeholder}
          disabled={disabled}
          InputProps={{ ...inputProps }}
          autoFocus={autoFocus}
          onClick={() => {
            setShowSuggestions(true);
          }}
          onChange={(event) => {
            setSearchValue(event.target.value);
            setSearchListState(
              searchList.filter(
                (val: string) =>
                  val
                    ?.toLowerCase()
                    .includes(event.target.value?.toLowerCase()) &&
                  !selectedValues.includes(val),
              ),
            );
          }}
          sx={{
            marginTop: '8px',
            borderRadius: '4px',
            '& .MuiOutlinedInput-root': {
              height,
              background: '#fff',
            },
            '& .MuiOutlinedInput-input': {
              lineHeight: '16px',
              fontSize: '12px',
              fontWeight: 400,
              WebkitTextFillColor: '#000000 !important',
            },
          }}
        />
        {((searchValue != '' && searchListState.length > 0) ||
          showSuggestions) && (
          <Box
            maxHeight={'200px'}
            overflow={'auto'}
            ref={searchFieldRef}
            borderRadius={'4px'}
            sx={{ background: '#fff' }}
            position={'absolute'}
            top={'46px'}
            width={dropdownWidth}
            zIndex={1}
            border={'1px solid #CCCCCC'}
            display={'block'}
          >
            {searchListState.length > 0 ? (
              searchListState.map((val: string) => {
                return (
                  <MenuItem
                    onClick={() => {
                      handleAdd(val);
                      setSearchValue('');
                      setShowSuggestions(false);
                    }}
                    key={val}
                    style={{ fontSize: '12px' }}
                  >
                    {val}
                  </MenuItem>
                );
              })
            ) : (
              <Box
                textAlign={'center'}
                sx={{
                  cursor: 'not-allowed',
                  fontSize: '12px',
                  padding: '10px 20px',
                }}
              >
                No results found
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(SearchField);
