import { createSelector } from 'reselect';
import { featureFlagsSelector } from './app.selectors';

export const featureFlagsListSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.featureFlags,
);

export const featureFlagsLoadingSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.loadingFeatureFlagsList,
);

export const featureFlagsTotalSizeSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.totalSize,
);

export const featureFlagsListErrorSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.listFeatureFlagsError,
);

export const createdFeatureFlagSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.createdFeatureFlag,
);

export const updatedFeatureFlagSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.updatedFeatureFlag,
);

export const processFeatureFlagErrorSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.processFeatureFlagError,
);

export const processingFeatureFlagSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.processingFeatureFlag,
);

export const selectedFeatureFlagSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.selectedFeatureFlag,
);

export const selectedFeatureFlagLoadingSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.selectedFeatureFlagLoading,
);

export const selectedFeatureFlagErrorSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.selectedFeatureFlagError,
);

export const getFeatureFlagsForOrgAndUserSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.featureFlagsForOrgAndUser,
);

export const getFeatureFlagsForOrgAndUserLoadingSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.featureFlagsForOrgAndUserLoading,
);
