export type FeatureFlagFormValues = {
  name: string;
  description: string;
  enabled: boolean;
  enabledUsers: string[];
  blockedUsers: string[];
  enabledOrgs: string[];
  blockedOrgs: string[];
  enabledWorkflows: string[];
  blockedWorkflows: string[];
};

// function to check if a feature flag is enabled
export const isFeatureFlagEnabled = (
  name: string,
  featureFlags?: { [name: string]: boolean },
): boolean => {
  return featureFlags ? featureFlags[name.toLowerCase()] : false;
};
