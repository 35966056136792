import {
  GetSuggestionNextStepsResponse,
  SuggestionsClientImpl,
  UpdateSuggestionResponse,
} from 'protos/pb/v1alpha1/suggestions_service';
import {
  BatchUpdateSuggestionsRequest,
  BatchUpdateSuggestionsResponse,
  GetSuggestionNextStepsRequest,
  SummarizeSuggestionsRequest,
  GetSuggestionRequest,
  ListSuggestionsRequest,
  SummarizeSuggestionsResponse,
  UpdateSuggestionRequest,
  ListSuggestionsResponse,
} from 'protos/pb/v1alpha1/suggestions_service';
import { Suggestion } from 'protos/pb/v1alpha1/suggestion';
import { storageService } from './StorageService';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';

class SuggestionService {
  private static instance: SuggestionService;
  private static client = new SuggestionsClientImpl(rpcWithErrorHandling);

  public static getInstance(): SuggestionService {
    if (!this.instance) {
      this.instance = new SuggestionService();
    }
    return this.instance;
  }

  async getSuggestions(
    req: ListSuggestionsRequest,
  ): Promise<ListSuggestionsResponse> {
    const authorization = await storageService.getAuthorizationHeader();
    return SuggestionService.client.ListSuggestions(
      req,
      getMetaData({ authorization }),
    );
  }

  async updateSuggestion(
    req: UpdateSuggestionRequest,
  ): Promise<UpdateSuggestionResponse> {
    const authorization = await storageService.getAuthorizationHeader();
    return SuggestionService.client.UpdateSuggestion(
      req,
      getMetaData({ authorization }),
    );
  }

  async batchUpdateSuggestion(
    req: BatchUpdateSuggestionsRequest,
  ): Promise<BatchUpdateSuggestionsResponse> {
    const authorization = await storageService.getAuthorizationHeader();
    return SuggestionService.client.BatchUpdateSuggestions(
      req,
      getMetaData({ authorization }),
    );
  }

  async getSuggestionSummary(
    req: SummarizeSuggestionsRequest,
  ): Promise<SummarizeSuggestionsResponse> {
    const authorization = await storageService.getAuthorizationHeader();
    return SuggestionService.client.SummarizeSuggestions(
      req,
      getMetaData({ authorization }),
    );
  }

  async getNextStep(suggestion: Suggestion): Promise<Suggestion> {
    const authorization = await storageService.getAuthorizationHeader();
    return new Promise<Suggestion>((resolve, reject) => {
      const req: GetSuggestionNextStepsRequest = {};
      req.suggestion = suggestion;
      return SuggestionService.client
        .GetSuggestionNextSteps(req, getMetaData({ authorization }))
        .then((resp: GetSuggestionNextStepsResponse) =>
          resolve(resp.suggestion as Suggestion),
        )
        .catch((err: any) => reject(err));
    });
  }

  async getSuggestionByName(name: string): Promise<Suggestion> {
    const authorization = await storageService.getAuthorizationHeader();
    const getSuggestionRequest: GetSuggestionRequest = {};
    getSuggestionRequest.name = name;
    return SuggestionService.client.GetSuggestion(
      getSuggestionRequest,
      getMetaData({ authorization }),
    );
  }
}

export const suggestionService = SuggestionService.getInstance();
