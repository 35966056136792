export const getCheckboxSize = (size: string) => {
  switch (size) {
    case 'sm':
      return '16px';
    case 'md':
      return '20px';
    default:
      return '16px'; // Default to 'sm' if no size is specified
  }
};
