import React from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import CustomModal from '../../../../components/CustomModal';
import { Box, List, ListItem } from '@mui/material';

interface WorkflowDeletionModalProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  isMultiple?: boolean;
}

const WorkflowDeletionModal: React.FC<WorkflowDeletionModalProps> = ({
  open,
  handleClose,
  onSubmit,
  isMultiple = false,
}) => {
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      onPrimaryClick={() => onSubmit()}
      heading={isMultiple ? 'Delete Workflows?' : 'Delete Workflow?'}
      content={
        <>
          <Box sx={{ width: '433px' }}>
            <CustomTypography>
              By deleting this, you will also delete the following :
            </CustomTypography>
            <List
              disablePadding
              sx={{
                listStyleType: 'disc',
                listStylePosition: 'inside',
                lineHeight: '8px',
                mt: '24px',
                mb: '24px',
              }}
            >
              <ListItem sx={{ display: 'list-item', fontFamily: 'Inter' }}>
                All tasks related to this workflow
              </ListItem>
            </List>
          </Box>
        </>
      }
      primaryLabel={'Delete'}
      secondaryLabel={'Cancel'}
    />
  );
};

export default React.memo(WorkflowDeletionModal);
