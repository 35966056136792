import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';
import { storageService } from './StorageService';
import {
  GetBlockedWorkflowExecutionStatisticsRequest,
  GetBlockedWorkflowExecutionStatisticsResponse,
  ListWorkflowExecutionsRequest,
  ListWorkflowExecutionsResponse,
  WorkflowExecutionsClientImpl,
} from 'protos/pb/v1alpha2/workflow_executions_service';

export class WorkflowExecutionsService {
  private static instance: WorkflowExecutionsService;

  private static client = new WorkflowExecutionsClientImpl(
    rpcWithErrorHandling,
  );

  public static getInstance(): WorkflowExecutionsService {
    if (!this.instance) {
      this.instance = new WorkflowExecutionsService();
    }
    return this.instance;
  }

  async getApiExecutionHistory(
    req: ListWorkflowExecutionsRequest,
  ): Promise<{ response?: ListWorkflowExecutionsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response =
        await WorkflowExecutionsService.client.ListWorkflowExecutions(
          req,
          getMetaData({ authorization }),
        );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getBlockedWorkflowExecutionStatistics(
    req: GetBlockedWorkflowExecutionStatisticsRequest,
  ): Promise<{
    response?: GetBlockedWorkflowExecutionStatisticsResponse;
    error?: Error;
  }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response =
        await WorkflowExecutionsService.client.GetBlockedWorkflowExecutionStatistics(
          req,
          getMetaData({ authorization }),
        );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const workflowExecutionsService =
  WorkflowExecutionsService.getInstance();
