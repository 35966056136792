import { GetFeatureFlagsForOrgAndUserRequest } from 'protos/pb/v1alpha2/feature_flag_service';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeatureFlagsForOrgAndUserAction } from '../redux/actions/feature_flags.action';
import { loggedInUserSelector } from '../redux/selectors/user.selectors';

export const useFetchFeatureFlagsForOrgAndUser = (orgResourceName?: string) => {
  const dispatch = useDispatch();
  const user = useSelector(loggedInUserSelector);

  useEffect(() => {
    if (user && orgResourceName) {
      const req = GetFeatureFlagsForOrgAndUserRequest.create({
        orgResourceName: orgResourceName,
      });
      dispatch(getFeatureFlagsForOrgAndUserAction(req));
    }
  }, [user, orgResourceName]);
};
