import React, { FC, memo } from 'react';
import {
  Button,
  CircularProgress,
  SxProps,
  Theme,
  ButtonProps,
} from '@mui/material';
import {
  BUTTON_VARIANTS,
  getButtonHeight,
  getButtonPadding,
} from './button-utils';
import {
  getFontSize,
  getFontWeight,
  getLineHeight,
} from '../typography/typography-utils';
import '../../styles/global.css';
export interface OrbyButtonProps extends Pick<ButtonProps, 'ref'> {
  /**
   * The type of the button
   * @default 'button'
   */
  type?: 'button' | 'reset' | 'submit';

  /**
   * id of the button
   */
  id?: string;

  /**
   * The form attribute of the button
   */
  form?: string;

  /**
   * The onClick event handler of the button
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

  /**
   * Set the disabled state of the button
   */
  disabled?: boolean;

  /**
   * Set the loading state of the button
   */
  loading?: boolean;

  /**
   * Set the label on the button
   */
  label?: string;

  /**
   * Set the start icon on the button
   */
  startIcon?: any;

  /**
   * Set the start icon on the button
   */
  endIcon?: any;

  /**
   * The type of the button
   * @default 'primary-contained'
   *
   */
  variant:
    | 'primary-contained'
    | 'primary-outline'
    | 'primary-text'
    | 'primary-text-flat'
    | 'monochrome-outline'
    | 'monochrome-text'
    | 'monochrome-text-flat';

  /**
   * The size of the button
   * Defines the height
   */
  size?: 'small' | 'medium' | 'large';

  /**
   * The aria-label for the button
   * Used for A11y purposes
   */
  ariaLabel?: string;
  /**
   * The custom styles for the button
   */
  sx?: SxProps<Theme>;
  /**
   * The data-testid for the button
   * Used for testing purposes
   */
  dataTestId?: string;
}

const OrbyButton: FC<OrbyButtonProps> = (props) => {
  const {
    loading,
    id,
    form,
    type = 'button',
    ariaLabel,
    size,
    startIcon,
    endIcon,
    label,
    variant,
    disabled = false,
    onClick,
    sx,
    ref,
    dataTestId,
  } = props;

  return (
    <Button
      ref={ref}
      id={id}
      aria-label={ariaLabel}
      form={form}
      type={type}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      disableRipple={true}
      sx={{
        height: getButtonHeight(size),
        borderRadius: '40px',
        padding: variant.includes('text-flat') ? 0 : getButtonPadding(size),
        fontFamily: 'var(--font-family-inter-variable)',
        fontWeight: getFontWeight('semibold'),
        fontSize: getFontSize(size === 'large' ? 'md' : 'sm'),
        lineHeight: getLineHeight(size === 'large' ? 'md' : 'sm'),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        border: `1px solid ${disabled ? BUTTON_VARIANTS[variant].disabled.border : BUTTON_VARIANTS[variant].border}`,
        boxShadow: disabled
          ? BUTTON_VARIANTS[variant].disabled.boxShadow
          : BUTTON_VARIANTS[variant].boxShadow,
        background: disabled
          ? BUTTON_VARIANTS[variant].disabled.background
          : BUTTON_VARIANTS[variant].background,
        color: `${disabled ? `${BUTTON_VARIANTS[variant].disabled.color}!important` : BUTTON_VARIANTS[variant].color}`,
        '&:hover': {
          background: BUTTON_VARIANTS[variant].hover.background,
          color: BUTTON_VARIANTS[variant].hover.color,
          border: `1px solid ${BUTTON_VARIANTS[variant].hover.border}`,
        },
        '&:focus': {
          background: BUTTON_VARIANTS[variant].focus.background,
          color: BUTTON_VARIANTS[variant].focus.color,
          boxShadow: BUTTON_VARIANTS[variant].focus.boxShadow,
        },
        ...sx,
      }}
      data-testid={dataTestId}
    >
      {loading ? (
        <CircularProgress
          sx={{ color: BUTTON_VARIANTS[variant].color }}
          size={'27px'}
        />
      ) : (
        label
      )}
    </Button>
  );
};

export default memo(OrbyButton);
