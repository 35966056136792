import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import {
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadingSelector,
  userObjectSelector,
} from '../../../redux/selectors/auth.selectors';
import { authSignUpAction } from '../../../redux/actions/auth.action';
import { RegisterRequest } from 'protos/pb/v1alpha1/users_service';
import { User } from 'protos/pb/v1alpha1/user';

export const SignupForm: React.FC = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const user: User | undefined = useSelector(userObjectSelector);
  const loading = useSelector(loadingSelector);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const registerRequest: RegisterRequest = {};
      registerRequest.email = values.email;
      registerRequest.password = values.password;
      dispatch(authSignUpAction(registerRequest));
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    if (user !== undefined) {
      window.location.href = '/';
    }
  }, [user]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Box sx={{ marginBottom: 3 }}>
          <TextField
            fullWidth
            sx={{
              marginBottom: 3,
            }}
            autoComplete='username'
            type='email'
            label='Email address'
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            sx={{
              marginBottom: 1,
            }}
            autoComplete='current-password'
            type={showPassword ? 'text' : 'password'}
            label='Password'
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleShowPassword} edge='end'>
                    <Icon
                      icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Box>
        <Button
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          sx={{ marginBottom: 2 }}
        >
          {loading ? 'Loading...' : 'Register'}
        </Button>
      </Form>
    </FormikProvider>
  );
};
