import { UserActionType } from './actions.constants';
import { OrgInfo, User } from 'protos/pb/v1alpha1/user';
import {
  AddUserToOrganizationRequest,
  ListUsersRequest,
  UpdatePasswordRequest,
  UpdateRequest,
} from 'protos/pb/v1alpha1/users_service';

export const fetchLoggedInUserAction = () => ({
  type: UserActionType.FETCH_LOGGED_IN_USER,
});

export const fetchLoggedInUserCompletedAction = (user: User) => ({
  type: UserActionType.FETCH_LOGGED_IN_USER_COMPLETED,
  payload: user,
});

export const fetchLoggedInUserErrorAction = (error: string) => ({
  type: UserActionType.FETCH_LOGGED_IN_USER_ERROR,
  payload: error,
});

export const updateUserAction = (req: UpdateRequest) => ({
  type: UserActionType.UPDATE_USER,
  payload: req,
});

export const updateUserCompletedAction = (user: User) => ({
  type: UserActionType.UPDATE_USER_COMPLETED,
  payload: user,
});

export const updateUserErrorAction = (error?: Error) => ({
  type: UserActionType.UPDATE_USER_ERROR,
  payload: error,
});

export const updatePasswordAction = (req: UpdatePasswordRequest) => ({
  type: UserActionType.UPDATE_PASSWORD,
  payload: req,
});

export const updatePasswordCompletedAction = (message: string) => ({
  type: UserActionType.UPDATE_PASSWORD_COMPLETED,
  payload: message,
});

export const updatePasswordErrorAction = (error: string) => ({
  type: UserActionType.UPDATE_PASSWORD_ERROR,
  payload: error,
});

export const listUsersAction = (req: ListUsersRequest, refresh = false) => ({
  type: UserActionType.LIST_USERS,
  payload: req,
  refresh,
});

export const listUsersCompletedAction = (
  users: User[],
  nextPageToken: string,
  totalSize: number,
  refresh: boolean,
) => ({
  type: UserActionType.LIST_USERS_COMPLETED,
  payload: users,
  nextPageToken,
  totalSize,
  refresh,
});

export const listUsersErrorAction = (error?: Error) => ({
  type: UserActionType.LIST_USERS_ERROR,
  payload: error,
});

export const addUserToOrganizationAction = (
  req: AddUserToOrganizationRequest,
) => ({
  type: UserActionType.ADD_USER_TO_ORGANIZATION,
  payload: req,
});

export const addUserToOrganizationCompletedAction = (user: User) => ({
  type: UserActionType.ADD_USER_TO_ORGANIZATION_COMPLETED,
  payload: user,
});

export const addUserToOrganizationErrorAction = (error: Error | undefined) => ({
  type: UserActionType.ADD_USER_TO_ORGANIZATION_ERROR,
  payload: error,
});

export const setSelectedOrgInfo = (orgInfo: OrgInfo) => ({
  type: UserActionType.SET_SELECTED_ORG_INFO,
  payload: orgInfo,
});

export const setUpdatedUserAction = (user?: User) => ({
  type: UserActionType.SET_UPDATED_USER,
  payload: user,
});

export const setAddedUserAction = (user?: User) => ({
  type: UserActionType.SET_ADDED_USER,
  payload: user,
});

export const updateGoogleTokenAction = (scope: string) => ({
  type: UserActionType.UPDATE_GOOGLE_TOKEN,
  payload: { scope },
});

export const updateGoogleTokenCompletedAction = () => ({
  type: UserActionType.UPDATE_GOOGLE_TOKEN_COMPLETED,
});

export const updateGoogleTokenErrorAction = (error?: Error) => ({
  type: UserActionType.UPDATE_GOOGLE_TOKEN_ERROR,
  payload: error,
});

export const clearGoogleTokenUpdateStatusAction = () => ({
  type: UserActionType.CLEAR_GOOGLE_TOKEN_UPDATE_STATUS,
});

export const getUserPermissionsCompletedAction = (permissions: string[]) => ({
  type: UserActionType.GET_USER_PERMISSIONS_COMPLETED,
  payload: permissions,
});

export const getUserPermissionsErrorAction = (error: Error) => ({
  type: UserActionType.GET_USER_PERMISSIONS_ERROR,
  payload: error,
});
