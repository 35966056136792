import { Box } from '@mui/material';
import React from 'react';
import { ReactComponent as GreenCircle } from '../../../static/icons/circle-filled-green.svg';
import { ReactComponent as GreyCircle } from '../../../static/icons/circle-filled-grey.svg';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { Schedule, ScheduleScheduleStatus } from 'protos/pb/v1alpha1/schedule';
import { formatDateTime } from '../../../utils/helpers';
import { getRecurrencePattern } from '../details/Schedule-table-helper';

const RunSchedule: React.FC<{
  schedule?: Schedule;
  tooltipChild?: React.ReactNode;
}> = ({ schedule, tooltipChild }) => {
  const { date, time } = formatDateTime(
    schedule?.futureActionTimes?.[0],
    'MM / DD / YYYY',
    'HH:mma',
  );

  return (
    <Box display={'flex'} gap={'12px'} alignItems={'center'}>
      {schedule?.status === ScheduleScheduleStatus.ACTIVE ? (
        <GreenCircle />
      ) : (
        <GreyCircle />
      )}
      <Box>
        <OrbyTypography
          color={OrbyColorPalette['grey-900']}
          size='sm'
          weight='medium'
          sx={{ display: 'inline-block' }}
        >
          Next run: {date} {time}
        </OrbyTypography>
        {
          <OrbyTypography color={OrbyColorPalette['grey-500']} size='sm'>
            {schedule?.config?.schedulePattern?.recurrencePattern
              ?.noRecurrence ? (
              'No Recurrence'
            ) : (
              <>
                Repeat{' '}
                {getRecurrencePattern(
                  schedule?.config?.schedulePattern?.recurrencePattern,
                )}
              </>
            )}{' '}
            {schedule?.status === ScheduleScheduleStatus.ACTIVE
              ? '· Enabled'
              : ''}
          </OrbyTypography>
        }
      </Box>

      {tooltipChild}
    </Box>
  );
};

export default RunSchedule;
