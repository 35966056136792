import React, { useState } from 'react';

const SkipLink: React.FC = () => {
  const [isFocused, setIsFocused] = useState(false);

  const handleSkipLinkClick = (
    event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event?.preventDefault();
    const mainContentElement = document.getElementById('main-content');
    if (mainContentElement) {
      mainContentElement.tabIndex = -1; // Make the main content focusable
      mainContentElement.focus(); // Move focus to the main content
      mainContentElement.removeAttribute('tabIndex'); // Remove the tabIndex attribute after focusing
    }
  };

  return (
    <a
      href='#main-content'
      onClick={handleSkipLinkClick}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      style={{
        position: 'absolute',
        top: '72px',
        left: '88px',
        padding: '3px',
        borderRadius: '3px',
        outline: '2px solid red',
        pointerEvents: isFocused ? 'auto' : 'none',
        opacity: isFocused ? 1 : 0, // Show the skip link when it is focused (opacity: 1), otherwise hide it (opacity: 0)
      }}
    >
      Skip to main content
    </a>
  );
};

export default React.memo(SkipLink);
