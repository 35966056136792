export const setCookie = (name: string, value: string, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/; secure';
};

export const getCookie = (name: string): string | null => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const parseCookie = (cookieString: string, separator = ',') => {
  // Initialize an empty object to hold the parsed cookies
  const cookies: Record<any, any> = {};

  // Split the cookie string by desired separator to get individual cookie pairs
  const cookiePairs = cookieString.split(separator);

  // Iterate over each cookie pair
  cookiePairs.forEach((cookie) => {
    // Split each cookie pair by '=' to get the name and value
    const [name, value] = cookie.split('=');

    // add  name and value to the cookies object
    cookies[name] = value;
  });

  return cookies;
};
