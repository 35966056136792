import {
  DocumentEntity,
  DocumentPageAnchor,
} from 'protos/google/cloud/documentai/v1/document';
import { boxPositionValuesUtilV2 } from './BoxPositionValuesUtilV2';
import { checkIfNotesEntityType, isNestedEntity } from './entities';
import { isEqual } from 'lodash';

// To get the sequence of root level of entities
/**
 * The getEntitiesInSequence function takes an array of DocumentEntity objects and sorts them based on certain criteria.
 *  - First, If nested entity it assigns location (pageAnchor) of the first child to the parent.
 *  - Then, it sorts the entities in two stages:
 *    - It checks if they (a, b) are Notes entities and sorts them alphabetically by type.
 *    - Else If a is Notes entity then sort by [b, a]
 *    - Else If b is Notes entity then sort by  [a, b]
 *    - Else sort by page reference y coordinate.
 *  - It further sorts the entities based on their page references, ensuring they are in the expected page order.
 *
 * The function returns the sorted DocumentEntity array as per the specified sequence.
 */
export const getEntitiesInSequence = (
  docEntities: DocumentEntity[],
  forNestedEntities = false,
): DocumentEntity[] => {
  docEntities = assignParentOftheFirstChildLocation(docEntities);
  const sortedEntities = docEntities
    .sort((a, b) => {
      const isANotesEntity = checkIfNotesEntityType(a.type as string);
      const isBNotesEntity = checkIfNotesEntityType(b.type as string);

      if (isANotesEntity && isBNotesEntity) {
        // If both are extra then sort by name
        return (a.type as string).localeCompare(b.type as string);
      } else if (isANotesEntity) {
        // If a is extra then sort by [b, a]
        return 1;
      } else if (isBNotesEntity) {
        // If b is extra then sort by  [a, b]
        return -1;
      }

      return sortByPosition(a, b, forNestedEntities) as number;
    })
    .sort((a, b) => {
      return (
        (a.pageAnchor?.pageRefs?.[0]?.page as number) -
        (b.pageAnchor?.pageRefs?.[0]?.page as number)
      );
    });

  /**
   * Now sort the entities and put the empty entities at the end
   */
  const emptyEntities: DocumentEntity[] = []; // Entities which are empty doesn't have value in mentionText
  const halfEmptyEntities: DocumentEntity[] = []; // Entities which have value in mentionText but doesn't have text segments
  const nonEmptyEntities: DocumentEntity[] = []; // Entities which have value in mentionText and has value in text segments
  for (const entity of sortedEntities) {
    if (entity.mentionText && entity.textAnchor) {
      nonEmptyEntities.push(entity);
    } else {
      if (entity.mentionText && !entity.textAnchor) {
        halfEmptyEntities.push(entity);
      } else {
        emptyEntities.push(entity);
      }
    }
  }
  return [...nonEmptyEntities, ...halfEmptyEntities, ...emptyEntities];
};

/**
 * This function sorts the entities using their position from left to right/ top to bottom
 * If the entities' bounding boxes are positioned in such a way that the difference of pixels in their
 * first side coordinates (say top) is less than 20, then we sort them by calculating the
 * difference for the next side and it goes on until last side of the box is reached
 * e.g. if two boxes are present 30, 40 pixels from top, the difference is 10 which is less than 20, so we check
 * bottom side, if it is also less than 20, we check left side and then right side. This facilitates to sort
 * entities from top to bottom/ left to right
 */
export const sortByPosition = (
  firstEntity: DocumentEntity,
  secondEntity: DocumentEntity,
  hasNestedEntity = false,
) => {
  let firstEntityPageAnchor, secondEntityPageAnchor;
  // If both entities are nested with same type, then we will further check whether
  // first child is same or not, if yes, we will use other child info to sort
  if (
    firstEntity.properties?.length &&
    secondEntity.properties?.length &&
    firstEntity.type === secondEntity.type
  ) {
    const pageAnchorForDifferentNestedEntities =
      getPageAnchorForDifferentNestedEntities(firstEntity, secondEntity);
    firstEntityPageAnchor =
      pageAnchorForDifferentNestedEntities.firstEntityPageAnchor;
    secondEntityPageAnchor =
      pageAnchorForDifferentNestedEntities.secondEntityPageAnchor;
  } else {
    firstEntityPageAnchor = firstEntity.pageAnchor;
    secondEntityPageAnchor = secondEntity.pageAnchor;
  }
  const firstEntityVertices =
    firstEntityPageAnchor?.pageRefs?.[0]?.boundingPoly?.vertices || [];
  // If no vertices data is present for first entity, second entity should appear on top
  if (!firstEntityVertices.length) return 1;
  const secondEntityVertices =
    secondEntityPageAnchor?.pageRefs?.[0]?.boundingPoly?.vertices || [];
  // If no vertices data is present for second entity, first entity should appear on top
  if (!secondEntityVertices.length) return -1;

  const firstEntityTopLeftVertex =
    boxPositionValuesUtilV2.getTopLeftCornerVertices(firstEntityVertices);
  const secondEntityTopLeftVertex =
    boxPositionValuesUtilV2.getTopLeftCornerVertices(secondEntityVertices);
  const firstEntityBottomRightVertex =
    boxPositionValuesUtilV2.getBottomRightCornerVertices(firstEntityVertices);
  const secondEntityBottomRightVertex =
    boxPositionValuesUtilV2.getBottomRightCornerVertices(secondEntityVertices);

  if (!hasNestedEntity) {
    // Comparing top y coord of both entities to see which one lies below
    const topYDiff: number =
      (firstEntityTopLeftVertex?.y as number) -
      (secondEntityTopLeftVertex?.y as number);

    // If difference is more than 20 pixels, then we sort them from top to bottom
    if (Math.abs(topYDiff) > 20) return topYDiff;

    // Comparing bottom y coord of both entities to see which one lies below
    const bottomYDiff: number =
      (firstEntityBottomRightVertex?.y as number) -
      (secondEntityBottomRightVertex?.y as number);

    // If difference is more than 20 pixels, then again we sort them from top to bottom
    if (Math.abs(bottomYDiff) > 20) return bottomYDiff;
  }

  // Comparing top left x coord of both entities to see which one lies to the right
  const leftXDiff: number =
    (firstEntityTopLeftVertex?.x as number) -
    (secondEntityTopLeftVertex?.x as number);

  // If difference is more than 20 pixels, then we sort them from left to right
  if (Math.abs(leftXDiff) > 20) return leftXDiff;

  // Comparing bottom right x coord of both entities to see which one lies to the right
  const rightXDiff: number =
    (firstEntityBottomRightVertex?.x as number) -
    (secondEntityBottomRightVertex?.x as number);

  // Sort them from left to right
  if (Math.abs(rightXDiff) > 20) return rightXDiff;

  // Sort alphabetically
  return firstEntity?.type?.localeCompare(secondEntity?.type as string);
};

/**
 * Handling Entities Sequence
 */

// This facilitates the ordering of the entities, because the parent bears no
// position, so we endow the first child's position to it
export const assignParentOftheFirstChildLocation = (
  docEntities: DocumentEntity[],
): DocumentEntity[] => {
  for (const entity of docEntities) {
    if (
      isNestedEntity(entity) &&
      entity?.properties &&
      entity.properties.length
    ) {
      entity.pageAnchor = getEntitiesInSequence(
        entity.properties,
      )[0].pageAnchor; // This is temporary assignment used for sorting the entities only
    }
  }
  return docEntities;
};

// Used to check whether the entity is in the same place on the document.
export const isSamePredictedEntity = (
  entity1: DocumentEntity,
  entity2: DocumentEntity,
): boolean => {
  if (entity1.type !== entity2.type) {
    return false;
  }
  if (entity1.mentionText !== entity2.mentionText) {
    return false;
  }
  if (
    entity1.textAnchor !== undefined &&
    entity2.textAnchor !== undefined &&
    isEqual(entity1.textAnchor, entity2.textAnchor)
  ) {
    return false;
  }
  if (
    entity1.pageAnchor !== undefined &&
    entity2.pageAnchor !== undefined &&
    isEqual(entity1.pageAnchor, entity2.pageAnchor)
  ) {
    return false;
  }
  return true;
};

export const getPageAnchorForDifferentNestedEntities = (
  firstEntity: DocumentEntity,
  secondEntity: DocumentEntity,
): {
  firstEntityPageAnchor: DocumentPageAnchor | undefined;
  secondEntityPageAnchor: DocumentPageAnchor | undefined;
} => {
  let firstEntityPageAnchor, secondEntityPageAnchor;
  const firstEntityChildEntitiesLength = firstEntity.properties?.length || 1;
  const secondEntityChildEntitiesLength = secondEntity.properties?.length || 1;
  const iterationLength = Math.min(
    firstEntityChildEntitiesLength,
    secondEntityChildEntitiesLength,
  );
  // If only one child entity is present then that will be used for sorting
  if (iterationLength === 1) {
    return {
      firstEntityPageAnchor: firstEntity.properties![0].pageAnchor,
      secondEntityPageAnchor: secondEntity.properties![0].pageAnchor,
    };
  }
  for (let i = 0; i < iterationLength; i++) {
    // Checking if the position of entities are same, case will only fail if
    // case 1: entities are different
    // case 2: it is the last iteration, so we use last child entity's position as fallback
    if (
      isSamePredictedEntity(
        firstEntity.properties![i],
        secondEntity.properties![i],
      ) &&
      i < iterationLength - 1
    ) {
      continue;
    }
    firstEntityPageAnchor = firstEntity.properties![i].pageAnchor;
    secondEntityPageAnchor = secondEntity.properties![i].pageAnchor;
  }
  return { firstEntityPageAnchor, secondEntityPageAnchor };
};
