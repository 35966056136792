import React, { memo } from 'react';
import { Box, Tooltip } from '@mui/material';
import { ELLIPSIS_STYLE } from '../../utils/constants';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import UserIcon from '../../components/UserIcon';

interface Props {
  email: string;
  imageUrl?: string;
  fullName?: string;
  collapse: boolean;
}

// TODO: Remove this component and use UserCard instead
const DrawerUserCard: React.FC<Props> = ({
  email,
  imageUrl,
  fullName,
  collapse,
}) => {
  const displayName = fullName?.trim() || email;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        minHeight: '44px',
      }}
      width={'100%'}
    >
      <Box alignItems={'center'} display={'flex'}>
        <UserIcon
          imageUrl={imageUrl}
          fullName={fullName}
          email={email}
          textColor={OrbyColorPalette['white-0']}
          sx={{
            width: 32,
            height: 32,
            fontSize: 20,
          }}
        />
      </Box>
      <Tooltip
        title={
          <Box>
            <OrbyTypography
              size='xs'
              weight='semibold'
              color={OrbyColorPalette['grey-700']}
            >
              {displayName}
            </OrbyTypography>
            {fullName?.trim() && (
              <OrbyTypography
                size='xs'
                weight='semibold'
                color={OrbyColorPalette['grey-700']}
              >
                {email}
              </OrbyTypography>
            )}
          </Box>
        }
        arrow
        placement='right-end'
        PopperProps={{
          sx: {
            '& .MuiTooltip-arrow': { color: OrbyColorPalette['white-0'] },
            '& .MuiTooltip-tooltip': {
              backgroundColor: OrbyColorPalette['white-0'],
              borderRadius: '8px',
              padding: '8px 12px',
            },
          },
        }}
      >
        <Box
          sx={{
            display: collapse ? 'none' : 'flex',
            flexDirection: 'column',
            minWidth: '0px',
            transition: 'all 0.1s ease',
            opacity: collapse ? 0 : 1,
            pointerEvents: collapse ? 'none' : 'unset',
          }}
        >
          <OrbyTypography
            size='md'
            weight='semibold'
            color={OrbyColorPalette['white-0']}
            sx={{ ...ELLIPSIS_STYLE, textWrap: 'nowrap' }}
          >
            {displayName}
          </OrbyTypography>
          {fullName?.trim() && (
            <OrbyTypography
              size='sm'
              weight='regular'
              color={OrbyColorPalette['grey-400']}
              sx={{ ...ELLIPSIS_STYLE, textWrap: 'nowrap' }}
            >
              {email}
            </OrbyTypography>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default memo(DrawerUserCard);
