import { all, put, takeLatest } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { WorkflowSecretActionType } from '../actions/actions.constants';
import { secretsService } from '../../services/SecretsService';
import { WorkflowSecretStore } from '../reducers/workflow_secrets.reducer';
import {
  createSecretBlockAction,
  createSecretBlockCompletedAction,
  createSecretBlockErrorAction,
  deleteSecretBlockAction,
  fetchSecretsFromWorkflowAction,
  setSecretsInfoFromWorkflowAction,
  updateSecretBlockAction,
  updateSecretBlockCompletedAction,
  updateSecretBlockErrorAction,
} from '../actions/workflow_secrets.actions';
import {
  saveCurrentWorkflow,
  updateSecretIDsInWorkflowAction,
} from '../actions/workflow_details.constants';
import { workflowSecretSelector } from '../selectors/app.selectors';
import {
  workflowHasUnsavedChangesSelector,
  workflowSelector,
} from '../selectors/workflow_details.selectors';
import { getSecretBlockToActionIdMap } from '../../utils/OrbotWorkflowUtils';

/* This function should be called when a workflow is loaded.
 * It fetches all the secret blocks needed for the workflow, and sets up our
 * redux store with the secret blocks (workflowSecretStore). It keeps track of
 * which secret blocks haven't been created, or doesn't have the value set for a
 * particular user.
 *
 * For the secret blocks that exist and are set by the user, it fetches the
 * secret block and sets the secret IDs in the workflow.
 * It also fetches either the username or email of the secret block, so we can
 * show this in the UI
 *
 * For secret blocks that return with PERMISSION_DENIED, we don't have the real
 * secret IDs yet, so we can't set them until the user updates with their values
 * and we send a updateSecretBlock request to the server. For secret blocks that
 * don't exist, we create them after the user fills in with their values.
 */
function* fetchSecretsFromWorkflow(
  action: ReturnType<typeof fetchSecretsFromWorkflowAction>,
) {
  const { workflow } = action.payload;
  const workflowSecretStore: WorkflowSecretStore = {};

  // 1. Build map of gotoURL -> {rootActionID: set,  secretValues: set}
  // Use this to reference which gotoURLs we need to fetch secret blocks for
  // Keep info on which root action has this goto URL for rendering purposes
  // Keep information of secretValues we see in case the secretBlock is not created yet
  const secretBlockToActionIdMap = getSecretBlockToActionIdMap(workflow);

  // 2. Fetch all secret blocks first
  // TODO: Maybe filter based on which ones we need?
  const { response, error } = yield* call(secretsService.listSecretBlocks, {
    orgId: workflow.orgId,
  });

  if (error || !response?.secretBlocks) {
    console.error('Error fetching secret blocks', error);
  }

  // 3. Loop through each secretBlockToActionIdMap and build the workflowSecretStore,
  // updating the workflow's secretValues if necessary
  for (const [gotoURL, { rootActionIDs, secretValues }] of Object.entries(
    secretBlockToActionIdMap,
  )) {
    const secretBlock = response?.secretBlocks?.find(
      (block) => block.blockName === gotoURL,
    );
    if (secretBlock) {
      workflowSecretStore[gotoURL] = {
        actionIDs: rootActionIDs,
        secretBlock,
        isCreated: true,
      };

      // Dispatch action to update secret IDs in the workflow
      yield put(updateSecretIDsInWorkflowAction(workflowSecretStore[gotoURL]));
    } else {
      workflowSecretStore[gotoURL] = {
        actionIDs: rootActionIDs,
        secretBlock: {
          blockName: gotoURL,
          orgId: workflow.orgId,
          secretInfos: Array.from(secretValues).map((id) => ({
            // Note: ID is in template form (#username, #password, etc)
            // for secret that isn't created yet
            secret: { id },
          })),
        },
        isCreated: false,
      };
    }
  }

  // 4. Save the workflow with API if there are any changes
  if (yield* select(workflowHasUnsavedChangesSelector)) {
    yield put(saveCurrentWorkflow());
  }
  yield put(setSecretsInfoFromWorkflowAction({ workflowSecretStore }));
}

// in this function, if updateWorkflow is set to a workflow, then
// call updateSecretIDsInWorkflow
export function* createSecretBlockSaga(
  action: ReturnType<typeof createSecretBlockAction>,
) {
  try {
    const wfSecretStore = (yield* select(workflowSecretSelector))
      .workflowSecretStore;
    const secretStoreItem =
      wfSecretStore[action.payload.secretBlock.blockName || ''];
    if (!secretStoreItem) {
      throw new Error('Secret block not found');
    }

    const { response, error } = yield* call(
      secretsService.createSecretBlock,
      action.payload.secretBlock,
    );
    if (response?.secretBlock) {
      yield put(
        createSecretBlockCompletedAction({
          secretBlock: response.secretBlock,
        }),
      );

      // Update the secret IDs
      const newSecretStoreItem = (yield* select(workflowSecretSelector))
        .workflowSecretStore[action.payload.secretBlock.blockName || ''];

      yield put(updateSecretIDsInWorkflowAction(newSecretStoreItem));

      // Now, save the workflow (calls the updateWorkflow API)
      yield put(saveCurrentWorkflow());
    } else if (error) {
      yield put(createSecretBlockErrorAction({ error }));
    }
  } catch (error) {
    console.error('Other error in createSecretBlockSaga', error);
    yield put(createSecretBlockErrorAction({ error }));
  }
}

export function* updateSecretBlockSaga(
  action: ReturnType<typeof updateSecretBlockAction>,
) {
  try {
    const wfSecretStore = (yield* select(workflowSecretSelector))
      .workflowSecretStore;
    const secretStoreItem = wfSecretStore[action.payload.req.blockName || ''];

    const { response, error } = yield* call(
      secretsService.updateSecretBlock,
      action.payload.req,
    );
    if (response?.secretBlock) {
      yield put(
        updateSecretBlockCompletedAction({
          secretBlock: response.secretBlock,
        }),
      );
      yield put(updateSecretIDsInWorkflowAction(secretStoreItem));
    } else {
      yield put(updateSecretBlockErrorAction({ error: error as Error }));
    }
  } catch (error) {
    console.error('Other error in updateSecretBlockSaga', error);
    yield put(updateSecretBlockErrorAction({ error: error as Error }));
  }
}

export function* deleteSecretBlockSaga(
  action: ReturnType<typeof deleteSecretBlockAction>,
) {
  try {
    const { error } = yield* call(secretsService.deleteSecretBlock, {
      id: action.payload.secretBlock.id,
      orgId: action.payload.secretBlock.orgId,
    });
    if (error) {
      throw error instanceof Error
        ? error
        : new Error(error || 'Unknown error');
    }
    const workflow = yield* select(workflowSelector);
    if (!workflow) {
      throw new Error('Workflow not found');
    }

    // TODO: Update the state better. We should update the secret block in the store
    // instead of refetching all the secrets from the workflow.
    // TODO: Bug. We need to also update the secret IDs in the workflow back to the templaet
    // form if the secret block is deleted.
    yield put(fetchSecretsFromWorkflowAction({ workflow }));
  } catch (error) {
    console.error('Other error in deleteSecretBlockSaga', error);
  }
}

export default function* secretSaga() {
  // yield takeLatest(WorkflowSecretActionType.FETCH_SECRET, fetchSecret);
  yield all([
    takeLatest(
      WorkflowSecretActionType.FETCH_SECRETS_FROM_WORKFLOW,
      fetchSecretsFromWorkflow,
    ),
    takeLatest(
      WorkflowSecretActionType.CREATE_SECRET_BLOCK,
      createSecretBlockSaga,
    ),
    takeLatest(
      WorkflowSecretActionType.UPDATE_SECRET_BLOCK,
      updateSecretBlockSaga,
    ),
    takeLatest(
      WorkflowSecretActionType.DELETE_SECRET_BLOCK,
      deleteSecretBlockSaga,
    ),
  ]);
}
