import { Box, SxProps, Theme } from '@mui/material';
import React, { memo } from 'react';
import OrbyTypography from '../../../typography/OrbyTypography';
interface OrbyBodyLabelProps {
  label: JSX.Element | string;
  color: string;
  fontWeight: 'regular' | 'medium' | 'semibold' | 'bold';
  fontSize: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'display-sm' | 'display-xs';
  title?: string;
  /**
   * Box sx
   */
  boxSx?: SxProps<Theme>;
}

const OrbyBodyLabel: React.FC<OrbyBodyLabelProps> = ({
  label,
  fontSize,
  fontWeight,
  color,
  title = '',
  boxSx,
}) => {
  return (
    <Box
      title={title}
      display={'flex'}
      maxWidth={'100%'}
      alignItems={'center'}
      sx={{ ...boxSx }}
    >
      <OrbyTypography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2, // max no of lines
          wordBreak: 'break-word',
          textWrap: 'wrap',
        }}
        size={fontSize}
        weight={fontWeight}
        color={color}
      >
        {label}
      </OrbyTypography>
    </Box>
  );
};

export default memo(OrbyBodyLabel);
