import React, { useMemo, useState } from 'react';
import { Avatar, SxProps, Theme } from '@mui/material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { addAlpha, getInitials } from '../utils/helpers';
import { colorValuesUtil } from '../utils/ColorValuesUtil';

interface UserIconProps {
  imageUrl?: string;
  fullName?: string;
  email: string;
  sx?: SxProps<Theme>;
  textColor?: string;
}

const UserIcon: React.FC<UserIconProps> = ({
  imageUrl,
  fullName,
  email,
  sx = {},
  textColor = OrbyColorPalette['grey-900'],
}) => {
  const [imgError, setImgError] = useState(false);
  const displayName = fullName?.trim() || email;

  const initials = useMemo(() => getInitials(displayName), [displayName]);

  return imgError || !imageUrl ? (
    <Avatar
      alt='user-initials'
      sx={{ ...sx, bgcolor: addAlpha(colorValuesUtil.getColor(email), 0.4) }}
    >
      <OrbyTypography size='md' weight='medium' color={textColor}>
        {initials}
      </OrbyTypography>
    </Avatar>
  ) : (
    <Avatar
      alt='user-photo'
      sx={sx}
      slotProps={{
        img: { referrerPolicy: 'no-referrer' }, // this is added for security purpose
      }}
      src={imageUrl}
      onError={() => setImgError(true)}
    />
  );
};

export default React.memo(UserIcon);
