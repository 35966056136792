import React, { memo, FC, useEffect, useState, useRef } from 'react';
import { Box, Dialog } from '@mui/material';
import warningIcon from '../../static/icons/logout-warning-icon.svg';
import { OrbyButton, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { OpenInNew } from '@mui/icons-material';
import { storageService } from '../../services/StorageService';
import {
  IDLE_SESSION_TIMEOUT_POPUP_ID,
  SESSION_BROADCAST_ACTIONS,
  SESSION_TIMEOUT_POPUP_ID,
  SESSION_LOGOUT_WARNING_THRESHOLD_MS,
} from '../../utils/constants';

interface Props {
  isSessionExpired: boolean;
  open: boolean;
  onDismiss: () => void;
  onLogIn: () => void;
  display: string;
}

const WarningPopup: FC<Props> = ({
  isSessionExpired,
  open,
  onDismiss,
  onLogIn,
  display,
}) => {
  return (
    <Dialog
      id={SESSION_TIMEOUT_POPUP_ID}
      open={open}
      style={{ display }}
      PaperProps={{
        sx: {
          width: '400px',
          padding: '24px',
          borderRadius: '12px',
          gap: '32px',
        },
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <img src={warningIcon} alt='Hello Icon' />
        <OrbyTypography
          weight='semibold'
          size='lg'
          sx={{ paddingBottom: '6px', paddingTop: '10px' }}
        >
          {isSessionExpired
            ? 'Your session has expired'
            : 'Your session is about to expire in 15 min'}
        </OrbyTypography>
        <OrbyTypography sx={{ textAlign: 'start' }}>
          For security reasons, please sign in again to renew your session.
          Click ‘Log In’ and enter your credentials. Don’t worry, your progress
          on this page will be saved.
        </OrbyTypography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        {!isSessionExpired && (
          <OrbyButton
            onClick={onDismiss}
            variant='primary-outline'
            label='Dismiss'
            size='large'
            sx={{
              paddingX: '54px',
              color: OrbyColorPalette['grey-700'],
              border: `1px solid ${OrbyColorPalette['grey-300']}`,
              '&:hover, &:focus': {
                color: OrbyColorPalette['purple-600'],
                border: `1px solid ${OrbyColorPalette['purple-600']}`,
              },
            }}
          />
        )}
        <OrbyButton
          onClick={onLogIn}
          variant='primary-contained'
          startIcon={<OpenInNew />}
          label='Log In'
          size='large'
          sx={{
            paddingX: isSessionExpired ? '139.5px' : '49px',
            background: OrbyColorPalette['purple-600'],
            border: `1px solid ${OrbyColorPalette['purple-600']}`,
            '&:hover, &:focus': {
              background: OrbyColorPalette['purple-800'],
              border: `1px solid ${OrbyColorPalette['purple-800']}`,
            },
          }}
        />
      </Box>
    </Dialog>
  );
};

const SessionTimeoutManager: FC = () => {
  const [showWarning, setShowWarning] = useState(false);
  // BroadcastChannel to synchronize state between multiple tabs
  const broadcastChannelRef = useRef<BroadcastChannel>(
    new BroadcastChannel('SessionLogoutChannel'),
  );
  const [isSessionExpired, setIsSessionExpired] = useState(false); // To track whether session is expired

  const handleLogIn = () => {
    localStorage.setItem('reauthenticate', 'true');
    const newWindow = window.open(`/login`, 'reauthenticate'); // Open new tab with login
    if (newWindow) newWindow.name = 'reauthenticate';
  };

  const handleDismiss = () => {
    // Once Warning is dismissed we don't need to show that warning again until
    // user log in again
    storageService.setIsSessionExpirationWarningShown(true);
    setShowWarning(false);
    // Broadcast visibility state to other tabs
    broadcastChannelRef.current.postMessage({
      type: SESSION_BROADCAST_ACTIONS.SYNC_SESSION_STATE,
      visibility: false,
    });
  };

  // Handle broadcast channel messages for cross-tab communication
  useEffect(() => {
    const broadcastChannelApi = broadcastChannelRef.current;
    if (broadcastChannelApi) {
      broadcastChannelApi.onmessage = (event) => {
        switch (event.data.type) {
          case SESSION_BROADCAST_ACTIONS.SYNC_SESSION_STATE: {
            const data = event.data;
            if (data.visibility !== showWarning) {
              setShowWarning(data.visibility); // Sync only if visibility has changed
            }
            if (data.isReAuthenticated) {
              setIsSessionExpired(false);
            }
            break;
          }
        }
      };
    }
  }, [showWarning]);

  useEffect(() => {
    const checkSessionExpiration = () => {
      // Skip if:
      // 1. Session is already expired
      // 2. `session expiration time` is not available (refreshTokenExpireAt)
      if (isSessionExpired || !storageService.getSessionExpirationTime()) {
        return;
      }

      const remainingTime =
        storageService.getSessionExpirationTime() - Date.now();
      if (remainingTime <= 0) {
        setIsSessionExpired(true);
      } else if (remainingTime <= SESSION_LOGOUT_WARNING_THRESHOLD_MS) {
        // Show warning popup when session is nearing expiration
        // Only show if the warning hasn't been displayed yet
        const isWarningShownOnce =
          storageService.getIsSessionExpirationWarningShown();
        if (!isWarningShownOnce) {
          setShowWarning(true);
        }
      }
    };

    const intervalId = setInterval(checkSessionExpiration, 1000);
    // Cleanup function to clear the interval
    return () => {
      clearInterval(intervalId);
    };
  }, [isSessionExpired]);

  return (
    <WarningPopup
      isSessionExpired={isSessionExpired}
      open={showWarning || isSessionExpired}
      onDismiss={handleDismiss}
      // Hide popup if idle session manager is open
      display={
        document.getElementById(IDLE_SESSION_TIMEOUT_POPUP_ID)
          ? 'none'
          : 'block'
      }
      onLogIn={handleLogIn}
    />
  );
};

export default memo(SessionTimeoutManager);
