import {
  CreateFeatureFlagRequest,
  DeleteFeatureFlagRequest,
  FeatureFlag,
  ListFeatureFlagsRequest,
  UpdateFeatureFlagRequest,
  GetFeatureFlagRequest,
  GetFeatureFlagsForOrgAndUserResponse,
  GetFeatureFlagsForOrgAndUserRequest,
} from 'protos/pb/v1alpha2/feature_flag_service';
import { FeatureFlagActionType } from './actions.constants';

export const listFeatureFlagsAction = (
  req: ListFeatureFlagsRequest,
  refresh = false,
) => {
  return {
    type: FeatureFlagActionType.LIST_FEATURE_FLAGS,
    payload: req,
    refresh,
  };
};

export const listFeatureFlagsCompletedAction = (
  featureFlags: any,
  totalSize: number,
  refresh: boolean,
) => {
  return {
    type: FeatureFlagActionType.LIST_FEATURE_FLAGS_COMPLETED,
    payload: featureFlags,
    totalSize,
    refresh,
  };
};

export const listFeatureFlagsErrorAction = (error?: Error) => {
  return {
    type: FeatureFlagActionType.LIST_FEATURE_FLAGS_ERROR,
    payload: error,
  };
};

export const createFeatureFlagAction = (req: CreateFeatureFlagRequest) => {
  return {
    type: FeatureFlagActionType.CREATE_FEATURE_FLAG,
    payload: req,
  };
};

export const createFeatureFlagCompletedAction = (featureFlag: FeatureFlag) => {
  return {
    type: FeatureFlagActionType.CREATE_FEATURE_FLAG_COMPLETED,
    payload: featureFlag,
  };
};

export const createFeatureFlagErrorAction = (error?: Error) => {
  return {
    type: FeatureFlagActionType.CREATE_FEATURE_FLAG_ERROR,
    payload: error,
  };
};

export const updateFeatureFlagAction = (req: UpdateFeatureFlagRequest) => {
  return {
    type: FeatureFlagActionType.UPDATE_FEATURE_FLAG,
    payload: req,
  };
};

export const updateFeatureFlagCompletedAction = (featureFlag: FeatureFlag) => {
  return {
    type: FeatureFlagActionType.UPDATE_FEATURE_FLAG_COMPLETED,
    payload: featureFlag,
  };
};

export const updateFeatureFlagErrorAction = (error?: Error) => {
  return {
    type: FeatureFlagActionType.UPDATE_FEATURE_FLAG_ERROR,
    payload: error,
  };
};

export const deleteFeatureFlagAction = (req: DeleteFeatureFlagRequest) => {
  return {
    type: FeatureFlagActionType.DELETE_FEATURE_FLAG,
    payload: req,
  };
};

export const deleteFeatureFlagCompletedAction = () => {
  return {
    type: FeatureFlagActionType.DELETE_FEATURE_FLAG_COMPLETED,
  };
};

export const deleteFeatureFlagErrorAction = (error?: Error) => {
  return {
    type: FeatureFlagActionType.DELETE_FEATURE_FLAG_ERROR,
    payload: error,
  };
};

export const setSelectedFeatureFlagAction = (featureFlag?: FeatureFlag) => {
  return {
    type: FeatureFlagActionType.SET_SELECTED_FEATURE_FLAG,
    payload: featureFlag,
  };
};

export const getFeatureFlagAction = (req: GetFeatureFlagRequest) => {
  return {
    type: FeatureFlagActionType.GET_FEATURE_FLAG,
    payload: req,
  };
};

export const getFeatureFlagCompletedAction = (featureFlag: FeatureFlag) => {
  return {
    type: FeatureFlagActionType.GET_FEATURE_FLAG_COMPLETED,
    payload: featureFlag,
  };
};

export const getFeatureFlagErrorAction = (error?: Error) => {
  return {
    type: FeatureFlagActionType.GET_FEATURE_FLAG_ERROR,
    payload: error,
  };
};

export const getFeatureFlagsForOrgAndUserAction = (
  req: GetFeatureFlagsForOrgAndUserRequest,
) => {
  return {
    type: FeatureFlagActionType.GET_FEATURE_FLAGS_FOR_ORG_AND_USER,
    payload: req,
  };
};

export const getFeatureFlagsForOrgAndUseCompletedAction = (
  resp: GetFeatureFlagsForOrgAndUserResponse,
) => {
  return {
    type: FeatureFlagActionType.GET_FEATURE_FLAGS_FOR_ORG_AND_USER_COMPLETED,
    payload: resp.featureFlags,
  };
};

export const getFeatureFlagsForOrgAndUserErrorAction = (error?: Error) => {
  return {
    type: FeatureFlagActionType.GET_FEATURE_FLAGS_FOR_ORG_AND_USER_ERROR,
    payload: error,
  };
};

export const updateFeatureFlagsLoaderForOrgAndUserErrorAction = (
  status: boolean,
) => {
  return {
    type: FeatureFlagActionType.UPDATE_FEATURE_FLAGS_LOADER_FOR_ORG_AND_USER,
    payload: status,
  };
};
