import { all, call, put, takeLatest } from 'redux-saga/effects';
import { DashboardActionType } from '../actions/actions.constants';
import {
  getDashboardStatsCompletedAction,
  getDashboardStatsErrorAction,
} from '../actions/dashboard.action';
import { dashboardService } from '../../services/DashboardService';
import store from '../../redux/store';
import {
  GetDashboardStatsRequest,
  GetDashboardStatsResponse,
} from 'protos/pb/v1alpha2/dashboard_service';
import { Observable } from 'rxjs';

export function* getDashboardStats(data: {
  type: DashboardActionType;
  payload: GetDashboardStatsRequest;
}): any {
  try {
    const {
      response,
      error,
    }: { response: Observable<GetDashboardStatsResponse>; error: Error } =
      yield call(dashboardService.getDashboardStats, data.payload);
    if (response) {
      response.subscribe({
        next(x) {
          store.dispatch(getDashboardStatsCompletedAction(x));
        },
        error(err) {
          store.dispatch(getDashboardStatsErrorAction(err));
        },
      });
    } else {
      yield put(getDashboardStatsErrorAction(error));
    }
  } catch (error) {
    yield put(getDashboardStatsErrorAction(error as Error));
  }
}
function* dashboardSaga() {
  yield all([
    takeLatest(DashboardActionType.GET_DASHBOARD_STATS, getDashboardStats),
  ]);
}

export default dashboardSaga;
