import { DocumentEntity } from 'protos/google/cloud/documentai/v1/document';

export const TEXT_ANCHOR_SEPARATOR = '<=|||||=>';

/**
 * This function is used to identify if the entity is a nested entity or not
 * @param {DocumentEntity} entity
 * @returns
 */
export const isNestedEntity = (entity: DocumentEntity) => {
  return (entity?.properties?.length as number) > 0;
};

export const checkIfNestedEntityType = (entity: string): boolean => {
  const entityNesting = entity.split('/');
  if (entityNesting.length !== 2) {
    return false;
  }
  return entityNesting[0].length > 0 && entityNesting[1].length > 0;
};

export const checkIfNotesEntityType = (entity: string): boolean => {
  return entity.startsWith('*');
};

export const isInvalidNotesEntity = (entityName: string) => {
  // Check if entity name contains the special notes indicator '*'
  if (entityName.includes('*')) {
    // Return true if the entityName does not start with '*' or if it contains more than one '*'
    return !entityName.startsWith('*') || entityName.split('*').length > 2;
  }
};

export const hasInvalidCharacters = (entityName: string) => {
  // Check if the entityName contains any characters other than
  // letters, numbers, spaces, notes "*", underscores "_", or dashes "-"
  const regex = /^[a-zA-Z0-9 *_-]*$/;
  return !regex.test(entityName);
};

export const hasInvalidClassificationCharacters = (entityName: string) => {
  // Check if the entityName contains any characters other than
  // letters, numbers, spaces underscores "_", or dashes "-"
  const regex = /^[a-zA-Z0-9 _-]*$/;
  return !regex.test(entityName);
};
