import { Connector } from 'protos/pb/v1alpha2/connector';
import {
  DeleteConnectorRequest,
  ListConnectorRequest,
  UpdateConnectorRequest,
} from 'protos/pb/v1alpha2/connector_service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ConnectorActionType } from '../actions/actions.constants';
import {
  createConnectorCompletedAction,
  createConnectorErrorAction,
  deleteConnectorCompletedAction,
  deleteConnectorErrorAction,
  getConnectorCompletedAction,
  getConnectorErrorAction,
  listConnectorCompletedAction,
  listConnectorErrorAction,
  updateConnectorCompletedAction,
  updateConnectorErrorAction,
} from '../actions/connector.action';
import { connectorService } from '../../services/ConnectorService';

export function* listConnectorsSaga(data: {
  type: ConnectorActionType.LIST_CONNECTORS;
  req: ListConnectorRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(
      connectorService.listConnectors,
      data.req,
    );
    if (response) {
      yield put(
        listConnectorCompletedAction(
          response.connectors,
          response.nextPageToken,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listConnectorErrorAction(error));
    }
  } catch (error) {
    yield put(listConnectorErrorAction(error as Error));
  }
}

export function* createConnectorSaga(data: {
  type: ConnectorActionType.CREATE_CONNECTOR;
  payload: Connector;
}): any {
  try {
    const { response, error } = yield call(
      connectorService.createConnector,
      data.payload,
    );
    if (response) {
      yield put(createConnectorCompletedAction(response));
    } else {
      yield put(createConnectorErrorAction(error));
    }
  } catch (error) {
    yield put(createConnectorErrorAction(error as Error));
  }
}

export function* updateConnectorSaga(data: {
  type: ConnectorActionType.UPDATE_CONNECTOR;
  payload: UpdateConnectorRequest;
}): any {
  try {
    const { response, error } = yield call(
      connectorService.updateConnector,
      data.payload,
    );
    if (response) {
      yield put(updateConnectorCompletedAction(response));
    } else {
      yield put(updateConnectorErrorAction(error));
    }
  } catch (error) {
    yield put(updateConnectorErrorAction(error as Error));
  }
}

export function* deleteConnectorSaga(data: {
  type: ConnectorActionType.DELETE_CONNECTOR;
  payload: DeleteConnectorRequest;
}): any {
  try {
    yield call(connectorService.deleteConnector, data.payload);
    yield put(deleteConnectorCompletedAction(data.payload.name!));
  } catch (e: any) {
    yield put(
      deleteConnectorErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

export function* getConnectorSaga(data: {
  type: ConnectorActionType.GET_CONNECTOR;
  payload: string;
}): any {
  try {
    const { response, error } = yield call(
      connectorService.getConnector,
      data.payload,
    );
    if (response) {
      yield put(getConnectorCompletedAction(response));
    } else {
      yield put(getConnectorErrorAction(error));
    }
  } catch (e: any) {
    yield put(getConnectorErrorAction((e?.errors && e.errors[0]) || e));
  }
}

function* connectorSaga() {
  yield all([
    takeLatest(ConnectorActionType.LIST_CONNECTORS, listConnectorsSaga),
    takeLatest(ConnectorActionType.CREATE_CONNECTOR, createConnectorSaga),
    takeLatest(ConnectorActionType.UPDATE_CONNECTOR, updateConnectorSaga),
    takeLatest(ConnectorActionType.DELETE_CONNECTOR, deleteConnectorSaga),
    takeLatest(ConnectorActionType.GET_CONNECTOR, getConnectorSaga),
  ]);
}

export default connectorSaga;
