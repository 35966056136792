export const getBadgeFontSize = (size: string) => {
  switch (size) {
    case 'small':
      return 'xs';
    case 'medium':
      return 'sm';
    case 'large':
      return 'sm';
    default:
      return 'xs'; // Default to 'xs'
  }
};

export const getBadgePaddingX = (size: string) => {
  switch (size) {
    case 'small':
      return '8px';
    case 'medium':
      return '10px';
    case 'large':
      return '12px';
    default:
      return '8px'; // Default to '8px'
  }
};

export const getBadgePaddingY = (size: string) => {
  switch (size) {
    case 'small':
      return '2px';
    case 'medium':
      return '2px';
    case 'large':
      return '4px';
    default:
      return '2px'; // Default to '8px'
  }
};
