import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ArchivedResourceActionType } from '../actions/actions.constants';
import {
  ArchivedResource,
  ListArchivedResourcesRequest,
  ListArchivedResourcesResponse,
  RestoreArchivedResourcesRequest,
} from 'protos/pb/v1alpha2/archived_resources_service';
import { archivedResourcesService } from '../../services/ArchivedResourcesService';
import {
  listArchivedResourcesCompletedAction,
  listArchivedResourcesErrorAction,
  restoreArchivedResourcesCompletedAction,
  restoreArchivedResourcesErrorAction,
} from '../actions/archived_resource.action';

function* listArchivedResourcesSaga(data: {
  type: ArchivedResourceActionType.LIST_ARCHIVED_RESOURCES;
  payload: ListArchivedResourcesRequest;
  refresh: boolean;
}): any {
  try {
    const {
      response,
      error,
    }: { response: ListArchivedResourcesResponse; error: Error } = yield call(
      archivedResourcesService.listArchivedResources,
      data.payload,
    );
    if (response) {
      yield put(
        listArchivedResourcesCompletedAction(
          response.archivedResource as ArchivedResource[],
          response.totalSize as number,
          data.refresh,
        ),
      );
    } else {
      yield put(listArchivedResourcesErrorAction(error));
    }
  } catch (error) {
    yield put(listArchivedResourcesErrorAction(error as Error));
  }
}

function* restoreArchivedResourcesSaga(data: {
  type: ArchivedResourceActionType.RESTORE_ARCHIVED_RESOURCES;
  payload: RestoreArchivedResourcesRequest;
}): any {
  try {
    const { response, error } = yield call(
      archivedResourcesService.restoreArchivedResources,
      data.payload,
    );
    if (response) {
      yield put(
        restoreArchivedResourcesCompletedAction(data.payload.name as string[]),
      );
    } else {
      yield put(restoreArchivedResourcesErrorAction(error));
    }
  } catch (error) {
    yield put(restoreArchivedResourcesErrorAction(error as Error));
  }
}

function* archivedResourcesSaga() {
  yield all([
    takeLatest(
      ArchivedResourceActionType.LIST_ARCHIVED_RESOURCES,
      listArchivedResourcesSaga,
    ),
    takeLatest(
      ArchivedResourceActionType.RESTORE_ARCHIVED_RESOURCES,
      restoreArchivedResourcesSaga,
    ),
  ]);
}

export default archivedResourcesSaga;
