import React, { memo, FC, useEffect, useState, useRef } from 'react';
import { DEFAULT_EVENTS, useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { isAnyEntityModified } from '../../utils/UnsavedChangesUtils';
import { Activity } from 'protos/automation_mining/automation_mining';
import {
  getDocumentActivityTypeSelector,
  getOriginalPredictedResultSelector,
  getPredictedResultSelector,
  infoToDetectChangesSelector,
} from '../../redux/selectors/review_task.selectors';
import {
  CHECK_INTERVAL_MS,
  INACTIVITY_WARNING_THRESHOLD_MS,
  IDLE_SESSION_TIMEOUT_MS,
  IDLE_SESSION_THROTTLE_MS,
  QA_IDLE_SESSION_THROTTLE_MS,
  QA_IDLE_SESSION_TIMEOUT_MS,
  QA_INACTIVITY_WARNING_THRESHOLD_MS,
  QA_CHECK_INTERVAL_MS,
  SESSION_TIMEOUT_POPUP_ID,
} from '../../utils/constants';
import useLogoutHandler from '../../hooks/useLogoutHandler';
import InactivityWarningPopup from './components/InactivityWarningPopup';
import { logoutCompletedAction } from '../../redux/actions/auth.action';
import { storageService } from '../../services/StorageService';
import { matchPath, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../ProtectedRoutes/Routes';

enum BROADCAST_ACTIONS {
  CHECK_FOR_UNSAVED_CHANGES = 'check_for_unsaved_changes',
  CHECK_FOR_UNSAVED_CHANGES_RESPONSE = 'check_for_unsaved_changes_response',
  UPDATE_LAST_ACTIVITY_TIME = 'update_last_activity_time',
  LOGOUT_FROM_ALL_SESSIONS = 'logout_from_all_sessions',
  SYNC_WARNING_POPUP_STATE = 'sync_warning_popup_state_across_sessions',
}

interface Props {
  overrideSessionTimeoutTo1Min: boolean;
}

// To safeguard user account/data and optimize system resources,
// idle session management automatically logs out inactive users.
// For more details, refer to the documentation: https://docs.google.com/document/d/1KJtluncjxTc8k2lej0CL4pvdDX_0tAuXqp39rU3-Yc8
const IdleSessionManager: FC<Props> = ({ overrideSessionTimeoutTo1Min }) => {
  const documentActivityType = useSelector(getDocumentActivityTypeSelector);
  const predictedResult = useSelector(getPredictedResultSelector);
  const originalPredictedResult = useSelector(
    getOriginalPredictedResultSelector,
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const { handleLogout } = useLogoutHandler();
  const infoToDetectChanges = useSelector(infoToDetectChangesSelector);
  const lastActivityAt = useRef<number>(Date.now());
  const broadcastChannelRef = useRef<BroadcastChannel>(
    new BroadcastChannel('SessionManagement'),
  );
  const openDialogTimeout = useRef<NodeJS.Timeout | null>(null);
  const logoutTimeout = useRef<NodeJS.Timeout | null>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const sessionSettings = overrideSessionTimeoutTo1Min
    ? {
        idleTimeout: QA_IDLE_SESSION_TIMEOUT_MS,
        warningThreshold: QA_INACTIVITY_WARNING_THRESHOLD_MS,
        checkInterval: QA_CHECK_INTERVAL_MS,
        throttle: QA_IDLE_SESSION_THROTTLE_MS,
      }
    : {
        idleTimeout: IDLE_SESSION_TIMEOUT_MS,
        warningThreshold: INACTIVITY_WARNING_THRESHOLD_MS,
        checkInterval: CHECK_INTERVAL_MS,
        throttle: IDLE_SESSION_THROTTLE_MS,
      };

  const { idleTimeout, warningThreshold, checkInterval, throttle } =
    sessionSettings;

  const areUnsavedChangesPresentInTaskReviewPage = () => {
    // Check if the current route matches the automation review page
    // If it doesn't match, there are no unsaved changes to consider
    // TODO: Check for unsaved changes in ORBOT HITL as well in future
    if (matchPath(APP_ROUTES.TASK_REVIEW, location.pathname) === null) {
      return false;
    }
    // classification
    if (documentActivityType === Activity.CLASSIFY_DOCUMENT) {
      return (
        predictedResult?.classificationLabel !==
        originalPredictedResult?.classificationLabel
      );
    }
    // extraction
    return isAnyEntityModified(infoToDetectChanges, undefined, false);
  };

  // This function is called whenever user activity is detected
  // It helps in synchronizing the last activity time across all open sessions
  const updateLastActivityTime = () => {
    // Update the last activity timestamp to the current time
    const presentTime = Date.now();
    lastActivityAt.current = presentTime;
    // Update in localStorage
    storageService.setLastActivityTime(presentTime);
    // Broadcast the updated activity time to all tabs/windows
    broadcastChannelRef.current.postMessage({
      type: BROADCAST_ACTIONS.UPDATE_LAST_ACTIVITY_TIME,
      lastActivityAt: lastActivityAt.current,
    });
  };

  const timeElapsedSinceLastActive = () => {
    const lastActivityTime = lastActivityAt.current;
    return Date.now() - lastActivityTime;
  };

  const getRemainingTime = () => {
    const elapsedTimeInMs = timeElapsedSinceLastActive();
    // Calculate remaining time
    const remainingTimeInMs = idleTimeout - elapsedTimeInMs;

    if (remainingTimeInMs <= 0) {
      return '00:00'; // Session expired
    }

    // Convert to minutes and seconds
    const remainingMinutes = Math.floor(remainingTimeInMs / 1000 / 60);
    const remainingSeconds = Math.floor((remainingTimeInMs / 1000) % 60);

    // Format as mm:ss
    const formattedMinutes = String(remainingMinutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const { reset, isLeader } = useIdleTimer({
    onAction: () => {
      if (!showDialog) {
        updateLastActivityTime();
      }
    },
    // Enabled leader selection across tabs
    // This is beneficially to prevent multiple tabs from calling logout function
    crossTab: true,
    leaderElection: true,
    events: DEFAULT_EVENTS.filter(
      (event) => !['focus', 'visibilitychange'].includes(event),
    ),
    throttle: throttle,
  });

  useEffect(() => {
    // Reset idle timer when window regains focus (after a native dialog is closed)
    const handleWindowFocus = () => {
      reset(); // Reset the idle timer after alert is dismissed
    };

    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, [reset]);

  // Controls the visibility of the session timeout popup in relation to the idle session popup.
  // When conflicts arise between idle session timeout and session timeout popups,
  // we prioritizes displaying the idle session timeout popup.
  // ref: https://github.com/orby-ai-engineering/orby-web-app/pull/1976#discussion_r1802531952
  const setSessionTimeoutPopupVisibility = (visibility: boolean) => {
    // Get the session timeout popup element by its ID.
    const sessionPopUpElm = document.getElementById(SESSION_TIMEOUT_POPUP_ID);
    // If the element is found:
    if (sessionPopUpElm) {
      // Hide the session timeout popup if the 'visibility' argument is true
      // (meaning the idle session popup is visible).
      // Otherwise, show the session timeout popup by setting its display to 'block'.
      sessionPopUpElm.style.display = visibility ? 'none' : 'block';
    }
  };

  // Function to set the visibility of the inactivity warning dialog across all tabs
  const setDialogVisibilityForAllSessions = (visibility: boolean) => {
    setSessionTimeoutPopupVisibility(visibility);
    // Update the local state of the dialog visibility
    setShowDialog(visibility);

    const broadcastChannelApi = broadcastChannelRef.current;

    // Broadcast a message to all tabs to synchronize the dialog visibility state
    broadcastChannelApi.postMessage({
      type: BROADCAST_ACTIONS.SYNC_WARNING_POPUP_STATE,
      visibility,
    });
  };

  const resetAllSessions = () => {
    updateLastActivityTime();
    setDialogVisibilityForAllSessions(false);
  };

  const onLogout = () => {
    handleLogout().finally(() => {
      broadcastChannelRef.current.postMessage({
        type: BROADCAST_ACTIONS.LOGOUT_FROM_ALL_SESSIONS,
      });
    });
  };

  useEffect(() => {
    const lastActivityAt = storageService.getLastActivityTime();
    // Retrieve the last activity time from storage.
    if (lastActivityAt) {
      // If a last activity time is found, calculate the time difference.
      const remainingTime = Date.now() - lastActivityAt;
      // If the remaining time is less than or equal to 0, it means the session has expired.
      if (remainingTime >= idleTimeout) {
        handleLogout(); // Trigger the logout function to end the user's session.
        return;
      }
    }
    // Ensure all sessions across tabs are synchronized by resetting their state
    // whenever any active tab session reloads or mounts
    resetAllSessions();
  }, []);

  // Handle broadcast channel messages for cross-tab communication
  useEffect(() => {
    const broadcastChannelApi = broadcastChannelRef.current;
    if (broadcastChannelApi) {
      broadcastChannelApi.onmessage = (event) => {
        switch (event.data.type) {
          case BROADCAST_ACTIONS.CHECK_FOR_UNSAVED_CHANGES: {
            // Check for unsaved changes and respond
            const hasUnsavedChanges =
              areUnsavedChangesPresentInTaskReviewPage();
            broadcastChannelApi.postMessage({
              type: BROADCAST_ACTIONS.CHECK_FOR_UNSAVED_CHANGES_RESPONSE,
              hasUnsavedChanges,
            });
            break;
          }
          case BROADCAST_ACTIONS.CHECK_FOR_UNSAVED_CHANGES_RESPONSE: {
            // Clear timeouts if unsaved changes are detected
            if (openDialogTimeout.current && event.data.hasUnsavedChanges) {
              clearTimeout(openDialogTimeout.current);
              openDialogTimeout.current = null;
            }
            if (logoutTimeout.current && event.data.hasUnsavedChanges) {
              clearTimeout(logoutTimeout.current);
              logoutTimeout.current = null;
            }
            break;
          }
          case BROADCAST_ACTIONS.UPDATE_LAST_ACTIVITY_TIME: {
            // Sync last activity time across tabs
            lastActivityAt.current = event.data.lastActivityAt;
            break;
          }
          case BROADCAST_ACTIONS.LOGOUT_FROM_ALL_SESSIONS: {
            // Logout the user across all tabs
            // Dispatch logout complete action to clear the state
            // No need to call the logout API here, as it's handled by the leader tab
            storageService.deleteStoredValues();
            dispatch(logoutCompletedAction());
            break;
          }
          case BROADCAST_ACTIONS.SYNC_WARNING_POPUP_STATE: {
            const { visibility } = event.data;
            setSessionTimeoutPopupVisibility(visibility);
            // Sync warning popup visibility across tabs
            setShowDialog(visibility);
            break;
          }
        }
      };
    }
  }, [areUnsavedChangesPresentInTaskReviewPage]);

  // Monitor user inactivity and trigger warnings or logout
  useEffect(() => {
    const checkInactivity = () => {
      // Skip inactivity check if this tab is not the leader or if the warning dialog is already shown
      if (!isLeader() || showDialog) return;

      const timeElapsedInMs = timeElapsedSinceLastActive();
      if (timeElapsedInMs >= idleTimeout) {
        // Initiate logout process if session timeout is reached
        if (
          !logoutTimeout.current &&
          !areUnsavedChangesPresentInTaskReviewPage()
        ) {
          // Broadcast a message to all tabs to check for unsaved changes
          broadcastChannelRef.current.postMessage({
            type: BROADCAST_ACTIONS.CHECK_FOR_UNSAVED_CHANGES,
          });
          // Set a timeout to logout
          // The 1000ms delay allows time for responses from other tabs
          // This timeout will be cleared if unsaved changes are detected on any tab
          // See BROADCAST_ACTIONS.CHECK_FOR_UNSAVED_CHANGES_RESPONSE handler
          logoutTimeout.current = setTimeout(() => {
            onLogout();
            logoutTimeout.current = null;
          }, 1000);
        }
      } else if (timeElapsedInMs >= warningThreshold) {
        // Show inactivity warning if the user has been inactive for longer than the warning threshold
        if (
          !openDialogTimeout.current &&
          !areUnsavedChangesPresentInTaskReviewPage()
        ) {
          // Check if there's no existing timeout and no unsaved changes

          // Broadcast a message to all tabs to check for unsaved changes
          broadcastChannelRef.current.postMessage({
            type: BROADCAST_ACTIONS.CHECK_FOR_UNSAVED_CHANGES,
          });

          // Set a timeout to show the inactivity warning dialog
          // The 1000ms delay allows time for responses from other tabs
          // This timeout will be cleared if unsaved changes are detected on any tab
          // See BROADCAST_ACTIONS.CHECK_FOR_UNSAVED_CHANGES_RESPONSE handler
          openDialogTimeout.current = setTimeout(() => {
            // Show the inactivity warning dialog across all tabs
            setDialogVisibilityForAllSessions(true);
            openDialogTimeout.current = null;
          }, 1000);
        }
      }
    };

    // Set up interval to regularly check for inactivity
    const intervalId = setInterval(checkInactivity, checkInterval);
    return () => clearInterval(intervalId);
  }, [areUnsavedChangesPresentInTaskReviewPage, isLeader, showDialog]);

  return showDialog ? (
    <InactivityWarningPopup
      // Leader Tab: The tab responsible for managing idle session checks and initiating logout processes.
      // Whichever tab loads first will be considered as the leader tab.
      // If the leader tab is closed, another tab is assigned as the leader
      isLeaderTab={isLeader}
      onLogout={onLogout}
      getRemainingTime={getRemainingTime}
      onStayLoggedIn={resetAllSessions}
    />
  ) : (
    <></>
  );
};

export default memo(IdleSessionManager);
