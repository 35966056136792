import React, { FC, memo, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
  MobileStepper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// @ts-expect-error TS1479
import ReactMarkdown from 'react-markdown';
// @ts-expect-error TS1479
import remarkGfm from 'remark-gfm';
// @ts-expect-error TS1479
import rehypeRaw from 'rehype-raw';
import { OrbyButton } from 'orby-ui/src';
import { Announcement } from 'protos/pb/v1alpha1/user';
import './AnnouncementPopup.css';

interface AnnouncementPopupProps {
  announcements: Announcement[];
}

const AnnouncementPopup: FC<AnnouncementPopupProps> = ({ announcements }) => {
  // selectedPageIndex is used to show content blocks of the announcement
  // user can click Next/Previous button to switch between pages
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  // currentAnnouncementIndex is used to show announcement one by one
  // user can click Got It/Finish to close announcement, when there are
  // multiple announcements, we will show next announcement instead of
  // closing this popup dialog
  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(-1);
  // viewedAnnouncementsKey is the localStorage key to store viewed announcement ids
  const viewedAnnouncementsKey = 'viewed-announcements';

  const getViewedAnnouncementIds = () => {
    const viewedIds: string[] = [];
    const item = localStorage.getItem(viewedAnnouncementsKey);
    if (item) {
      JSON.parse(item).forEach((id: string) => {
        viewedIds.push(id);
      });
    }
    return viewedIds;
  };

  const setViewedAnnouncementIds = (ids: string[]) => {
    localStorage.setItem(viewedAnnouncementsKey, JSON.stringify(ids));
  };

  useEffect(() => {
    const viewedIds = getViewedAnnouncementIds();
    // ignore viewed announcements
    announcements = announcements.filter(
      (announcement) => announcement.id && !viewedIds.includes(announcement.id),
    );
    if (announcements.length > 0) {
      setCurrentAnnouncementIndex(0);
    }
  }, []);

  const handlePrevious = () => {
    if (selectedPageIndex === 0) {
      return;
    }
    setSelectedPageIndex(selectedPageIndex - 1);
  };

  const handleNext = () => {
    if (
      announcements[currentAnnouncementIndex]?.contentBlocks?.length ===
      selectedPageIndex + 1
    ) {
      return;
    }
    setSelectedPageIndex(selectedPageIndex + 1);
  };

  const handleClose = (event: object, reason: string) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
    setSelectedPageIndex(0);
    if (currentAnnouncementIndex < announcements.length - 1) {
      setCurrentAnnouncementIndex(currentAnnouncementIndex + 1);
    } else {
      setCurrentAnnouncementIndex(-1);
      // store viewed announcement ids
      const viewedIds: string[] = getViewedAnnouncementIds();
      announcements.forEach((announcement) => {
        if (announcement.id) {
          viewedIds.push(announcement.id);
        }
      });
      setViewedAnnouncementIds(viewedIds);
    }
  };

  function customMarkdownLink({ ...props }) {
    return (
      <a {...props} target='_blank'>
        {props.children}
      </a>
    );
  }

  const renderPageButton = () => {
    if (currentAnnouncementIndex < 0) return;
    if (
      (announcements[currentAnnouncementIndex]?.contentBlocks?.length ?? 0) > 1
    ) {
      if (selectedPageIndex === 0) {
        return (
          <OrbyButton
            onClick={handleNext}
            size='large'
            variant='primary-contained'
            sx={{ width: '100%', margin: '16px' }}
            label='Next'
          />
        );
      } else if (
        selectedPageIndex + 1 ===
        announcements[currentAnnouncementIndex]?.contentBlocks?.length
      ) {
        return (
          <Stack
            direction='row'
            spacing={2}
            sx={{ width: '100%', margin: '16px' }}
          >
            <OrbyButton
              onClick={handlePrevious}
              size='large'
              variant='monochrome-outline'
              sx={{ width: '50%' }}
              label='Previous'
            />
            <OrbyButton
              onClick={(event) => handleClose(event, 'buttonClick')}
              size='large'
              variant='primary-contained'
              sx={{ width: '50%' }}
              label='Finish'
            />
          </Stack>
        );
      }
      return (
        <Stack
          direction='row'
          spacing={2}
          sx={{ width: '100%', margin: '16px' }}
        >
          <OrbyButton
            onClick={handlePrevious}
            size='large'
            variant='monochrome-outline'
            sx={{ width: '50%' }}
            label='Previous'
          />
          <OrbyButton
            onClick={handleNext}
            size='large'
            variant='primary-contained'
            sx={{ width: '50%' }}
            label='Next'
          />
        </Stack>
      );
    }
    return (
      <OrbyButton
        onClick={(event) => handleClose(event, 'buttonClick')}
        size='large'
        variant='primary-contained'
        sx={{ width: '100%', margin: '16px' }}
        label='Got it'
      />
    );
  };

  return (
    <Dialog open={currentAnnouncementIndex >= 0} onClose={handleClose}>
      {announcements[currentAnnouncementIndex]?.header?.data && (
        <DialogTitle sx={{ width: '600px', paddingTop: '24px' }}>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            className={'custom-markdown '}
            components={{
              a: customMarkdownLink,
            }}
          >
            {announcements[currentAnnouncementIndex]?.header?.data}
          </ReactMarkdown>
        </DialogTitle>
      )}
      <IconButton
        aria-label='close'
        onClick={(event) => handleClose(event, 'buttonClick')}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      {announcements[currentAnnouncementIndex]?.contentBlocks?.length && (
        <DialogContent sx={{ width: '600px', padding: '0 24px' }}>
          {announcements[currentAnnouncementIndex]?.contentBlocks?.at(
            selectedPageIndex,
          )?.body?.data && (
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              className={'custom-markdown'}
              components={{
                a: customMarkdownLink,
              }}
            >
              {
                announcements[currentAnnouncementIndex]?.contentBlocks?.at(
                  selectedPageIndex,
                )?.body?.data
              }
            </ReactMarkdown>
          )}
          {(announcements[currentAnnouncementIndex]?.contentBlocks?.length ??
            0) > 1 && (
            <MobileStepper
              variant='dots'
              steps={
                announcements[currentAnnouncementIndex]?.contentBlocks
                  ?.length ?? 0
              }
              position='static'
              activeStep={selectedPageIndex}
              sx={{ justifyContent: 'center' }}
              nextButton={<></>}
              backButton={<></>}
            />
          )}
        </DialogContent>
      )}
      <DialogActions>{renderPageButton()}</DialogActions>
    </Dialog>
  );
};

export default memo(AnnouncementPopup);
