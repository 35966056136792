import { Box, CircularProgress } from '@mui/material';
import React, { memo, FC, ReactNode, Suspense } from 'react';

interface Props {
  children: ReactNode;
}

const SuspenseLoader: FC<Props> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'100vh'}
        >
          <CircularProgress />
        </Box>
      }
    >
      {children}
    </Suspense>
  );
};

export default memo(SuspenseLoader);
