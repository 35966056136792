export enum APP_ROUTES {
  DASHBOARD = '/dashboard',
  TASKS = '/tasks',
  EXECUTIONS = '/executions',
  EXECUTION_DETAILS = '/executions/:workflow_task_id',
  WORKFLOW_TEMPLATE = '/workflow/template',
  TASK_REVIEW = '/workflows/:workflow_id/tasks/:task_id/automation-review',
  HITL_DETAILS = '/orbot_workflow_tasks/:workflow_id/tasks/:task_id/hitl-details',
  DEV_TESTING = '/dev-testing',
  DEV_TESTING_DOCUMENT = '/dev-testing/document/:file_name',
  INTEGRATION_TEST = '/integration-test',
  WORKFLOW = '/workflow',
  WORKFLOW_DEFINITION = '/workflow/:workflow_id/definition',
  WORKFLOW_UPDATE = '/workflow/:workflow_id/update',
  WORKFLOW_CREATE = '/workflow/create',
  WORKFLOW_SETTINGS = '/workflow/:workflow_id/settings',
  WORKFLOW_SCHEDULE = '/workflow/:workflow_id/schedule',
  WORKFLOW_ADVANCED_EDITOR = '/workflow/:workflow_id/advanced-editor',
  DELETED_ITEMS = '/deleted-items',
  FIELDS = '/fields',
  TEMPLATE_DEFINITION = '/template/:template_id/definition',
  CONNECTOR_CREATE = '/workflow/connector/create',
  CONNECTOR_UPDATE = '/workflow/connector/:connector_id/update',
  SETTINGS = '/settings',
  CONFIGURE_SSO = '/settings/configure-sso/:organization_id',
  SCHEDULED_TASKS = '/scheduled-tasks',
  TEAMS = '/teams',
  FEATURE_FLAGS = '/feature-flags',
  FEATURE_FLAGS_CREATE = '/feature-flags/create',
  FEATURE_FLAGS_UPDATE = '/feature-flags/:feature_flag_id/update',
}
