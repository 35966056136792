import { AutomationMiningActionType } from './actions.constants';

export const generateActivityGraphAction = (start: Date, end: Date) => ({
  type: AutomationMiningActionType.GENERATE_ACTIVITY_GRAPH,
  payload: { start: start.toISOString(), end: end.toISOString() },
});

export const generateActivityGraphActionCompletedAction = (
  mimeType: string,
  activityGraph: string,
) => ({
  type: AutomationMiningActionType.GENERATE_ACTIVITY_GRAPH_COMPLETED,
  payload: { mimeType, activityGraph },
});

export const generateActivityGraphActionErrorAction = (error: string) => ({
  type: AutomationMiningActionType.GENERATE_ACTIVITY_GRAPH_ERROR,
  payload: error,
});
