import { Box, Dialog } from '@mui/material';
import React, { FC, memo, useEffect, useState } from 'react';
import helloIcon from '../../../static/icons/hello-icon.svg';
import { OrbyButton, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { IDLE_SESSION_TIMEOUT_POPUP_ID } from '../../../utils/constants';

interface Props {
  onLogout: () => void;
  onStayLoggedIn: () => void;
  getRemainingTime: () => string;
  isLeaderTab: () => boolean;
}

const InactivityWarningPopup: FC<Props> = ({
  onLogout,
  onStayLoggedIn,
  getRemainingTime,
  isLeaderTab,
}) => {
  const [remainingTime, setRemainingTime] = useState(getRemainingTime());

  useEffect(() => {
    // Set an interval to update the remaining time every second
    const intervalId = setInterval(() => {
      const remainingTime = getRemainingTime();
      // Only trigger logout from the leader tab when time expires
      // This prevents multiple logout calls across tabs and ensures consistent behavior
      if (remainingTime === '00:00' && isLeaderTab()) {
        onLogout();
        return;
      }
      setRemainingTime(remainingTime);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isLeaderTab]);

  return (
    <Dialog
      id={IDLE_SESSION_TIMEOUT_POPUP_ID}
      open={true}
      PaperProps={{
        sx: {
          width: '400px',
          padding: '24px',
          borderRadius: '12px',
          gap: '32px',
        },
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <img src={helloIcon} alt='Hello Icon' />
        <OrbyTypography
          weight='semibold'
          size='lg'
          sx={{ paddingBottom: '6px', paddingTop: '10px' }}
        >
          You&apos;ve been inactive
        </OrbyTypography>
        <OrbyTypography>
          To protect your information, if there is no further activity, we will
          log you out in {remainingTime}.
        </OrbyTypography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <OrbyButton
          onClick={onLogout}
          variant='primary-outline'
          label='Log Out'
          size='large'
          sx={{
            paddingX: '54px',
            color: OrbyColorPalette['grey-700'],
            border: `1px solid ${OrbyColorPalette['grey-300']}`,
            '&:hover, &:focus': {
              color: OrbyColorPalette['purple-600'],
              border: `1px solid ${OrbyColorPalette['purple-600']}`,
            },
          }}
        />
        <OrbyButton
          onClick={onStayLoggedIn}
          variant='primary-contained'
          label='Stay Logged In'
          size='large'
          sx={{
            paddingX: '27px',
            background: OrbyColorPalette['purple-600'],
            border: `1px solid ${OrbyColorPalette['purple-600']}`,
            '&:hover, &:focus': {
              background: OrbyColorPalette['purple-800'],
              border: `1px solid ${OrbyColorPalette['purple-800']}`,
            },
          }}
        />
      </Box>
    </Dialog>
  );
};

export default memo(InactivityWarningPopup);
