import { DashboardActionType } from './actions.constants';
import {
  GetDashboardStatsRequest,
  GetDashboardStatsResponse,
} from 'protos/pb/v1alpha2/dashboard_service';

export const getDashboardStatsAction = (req: GetDashboardStatsRequest) => ({
  type: DashboardActionType.GET_DASHBOARD_STATS,
  payload: req,
});

export const getDashboardStatsCompletedAction = (
  resp: GetDashboardStatsResponse,
) => ({
  type: DashboardActionType.GET_DASHBOARD_STATS_COMPLETED,
  payload: resp,
});

export const getDashboardStatsErrorAction = (error?: Error) => ({
  type: DashboardActionType.GET_DASHBOARD_STATS_ERROR,
  payload: error,
});
