import {
  GetTaskRequest,
  Task,
  UpdateReviewTaskRequest,
  UpdateTaskRequest,
} from 'protos/pb/v1alpha2/tasks_service';
import { ReviewTaskActionType } from './actions.constants';
import {
  EntityInfo,
  SelectedParentEntity,
} from '../reducers/review_task.reducer';
import {
  AutomationProgressStatus,
  EntityFilter,
  FEATURE_FLAGS,
} from '../../utils/constants';
import { DocumentPageToken } from 'protos/google/cloud/documentai/v1/document';
import store from '../../redux/store';
import { isFeatureFlagEnabled } from '../../pages/FeatureFlags/FeatureFlagUtils';
import { Vertex } from 'protos/google/cloud/documentai/v1/geometry';
import { FieldGroupMatch } from 'protos/pb/v1alpha1/actionprocessing';

export const getTaskForReviewAction = (req?: GetTaskRequest, task?: Task) => ({
  type: ReviewTaskActionType.GET_TASK_FOR_REVIEW,
  payload: req,
  task: task,
});

export const getTaskForReviewCompletedAction = (
  task: Task,
  showNewNestedUI = false,
  debugLayout = false,
) => ({
  type: ReviewTaskActionType.GET_TASK_FOR_REVIEW_COMPLETED,
  payload: task,
  showNewNestedUI,
  debugLayout,
});

export const getTaskForReviewErrorAction = (
  error: string | Error | undefined,
) => ({
  type: ReviewTaskActionType.GET_TASK_FOR_REVIEW_ERROR,
  payload: error,
});

export const setSelectedEntityIdAction = (id?: string | null) => ({
  type: ReviewTaskActionType.SET_SELECTED_ENTITY_ID,
  payload: id,
});

export const setSelectedEntityInfoAction = (info: EntityInfo) => ({
  type: ReviewTaskActionType.SET_SELECTED_ENTITY_INFO,
  payload: info,
});

export const copySelectedEntityOldUIAction = (id: string) => ({
  type: ReviewTaskActionType.COPY_SELECTED_ENTITY_OLD_UI,
  payload: id,
});

export const deleteSelectedEntityOldUIAction = (id: string) => ({
  type: ReviewTaskActionType.DELETE_SELECTED_ENTITY_OLD_UI,
  payload: id,
});

export const copySelectedEntity = (
  id: string,
  isSuggestion = false,
  excludeFromHistory = false,
  indexToAddRow = -1,
) => ({
  type: ReviewTaskActionType.COPY_SELECTED_ENTITY,
  payload: id,
  isSuggestion,
  excludeFromHistory,
  indexToAddRow,
});

export const deleteSelectedEntity = (id: string) => ({
  type: ReviewTaskActionType.DELETE_SELECTED_ENTITY,
  payload: id,
});

export const updateEntityInfoAction = (
  info: EntityInfo,
  excludeFromHistory = false,
  forceSaveHistory = false,
) => ({
  type: ReviewTaskActionType.UPDATE_ENTITY_INFO,
  payload: info,
  excludeFromHistory,
  forceSaveHistory,
});

export const updateEntityInfoNormalizedValueAction = (
  info: EntityInfo,
  excludeFromHistory = false,
) => ({
  type: ReviewTaskActionType.UPDATE_ENTITY_INFO_NORMALIZED_VALUE,
  payload: info,
  excludeFromHistory,
});

export const setSelectedTextSegmentIdAction = (id: string) => ({
  type: ReviewTaskActionType.SET_SELECTED_TEXT_SEGMENT_ID,
  payload: id,
});

export const updateTaskEntityInfoAction = (id: string, info: EntityInfo) => {
  const featureFlags = store.getState().featureFlags.featureFlagsForOrgAndUser;
  const showNewNestedUI = isFeatureFlagEnabled(
    FEATURE_FLAGS.NESTED_HITL,
    featureFlags,
  );
  return {
    type: ReviewTaskActionType.UPDATE_TASK_ENTITY_INFO,
    payload: { id, info, showNewNestedUI },
  };
};

export const updateTaskAction = (req: UpdateTaskRequest) => ({
  type: ReviewTaskActionType.UPDATE_TASK,
  payload: req,
});

export const updateReviewTaskAction = (req: UpdateReviewTaskRequest) => ({
  type: ReviewTaskActionType.UPDATE_REVIEW_TASK,
  payload: req,
});

export const updateReviewTaskCompleted = (resp: Task) => ({
  type: ReviewTaskActionType.UPDATE_REVIEW_TASK_COMPLETED,
  payload: resp,
});

export const updateReviewTaskError = (error?: Error) => ({
  type: ReviewTaskActionType.UPDATE_REVIEW_TASK_ERROR,
  payload: error,
});

export const updatePredictedClassification = (label: string) => ({
  type: ReviewTaskActionType.UPDATE_CLASSIFICATION_LABEL,
  payload: label,
});

export const setAutomationInProgress = (data: AutomationProgressStatus) => ({
  type: ReviewTaskActionType.SET_AUTOMATION_IN_PRORGRESS,
  payload: data,
});

export const setAutomationCompleted = (data: boolean) => ({
  type: ReviewTaskActionType.SET_AUTOMATION_COMPLETED,
  payload: data,
});

export const setAutomationCompletedError = (data: Error) => ({
  type: ReviewTaskActionType.SET_AUTOMATION_COMPLETED_ERROR,
  payload: data,
});

export const updateSelectedReviewFilterSection = (
  filter: EntityFilter | null,
) => ({
  type: ReviewTaskActionType.UPDATE_SELECTED_REVIEW_FILTER_SECTION,
  payload: filter,
});

export const clearReviewState = () => ({
  type: ReviewTaskActionType.CLEAR,
});

export const setSearchTextAction = (text: string) => ({
  type: ReviewTaskActionType.SET_SEARCH_TEXT,
  payload: text,
});

export const toggleSearchResultAction = (
  nextTokenIndex: number | undefined,
) => ({
  type: ReviewTaskActionType.TOGGLE_SEARCH_RESULT,
  payload: nextTokenIndex,
});

export const handleAnnotationSuggestionAction = (
  accept: boolean,
  parentEntityId?: string,
) => ({
  type: ReviewTaskActionType.HANDLE_ANNOTATION_SUGGESTION,
  payload: accept,
  parentEntityId,
});

export const clearSuggestionAnnotationDataAction = () => ({
  type: ReviewTaskActionType.CLEAR_SUGGESTION_ANNOTATION_DATA,
});

export const setTokenForHighlightAction = (token?: DocumentPageToken) => ({
  type: ReviewTaskActionType.SET_TOKEN_FOR_HIGHLIGHT,
  payload: token,
});

export const setSelectedParentEntityTypeAction = (
  parentEntityInfo?: SelectedParentEntity,
  clearTableData = true,
) => ({
  type: ReviewTaskActionType.SET_SELECTED_PARENT_ENTITY_INFO,
  payload: parentEntityInfo,
  clearTableData,
});

export const setSelectedEntityIdsForAnnotationAction = (ids: string[]) => ({
  type: ReviewTaskActionType.SET_SELECTED_ENTITY_IDS_FOR_ANNOTATION,
  payload: ids,
});

// TODO: Refactor this action to take a single object as argument so we are not forced to pass every parameter we don't want to change
// Example:
// Before:
//  updateEntityInfoForTableAnnotationAction(entityInfo, true, false, false, true, false)
// After: This is more clean
//  updateEntityInfoForTableAnnotationAction({
//    entityInfo,
//    computeNormalizedValue: true,
//    forceSaveHistory: true
//  })
export const updateEntityInfoForTableAnnotationAction = (
  entityInfo: EntityInfo,
  computeNormalizedValue = true,
  isSuggestion = false,
  excludeFromHistory = false,
  forceSaveHistory = false,
  ignoreVerticalLines = false,
) => ({
  type: ReviewTaskActionType.UPDATE_ENTITY_INFO_FOR_TABLE_ANNOTATION,
  payload: entityInfo,
  computeNormalizedValue: computeNormalizedValue,
  isSuggestion,
  excludeFromHistory,
  forceSaveHistory,
  ignoreVerticalLines,
});

export const confirmTableEntitiesInfoAction = () => ({
  type: ReviewTaskActionType.CONFIRM_TABLE_ENTITIES_INFO,
});

export const clearAllTableEntitiesInfoAction = () => ({
  type: ReviewTaskActionType.CLEAR_ALL_TABLE_ENTITIES_INFO,
});

export const setAddLocationAction = (payload: boolean) => ({
  type: ReviewTaskActionType.ADD_LOCATION,
  payload,
});

export const setLastLocatedEntityTypeAction = (type?: string) => ({
  type: ReviewTaskActionType.LAST_LOCATED_ENTITY_TYPE,
  payload: type,
});

export const updateOrderOfTableEntitiesAction = (
  entityInfoList: EntityInfo[],
) => ({
  type: ReviewTaskActionType.UPDATE_ORDER_OF_TABLE_ENTITIES,
  payload: entityInfoList,
});

export const hideColumnFromTableAnnotationAction = (type: string) => ({
  type: ReviewTaskActionType.HIDE_COLUMN_FROM_TABLE_ANNOTATION,
  payload: type,
});

export const showColumnsForTableAnnotationAction = () => ({
  type: ReviewTaskActionType.SHOW_COLUMNS_FOR_TABLE_ANNOTATION,
});

export const clearTaskAnnotationInfo = () => ({
  type: ReviewTaskActionType.CLEAR_TASK_ANNOTATION_INFO,
});

export const clearReviewTask = () => ({
  type: ReviewTaskActionType.CLEAR_REVIEW_TASK,
});

export const deleteTextSegmentsFromEntitiesAction = (entityIds: string[]) => ({
  type: ReviewTaskActionType.DELETE_TEXT_SEGMENTS_FROM_ENTITIES,
  payload: entityIds,
});

export const updateOrderOfTableRowsAction = (ids: string[]) => ({
  type: ReviewTaskActionType.UPDATE_ORDER_OF_TABLE_ROWS,
  payload: ids,
});

export const collapseEntitySidePanelAction = (collapse: boolean) => ({
  type: ReviewTaskActionType.COLLAPSE_ENTITY_SIDE_PANEL,
  payload: collapse,
});

export const setTokenListToHighlightAction = (
  pageCorrespondingStyles?: { start: Vertex; end: Vertex },
  page?: number,
) => ({
  type: ReviewTaskActionType.SET_TOKEN_LIST_TO_HIGHLIGHT,
  payload: pageCorrespondingStyles,
  page,
});

export const openAddRowModalAction = (
  open: boolean,
  excludeFromHistory = false,
) => ({
  type: ReviewTaskActionType.OPEN_ADD_ROW_MODAL,
  payload: open,
  excludeFromHistory,
});

export const setSuggestionDataAction = (data?: {
  cells: Vertex[];
  page: number;
  entityType: string;
}) => ({
  type: ReviewTaskActionType.SET_SUGGESTION_DATA,
  payload: data,
});

export const addSuggestedRowsAction = () => ({
  type: ReviewTaskActionType.ADD_SUGGESTED_ROWS,
});

export const allowEditingTaskAction = (edit: boolean) => ({
  type: ReviewTaskActionType.ALLOW_EDITING_TASK,
  payload: edit,
});

export const actionUndo = () => ({
  type: ReviewTaskActionType.TASK_UNDO,
});

export const actionRedo = () => ({
  type: ReviewTaskActionType.TASK_REDO,
});

export const clearActionHistory = () => ({
  type: ReviewTaskActionType.CLEAR_TASK_HISTORY,
});

export const getTaskForHitlAction = (req?: GetTaskRequest) => ({
  type: ReviewTaskActionType.GET_TASK_N_HITL_DATA,
  payload: req,
});

export const setTaskForHitlAction = (data: { task: Task }) => ({
  type: ReviewTaskActionType.SET_TASK_N_HITL_DATA,
  payload: data,
});

export const getTaskForHitlErrorAction = (
  error: string | Error | undefined,
) => ({
  type: ReviewTaskActionType.GET_TASK_N_HITL_DATA_ERROR,
  payload: error,
});

export const updateMatches = (data: {
  matches: FieldGroupMatch[];
  stepIndex: number;
}) => ({
  type: ReviewTaskActionType.UPDATE_MATCHES,
  payload: data,
});

export const recordIdleSessionAction = (session: {
  start: number;
  end: number;
}) => ({
  type: ReviewTaskActionType.RECORD_IDLE_SESSION,
  payload: session,
});

export const reviewStartTimeAction = () => ({
  type: ReviewTaskActionType.REVIEW_START_TIME,
});
