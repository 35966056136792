import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  authLoginCompletedAction,
  authLoginErrorAction,
  authSignUpCompletedAction,
  authSignUpErrorAction,
  googleAuthLoginAction,
  googleAuthLoginCompletedAction,
  googleAuthLoginErrorAction,
  logoutCompletedAction,
  logoutErrorAction,
  microsoftAuthLoginAction,
  microsoftAuthLoginCompletedAction,
  microsoftAuthLoginErrorAction,
  samlLoginCompletedAction,
  samlLoginErrorAction,
} from '../actions/auth.action';
import { authService } from '../../services/AuthService';
import { AuthActionType } from '../actions/actions.constants';
import { convertToken, storageService } from '../../services/StorageService';
import {
  SingleSignOnRequest,
  RegisterRequest,
  LoginRequest,
  LogoutRequest,
  LoginResponse,
  MicrosoftSingleSignOnRequest,
  ExchangeTokenRequest,
} from 'protos/pb/v1alpha1/users_service';
import { User } from 'protos/pb/v1alpha1/user';
import { AuthPlatform } from '../../utils/constants';
import { userService } from '../../services/UserService';
import { getGoogleAuthCode, getMicrosoftAuthCode } from '../../utils/helpers';

export function* loginSaga(data: {
  type: AuthActionType.LOGIN;
  payload: LoginRequest;
}): any {
  try {
    const loginResponse = yield call(authService.login, data.payload);
    const token = convertToken(loginResponse);
    token.platform = AuthPlatform.PASSWORD;
    yield call(storageService.setSessionId, token.sessionId as string);
    if (loginResponse?.user?.googleToken?.accessToken) {
      yield call(storageService.setStoredGoogleToken, {
        accessToken: loginResponse.user.googleToken.accessToken as string,
        accessTokenExpiresAt: loginResponse.user.googleToken.expiry,
      });
    }
    yield call(storageService.setStoredToken, token);
    yield put(authLoginCompletedAction(token));
  } catch (e: any) {
    yield put(
      authLoginErrorAction((e?.errors && e.errors[0]?.message) || e?.message),
    );
  }
}

function* samlLoginSaga(data: {
  type: AuthActionType.SAML_LOGIN;
  payload: {
    access_token: string;
    session_id: string;
  };
}) {
  try {
    const { response, error }: { response?: LoginResponse; error?: Error } =
      yield call(
        userService.exchangeToken,
        ExchangeTokenRequest.create({
          sessionResourceName: data.payload.session_id,
        }),
        data.payload.access_token,
      );
    if (response) {
      const token = convertToken(response);
      token.platform = AuthPlatform.SAML;
      yield call(storageService.setSessionId, token.sessionId as string);
      if (response?.user?.googleToken?.accessToken) {
        yield call(storageService.setStoredGoogleToken, {
          accessToken: response.user.googleToken.accessToken as string,
          accessTokenExpiresAt: response.user.googleToken.expiry!,
        });
      }
      yield call(storageService.setStoredToken, token);
      yield put(samlLoginCompletedAction(token));
    } else {
      yield put(samlLoginErrorAction(error?.message as string));
    }
  } catch (e: any) {
    yield put(
      samlLoginErrorAction((e?.errors && e.errors[0]?.message) || e?.message),
    );
  }
}

export function* googleLoginSaga(data: {
  type: AuthActionType.GOOGLE_LOGIN;
  payload: SingleSignOnRequest;
}): any {
  try {
    const loginResponse: LoginResponse = yield call(
      authService.googleLogin,
      data.payload,
    );
    const token = convertToken(loginResponse);
    token.platform = AuthPlatform.GOOGLE;
    yield call(storageService.setStoredToken, token);
    yield call(storageService.setSessionId, token.sessionId as string);
    if (loginResponse?.user?.googleToken?.accessToken) {
      yield call(storageService.setStoredGoogleToken, {
        accessToken: loginResponse.user.googleToken.accessToken,
        accessTokenExpiresAt: loginResponse.user.googleToken.expiry!,
      });
    }
    yield put(googleAuthLoginCompletedAction(token));
  } catch (e: any) {
    yield put(
      googleAuthLoginErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

export function* registerUserSaga(data: {
  type: AuthActionType.SIGN_UP;
  payload: RegisterRequest;
}): any {
  try {
    const user: User = yield call(authService.registerUser, data.payload);
    yield put(authSignUpCompletedAction(user));
  } catch (e: any) {
    yield put(
      authSignUpErrorAction((e?.errors && e.errors[0]?.message) || e?.message),
    );
  }
}

export function* logoutUserSaga(data: {
  type: AuthActionType;
  payload: LogoutRequest;
}): any {
  try {
    yield call(authService.logoutUser, data.payload);
  } catch (e: any) {
    yield put(
      logoutErrorAction((e?.errors && e.errors[0]?.message) || e?.message),
    );
  } finally {
    // FALLBACK: Many a times the API call fails silently and the user is not logged out
    // So we are deleting the stored values in the finally block
    yield call(storageService.deleteStoredValues);
    yield put(logoutCompletedAction());
  }
}

export function* googleSignInSaga(data: {
  type: AuthActionType;
  payload: { scope: string };
}) {
  try {
    const response: Awaited<ReturnType<typeof getGoogleAuthCode>> = yield call(
      getGoogleAuthCode,
      data.payload.scope,
    );
    const singleSignOnRequest: SingleSignOnRequest = {};
    singleSignOnRequest.googleAuthorizationCode = response.code;
    // Dispatch the googleAuthLoginAction using the put effect
    yield put(googleAuthLoginAction(singleSignOnRequest));
    if (response.scope) {
      // Set the google scope in the local storage
      yield call(storageService.setStoredGoogleScope, response.scope);
    }
  } catch (e: any) {
    yield put(
      logoutErrorAction((e?.errors && e.errors[0]?.message) || e?.message),
    );
  }
}

function* microsoftSignInSaga(data: {
  type: AuthActionType;
  payload: { scope: string[] };
}) {
  try {
    const response: Awaited<
      ReturnType<typeof authService.getMicrosoftLoginUrl>
    > = yield call(authService.getMicrosoftLoginUrl, data.payload.scope);
    // Redirect user to the url obtained from the server
    if (response.authCodeUrl) {
      const code: Awaited<ReturnType<typeof getMicrosoftAuthCode>> = yield call(
        getMicrosoftAuthCode,
        response.authCodeUrl,
      );
      // Handle the code (e.g., continue with OAuth flow, send to backend, etc.)
      if (code) {
        const microsoftLoginRequest: MicrosoftSingleSignOnRequest = {};
        microsoftLoginRequest.msAuthorizationCode = code;
        yield put(microsoftAuthLoginAction(microsoftLoginRequest));
      }
    }
  } catch (e: any) {
    yield put(
      logoutErrorAction((e?.errors && e.errors[0]?.message) || e?.message),
    );
  }
}

function* microsoftLoginSaga(data: {
  type: AuthActionType.MICROSOFT_LOGIN;
  payload: MicrosoftSingleSignOnRequest;
}): any {
  try {
    const loginResponse: LoginResponse = yield call(
      authService.microsoftLogin,
      data.payload,
    );
    const token = convertToken(loginResponse);
    token.platform = AuthPlatform.MICROSOFT;
    yield call(storageService.setSessionId, token.sessionId as string);
    if (loginResponse.user?.microsoftUserInfo) {
      yield call(
        storageService.setStoredMicrosoftInfo,
        loginResponse.user.microsoftUserInfo,
      );
    }
    yield call(storageService.setStoredToken, token);
    yield put(microsoftAuthLoginCompletedAction(token));
  } catch (e: any) {
    yield put(
      microsoftAuthLoginErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

function* authSaga() {
  yield all([
    takeLatest(AuthActionType.LOGIN, loginSaga),
    takeLatest(AuthActionType.GOOGLE_SIGN_IN_REQUEST, googleSignInSaga),
    takeLatest(AuthActionType.GOOGLE_LOGIN, googleLoginSaga),
    takeLatest(AuthActionType.MICROSOFT_SIGN_IN_REQUEST, microsoftSignInSaga),
    takeLatest(AuthActionType.MICROSOFT_LOGIN, microsoftLoginSaga),
    takeLatest(AuthActionType.SAML_LOGIN, samlLoginSaga),
    takeLatest(AuthActionType.SIGN_UP, registerUserSaga),
    takeLatest(AuthActionType.LOGOUT, logoutUserSaga),
  ]);
}

export default authSaga;
