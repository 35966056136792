export function getEnumName(map: any, val: any): string | undefined {
  return Object.keys(map).find((key) => map[key] === val);
}

export enum ApplicationName {
  GoogleDrive = 'Google Drive',
  Gmail = 'Gmail',
  GoogleSheets = 'Google Sheets',
  EntityExtraction = 'Entity Extraction',
  SalesForce = 'sales_force',
  Sap = 'sap',
  SftpServer = 'SFTP Server',
  DocumentClassification = 'Document Classification',
  GenerateOutput = 'Generate Output',
  Outlook = 'Outlook',
  MSExcel = 'MS Excel',
  UiAutomation = 'UI Automation',
}
