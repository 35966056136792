import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import React, { FC, memo, useEffect, useState } from 'react';
import { ELLIPSIS_STYLE } from '../../utils/constants';

interface CustomFilterSelectProps {
  label?: string;
  filters: { value: string | number; label: string | number }[];
  value: string | number;
  height?: string | number;
  width?: string | number;
  gapTop?: string | number;
  padding?: string | number;
  onChange?: (x: string | number) => void;
  left?: string | number;
  color?: string;
  placeholder?: string;
  name?: string;
  extraProps?: any;
  error?: boolean;
  helperText?: any;
  ariaLabel?: string;
  autoFocus?: boolean;
  bottom?: string | number;
  ellipsis?: boolean;
  disabled?: boolean;
}

const CustomFilterSelect: FC<CustomFilterSelectProps> = ({
  value,
  filters,
  height,
  width,
  left,
  padding,
  onChange,
  color,
  label,
  placeholder,
  name,
  extraProps,
  error,
  helperText,
  ariaLabel,
  gapTop,
  autoFocus,
  bottom,
  ellipsis,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const labelId = 'label' + Date.now();
  const selectedValue = extraProps?.value ?? value;

  const primaryTheme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: padding ?? '5px',
            fontSize: '14px',
            height: placeholder && '0px',
            marginLeft: '5px',
            color: color ?? '#545454',
          },
        },
      },
    },
  });

  useEffect(() => {
    if (open) {
      const elm = document.getElementById('root');
      if (elm) {
        elm.removeAttribute('aria-hidden');
      }
    }
  }, [open]);

  return (
    <FormControl
      error={error}
      sx={{
        width: width ?? 160,
        height: height ?? 24,
        marginLeft: left ?? 0,
        marginBottom: bottom ?? 0,
      }}
    >
      <Box id={labelId} display={'none'}>
        {ariaLabel ||
          (label && (
            <span>
              {ariaLabel ?? label}
              {filters.find((f) => f.value === value)?.label} selected
            </span>
          ))}
      </Box>
      {label && (
        <CustomTypography
          typographyType={TypographyType.Label}
          color='#344054'
          size='14px'
          weight={500}
          sx={{
            fontFamily: 'unset',
          }}
        >
          {label}
        </CustomTypography>
      )}
      <ThemeProvider theme={primaryTheme}>
        <Select
          disabled={disabled}
          autoFocus={autoFocus}
          name={name}
          value={value}
          error={error}
          labelId={labelId}
          title={filters.find((f) => f.value === selectedValue)?.label}
          sx={{
            marginTop: gapTop ? gapTop : label ? '8px' : 0,
            '& .MuiOutlinedInput-input  ': {
              cursor: disabled ? 'not-allowed' : 'pointer',
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#D0D5DD',
            },
          }}
          MenuProps={{
            style: { zIndex: 35001 },
          }}
          aria-label={ariaLabel ?? label}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(e: any) => {
            if (extraProps?.onChange) {
              extraProps?.onChange(e);
            } else {
              onChange?.(e.target.value);
            }
          }}
          {...extraProps}
        >
          {placeholder && (
            // When there is no value selected, we display the placeholder and for the validation to
            // work, the value of the selected value (which is placeholder in the case) should be
            // an empty string as per MUI
            <MenuItem value={''}>
              <CustomTypography color='#B6B6B6'>{placeholder}</CustomTypography>
            </MenuItem>
          )}
          {filters.map((obj) => {
            return (
              <MenuItem
                title={ellipsis ? (obj?.label as string) : ''}
                key={obj?.label}
                value={obj?.value}
              >
                {ellipsis ? (
                  <CustomTypography sx={ELLIPSIS_STYLE}>
                    {obj?.label}
                  </CustomTypography>
                ) : (
                  obj?.label
                )}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{error && helperText}</FormHelperText>
      </ThemeProvider>
    </FormControl>
  );
};

export default memo(CustomFilterSelect);
