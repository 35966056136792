import React, { FC, memo, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import Event from '@mui/icons-material/Event';
import { Box, Button } from '@mui/material';
import CustomTypography from '../../components/CustomTypography';
import './DateRangePicker.css';
import { DateRange } from '../../utils/constants';
import { OrbyColorPalette } from 'orby-ui/src';

interface DateButtonProps {
  date: dayjs.Dayjs;
  selected: boolean;
  onClick: () => void;
}

const DateButton: FC<DateButtonProps> = ({ date, selected, onClick }) => {
  return (
    <Box
      paddingY={'8px'}
      borderRadius={'12px'}
      bgcolor={selected ? '#FFFFFF' : '#7F56D9'}
      paddingX={'10px'}
      display={'flex'}
      alignItems={'center'}
      gap={'4px'}
      sx={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <Event
        sx={{ color: selected ? '#7F56D9' : '#FFFFFF' }}
        fontSize='small'
      />
      <CustomTypography
        weight={500}
        color={selected ? '#7F56D9' : '#FFFFFF'}
        size='14px'
        lineHeight='16px'
      >
        {dayjs(date).format('MMM D, YY')}
      </CustomTypography>
    </Box>
  );
};

interface CustomDateRangePickerProps {
  onClose: () => void;
  onRangeChange: (range: DateRange) => void;
  customRange: DateRange;
}

const CustomDateRangePicker: FC<CustomDateRangePickerProps> = ({
  customRange,
  onClose,
  onRangeChange,
}) => {
  const [startDate, setStartDate] = useState<Dayjs>(customRange.startDate);
  const [endDate, setEndDate] = useState(customRange.endDate);
  const [selectedRange, setSelectedRange] = useState('start');

  const handleDateRangeChange = (date: Dayjs) => {
    if (selectedRange === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleOk = () => {
    if (selectedRange === 'end') {
      onRangeChange({ startDate, endDate });
    } else {
      setSelectedRange('end');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        position={'absolute'}
        bgcolor={'#FFFFFF'}
        zIndex={1}
        top={'44px'}
        right={'0px'}
        sx={{
          '&.MuiCalendarPicker-root': {
            border: `1px solid ${OrbyColorPalette['grey-300']}`,
            borderRadius: '8px',
          },
        }}
      >
        <Box
          paddingX={'25px'}
          paddingY={'17px'}
          height={'95px'}
          bgcolor={'#7F56D9'}
          sx={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px' }}
        >
          <CustomTypography
            weight={600}
            color='#FFFFFF'
            size='14px'
            lineHeight='16px'
          >
            SELECT DATE RANGE
          </CustomTypography>
          <Box
            display={'flex'}
            marginTop={'12px'}
            justifyContent={'space-between'}
          >
            <DateButton
              date={startDate}
              selected={selectedRange === 'start'}
              onClick={() => setSelectedRange('start')}
            />
            <DateButton
              date={endDate}
              selected={selectedRange === 'end'}
              onClick={() => setSelectedRange('end')}
            />
          </Box>
        </Box>
        <CalendarPicker
          minDate={selectedRange === 'end' ? startDate : undefined}
          maxDate={selectedRange === 'start' ? endDate : undefined}
          date={selectedRange === 'start' ? startDate : endDate}
          onChange={(date) => handleDateRangeChange(date as Dayjs)}
          disableFuture
        />
        <Box
          display={'flex'}
          gap={1}
          position={'relative'}
          justifyContent={'flex-end'}
          paddingRight={'25px'}
          marginTop={'-35px'}
          height={'34px'}
          bgcolor={'#FFFFFF'}
          zIndex={1}
          marginX={'2px'}
          paddingY={'8px'}
          sx={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}
        >
          <Button
            onClick={onClose}
            sx={{ minWidth: 0, paddingX: '6px', paddingY: '3px' }}
          >
            <CustomTypography
              weight={600}
              color='#7F56D9'
              size='14px'
              lineHeight='16px'
            >
              Cancel
            </CustomTypography>
          </Button>
          <Button
            onClick={handleOk}
            sx={{ minWidth: 0, paddingX: '6px', paddingY: '3px' }}
          >
            <CustomTypography
              weight={600}
              color='#7F56D9'
              size='14px'
              lineHeight='16px'
            >
              Ok
            </CustomTypography>
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default memo(CustomDateRangePicker);
