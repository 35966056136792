import { Vertex } from 'protos/google/cloud/documentai/v1/geometry';

export interface BoxPositionValues {
  top: number;
  left: number;
  width: number;
  height: number;
}

export class BoxPositionValuesUtil {
  private static instance: BoxPositionValuesUtil;

  public static getInstance(): BoxPositionValuesUtil {
    if (!this.instance) {
      this.instance = new BoxPositionValuesUtil();
    }
    return this.instance;
  }

  getBoxPositionValues(width: number, height: number, vertices: Vertex[]) {
    const topLeft = boxPositionValuesUtil.getTopLeftCornerVertices(vertices);
    const topRight = boxPositionValuesUtil.getTopRightCornerVertices(vertices);
    const bottomRight =
      boxPositionValuesUtil.getBottomRightCornerVertices(vertices);

    const topPer = boxPositionValuesUtil.getTopPixel(height, topLeft.y!);
    const leftPer = boxPositionValuesUtil.getLeftPixel(width, topLeft.x!);
    const boxWidthPer = ((topRight.x! - topLeft.x!) / width) * 100;
    const boxHeightPer = ((bottomRight.y! - topRight.y!) / height) * 100;

    return {
      top: topPer,
      left: leftPer,
      width: boxWidthPer,
      height: boxHeightPer,
    };
  }

  getTopLeftCornerVertices(vertices: Vertex[]): Vertex {
    let topLeft: Vertex | null = null;
    vertices.forEach((v) => {
      if (topLeft === null) topLeft = v;
      if (v.x! < topLeft.x! || v.y! < topLeft.y!) topLeft = v;
    });
    return topLeft!;
  }

  getTopRightCornerVertices(vertices: Vertex[]): Vertex {
    let topRight: Vertex | null = null;
    vertices.forEach((v) => {
      if (topRight === null) topRight = v;
      if (v.x! > topRight.x! || v.y! < topRight.y!) topRight = v;
    });
    return topRight!;
  }

  getBottomRightCornerVertices(vertices: Vertex[]): Vertex {
    let bottomRight: Vertex | null = null;
    vertices.forEach((v) => {
      if (bottomRight === null) bottomRight = v;
      if (v.x! > bottomRight.x! || v.y! > bottomRight.y!) bottomRight = v;
    });
    return bottomRight!;
  }

  getTopPixel(height: number, yCoord: number) {
    return (yCoord / height) * 100;
  }

  getLeftPixel(width: number, xCoord: number) {
    return (xCoord / width) * 100;
  }
}

export const boxPositionValuesUtil = BoxPositionValuesUtil.getInstance();
