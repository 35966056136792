import {
  DocumentPageToken,
  DocumentTextAnchor,
  DocumentTextAnchorTextSegment,
} from 'protos/google/cloud/documentai/v1/document';

export class TokenValuesUtil {
  private static instance: TokenValuesUtil;

  public static getInstance(): TokenValuesUtil {
    if (!this.instance) {
      this.instance = new TokenValuesUtil();
    }
    return this.instance;
  }

  getSubString(textAnchor: DocumentTextAnchor, text: string) {
    const startIndex = textAnchor?.textSegments?.[0].startIndex ?? 0;
    const endIndex = textAnchor?.textSegments?.[0].endIndex ?? 0;
    return text.substring(startIndex, endIndex);
  }

  getSubStringFromTextSegment(
    textSegment: DocumentTextAnchorTextSegment,
    text: string,
  ) {
    const startIndex = textSegment?.startIndex ?? 0;
    const endIndex = textSegment?.endIndex ?? 0;

    return text.substring(startIndex, endIndex);
  }

  getTextFromTokens(tokens: DocumentPageToken[], text: string) {
    if (tokens === undefined) return undefined;
    const inputs: string[] = [];
    tokens.forEach((token: DocumentPageToken) => {
      inputs.push(
        this.getSubString(
          token?.layout?.textAnchor as DocumentTextAnchor,
          text,
        ),
      );
    });

    return this.removeTrailingPunctuations(inputs.join('')?.trim());
  }

  removeTrailingPunctuations(text: string) {
    return text?.replace(/[,.?!;:-]+$/, '')?.trim();
  }
}

export const tokenValuesUtil = TokenValuesUtil.getInstance();
