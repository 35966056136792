/**
 * This file contains the TaskCopySuccessConfirmation component.
 * This component is used to show the confirmation message after the user clicks on the copy button.
 * This component is wrapped by the TaskCopyModal component.
 */
import CustomTypography from '../../../components/CustomTypography';
import React, { FC, memo } from 'react';
import CustomModal from '../../CustomModal';

interface Props {
  showTaskCopyConfirmModal: boolean;
  setShowTaskCopyConfirmModal: (showTaskCopyConfirmModal: boolean) => void;
}

const TaskCopySuccessConfirmation: FC<Props> = ({
  showTaskCopyConfirmModal,
  setShowTaskCopyConfirmModal,
}) => {
  return (
    <CustomModal
      closable={false}
      open={showTaskCopyConfirmModal}
      heading={'Confirmation'}
      content={
        <CustomTypography sx={{ fontSize: '14px', color: '#101828' }}>
          We are working on it. You will receive an email once it’s completed.
        </CustomTypography>
      }
      handleClose={() => setShowTaskCopyConfirmModal(false)}
      primaryLabel={'OK'}
      secondaryLabel={''}
      onPrimaryClick={() => setShowTaskCopyConfirmModal(false)}
    />
  );
};

export default memo(TaskCopySuccessConfirmation);
