import {
  CancelExecutionRequest,
  CancelExecutionResponse,
  DeleteWorkflowRequest,
  ListWorkflowsRequest,
} from 'protos/pb/v1alpha1/orbot_service';
import {
  OrbotWorkflowActionType,
  WorkflowTaskActionType,
} from './actions.constants';
import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';

export const listWorkflowAction = (
  req: ListWorkflowsRequest,
  refresh = false,
) => ({
  type: OrbotWorkflowActionType.LIST_WORKFLOWS,
  req,
  refresh,
});

export const listWorkflowHookAction = (
  req: ListWorkflowsRequest,
  refresh = false,
) => ({
  type: OrbotWorkflowActionType.LIST_WORKFLOWS_HOOK,
  req,
  refresh,
});

export const listWorkflowCompletedAction = (
  workflows: Workflow[],
  nextPageToken: string,
  totalSize: number,
  refresh: boolean,
) => ({
  type: OrbotWorkflowActionType.LIST_WORKFLOWS_COMPLETED,
  payload: workflows,
  nextPageToken,
  totalSize,
  refresh,
});

export const listWorkflowErrorAction = (error?: Error) => ({
  type: OrbotWorkflowActionType.LIST_WORKFLOWS_ERROR,
  payload: error,
});

export const deleteWorkflowAction = (payload: DeleteWorkflowRequest) => ({
  type: OrbotWorkflowActionType.DELETE_WORKFLOW,
  payload,
});

export const deleteWorkflowCompletedAction = (name: string) => ({
  type: OrbotWorkflowActionType.DELETE_WORKFLOW_COMPLETED,
  payload: name,
});

export const deleteWorkflowErrorAction = (error: string) => ({
  type: OrbotWorkflowActionType.DELETE_WORKFLOW_ERROR,
  payload: error,
});

export const cancelExecutionAction = (req: CancelExecutionRequest) => ({
  type: WorkflowTaskActionType.CANCEL_EXECUTION,
  payload: req,
});

export const cancelExecutionActionCompleted = (
  resp: CancelExecutionResponse,
) => ({
  type: WorkflowTaskActionType.CANCEL_EXECUTION_COMPLETED,
  payload: resp,
});

export const cancelExecutionActionError = (err: Error) => ({
  type: WorkflowTaskActionType.CANCEL_EXECUTION_ERROR,
  payload: err,
});

export const cancelChildExecutionsAction = (req: CancelExecutionRequest) => ({
  type: WorkflowTaskActionType.CANCEL_CHILD_EXECUTIONS,
  payload: req,
});

export const cancelChildExecutionsCompleted = (
  resp: CancelExecutionResponse,
) => ({
  type: WorkflowTaskActionType.CANCEL_CHILD_EXECUTIONS_COMPLETED,
  payload: resp,
});

export const cancelChildExecutionsError = (err: Error) => ({
  type: WorkflowTaskActionType.CANCEL_CHILD_EXECUTIONS_ERROR,
  payload: err,
});

export const clearCancelExecutionAction = () => ({
  type: WorkflowTaskActionType.CLEAR_CANCELLING_EXECUTION,
});
