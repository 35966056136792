import { fetchSessionId } from '../utils/Auth';
import { LogoutRequest } from 'protos/pb/v1alpha1/users_service';
import { logoutAction } from '../redux/actions/auth.action';
import { useDispatch } from 'react-redux';

// This hook is used to handle the logout process
const useLogoutHandler = () => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    const sessionId = await fetchSessionId(false);
    const req: LogoutRequest = {};
    req.name = sessionId || '';
    dispatch(logoutAction(req));
  };

  return { handleLogout };
};

export default useLogoutHandler;
