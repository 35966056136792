import React, { memo, useImperativeHandle } from 'react';
import CustomTextField from '../../../components/CustomTextField';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

interface ItemDeletionFormProps {
  onSubmit: (deletionReason: string) => void;
}
export interface FormikFormRef {
  submitForm: () => void;
}

const ItemDeletionForm = React.forwardRef<FormikFormRef, ItemDeletionFormProps>(
  ({ onSubmit }, ref) => {
    const formik = useFormik({
      initialValues: {
        deletionReason: '',
      },
      validationSchema: Yup.object({
        deletionReason: Yup.string().required('Deletion reason is mandatory'),
      }),
      onSubmit: (values) => {
        onSubmit(values.deletionReason);
      },
    });
    const { getFieldProps, values, touched, errors, submitForm } = formik;

    useImperativeHandle(ref, () => ({
      submitForm: submitForm,
    }));

    return (
      <FormikProvider value={formik}>
        <Form id='form1' autoComplete='off' noValidate>
          <CustomTextField
            placeholder='Enter the reason for deletion'
            labelStyle={{
              color: '#344054',
              fontSize: '14px',
              fontWeight: 500,
            }}
            ariaLabel='Reason for deletion'
            label=''
            name='deletionReason'
            size='full'
            value={values.deletionReason}
            extraProps={getFieldProps('deletionReason')}
            error={Boolean(touched.deletionReason && errors.deletionReason)}
            helperText={touched.deletionReason && errors.deletionReason}
            helperTextStyle={{ marginLeft: '0px !important' }}
          />
        </Form>
      </FormikProvider>
    );
  },
);

ItemDeletionForm.displayName = 'ItemDeletionForm';

export default memo(ItemDeletionForm);
