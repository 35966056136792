export const ANCHOR_ID = 'orby-root';
export const POINTER_ID = 'orby-pointer';
export const CONTEXT_CONTAINER_ID = 'orby-context-container';
export const CLICK_ANIMATION_ID = 'orby-click-animation';

export const ORBY_GRPC_URL = process.env.ORBY_GRPC_URL;
export const ORBY_LOGIN_URL = process.env.ORBY_LOGIN_URL;
export const WEB_SOCKET_SERVER_PREFIX = 'ws-server/v1alpha1';
export const TIME_TO_WAIT_FOR_NO_DOM_CHANGE = 800;
export const EXTENSION_ID = 'hijnmoemdldebjokiebfcalfjkkdnejb';
export const EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/' + EXTENSION_ID;

// This duration must be less than above one.
export const TIME_TO_WAIT_FOR_REASSIGN_ORBY_ID = 200;

export const TIME_TO_WAIT_FOR_NO_TYPE = 300;
export const TIME_TO_WAIT_FOR_EVENT_MERGE = 150;
export const RECORD_PAGE_ID = 'orby-record-page';

export const LOW_CONFIDENCE_THRESHOLD = 20;
export const HIGH_CONFIDENCE_THRESHOLD = 80;

// default max tabs per machine
export const DEFAULT_MAX_TABS = 30;

// Dev only settings
export const EXECUTE_TASK_ON_CURRENT_TAB = false;
export const ALLOW_RECORDING_CHILD_WORKFLOW = false;
