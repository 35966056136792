import { Box, Button, CircularProgress, SxProps, Theme } from '@mui/material';
import React, { FC, memo } from 'react';

interface CustomComboButtonsProp {
  primaryLabel: string;
  secondaryLabel: string;
  size: 'small' | 'medium' | 'large';
  marginLeft?: string | number;
  primarySx?: SxProps<Theme>;
  secondarySx?: SxProps<Theme>;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  primaryDisabled?: boolean;
  secondaryDisabled?: boolean;
  secondaryLoading?: boolean;
  sx?: SxProps<Theme>;
  reverse?: boolean;
  secondaryAutoFocus?: boolean;
  rounded?: string | number;
  primaryTitle?: string;
  secondaryTitle?: string;
  secondaryId?: string;
}

// THIS COMPONENT IS USED TO DISPLAY TWO BUTTONS - PRIMARY BUTTON (DISPLAYS FIRST), SECONDARY BUTTON (DISPLAYS AFTER PRIMARY)
// EACH OF WHICH HAS ITS OWN PROPS LIKE label, onClick, disabled, sx, loading, title (for tooltip) and id
const CustomComboButtons: FC<CustomComboButtonsProp> = ({
  primaryLabel,
  secondaryLabel,
  onPrimaryClick,
  onSecondaryClick,
  primaryDisabled,
  secondaryDisabled,
  size,
  marginLeft,
  primarySx,
  secondarySx,
  secondaryLoading,
  sx,
  reverse,
  secondaryAutoFocus,
  rounded,
  primaryTitle,
  secondaryTitle,
  secondaryId,
}) => {
  const spaceBetween = size === 'large' ? '48px' : '23px';
  const padding = size === 'large' ? 2 : 1;
  const borderRadius = rounded ? rounded : size === 'large' ? '4px' : '2px';
  return (
    <Box
      sx={sx}
      padding={padding}
      marginLeft={marginLeft ?? 0}
      display={'flex'}
      flexDirection={reverse ? 'row' : 'row-reverse'}
      alignItems={'center'}
      gap={spaceBetween}
    >
      <Button
        id={secondaryId}
        autoFocus={secondaryAutoFocus}
        disabled={secondaryDisabled}
        onClick={onSecondaryClick}
        variant='contained'
        size={size}
        title={secondaryTitle}
        sx={{
          borderRadius,
          ...secondarySx,
          '&.Mui-disabled': {
            color: '#474747',
            backgroundColor: '#D0E1FD',
          },
        }}
      >
        {secondaryLoading ? <CircularProgress size={'27px'} /> : secondaryLabel}
      </Button>
      <Button
        title={primaryTitle}
        onClick={onPrimaryClick}
        disabled={primaryDisabled}
        sx={{ borderRadius, ...primarySx }}
        size={size}
      >
        {primaryLabel}
      </Button>
    </Box>
  );
};

export default memo(CustomComboButtons);
