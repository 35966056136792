import { UtilityActionType } from '../actions/actions.constants';
import {
  getGmailLabelsCompletedAction,
  getGmailLabelsErrorAction,
  getScheduledTasksCompletedAction,
  getScheduledTasksErrorAction,
} from '../actions/utility.action';
import { utilityService } from '../../services/UtilityService';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  GetEmailLabelsRequest,
  GetScheduleTasksRequest,
  GetScheduleTasksResponse,
  ScheduleTask,
} from 'protos/pb/v1alpha2/utility_service';

export function* getGmailLabelsSaga(data: {
  type: UtilityActionType.GET_GMAIL_LABELS;
  payload: GetEmailLabelsRequest;
}): any {
  try {
    const { response, error } = yield call(
      utilityService.getEmailLabels,
      data.payload,
    );
    if (response) {
      yield put(getGmailLabelsCompletedAction(response.labels));
    } else {
      yield put(getGmailLabelsErrorAction(error));
    }
  } catch (e: any) {
    yield put(getGmailLabelsErrorAction(e));
  }
}

export function* getScheduledTasksSaga(data: {
  type: UtilityActionType.GET_SCHEDULED_TASKS;
  payload: GetScheduleTasksRequest;
}): any {
  try {
    const { response, error } = yield call(
      utilityService.getScheduledTasks,
      data.payload,
    );
    if (response) {
      yield put(
        getScheduledTasksCompletedAction(
          (response as GetScheduleTasksResponse).tasks as ScheduleTask[],
        ),
      );
    } else {
      yield put(getScheduledTasksErrorAction(error));
    }
  } catch (e: any) {
    yield put(getScheduledTasksErrorAction(e));
  }
}

function* utilitySaga() {
  yield all([
    takeLatest(UtilityActionType.GET_GMAIL_LABELS, getGmailLabelsSaga),
    takeLatest(UtilityActionType.GET_SCHEDULED_TASKS, getScheduledTasksSaga),
  ]);
}

export default utilitySaga;
