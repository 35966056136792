import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ForgotPassword from './AuthPages/ForgotPasswordPage';
import LoginPage from './AuthPages/LoginPage';
import SignUp from './AuthPages/SignUpPage';
import { useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../redux/selectors/user.selectors';

const AuthPage: React.FC = () => {
  const navigate = useNavigate();
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const user = useSelector(loggedInUserSelector);

  useEffect(() => {
    if (user && !selectedOrgInfo) {
      navigate('/login');
    }
  }, [user, selectedOrgInfo]);

  return (
    <Suspense fallback={<span>Loading</span>}>
      <Routes>
        <Route path='/login/*' element={<LoginPage />} />
        <Route path='/sign-up/*' element={<SignUp />} />
        <Route path='/forgot-password/*' element={<ForgotPassword />} />
      </Routes>
    </Suspense>
  );
};

export default AuthPage;
