import { Empty } from 'protos/google/protobuf/empty';
import { storageService } from './StorageService';
import {
  ConnectorsClientImpl,
  CreateConnectorRequest,
  DeleteConnectorRequest,
  GetConnectorRequest,
  ListConnectorRequest,
  ListConnectorResponse,
  UpdateConnectorRequest,
} from 'protos/pb/v1alpha2/connector_service';
import { Connector } from 'protos/pb/v1alpha2/connector';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';

export class ConnectorService {
  private static instance: ConnectorService;
  private static client = new ConnectorsClientImpl(rpcWithErrorHandling);

  public static getInstance(): ConnectorService {
    if (!this.instance) {
      this.instance = new ConnectorService();
    }
    return this.instance;
  }

  async listConnectors(
    req: ListConnectorRequest,
  ): Promise<{ response?: ListConnectorResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await ConnectorService.client.ListConnectors(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error: any) {
      return { error };
    }
  }

  async createConnector(
    connector: Connector,
  ): Promise<{ response?: Connector; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    const req: CreateConnectorRequest = {};
    req.connector = connector;
    try {
      const response = await ConnectorService.client.CreateConnector(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error: any) {
      return { error };
    }
  }

  async updateConnector(
    req: UpdateConnectorRequest,
  ): Promise<{ response?: Connector; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await ConnectorService.client.UpdateConnector(
        req,
        getMetaData({
          authorization,
        }),
      );
      return { response };
    } catch (error: any) {
      return { error };
    }
  }

  async getConnector(
    name: string,
  ): Promise<{ response?: Connector; error?: string }> {
    const authorization = await storageService.getAuthorizationHeader();
    const req: GetConnectorRequest = {};
    req.name = name;
    try {
      const response = await ConnectorService.client.GetConnector(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error: any) {
      return { error };
    }
  }

  async deleteConnector(req: DeleteConnectorRequest): Promise<Empty> {
    const authorization = await storageService.getAuthorizationHeader();
    return await ConnectorService.client.DeleteConnector(req, {
      authorization,
    } as any);
  }
}

export const connectorService = ConnectorService.getInstance();
