import { storageService } from './StorageService';
import {
  DashboardClientImpl,
  GetDashboardStatsRequest,
  GetDashboardStatsResponse,
} from 'protos/pb/v1alpha2/dashboard_service';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';
import { Observable } from 'rxjs';

export interface Term {
  name: string;
  accuracy: number;
  createdAt: string;
  workflowName: string;
}

export interface WorkflowOptions {
  label: string;
  value: string;
}

export class DashboardService {
  private static instance: DashboardService;
  private static client = new DashboardClientImpl(rpcWithErrorHandling);

  public static getInstance(): DashboardService {
    if (!this.instance) {
      this.instance = new DashboardService();
    }
    return this.instance;
  }

  async getDashboardStats(req: GetDashboardStatsRequest): Promise<{
    response?: Observable<GetDashboardStatsResponse>;
    error?: Error;
  }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const call: Observable<GetDashboardStatsResponse> =
        DashboardService.client.GetDashboardStats(
          req,
          getMetaData({ authorization }),
        );

      return { response: call };
    } catch (error: any) {
      return { error };
    }
  }
}

export const dashboardService = DashboardService.getInstance();
