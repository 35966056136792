import {
  Action,
  ActionBlock,
  ActionGroup,
  PreparedAction,
  PrerequisiteActionPrerequisiteType,
} from 'protos/pb/v1alpha1/orbot_action';
import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';
import * as uuid from 'uuid';

/**
 * Migrate workflow loaded from DB to the latest version.
 * This allows us to clean up the business logic by only supporting a single version of the workflow.
 * The migration code can be removed when we are confident no legacy workflow is actively used in DB.
 * */
export function migrateWorkflow(workflow: Workflow): Workflow {
  return {
    ...workflow,
    processes: workflow.processes?.map((process) => ({
      ...process,
      generatedActionGroups: process.generatedActionGroups?.map((actionGroup) =>
        migrateActionGroup(actionGroup),
      ),
      actions:
        process.actions && process.actions.length > 0
          ? process.actions
          : process.generatedActionGroups
              ?.map((actionGroup) => migrateActionGroup(actionGroup))
              .flatMap((actionGroup) =>
                convertActionGroupToActions([actionGroup]),
              ),
    })),
  };
}

function convertActionGroupToActions(
  actionGroups: readonly ActionGroup[],
): Action[] {
  return actionGroups.map(convertActionGroup);
}

function migrateActionGroup(actionGroup: ActionGroup): ActionGroup {
  return {
    ...actionGroup,
    preparedActions: actionGroup.preparedActions?.map((action) => {
      const migratedAction = migrateAction(action);
      return migratedAction;
    }),
  };
}

function migrateAction(action: PreparedAction): PreparedAction {
  const migrate = (action: PreparedAction) => {
    // Additional migration logic can be added here
    return [migrateFixedLocator, migrateParams].reduce(
      (result, func) => func(result),
      action,
    );
  };

  const migratedAction = migrate(action);
  if (migratedAction.foreachAction) {
    return {
      ...migratedAction,
      foreachAction: {
        ...migratedAction.foreachAction,
        actions: migratedAction.foreachAction.actions?.map(migrateActionGroup),
      },
    };
  }
  if (migratedAction.conditionAction) {
    return {
      ...migratedAction,
      conditionAction: {
        ...migratedAction.conditionAction,
        trueActions:
          migratedAction.conditionAction.trueActions?.map(migrateActionGroup),
        falseActions:
          migratedAction.conditionAction.falseActions?.map(migrateActionGroup),
      },
    };
  }
  return migratedAction;
}

// Migrate PreparedAction.params to the corresponding action field
function migrateParams(action: PreparedAction): PreparedAction {
  if (!action.params || action.params.length === 0) {
    return action;
  }

  if (action.gotoAction) {
    return {
      ...action,
      gotoAction: {
        ...action.gotoAction,
        url: action.params[0],
      },
    };
  }

  if (action.clickAction) {
    return {
      ...action,
      clickAction: {
        ...action.clickAction,
        locator: action.params[0],
      },
    };
  }

  if (action.getElementAction) {
    return {
      ...action,
      getElementAction: {
        ...action.getElementAction,
        elementLocator: action.params[0],
      },
    };
  }

  // We only have fillFormAction in production for Google workflow, the migration only needs to support their use case
  if (action.fillFormAction) {
    let fieldLocator = JSON.parse(action.params[0].jsonValue!);
    if (action.fillFormAction.formLocator) {
      fieldLocator = {
        ...fieldLocator,
        parentLocator: action.fillFormAction.formLocator,
      };
    }
    return {
      ...action,
      setValueAction: {
        fieldLocator: { jsonValue: JSON.stringify(fieldLocator) },
        fieldValue: action.params[1],
      },
    };
  }

  if (action.jsFunctionAction) {
    return {
      ...action,
      jsFunctionAction: {
        ...action.jsFunctionAction,
        params: action.params,
      },
    };
  }

  if (action.validateAction) {
    return {
      ...action,
      validateAction: {
        ...action.validateAction,
        source: action.params[0],
      },
    };
  }

  if (action.conditionAction) {
    return {
      ...action,
      conditionAction: {
        ...action.conditionAction,
        condition: action.params[0],
      },
    };
  }

  if (action.foreachAction) {
    return {
      ...action,
      foreachAction: {
        ...action.foreachAction,
        items: action.params?.[0],
      },
    };
  }

  if (action.createTaskAction) {
    return {
      ...action,
      createTaskAction: {
        ...action.createTaskAction,
        workflowVariables: action.params,
      },
    };
  }

  if (action.detectDuplicateLineItemsAction) {
    return {
      ...action,
      detectDuplicateLineItemsAction: {
        ...action.detectDuplicateLineItemsAction,
        source: action.params[0],
      },
    };
  }

  if (action.flagKeywordsAction) {
    return {
      ...action,
      flagKeywordsAction: {
        ...action.flagKeywordsAction,
        source: action.params[0],
      },
    };
  }

  if (action.hoverAction) {
    return {
      ...action,
      hoverAction: {
        ...action.hoverAction,
        locator: action.params[0],
      },
    };
  }

  return action;
}

// Migrate fixed element locator to ActionParamValue that support both dynamic and fixed locator
function migrateFixedLocator(action: PreparedAction): PreparedAction {
  if (action.getElementAction?.locator) {
    return {
      ...action,
      getElementAction: {
        ...action.getElementAction,
        elementLocator: {
          jsonValue: JSON.stringify(action.getElementAction.locator),
        },
      },
    };
  }

  if (action.clickAction?.elementLocator) {
    return {
      ...action,
      clickAction: {
        ...action.clickAction,
        locator: {
          jsonValue: JSON.stringify(action.clickAction.elementLocator),
        },
      },
    };
  }

  if (action.hoverAction?.elementLocator) {
    return {
      ...action,
      hoverAction: {
        ...action.hoverAction,
        locator: {
          jsonValue: JSON.stringify(action.hoverAction.elementLocator),
        },
      },
    };
  }

  return action;
}

function convertActionGroup(actionGroup: ActionGroup): Action {
  if (!actionGroup.preparedActions || actionGroup.preparedActions.length < 1) {
    throw new Error('ActionGroup is expected to have only one PreparedAction');
  }

  if (actionGroup.preparedActions.length > 1) {
    // Special case condition with prerequisite
    if (
      actionGroup.preparedActions.length === 2 &&
      actionGroup.preparedActions[1].conditionAction
    ) {
      return Action.create({
        ...convertPreparedAction(actionGroup, actionGroup.preparedActions[1]),
        prerequisites: [
          {
            action: convertPreparedAction(
              actionGroup,
              actionGroup.preparedActions[0],
            ),
            type: PrerequisiteActionPrerequisiteType.SMART_BOOLEAN,
          },
        ],
      });
    }

    const actionBlock = ActionBlock.create({
      actions: actionGroup.preparedActions.map((action) =>
        convertPreparedAction(actionGroup, action),
      ),
    });
    return Action.create({
      id: uuid.v4(),
      description: actionGroup.description,
      block: actionBlock,
    });
  } else {
    return convertPreparedAction(actionGroup, actionGroup.preparedActions[0]);
  }
}

function convertPreparedAction(
  actionGroup: ActionGroup,
  preparedAction: PreparedAction,
): Action {
  const action = Action.create({
    id: preparedAction.uuid,
    description: actionGroup.description,
    tabIndex: preparedAction.tabIndex,
    snapshot: actionGroup.snapshot,
    screenshot: actionGroup.screenshot,

    goto: preparedAction.gotoAction,
    click: preparedAction.clickAction,
    getForm: preparedAction.getFormAction,
    fillForm: preparedAction.fillFormAction,
    extractFields: preparedAction.extractFieldsAction,
    jsFunction: preparedAction.jsFunctionAction,
    validate: preparedAction.validateAction,
    getList: preparedAction.getListAction,
    getElement: preparedAction.getElementAction,
    flagKeywords: preparedAction.flagKeywordsAction,
    detectDuplicateLineItems: preparedAction.detectDuplicateLineItemsAction,
    createTask: preparedAction.createTaskAction,
    reconcileItems: preparedAction.reconcileItemsAction,
    hover: preparedAction.hoverAction,
    exit: preparedAction.exitAction,
    setValue: preparedAction.setValueAction,
    customSmartAction: preparedAction.customSmartAction,
    getDocument: preparedAction.getDocumentAction,
    generateText: preparedAction.generateTextAction,
    classify: preparedAction.classifyAction,
  });

  if (preparedAction.foreachAction) {
    action.foreach = {
      items: preparedAction.foreachAction.items || preparedAction.params?.[0],
      loopActions: convertActionGroupToActions(
        preparedAction.foreachAction.actions || [],
      ),
    };
  } else if (preparedAction.conditionAction) {
    action.condition = {
      condition: preparedAction.conditionAction.condition,
      thenActions: convertActionGroupToActions(
        preparedAction.conditionAction.trueActions || [],
      ),
      elseActions: convertActionGroupToActions(
        preparedAction.conditionAction.falseActions || [],
      ),
    };
  }

  return action;
}
