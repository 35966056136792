import React, { useRef } from 'react';
import CustomTypography from '../../../components/CustomTypography';
import CustomModal from '../../../components/CustomModal';
import { Box, List, ListItem } from '@mui/material';
import ItemDeletionForm, {
  FormikFormRef,
} from '../../Tasks/component/ItemDeletionForm';

interface WorkflowDeletionModalProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: (deletionReason: string) => void;
  isMultiple?: boolean;
  showForm?: boolean;
}

const WorkflowDeletionModal: React.FC<WorkflowDeletionModalProps> = ({
  open,
  handleClose,
  onSubmit,
  isMultiple = false,
  showForm = true,
}) => {
  const formRef = useRef<FormikFormRef>(null);
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      onPrimaryClick={() => formRef.current?.submitForm()}
      heading={isMultiple ? 'Delete Items?' : 'Delete Item?'}
      content={
        <>
          <Box sx={{ width: '433px' }}>
            <CustomTypography>
              By deleting this, you will also delete the following :
            </CustomTypography>
            <List
              disablePadding
              sx={{
                listStyleType: 'disc',
                listStylePosition: 'inside',
                lineHeight: '8px',
                mt: '24px',
                mb: '24px',
              }}
            >
              <ListItem sx={{ display: 'list-item', fontFamily: 'Inter' }}>
                All connectors related to this workflow
              </ListItem>
              <ListItem sx={{ display: 'list-item', fontFamily: 'Inter' }}>
                All tasks related to this workflow
              </ListItem>
              {/* https://github.com/orby-ai-engineering/orby-web-app/pull/888#discussion_r1500154738
              <ListItem sx={{ display: 'list-item', fontFamily: 'Inter' }}>
                The workflow root folder
              </ListItem> */}
            </List>
          </Box>

          {showForm && <ItemDeletionForm ref={formRef} onSubmit={onSubmit} />}
        </>
      }
      primaryLabel={'Delete'}
      secondaryLabel={'Cancel'}
    />
  );
};

export default React.memo(WorkflowDeletionModal);
