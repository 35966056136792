import React from 'react';
import OrbyColorPalette from '../../colors/ColorPalette';
import OrbyTypography from '../../typography/OrbyTypography';
import { Box } from '@mui/material';

interface OrbyMenuItemContentProps {
  /**
   * Primary label to be displayed
   */
  title: string;
  /**
   * Icon/Image to be displayed as prefix
   */
  icon?: JSX.Element;
  /**
   * Secondary label to be displayed on menu item
   */
  description?: string;
  /**
   * Truncates the title or description of the menu item
   * @default 'Title'
   */
  truncate?: 'TITLE' | 'DESCRIPTION' | 'BOTH';
  /**
   * If the menu item is selected
   */
  isSelected?: boolean;
  /**
   * Override the title label
   */
  titleLabelOverride?: string;
}
const OrbyMenuItemContent: React.FC<OrbyMenuItemContentProps> = ({
  title,
  icon,
  description,
  truncate = 'TITLE',
  isSelected = false,
  titleLabelOverride = title,
}) => {
  const ELLIPSIS_STYLE = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <Box
      width={isSelected ? '80%' : '100%'}
      display={'flex'}
      gap={1}
      alignItems={'center'}
      title={titleLabelOverride}
    >
      {icon && (
        <Box
          width={'24px'}
          height={'24px'}
          display={'flex'}
          alignItems={'center'}
        >
          {icon}
        </Box>
      )}
      <OrbyTypography
        sx={truncate === 'TITLE' || truncate === 'BOTH' ? ELLIPSIS_STYLE : {}}
        color={OrbyColorPalette['grey-900']}
        size='md'
        weight='medium'
      >
        {title}
      </OrbyTypography>
      <OrbyTypography
        sx={
          truncate === 'DESCRIPTION' || truncate === 'BOTH'
            ? ELLIPSIS_STYLE
            : {}
        }
        color={OrbyColorPalette['grey-500']}
        size='md'
        weight='regular'
      >
        {description}
      </OrbyTypography>
    </Box>
  );
};

export default React.memo(OrbyMenuItemContent);
