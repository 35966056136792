import { useEffect, useMemo, useRef, useState } from 'react';

/**
 * Custom hook to track changes in the organization resource name.
 * @param {string} orgResourceName The name of the organization resource.
 * @returns {{ isOrgChanged: boolean }} An object containing a boolean flag indicating whether the organization has changed.
 */
export const useIsOrgChanged = (orgResourceName: string) => {
  const [isOrgChanged, setIsOrgChanged] = useState(false);
  const isFirstRender = useRef<boolean>(true);

  useEffect(() => {
    // skip on first render since first render does not indicate the change
    if (!isFirstRender.current) {
      setIsOrgChanged(true);
    }
    isFirstRender.current = false;
  }, [orgResourceName]);

  const orgInfo = useMemo(
    () => ({ isOrgChanged }),
    [orgResourceName, isOrgChanged],
  );

  return orgInfo;
};
