import {
  CreateWorkflowRequest,
  DeleteWorkflowRequest,
  DeleteWorkflowResponse,
  GetWorkflowRequest,
  ListWorkflowFieldsRequest,
  ListWorkflowFieldsResponse,
  ListWorkflowsRequest,
  ListWorkflowsResponse,
  UpdateWorkflowRequest,
  Workflow,
  WorkflowsClientImpl,
} from 'protos/pb/v1alpha2/workflows_service';
import { storageService } from './StorageService';
import { WorkflowType } from '../utils/constants';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';

export class WorkflowService {
  private static instance: WorkflowService;
  private static client = new WorkflowsClientImpl(rpcWithErrorHandling);

  public static getInstance(): WorkflowService {
    if (!this.instance) {
      this.instance = new WorkflowService();
    }
    return this.instance;
  }

  async listWorkflows(
    req: ListWorkflowsRequest,
  ): Promise<{ response?: ListWorkflowsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await WorkflowService.client.ListWorkflows(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async listWorkflowFields(
    req: ListWorkflowFieldsRequest,
  ): Promise<{ response?: ListWorkflowFieldsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await WorkflowService.client.ListWorkflowFields(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async listAllWorkflows(
    orgResourceName: string,
    workflowType?: WorkflowType,
    workflowList: Workflow[] = [],
    nextPage = 1,
  ): Promise<{ response?: Workflow[]; error?: Error }> {
    try {
      const req: ListWorkflowsRequest = {};
      req.pageSize = 20;
      req.fieldMask = ['name', 'display_name'];

      // workflowType != null checks for both null and undefined notice we used '!=' not '!=='
      if (workflowType != null && WorkflowType[workflowType]) {
        req.filter = `type=${WorkflowType[workflowType]}`;
      }
      req.orgResourceName = orgResourceName;
      req.pageNumber = nextPage;
      const { response, error } = await workflowService.listWorkflows(req);
      if (response && response.workflows && response.totalSize) {
        workflowList = [...workflowList, ...response.workflows];
        // This is to check if the workflow list is not empty and the total size of the list is not reached the workflow list
        if (
          response.workflows?.length > 0 &&
          workflowList.length < response.totalSize
        ) {
          return await workflowService.listAllWorkflows(
            orgResourceName,
            workflowType,
            workflowList,
            nextPage + 1,
          );
        } else {
          return {
            response: workflowList,
          };
        }
      } else {
        return { error };
      }
    } catch (error) {
      return { error: error as Error };
    }
  }

  async createWorkflow(
    workflow: Workflow,
  ): Promise<{ response?: Workflow; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    const req: CreateWorkflowRequest = {};
    req.workflow = workflow;
    try {
      const response = await WorkflowService.client.CreateWorkflow(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateWorkflow(
    req: UpdateWorkflowRequest,
  ): Promise<{ response?: Workflow; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await WorkflowService.client.UpdateWorkflow(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getWorkflow(
    name: string,
  ): Promise<{ response?: Workflow; error?: string }> {
    const authorization = await storageService.getAuthorizationHeader();
    const req: GetWorkflowRequest = {};
    req.name = name;
    try {
      const response = await WorkflowService.client.GetWorkflow(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as string };
    }
  }

  async deleteWorkflow(
    req: DeleteWorkflowRequest,
  ): Promise<DeleteWorkflowResponse> {
    const authorization = await storageService.getAuthorizationHeader();
    return await WorkflowService.client.DeleteWorkflow(
      req,
      getMetaData({ authorization }),
    );
  }
}

export const workflowService = WorkflowService.getInstance();
