import {
  GetOrganizationRequest,
  UpdateOrganizationRequest,
} from 'protos/pb/v1alpha1/organization_service';
import { OrganizationActionType } from './actions.constants';
import { Organization } from 'protos/pb/v1alpha1/organization';

export const getOrganizationAction = (req: GetOrganizationRequest) => ({
  type: OrganizationActionType.GET_ORGANIZATION,
  payload: req,
});

export const getOrganizationCompletedAction = (org: Organization) => ({
  type: OrganizationActionType.GET_ORGANIZATION_COMPLETED,
  payload: org,
});

export const getOrganizationErrorAction = (error?: Error) => ({
  type: OrganizationActionType.GET_ORGANIZATION_ERROR,
  payload: error,
});

export const updateOrganizationAction = (req: UpdateOrganizationRequest) => ({
  type: OrganizationActionType.UPDATE_ORGANIZATION,
  payload: req,
});

export const updateOrganizationCompletedAction = (payload: Organization) => ({
  type: OrganizationActionType.UPDATE_ORGANIZATION_COMPLETED,
  payload,
});

export const updateOrganizationErrorAction = (error?: Error) => ({
  type: OrganizationActionType.UPDATE_ORGANIZATION_ERROR,
  payload: error,
});

export const clearOrganizationUpdateStatusAction = () => ({
  type: OrganizationActionType.CLEAR_UPDATE_STATUS,
});
