import produce from 'immer';
import { Reducer } from 'redux';
import { Organization } from 'protos/pb/v1alpha1/organization';
import { OrganizationActionType } from '../actions/actions.constants';

export interface OrganizationState {
  organizationUpdating: boolean;
  organizationUpdated: boolean;
  organizationUpdatingError?: Error;

  organization?: Organization;
  organizationLoading: boolean;
  organizationLoadingError?: Error;
}

const initialState: OrganizationState = {
  organizationUpdating: false,
  organizationUpdated: false,
  organizationLoading: false,
};

export const organizationReducer: Reducer<OrganizationState> = (
  state: OrganizationState = initialState,
  action: any,
) =>
  produce(state, (draft: OrganizationState) => {
    switch (action.type) {
      case OrganizationActionType.GET_ORGANIZATION: {
        draft.organizationLoading = true;
        break;
      }
      case OrganizationActionType.GET_ORGANIZATION_COMPLETED: {
        draft.organizationLoading = false;
        draft.organization = action.payload;
        break;
      }
      case OrganizationActionType.GET_ORGANIZATION_ERROR: {
        draft.organizationLoading = false;
        draft.organizationLoadingError = action.payload;
        break;
      }
      case OrganizationActionType.UPDATE_ORGANIZATION: {
        draft.organizationUpdating = true;
        break;
      }
      case OrganizationActionType.UPDATE_ORGANIZATION_COMPLETED: {
        draft.organizationUpdating = false;
        draft.organizationUpdated = true;
        draft.organization = action.payload;
        break;
      }
      case OrganizationActionType.UPDATE_ORGANIZATION_ERROR: {
        draft.organizationUpdating = false;
        draft.organizationUpdatingError = action.payload;
        break;
      }

      case OrganizationActionType.CLEAR_UPDATE_STATUS: {
        draft.organizationUpdated = false;
        break;
      }
      default:
        break;
    }
  });
