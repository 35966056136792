import {
  DeleteWorkflowRequest,
  ListWorkflowFieldsRequest,
  ListWorkflowFieldsResponse,
  ListWorkflowsRequest,
  UpdateWorkflowRequest,
  Workflow,
  WorkflowField,
} from 'protos/pb/v1alpha2/workflows_service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { WorkflowActionType } from '../actions/actions.constants';
import {
  createWorkflowCompletedAction,
  createWorkflowErrorAction,
  deleteWorkflowCompletedAction,
  deleteWorkflowErrorAction,
  getWorkflowCompletedAction,
  getWorkflowErrorAction,
  listWorkflowCompletedAction,
  listWorkflowErrorAction,
  listWorkflowFieldsCompletedAction,
  listWorkflowFieldsErrorAction,
  listWorkflowTemplateCompletedAction,
  listWorkflowTemplateErrorAction,
  updateWorkflowCompletedAction,
  updateWorkflowErrorAction,
} from '../actions/workflow.action';
import { workflowService } from '../../services/WorkflowService';

export function* listWorkflowsSaga(data: {
  type: WorkflowActionType.LIST_WORKFLOWS;
  req: ListWorkflowsRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(
      workflowService.listWorkflows,
      data.req,
    );
    if (response) {
      yield put(
        listWorkflowCompletedAction(
          response.workflows,
          response.nextPageToken,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listWorkflowErrorAction(error));
    }
  } catch (error) {
    yield put(listWorkflowErrorAction(error as Error));
  }
}

export function* listWorkflowTemplatesSaga(data: {
  type: WorkflowActionType.LIST_TEMPLATE_WORKFLOWS;
  req: ListWorkflowsRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(
      workflowService.listWorkflows,
      data.req,
    );
    if (response) {
      yield put(
        listWorkflowTemplateCompletedAction(
          response.workflows.sort((a: Workflow, b: Workflow) =>
            a.displayName!.localeCompare(b.displayName!),
          ),
          response.nextPageToken,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listWorkflowTemplateErrorAction(error));
    }
  } catch (error) {
    yield put(listWorkflowTemplateErrorAction(error as Error));
  }
}

export function* createWorkflowSaga(data: {
  type: WorkflowActionType.CREATE_WORKFLOW;
  payload: Workflow;
}): any {
  try {
    const { response, error } = yield call(
      workflowService.createWorkflow,
      data.payload,
    );
    if (response) {
      yield put(createWorkflowCompletedAction(response));
    } else {
      yield put(createWorkflowErrorAction(error));
    }
  } catch (error) {
    yield put(createWorkflowErrorAction(error as Error));
  }
}

export function* updateWorkflowSaga(data: {
  type: WorkflowActionType.UPDATE_WORKFLOW;
  payload: UpdateWorkflowRequest;
}): any {
  try {
    const { response, error } = yield call(
      workflowService.updateWorkflow,
      data.payload,
    );
    if (response) {
      yield put(updateWorkflowCompletedAction(response));
    } else {
      yield put(updateWorkflowErrorAction(error));
    }
  } catch (error) {
    yield put(updateWorkflowErrorAction(error as Error));
  }
}

export function* deleteWorkflowSaga(data: {
  type: WorkflowActionType.DELETE_WORKFLOW;
  payload: DeleteWorkflowRequest;
}): any {
  try {
    yield call(workflowService.deleteWorkflow, data.payload);
    yield put(deleteWorkflowCompletedAction(data.payload.name as string));
  } catch (e: any) {
    yield put(
      deleteWorkflowErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

export function* getWorkflowSaga(data: {
  type: WorkflowActionType.GET_WORKFLOW;
  payload: string;
}): any {
  try {
    const { response, error } = yield call(
      workflowService.getWorkflow,
      data.payload,
    );
    if (response) {
      yield put(getWorkflowCompletedAction(response));
    } else {
      yield put(getWorkflowErrorAction(error));
    }
  } catch (e) {
    yield put(getWorkflowErrorAction(e as string));
  }
}

function* listWorkflowFieldsSaga(data: {
  type: WorkflowActionType.LIST_WORKFLOW_FIELDS;
  payload: ListWorkflowFieldsRequest;
  refresh: boolean;
}) {
  try {
    const {
      response,
      error,
    }: { response: ListWorkflowFieldsResponse; error: Error } = yield call(
      workflowService.listWorkflowFields,
      data.payload,
    );

    if (response) {
      yield put(
        listWorkflowFieldsCompletedAction(
          response.fields as WorkflowField[],
          response.totalSize as number,
          data.refresh,
        ),
      );
    } else {
      yield put(listWorkflowFieldsErrorAction(error));
    }
  } catch (error) {
    yield put(listWorkflowFieldsErrorAction(error as Error));
  }
}

function* workflowSaga() {
  yield all([
    takeLatest(WorkflowActionType.LIST_WORKFLOWS, listWorkflowsSaga),
    takeLatest(
      WorkflowActionType.LIST_TEMPLATE_WORKFLOWS,
      listWorkflowTemplatesSaga,
    ),
    takeLatest(WorkflowActionType.CREATE_WORKFLOW, createWorkflowSaga),
    takeLatest(WorkflowActionType.UPDATE_WORKFLOW, updateWorkflowSaga),
    takeLatest(WorkflowActionType.DELETE_WORKFLOW, deleteWorkflowSaga),
    takeLatest(WorkflowActionType.GET_WORKFLOW, getWorkflowSaga),
    takeLatest(WorkflowActionType.LIST_WORKFLOW_FIELDS, listWorkflowFieldsSaga),
  ]);
}

export default workflowSaga;
