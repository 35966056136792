import { isEqual, isEqualWith, isUndefined, omit, omitBy, pick } from 'lodash';
import {
  EntityInfo,
  SelectedParentEntity,
} from '../redux/reducers/review_task.reducer';
import { deepCloneEntityInfoObject } from './cloneObjectUtils';

// This interface contains all fields which are necessary for us to detect unsaved changes
export interface InfoToDetectChanges {
  selectedEntityInfo?: EntityInfo;
  selectedParentEntityInfo?: SelectedParentEntity;
  selectedTableChildEntities?: EntityInfo[];
  originalEntityInfoMap?: { [id: string]: EntityInfo };
  modifiedEntityInfoMap?: { [id: string]: EntityInfo };
}

/**
 * Determines if there are any modifications between two EntityInfo objects.
 *
 * @param {EntityInfo} originalEntity - The original entity object to compare.
 * @param {EntityInfo} modifiedEntity - The modified entity object to compare against the original.
 * @param {Array<keyof Partial<EntityInfo>>} [compareKeys] - Optional array of specific keys to compare.
 * @returns {boolean} - Returns true if the entities are different or if there's an error in the modified entity, otherwise false.
 */
// compareKeys take keys of entity info whose values has to be compared for two entities
// 1. Original 2. Current, to check if the entity has been modified or not. Only these keys
// would be used to check any modification, rest keys will be skipped
export const isEntityModified = (
  originalEntity: EntityInfo,
  modifiedEntity: EntityInfo,
  compareKeys?: (keyof Partial<EntityInfo>)[],
) => {
  if (!originalEntity || !modifiedEntity) return true;
  if (modifiedEntity.error) {
    return true;
  }
  const entityOne = compareKeys
    ? pick(deepCloneEntityInfoObject(modifiedEntity), compareKeys)
    : omit(deepCloneEntityInfoObject(modifiedEntity), [
        'isModified',
        'isNormalizationFailed',
        'isNormalizedValueModified',
        'error',
      ]);
  const entityTwo = compareKeys
    ? pick(deepCloneEntityInfoObject(originalEntity), compareKeys)
    : omit(deepCloneEntityInfoObject(originalEntity), [
        'isModified',
        'isNormalizationFailed',
        'isNormalizedValueModified',
        'error',
      ]);
  return !isEqualWith(entityOne, entityTwo, (obj1, obj2) => {
    // omit all the undefined properties from both objects
    return isEqual(omitBy(obj1, isUndefined), omitBy(obj2, isUndefined));
  });
};

/**
 * Determines if the parent entity has been modified by comparing child entities.
 *
 * @param {EntityInfo[]} childEntities - Array of modified child entities.
 * @param {{ [id: string]: EntityInfo }} selectedTaskEntityInfo - Object containing original entity info, keyed by entity ID.
 * @returns {boolean} - Returns true if there are modifications in the parent entity, otherwise false.
 */
export const isParentEntityModified = (
  childEntities: EntityInfo[],
  selectedTaskEntityInfo: { [id: string]: EntityInfo },
) => {
  const expectedType = childEntities[0].parentEntityType;
  const originalChildEntities: EntityInfo[] = [];

  // Collect original child entities from selectedTaskEntityInfo
  // whose parentEntityType matches the expected parent entity type
  Object.keys(selectedTaskEntityInfo).forEach((key) => {
    if (selectedTaskEntityInfo[key].parentEntityType === expectedType) {
      originalChildEntities.push(selectedTaskEntityInfo[key]);
    }
  });

  if (originalChildEntities.length !== childEntities.length) {
    return true;
  }

  return childEntities.some((modifiedChildEntity) => {
    const originalChildEntity = selectedTaskEntityInfo[modifiedChildEntity.id];
    if (!originalChildEntity) {
      return true;
    }
    return isEntityModified(originalChildEntity, modifiedChildEntity);
  });
};

/**
 * Determines if any entity has been modified by comparing original and modified entity info maps.
 *
 * @param {InfoToDetectChanges} info - Object containing maps of original and modified entity info.
 * @param {Array<keyof Partial<EntityInfo>>} [compareKeys] - Optional array of specific keys to compare.
 * @param {boolean} [showWarning] - Optional flag to show warning if there are unsaved changes.
 * @returns {boolean} - Returns true if any entity is modified or there are unsaved changes, otherwise false.
 */
export const isAnyEntityModified = (
  info: InfoToDetectChanges,
  compareKeys?: (keyof Partial<EntityInfo>)[],
  showWarning?: boolean,
) => {
  const { originalEntityInfoMap = {}, modifiedEntityInfoMap = {} } = info;

  if (checkForUnsavedChanges(info, showWarning)) {
    return true;
  }

  if (
    Object.keys(originalEntityInfoMap)?.length !==
    Object.keys(modifiedEntityInfoMap)?.length
  ) {
    return true;
  }
  // loop through all entities and return true if at least one of them is modified otherwise false
  return Object.values(originalEntityInfoMap).some((originalEntity) => {
    // this entity could be modified
    const entity = modifiedEntityInfoMap[originalEntity.id];
    if (!entity) {
      return true;
    }
    return isEntityModified(originalEntity, entity, compareKeys);
  });
};

/**
 * Displays a warning on a floating modal by shaking it and changing the header background color.
 *
 * @param {boolean} [alertTableModal=false] - Determines if the warning is for the table modal or the regular modal.
 */
export const showFloatingModalWarning = (alertTableModal = false) => {
  const modal = alertTableModal
    ? document.getElementById('review-page-floating-table-modal')
    : document.getElementById('review-page-floating-modal');
  if (modal) {
    modal.classList.add('shake-modal'); // shake the modal 5 times
    setTimeout(() => {
      modal.classList.remove('shake-modal'); // remove the class otherwise modal will keep on shaking
    }, 500);
  }
  const header = alertTableModal
    ? document.getElementById('floating-table-modal-header')
    : document.getElementById('floating-modal-header');
  if (header) {
    header.style.backgroundColor = '#FFFAEB'; // apply warning bg-color
  }
  const warningText = alertTableModal
    ? document.getElementById('floating-table-modal-header-warning-text')
    : document.getElementById('floating-modal-header-warning-text');
  if (warningText) {
    warningText.style.display = 'block'; // initially warning text is hidden so set display to block to make it reappear
  }
};

/**
 * Resets the style of the floating modal by restoring the default header background color and hiding the warning text.
 *
 * @param {boolean} [alertTableModal=false] - Determines if the reset is for the table modal or the regular modal.
 */
export const resetFloatingModalStyle = (alertTableModal = false) => {
  const header = alertTableModal
    ? document.getElementById('floating-table-modal-header')
    : document.getElementById('floating-modal-header');
  if (header) {
    header.style.backgroundColor = '#EAECF0'; // default background color
  }
  const warningText = alertTableModal
    ? document.getElementById('floating-table-modal-header-warning-text')
    : document.getElementById('floating-modal-header-warning-text');
  if (warningText) {
    warningText.style.display = 'none'; // hide the warning text
  }
};

/**
 * Checks for unsaved changes in the provided information and optionally shows a warning.
 *
 * @param {InfoToDetectChanges} info - Object containing maps of original and modified entity info, and selected entities.
 * @param {boolean} [showWarning=true] - Flag to determine if a warning should be shown when changes are detected.
 * @returns {boolean} - Returns true if there are unsaved changes, otherwise false.
 */
export const checkForUnsavedChanges = (
  info: InfoToDetectChanges,
  showWarning = true,
) => {
  const {
    modifiedEntityInfoMap,
    selectedEntityInfo,
    selectedTableChildEntities,
    selectedParentEntityInfo,
  } = info;

  // check for simple entities are modified
  if (
    selectedEntityInfo &&
    modifiedEntityInfoMap &&
    isEntityModified(
      modifiedEntityInfoMap[selectedEntityInfo.id],
      selectedEntityInfo,
    )
  ) {
    // show warning
    if (showWarning) showFloatingModalWarning();
    return true;
  }
  // check if table child entities are modified
  if (
    selectedParentEntityInfo?.id &&
    selectedTableChildEntities?.length &&
    modifiedEntityInfoMap &&
    isParentEntityModified(selectedTableChildEntities, modifiedEntityInfoMap)
  ) {
    // show warning
    if (showWarning) showFloatingModalWarning(true);
    return true;
  }

  // if no changes are detected reset the warnings
  resetFloatingModalStyle();
  resetFloatingModalStyle(true);

  return false;
};
