import { Box, Pagination } from '@mui/material';
import React from 'react';
import OrbyColorPalette from '../../../colors/ColorPalette';
import { DEFAULT_FIRST_PAGE } from '../../table-utils';

export interface OrbyPaginatorProps {
  /**
   * Total number of rows
   */
  totalSize?: number;
  /**
   * Current page number
   */
  currentPage: number;
  /**
   * Callback function to handle page change
   */
  onPageChange: (page: number) => void;
  /**
   * Position of the pagination
   */
  position?: 'start' | 'center' | 'end';
  /**
   * Number of rows per page
   * By default it is 20
   */
  rowsPerPage?: number;
}

const OrbyPaginator: React.FC<OrbyPaginatorProps> = ({
  totalSize = 0,
  currentPage,
  onPageChange,
  position = 'end',
  rowsPerPage = 20,
}) => {
  /**
   * Get the number of rows per page
   * Check if it is negative or 0, then return 20
   * else return the rows per page
   */
  const getRowsPerPage = () => {
    return rowsPerPage > 0 ? rowsPerPage : 20;
  };

  /**
   * Calculate the total number of pages
   */
  const totalPages = Math.ceil(totalSize / getRowsPerPage());

  return (
    <Box
      display={'flex'}
      component={'nav'}
      role='navigation'
      aria-label='Pagination Navigation'
      justifyContent={position}
      alignItems={'center'}
      marginTop={'16px'}
    >
      {totalPages > 1 && (
        <Box
          role='region'
          aria-label={`You’re currently on page ${
            currentPage + 1
          } out of total ${totalSize} page`}
        >
          <Pagination
            sx={{
              '& .Mui-selected': {
                backgroundColor: `${OrbyColorPalette['grey-500']} !important`,
                color: 'white',
                opacity: 1,
              },
              color: OrbyColorPalette['black-0'],
              opacity: 0.87,
            }}
            count={totalPages}
            defaultPage={DEFAULT_FIRST_PAGE}
            page={currentPage}
            /**
             * Show the boundary count if the total pages are greater than 10
             */
            boundaryCount={totalPages >= 10 ? 2 : 0}
            onChange={(event, page) => onPageChange(page)}
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(OrbyPaginator);
