import React, { FC, memo } from 'react';

import {
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import UserCard from '../../../../components/UserCard';

interface UserChipProps {
  email: string;
  fullName?: string;
  profileImageUrl?: string;
  isDeletable: boolean;
  isDisabled: boolean;
  onDelete?: () => void;
}

const UserChip: FC<UserChipProps> = ({
  email,
  fullName,
  profileImageUrl,
  isDeletable,
  isDisabled,
  onDelete,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '12px',
      }}
    >
      <Box sx={{ maxWidth: '230px' }}>
        <UserCard
          email={email}
          fullName={fullName}
          imageUrl={profileImageUrl}
        />
      </Box>

      <Box
        display={'flex'}
        alignItems={'center'}
        pl={'8px'}
        justifyContent={'space-evenly'}
        flexShrink={0}
      >
        <FormControl sx={{ m: 1, width: 170 }} size='small'>
          <Select
            value='admin'
            inputProps={{ 'aria-label': 'Without label' }}
            disabled={isDisabled}
          >
            <MenuItem value={'admin'}>Workflow admin</MenuItem>
          </Select>
        </FormControl>
        <Tooltip title={!isDeletable ? "Can't delete workflow creator" : ''}>
          <span>
            <IconButton disabled={!isDeletable} onClick={onDelete}>
              <CloseIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default memo(UserChip);
