import { useSelector } from 'react-redux';
import { getFeatureFlagsForOrgAndUserSelector } from '../redux/selectors/feature_flags.selectors';
import { isFeatureFlagEnabled } from '../pages/FeatureFlags/FeatureFlagUtils';

const useIsFeatureEnabled = (featureFlag: string): boolean => {
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);

  const flag = isFeatureFlagEnabled(featureFlag, featureFlags);
  return flag;
};

export default useIsFeatureEnabled;
