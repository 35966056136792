import { all, call, put, takeLatest } from 'redux-saga/effects';
import { OrganizationActionType } from '../actions/actions.constants';
import {
  GetOrganizationRequest,
  UpdateOrganizationRequest,
} from 'protos/pb/v1alpha1/organization_service';
import { Organization } from 'protos/pb/v1alpha1/organization';
import { organizationsService } from '../../services/OrganizationsService';
import {
  getOrganizationCompletedAction,
  getOrganizationErrorAction,
  updateOrganizationCompletedAction,
  updateOrganizationErrorAction,
} from '../actions/organization.actions';

function* getOrganization(data: {
  type: OrganizationActionType.GET_ORGANIZATION;
  payload: GetOrganizationRequest;
}) {
  try {
    const { response, error }: { response: Organization; error: Error } =
      yield call(organizationsService.getOrganization, data.payload);
    if (response) {
      yield put(getOrganizationCompletedAction(response));
    } else {
      yield put(getOrganizationErrorAction(error));
    }
  } catch (error) {
    yield put(getOrganizationErrorAction(error as Error));
  }
}

function* updateOrganization(data: {
  type: OrganizationActionType.UPDATE_ORGANIZATION;
  payload: UpdateOrganizationRequest;
}): any {
  try {
    const { response, error }: { response: Organization; error: Error } =
      yield call(organizationsService.updateOrganization, data.payload);
    if (response) {
      yield put(updateOrganizationCompletedAction(response));
    } else {
      yield put(updateOrganizationErrorAction(error));
    }
  } catch (error) {
    yield put(updateOrganizationErrorAction(error as Error));
  }
}

function* organizationSaga() {
  yield all([
    takeLatest(OrganizationActionType.GET_ORGANIZATION, getOrganization),
    takeLatest(OrganizationActionType.UPDATE_ORGANIZATION, updateOrganization),
  ]);
}

export default organizationSaga;
