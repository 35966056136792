import { Token } from '../../services/StorageService';
import { AuthActionType } from './actions.constants';

import {
  SingleSignOnRequest,
  RegisterRequest,
  LoginRequest,
  LogoutRequest,
  MicrosoftSingleSignOnRequest,
} from 'protos/pb/v1alpha1/users_service';
import { User } from 'protos/pb/v1alpha1/user';

export const authLoginAction = (req: LoginRequest) => ({
  type: AuthActionType.LOGIN,
  payload: req,
});

export const authLoginCompletedAction = (token: Token) => ({
  type: AuthActionType.LOGIN_COMPLETED,
  payload: token,
});

export const authLoginErrorAction = (message: string) => ({
  type: AuthActionType.LOGIN_ERROR,
  payload: message,
});

export const googleAuthLoginAction = (req: SingleSignOnRequest) => ({
  type: AuthActionType.GOOGLE_LOGIN,
  payload: req,
});

export const googleSignInAction = (req: { scope: string }) => ({
  type: AuthActionType.GOOGLE_SIGN_IN_REQUEST,
  payload: req,
});

export const googleAuthLoginCompletedAction = (token: Token) => ({
  type: AuthActionType.GOOGLE_LOGIN_COMPLETED,
  payload: token,
});

export const googleAuthLoginErrorAction = (message?: string) => ({
  type: AuthActionType.GOOGLE_LOGIN_ERROR,
  payload: message,
});

export const microsoftSignInAction = (req: { scope: string[] }) => ({
  type: AuthActionType.MICROSOFT_SIGN_IN_REQUEST,
  payload: req,
});

export const microsoftAuthLoginAction = (
  req: MicrosoftSingleSignOnRequest,
) => ({
  type: AuthActionType.MICROSOFT_LOGIN,
  payload: req,
});

export const microsoftAuthLoginCompletedAction = (token: Token) => ({
  type: AuthActionType.MICROSOFT_LOGIN_COMPLETED,
  payload: token,
});

export const microsoftAuthLoginErrorAction = (message?: string) => ({
  type: AuthActionType.MICROSOFT_LOGIN_ERROR,
  payload: message,
});

export const samlLoginAction = (req: {
  access_token: string;
  session_id: string;
}) => ({
  type: AuthActionType.SAML_LOGIN,
  payload: req,
});

export const samlLoginCompletedAction = (token: Token) => ({
  type: AuthActionType.SAML_LOGIN_COMPLETED,
  payload: token,
});

export const samlLoginErrorAction = (message: string) => ({
  type: AuthActionType.SAML_LOGIN_ERROR,
  payload: message,
});

export const authSignUpAction = (req: RegisterRequest) => ({
  type: AuthActionType.SIGN_UP,
  payload: req,
});

export const authSignUpCompletedAction = (user: User) => ({
  type: AuthActionType.SIGN_UP_COMPLETED,
  payload: user,
});

export const authSignUpErrorAction = (message: string) => ({
  type: AuthActionType.SIGN_UP_ERROR,
  payload: message,
});

export const logoutAction = (req: LogoutRequest) => ({
  type: AuthActionType.LOGOUT,
  payload: req,
});

export const logoutCompletedAction = () => ({
  type: AuthActionType.LOGOUT_COMPLETED,
});

export const logoutErrorAction = (error: string) => ({
  type: AuthActionType.LOGOUT_ERROR,
  payload: error,
});

export const googleLogoutAction = (name: string) => ({
  type: AuthActionType.GOOGLE_LOGOUT,
  payload: name,
});

export const googleLogoutCompletedAction = () => ({
  type: AuthActionType.GOOGLE_LOGOUT_COMPLETED,
});

export const googleLogoutErrorAction = (error: string) => ({
  type: AuthActionType.GOOGLE_LOGOUT_ERROR,
  payload: error,
});

export const unauthenticatedErrorAction = (error?: string) => ({
  type: AuthActionType.UNAUTHENTICATED_ERROR,
  payload: error,
});
