import { useEffect, useState } from 'react';
import { ListWorkflowsRequest } from 'protos/pb/v1alpha1/orbot_service';
import { orbotService } from '../services/OrbotService';

export const useFetchOrbotWorkflows = (orgResourceName?: string) => {
  const [orbotWorkflowLength, setOrbotWorkflowLength] = useState(0);
  const [orbotWorkflowsLoading, setOrbotWorkflowsLoading] = useState(false);

  const fetchWorkflows = async () => {
    if (orgResourceName) {
      setOrbotWorkflowsLoading(true);
      const req: ListWorkflowsRequest = ListWorkflowsRequest.create({
        orgId: orgResourceName.replace('organizations/', ''),
        pageNumber: 0,
        pageSize: 1,
      });
      const { response } = await orbotService.listWorkflows(req);
      if (response) {
        setOrbotWorkflowLength(response.totalSize ?? 0);
        setOrbotWorkflowsLoading(false);
      } else {
        setOrbotWorkflowsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (orgResourceName) {
      fetchWorkflows();
    }
  }, [orgResourceName]);

  return {
    orbotWorkflowLength,
    orbotWorkflowsLoading,
  };
};
