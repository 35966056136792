// @ts-nocheck
import { lazy } from 'react';

export const LazyDashboard = lazy(() => import('../Dashboard/Dashboard'));
export const LazyReviewPage = lazy(
  () => import('../AutomationReviewPage/AutomationReviewPage'),
);
export const LazyDebuggerReview = lazy(
  () => import('../DebuggerReview/DebuggerReview'),
);
export const LazyIntegrationTest = lazy(
  () => import('../IntegrationTest/IntegrationTest'),
);
export const LazyScheduledTasks = lazy(
  () => import('../ScheduledTasks/ScheduledTasks'),
);
export const LazyFeatureFlags = lazy(
  () => import('../FeatureFlags/FeatureFlags'),
);
export const LazyFeatureFlagCreationPage = lazy(
  () => import('../FeatureFlags/FeatureFlagCreationPage'),
);
export const LazyFeatureFlagUpdatePage = lazy(
  () => import('../FeatureFlags/FeatureFlagUpdatePage'),
);
export const LazyTasks = lazy(() => import('../Tasks/Tasks'));
export const LazyExecutionHistory = lazy(
  () => import('../ExecutionHistory/ExecutionHistory'),
);
export const LazySettings = lazy(() => import('../Settings/Settings'));
export const LazyConfigureSSO = lazy(
  () => import('../Settings/ConfigureSSO/ConfigureSSO'),
);
export const LazyUiAutomationsDetail = lazy(
  () =>
    import(
      '../ExecutionHistory/tabs/ui-automations/details/UiAutomationsDetail'
    ),
);
export const LazyWorkflowDetails = lazy(
  () => import('../MyWorkflows/details/WorkflowDetails'),
);
export const LazyTemplate = lazy(
  () => import('../MyWorkflows/details/Template'),
);
export const LazyCreateWorkflowTemplate = lazy(
  () => import('../MyWorkflows/CreateWorkflow/CreateWorkflowTemplate'),
);
export const LazyCreateConnector = lazy(
  () => import('../MyWorkflows/CreateConnector/CreateConnector'),
);
export const LazyUpdateConnector = lazy(
  () => import('../MyWorkflows/CreateConnector/UpdateConnector'),
);
export const LazyUpdateWorkflow = lazy(
  () => import('../MyWorkflows/CreateWorkflow/UpdateWorkflow'),
);
export const LazyCreateWorkflow = lazy(
  () => import('../MyWorkflows/CreateWorkflow/CreateWorkflow'),
);
export const LazyTeamsPage = lazy(() => import('../Teams/TeamsPage'));
export const LazyHITLDetails = lazy(
  () => import('../TasksPendingReview/HITLDetails'),
);
export const LazyFieldsPage = lazy(() => import('../Fields/FieldsPage'));
export const LazyMyWorkflows = lazy(() => import('../MyWorkflows/index'));
export const LazyTrashCan = lazy(() => import('../TrashCan/TrashCanPage'));
