import React from 'react';

import { KeyOutlined, KitchenTwoTone } from '@mui/icons-material';

import { DrawerTabType } from './DrawerTab';

import { ReactComponent as DashBoardHoverIcon } from '../../static/icons/drawer/home/full.svg';
import { ReactComponent as DashBoardIcon } from '../../static/icons/drawer/home/line.svg';

import { ReactComponent as WorkflowHoverIcon } from '../../static/icons/drawer/workflow/full.svg';
import { ReactComponent as WorkflowIcon } from '../../static/icons/drawer/workflow/line.svg';

import { ReactComponent as TaskHoverIcon } from '../../static/icons/drawer/task/full.svg';
import { ReactComponent as TaskIcon } from '../../static/icons/drawer/task/line.svg';

import { ReactComponent as ExecutionHoverIcon } from '../../static/icons/drawer/execution/full.svg';
import { ReactComponent as ExecutionIcon } from '../../static/icons/drawer/execution/line.svg';

import { ReactComponent as TeamsHoverIcon } from '../../static/icons/drawer/team/full.svg';
import { ReactComponent as TeamsIcon } from '../../static/icons/drawer/team/line.svg';

import { ReactComponent as SettingHoverIcon } from '../../static/icons/drawer/setting/full.svg';
import { ReactComponent as SettingIcon } from '../../static/icons/drawer/setting/line.svg';

import { ReactComponent as TrashHoverIcon } from '../../static/icons/drawer/trash/full.svg';
import { ReactComponent as TrashIcon } from '../../static/icons/drawer/trash/line.svg';
import { APP_ROUTES } from '../../pages/ProtectedRoutes/Routes';

export const DRAWER_TABS: DrawerTabType[] = [
  {
    title: 'Homepage',
    route: APP_ROUTES.DASHBOARD,
    icon: <DashBoardIcon />,
    hoverIcon: <DashBoardHoverIcon />,
  },
  {
    title: 'Workflows',
    route: APP_ROUTES.WORKFLOW,
    icon: <WorkflowIcon />,
    hoverIcon: <WorkflowHoverIcon />,
  },
  {
    title: 'Tasks',
    route: APP_ROUTES.TASKS,
    icon: <TaskIcon />,
    hoverIcon: <TaskHoverIcon />,
  },
  {
    title: 'Executions',
    route: APP_ROUTES.EXECUTIONS,
    icon: <ExecutionIcon />,
    hoverIcon: <ExecutionHoverIcon />,
  },
  {
    title: 'Trash Can',
    route: APP_ROUTES.DELETED_ITEMS,
    icon: <TrashIcon />,
    hoverIcon: <TrashHoverIcon />,
  },
  {
    title: 'Teams',
    route: APP_ROUTES.TEAMS,
    icon: <TeamsIcon />,
    hoverIcon: <TeamsHoverIcon />,
  },
  {
    title: 'Settings',
    route: APP_ROUTES.SETTINGS,
    icon: <SettingIcon />,
    hoverIcon: <SettingHoverIcon />,
  },
  {
    title: 'ML Testing',
    route: APP_ROUTES.DEV_TESTING,
    description: 'INTERNAL',
    icon: <KeyOutlined />,
    hoverIcon: <KeyOutlined />,
  },
  {
    title: 'Integration Test',
    route: APP_ROUTES.INTEGRATION_TEST,
    icon: <KitchenTwoTone />,
    hoverIcon: <KitchenTwoTone />,
  },
  {
    title: 'Feature Flags',
    route: APP_ROUTES.FEATURE_FLAGS,
    description: 'INTERNAL',
    icon: <KeyOutlined />,
    hoverIcon: <KeyOutlined />,
  },
];
