/**
 * This is a custom select field which is used to select the target workflow.
 * It has two dropdowns, one for selecting the organization and the other for selecting the workflow.
 * The organization dropdown is populated with the organizations of the user.
 * The organization dropdown is always visible and the workflow dropdown is visible only when an organization is selected.
 * The workflow dropdown is populated with the workflows of the selected organization.
 * The workflow dropdown is also searchable.
 */

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import CustomTypography from '../../../../components/CustomTypography';
import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import { GridSearchIcon } from '@mui/x-data-grid';
import { ELLIPSIS_STYLE, Filter } from '../../../../utils/constants';
import { useFetchWorkflowFilters } from '../../../../hooks/useFetchWorkflowFilters';

interface SelectFieldProps {
  copyFromWorkFlow: Workflow;
  selectedToWorkflow: Workflow;
  ariaLabel: string;
  dropdownWidth: string;
  height: string;
  bottom: string;
  organizationList: { value: string; label: string }[];
  handleSelectToWorkflow: (workflow: Workflow) => void;
  fieldProps: any;
  style: any;
}

const SelectField: FC<SelectFieldProps> = (props) => {
  const {
    ariaLabel,
    dropdownWidth,
    height,
    bottom,
    organizationList,
    handleSelectToWorkflow,
    selectedToWorkflow,
    copyFromWorkFlow,
    fieldProps,
    style,
  } = props;
  const searchFieldRef = useRef<HTMLElement>(null);
  const [showOrganizations, setShowOrganizations] = useState(false);
  const [showOrgWorkflows, setShowOrgWorkflows] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<Filter | null>(null);
  const [searchValue, setSearchValue] = useState('');

  const [selectedOrgWorkflowsList, setSelectedOrgWorkflowsList] = useState<
    Filter[]
  >([]);

  // Fetches all the workflows of the selected organization only when the organization is changed
  const { workflowFilters, workflowFiltersLoading } = useFetchWorkflowFilters(
    selectedOrg?.value,
  );

  const filteredWorkflows = useMemo(() => {
    return selectedOrgWorkflowsList.filter(
      (w) =>
        w.value !== copyFromWorkFlow.name && // Filter out the workflow from which we need to copy tasks
        w.label.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [selectedOrgWorkflowsList, copyFromWorkFlow, searchValue]);

  /**
   * This is used to close the dropdown when clicked outside the dropdown
   */
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        searchFieldRef.current &&
        !searchFieldRef.current.contains(event.target as Node)
      ) {
        setSearchValue('');
        setShowOrganizations(false);
        setShowOrgWorkflows(false);
        setSelectedOrgWorkflowsList([]);
        setSelectedOrg(null);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (workflowFilters.length) {
      setSelectedOrgWorkflowsList(workflowFilters);
    }
  }, [workflowFilters]);

  useEffect(() => {
    setSelectedOrgWorkflowsList([]);
  }, [selectedOrg?.value]);

  return (
    <Box>
      <Box position={'relative'}>
        <Grid
          paddingTop={'10px'}
          sx={{
            color: '#667085',
          }}
        >
          <CustomTypography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#344054',
            }}
          >
            To
          </CustomTypography>
          <Grid
            title={selectedToWorkflow?.displayName}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '4px',
              borderRadius: '4px',
              height: '44px',
              boxShadow: '0px 1px 2px 0px #1018280D',
              cursor: 'pointer',
              ...style,
            }}
            paddingX={'15px'}
            onClick={() => {
              setShowOrganizations(true);
            }}
          >
            <CustomTypography
              aria-label={ariaLabel}
              title={ariaLabel}
              placeholder='Select target destination to copy'
              {...fieldProps}
              color={selectedToWorkflow?.displayName ? '#101828' : '#667085'}
              sx={{
                ...ELLIPSIS_STYLE,
              }}
            >
              {selectedToWorkflow?.displayName
                ? selectedToWorkflow?.displayName
                : 'Select target destination to copy'}
            </CustomTypography>
            {showOrganizations ? (
              <KeyboardArrowUpIcon htmlColor='#101828' fontSize='small' />
            ) : (
              <KeyboardArrowDownIcon htmlColor='#101828' fontSize='small' />
            )}
          </Grid>
        </Grid>
        {
          /**
           * This is the dropdown for selecting the organization
           */
          showOrganizations && (
            <Box
              maxHeight={'300px'}
              overflow={'auto'}
              ref={searchFieldRef}
              borderRadius={'4px'}
              sx={{ background: '#fff' }}
              position={'absolute'}
              bottom={bottom}
              width={dropdownWidth}
              zIndex={1}
              boxShadow={'0px 4px 6px 0px #00000040'}
              display={'block'}
            >
              {organizationList.length > 0 ? (
                organizationList.map((org) => (
                  <MenuItem
                    title={org.label}
                    onClick={() => {
                      setSelectedOrg(org);
                      setShowOrganizations(false);
                      setSearchValue('');
                      setShowOrgWorkflows(true);
                    }}
                    key={org.value}
                    sx={{
                      background: `${
                        selectedToWorkflow?.organizationResourceName ===
                        org.value
                          ? '#ecf3ff'
                          : '#fff'
                      }`,
                      height: '40px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <CustomTypography
                      sx={{
                        fontSize: '16px',
                        color: '#101828',
                        ...ELLIPSIS_STYLE,
                      }}
                    >
                      {org.label}
                    </CustomTypography>

                    <ArrowForwardIosIcon
                      sx={{ fontSize: '12px', color: '#000000' }}
                    />
                  </MenuItem>
                ))
              ) : (
                <Box
                  textAlign={'center'}
                  sx={{
                    cursor: 'not-allowed',
                    fontSize: '12px',
                    padding: '10px 20px',
                  }}
                >
                  No results found
                </Box>
              )}
            </Box>
          )
        }
        {
          /**
           * This is the dropdown for selecting the workflow
           */
          showOrgWorkflows && (
            <>
              <Box
                maxHeight={'300px'}
                overflow={'auto'}
                ref={searchFieldRef}
                borderRadius={'4px'}
                sx={{ background: '#fff' }}
                position={'absolute'}
                bottom={bottom}
                width={dropdownWidth}
                zIndex={1}
                boxShadow={'0px 4px 6px 0px #00000040'}
                display={'block'}
              >
                <Grid
                  paddingX={'16px'}
                  position={'sticky'}
                  top={0}
                  sx={{
                    background: '#fff',
                  }}
                  zIndex={1}
                  borderBottom={'1px solid #E0E0E0'}
                >
                  <Grid
                    paddingTop={'10px'}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <ArrowBackIos
                      onClick={() => {
                        setShowOrganizations(true);
                        setShowOrgWorkflows(false);
                      }}
                      sx={{
                        cursor: 'pointer',
                        fontSize: '15px',
                        fontWeight: 700,
                      }}
                    />
                    <TextField
                      role='textbox'
                      size='small'
                      value={searchValue}
                      placeholder='SEARCH'
                      InputProps={{
                        startAdornment: (
                          <Box>
                            <InputAdornment position='start'>
                              <IconButton sx={{ width: '10px' }}>
                                <GridSearchIcon fontSize='medium' />
                              </IconButton>
                            </InputAdornment>
                          </Box>
                        ),
                      }}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                      }}
                      sx={{
                        width: '100%',
                        height: '32px',
                        borderRadius: '4px',
                        color: '#667085',
                        '& .MuiOutlinedInput-root': {
                          height,
                          background: '#fff',
                        },
                        '& .MuiOutlinedInput-input': {
                          lineHeight: '16px',
                          fontSize: '12px',
                          fontWeight: 400,
                          WebkitTextFillColor: '#667085 !important',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '24px',
                          border: '1px solid #EAECF0',
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      padding: '15px 0px',
                    }}
                  >
                    <CustomTypography
                      sx={{
                        fontSize: '16px',
                        color: '#101828',
                        fontWeight: 700,
                        justifyContent: 'space-between',
                      }}
                    >
                      {selectedOrg?.label}
                    </CustomTypography>
                  </Grid>
                </Grid>
                {
                  /**
                   * This is the loader for the workflow dropdown
                   * It is shown when the workflows are being fetched
                   */
                  workflowFiltersLoading ? (
                    <Grid
                      display={'flex'}
                      justifyContent={'center'}
                      paddingY={'20px'}
                    >
                      <CircularProgress
                        sx={{
                          '&.MuiCircularProgress-root': {
                            color: '#7F56D9',
                          },
                        }}
                      />
                    </Grid>
                  ) : (
                    <>
                      {filteredWorkflows.length > 0 ? (
                        filteredWorkflows.map((workflow) => (
                          <MenuItem
                            title={workflow.label}
                            onClick={() => {
                              handleSelectToWorkflow(
                                Workflow.fromJSON({
                                  name: workflow.value,
                                  displayName: workflow.label,
                                  organizationResourceName: selectedOrg?.value,
                                }),
                              );
                              setShowOrganizations(false);
                              setShowOrgWorkflows(false);
                            }}
                            key={workflow.value}
                            sx={{
                              background: `${
                                selectedToWorkflow?.name === workflow.value
                                  ? '#ecf3ff'
                                  : '#fff'
                              }`,
                              height: '45px',
                              paddingLeft: '40px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <CustomTypography
                              sx={{
                                fontSize: '16px',
                                color: '#101828',
                                ...ELLIPSIS_STYLE,
                              }}
                            >
                              {workflow.label}
                            </CustomTypography>
                          </MenuItem>
                        ))
                      ) : (
                        /**
                         * This is shown when there are no workflows in the organization
                         * or when the search value does not match any workflow
                         */
                        <Box
                          textAlign={'center'}
                          sx={{
                            cursor: 'not-allowed',
                            fontSize: '12px',
                            padding: '10px 20px',
                          }}
                        >
                          No results found
                        </Box>
                      )}
                    </>
                  )
                }
              </Box>
            </>
          )
        }
      </Box>
    </Box>
  );
};

export default memo(SelectField);
