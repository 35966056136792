import React from 'react';
import { CheckCircleOutlineRounded, InfoOutlined } from '@mui/icons-material';
import { OrbyColorPalette } from 'orby-ui/src';

export const getSuccessIcon = () => (
  <CheckCircleOutlineRounded
    sx={{
      color: OrbyColorPalette['grey-900'],
    }}
  />
);

export const getInfoIcon = () => (
  <InfoOutlined
    sx={{
      color: OrbyColorPalette['grey-900'],
    }}
  />
);
