import { Token } from '../services/StorageService';
import { EXTENSION_ID } from 'extension/src/constants';

/**
 * Send the authenticated token to our Chrome extension, which is usually
 * started on the extension side by navigating to the /login?redirectUrl=extension
 * page.
 *
 * Our Chrome extension would navigate to the extension dashboard page after
 * receiving the token, so orby-web-app doesn't need to have a separate page for
 * the extension login.
 */
export async function sendTokenToExtension(token: Token | undefined) {
  if (chrome && chrome.runtime) {
    await chrome.runtime.sendMessage(EXTENSION_ID, {
      type: 'loginSuccessful',
      token,
    });
  }
}
