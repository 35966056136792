import { User } from 'protos/pb/v1alpha1/user';
import {
  AddUserToOrganizationRequest,
  ExchangeTokenRequest,
  GetResponse,
  ListUsersRequest,
  ListUsersResponse,
  LoginResponse,
  UpdateGoogleTokenRequest,
  UpdateGoogleTokenResponse,
  UpdatePasswordResponse,
  UpdateResponse,
  UsersClientImpl,
  GetUserPermissionsRequest,
  GetUserPermissionsResponse,
  GetRequest,
  UpdatePasswordRequest,
  UpdateRequest,
  UpdateActiveOrgIdRequest,
} from 'protos/pb/v1alpha1/users_service';
import { storageService } from './StorageService';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';

export class UserService {
  private static instance: UserService;
  private static client = new UsersClientImpl(rpcWithErrorHandling);

  public static getInstance(): UserService {
    if (!this.instance) {
      this.instance = new UserService();
    }
    return this.instance;
  }

  async getLoggedInUser(): Promise<GetResponse> {
    const authorization = await storageService.getAuthorizationHeader();
    const getRequest: GetRequest = { filter: { includeAnnouncements: true } };
    return UserService.client.Get(getRequest, getMetaData({ authorization }));
  }

  async updateUser(
    req: UpdateRequest,
  ): Promise<{ response?: UpdateResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await UserService.client.Update(req, {
        authorization,
      } as any);
      return { response };
    } catch (error: any) {
      return { error };
    }
  }

  async updatePassword(
    req: UpdatePasswordRequest,
  ): Promise<{ response?: UpdatePasswordResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await UserService.client.UpdatePassword(req, {
        authorization,
      } as any);
      return { response };
    } catch (error: any) {
      return { error };
    }
  }

  async listUsers(
    req: ListUsersRequest,
  ): Promise<{ response?: ListUsersResponse; error?: Error }> {
    const metadata = await storageService.getMetadata();
    try {
      const response = await UserService.client.ListUsers(req, metadata);
      return { response };
    } catch (error: any) {
      return { error };
    }
  }

  async listAllUsers(
    orgResourceName: string,
    userList: User[] = [],
    nextPage = 1,
  ): Promise<{ response?: User[]; error?: Error }> {
    try {
      const req: ListUsersRequest = {};
      req.pageSize = 50;
      req.orgResourceName = orgResourceName;
      req.pageNumber = nextPage;
      const { response, error } = await userService.listUsers(req);
      if (response && response.users && response.totalSize) {
        userList = [...userList, ...response.users];
        if (userList.length < response.totalSize) {
          return await userService.listAllUsers(
            orgResourceName,
            userList,
            nextPage + 1,
          );
        } else {
          return {
            response: userList.sort((a, b) =>
              (a.email as string).localeCompare(b.email as string),
            ),
          };
        }
      } else {
        return { error };
      }
    } catch (error: any) {
      return { error };
    }
  }

  async addUserToOrganization(
    req: AddUserToOrganizationRequest,
  ): Promise<{ response?: User; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await UserService.client.AddUserToOrganization(req, {
        authorization,
      } as any);
      return { response };
    } catch (error: any) {
      return { error };
    }
  }
  async exchangeToken(
    req: ExchangeTokenRequest,
    samlToken: string,
  ): Promise<{ response?: LoginResponse; error?: Error }> {
    try {
      const response = await UserService.client.ExchangeToken(req, {
        authorization: `Bearer ${samlToken}`,
      } as any);
      return { response };
    } catch (error: any) {
      return { error };
    }
  }

  async updateGoogleToken(
    req: UpdateGoogleTokenRequest,
  ): Promise<{ response?: UpdateGoogleTokenResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await UserService.client.UpdateGoogleToken(req, {
        authorization,
      } as any);
      return { response };
    } catch (error: any) {
      return { error };
    }
  }

  async getUserPermissions(
    req: GetUserPermissionsRequest,
  ): Promise<{ response?: GetUserPermissionsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await UserService.client.GetUserPermissions(req, {
        authorization,
      } as any);
      return { response };
    } catch (error: any) {
      return { error };
    }
  }

  async updateActiveOrgId(
    req: UpdateActiveOrgIdRequest,
  ): Promise<{ response?: any; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await UserService.client.UpdateActiveOrgId(req, {
        authorization,
      } as any);
      return { response };
    } catch (error: any) {
      return { error };
    }
  }
}

export const userService = UserService.getInstance();
