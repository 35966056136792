import produce from 'immer';
import { GmailLabel } from 'protos/automation_mining/ontology/data_models';
import { Reducer } from 'redux';
import { UtilityActionType } from '../actions/actions.constants';
import { ScheduleTask } from 'protos/pb/v1alpha2/utility_service';

export interface UtilityState {
  tasks: ScheduleTask[];
  loadingTasks: boolean;
  tasksError?: Error;
  gmailLabels: GmailLabel[];
  loadingGmailLabels: boolean;
  getGmailLabelsError?: Error;
}

const initialState: UtilityState = {
  tasks: [],
  loadingTasks: false,
  gmailLabels: [],
  loadingGmailLabels: false,
};

export const utilityReducer: Reducer<UtilityState> = (
  state: UtilityState = initialState,
  action: any,
) => {
  return produce(state, (draft: UtilityState) => {
    switch (action.type) {
      case UtilityActionType.GET_GMAIL_LABELS:
        draft.loadingGmailLabels = true;
        break;
      case UtilityActionType.GET_GMAIL_LABELS_COMPLETED:
        draft.gmailLabels = action.payload;
        draft.loadingGmailLabels = false;
        draft.getGmailLabelsError = undefined;
        break;
      case UtilityActionType.GET_GMAIL_LABELS_ERROR:
        draft.loadingGmailLabels = false;
        draft.getGmailLabelsError = action.payload;
        break;
      case UtilityActionType.GET_SCHEDULED_TASKS:
        draft.loadingTasks = true;
        break;
      case UtilityActionType.GET_SCHEDULED_TASKS_COMPLETED:
        draft.tasks = action.payload;
        draft.loadingTasks = false;
        draft.tasksError = undefined;
        break;
      case UtilityActionType.GET_SCHEDULED_TASKS_ERROR:
        draft.loadingTasks = false;
        draft.tasksError = action.payload;
        break;
      default:
        break;
    }
  });
};
