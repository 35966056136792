import {
  Document,
  DocumentPageTable,
  DocumentPageTableTableCell,
  DocumentPageTableTableRow,
  DocumentPageToken,
  DocumentTextAnchorTextSegment,
} from 'protos/google/cloud/documentai/v1/document';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import {
  EntityInfo,
  TextSegmentInfo,
} from '../redux/reducers/review_task.reducer';
import { boxPositionValuesUtilV2 } from './BoxPositionValuesUtilV2';
import { getSelectedTaskDocument } from './helpers';
import {
  getNormalizedVertices,
  processNormalizedValue,
} from './ReviewTaskUtils';
import { tokenValuesUtil } from './TokenValuesUtil';
import { Vertex } from 'protos/google/cloud/documentai/v1/geometry';

export const getTableRowAndCellOfEntity = (
  table: DocumentPageTable,
  textSegment: TextSegmentInfo,
) => {
  let tableRowAndCell: {
    row: DocumentPageTableTableRow;
    cell: DocumentPageTableTableCell;
  };
  // Now checking which row in body rows of the table has user reviewed for the above entity
  for (const row of [...table.bodyRows!, ...table.headerRows!]) {
    const collidingCell: DocumentPageTableTableCell = row.cells!.find(
      (cell) => {
        return boxPositionValuesUtilV2.isBoxContainingTheCenterOfAnotherBox(
          cell.layout!.boundingPoly!.vertices!,
          textSegment.pageCorrespondingVertices,
        );
      },
    )!;
    if (collidingCell !== undefined) {
      tableRowAndCell = { row, cell: collidingCell };
      break;
    }
  }
  return tableRowAndCell!;
};

export const sortTextSegments = (
  textSegments: TextSegmentInfo[] | DocumentTextAnchorTextSegment[],
) => {
  // Sort the array based on startIndex and endIndex
  textSegments.sort((a, b) => {
    // Compare first by startIndex
    if (a.startIndex !== b.startIndex) {
      return a.startIndex! - b.startIndex!;
    }
    // If startIndex is the same, compare by endIndex
    return a.endIndex! - b.endIndex!;
  });
};

export const getCellText = (
  docText: string,
  cellSegments: TextSegmentInfo[] | DocumentTextAnchorTextSegment[],
) => {
  let text = '';
  // Concatenate text of segments based on their start and end indices
  cellSegments.forEach((s) => {
    text += docText.substring(s.startIndex!, s.endIndex);
  });
  return text;
};

export const doesTextSegmentsContainsSameTextValue = (
  textSegments1: TextSegmentInfo[] | DocumentTextAnchorTextSegment[],
  textSegments2: TextSegmentInfo[],
  docText?: string,
) => {
  // If either of the text segment arrays is empty, return false
  if (!textSegments1.length || !textSegments2.length) return false;

  // Sort the text segments based on their start and end indices
  sortTextSegments(textSegments1);
  sortTextSegments(textSegments2);

  // Extract text from text segments
  const segment1Text = docText
    ? getCellText(docText, textSegments1)
    : textSegments1
        .map((segment) => (segment as TextSegmentInfo).text)
        .join(' ')
        .trim();
  const segment2Text = textSegments2
    .map((segment) => segment.text)
    .join(' ')
    .trim();
  // Remove non-alphabetic characters and compare the cleaned texts
  return (
    segment1Text.replace(/[^a-zA-Z]/g, '') ===
    segment2Text.replace(/[^a-zA-Z]/g, '')
  );
};

// returns true if any of the text segments given in a list of segments lies on same page
export const doesAnyTextSegmentLiesOnSamePage = (
  textSegments1: TextSegmentInfo[],
  textSegments2: TextSegmentInfo[],
) => {
  const textSegment1Pages = textSegments1.map((t) => t.modifiedPage || t.page);
  const textSegment2Pages = textSegments2.map((t) => t.modifiedPage || t.page);
  const set1 = new Set(textSegment1Pages);
  for (const page of textSegment2Pages) {
    if (set1.has(page)) {
      return true;
    }
  }
  return false;
};

/**
 * Checks if for any entity that has been annotated for a cell inside a table whether it
 * covers all the text present inside that cell or not. Returns false if any one entity fails this criteria
 * @param entities Child entities of a parent entity which has been annotated
 * @param row Row of the table for which annotation is being done
 * @param selectedTaskEntityInfo  Info of all the entities present in the task
 * @param docText Text present in the document for a particular task
 * @returns boolean
 */
export const checkIfFullyAnnotatedEntities = (
  entities: EntityInfo[],
  row: DocumentPageTableTableRow,
  selectedTaskEntityInfo: { [id: string]: EntityInfo },
  docText: string,
) => {
  let hasPartialAnnotation = false;
  for (const entity of entities) {
    const textSegments = Object.values(entity.textSegments);
    const segment: TextSegmentInfo | undefined = textSegments.length
      ? textSegments[0]
      : undefined;

    if (!segment || !segment?.pageCorrespondingVertices?.length) continue;

    const cell = row.cells!.find((c) => {
      return boxPositionValuesUtilV2.isBoxContainingTheCenterOfAnotherBox(
        c.layout!.boundingPoly!.vertices!,
        segment.pageCorrespondingVertices,
      );
    });

    if (!cell) {
      // Means one of the entities lies outside the box
      // First check if any such entity is already present or not
      const sameEntities: EntityInfo[] = Object.values(
        selectedTaskEntityInfo,
      ).filter((e) => {
        if (e.id === entity.id || e.type !== entity.type) return false;

        const textSegments1 = Object.values(e.textSegments);
        const textSegments2 = Object.values(entity.textSegments);

        // Check if any two segments lies on the same page or not
        if (!doesAnyTextSegmentLiesOnSamePage(textSegments1, textSegments2))
          return false;

        return doesTextSegmentsContainsSameTextValue(
          textSegments1,
          textSegments2,
        );
      });
      if (sameEntities.length === 0) {
        // Means this is the only entity present in map which is outside table
        hasPartialAnnotation = true;
        break;
      } else {
        // Means there are other such entities present outside table, which means smart annotation should trigger
        continue;
      }
    }

    // Check if fully annotated
    const cellTextSegments = cell.layout!.textAnchor!.textSegments!;
    const entityTextSegments = Object.values(entity.textSegments);

    if (
      !doesTextSegmentsContainsSameTextValue(
        cellTextSegments,
        entityTextSegments,
        docText,
      )
    ) {
      hasPartialAnnotation = true;
      break;
    } else {
      continue;
    }
  }
  return !hasPartialAnnotation;
};

// Checks whether any row in a table contains an entity already annotated by user
// This is used to check if suggestion is to be shown for any row or not
export const doesRowContainsNotesEntity = (
  row: DocumentPageTableTableRow,
  selectedTaskEntityInfo: { [id: string]: EntityInfo },
): boolean => {
  let containsNotesEntity = false;
  for (const cell of row.cells!) {
    const entityInfo = Object.values(selectedTaskEntityInfo).find((e) => {
      const textSegments: TextSegmentInfo[] = Object.values(e.textSegments);
      if (textSegments.length) {
        return boxPositionValuesUtilV2.isBoxContainingTheCenterOfAnotherBox(
          cell.layout!.boundingPoly!.vertices!,
          textSegments[0]?.pageCorrespondingVertices ||
            textSegments[0]?.vertices,
        );
      } else {
        return false;
      }
    });
    if (entityInfo) {
      containsNotesEntity = true;
      break;
    }
  }
  return containsNotesEntity;
};

// This is used to check whether for any entity, if any SiblingChildEntity (shares the same parent entity)
// is present in the entity info list that is present over the table
export const getFirstChildEntityThatCollidesWithTables = (
  nestedEntities: EntityInfo[],
  tables: DocumentPageTable[],
) => {
  let siblingEntity;
  for (const table of tables) {
    for (const entity of nestedEntities) {
      const textSegments: TextSegmentInfo[] = Object.values(
        entity.textSegments,
      );
      if (!textSegments.length) continue;
      const collides =
        boxPositionValuesUtilV2.isBoxContainingTheCenterOfAnotherBox(
          table.layout!.boundingPoly!.vertices!,
          textSegments[0]?.pageCorrespondingVertices ||
            textSegments[0]?.vertices,
        );
      if (collides) {
        siblingEntity = entity;
        break;
      }
    }
    if (siblingEntity) break;
  }
  return siblingEntity;
};

/**
 * Function to return array of pages where entities' textSegments lies
 * e.g. If there are 4 entities and every entity has 2 segments such that they lie on
 * pages 0, 0, 3, 1, 6, 6, 4, 1, then this will return [0, 1, 3, 4, 6]
 */
export const getPagesOfEntitiesSegments = (entities: EntityInfo[]) => {
  const pages: number[] = [];
  entities.forEach((entityInfo: EntityInfo) =>
    Object.values(entityInfo.textSegments).forEach(
      (segment: TextSegmentInfo) => {
        if (!pages.includes(segment.modifiedPage)) {
          pages.push(segment.modifiedPage);
        }
      },
    ),
  );
  return pages;
};

// Function to add margin to suggestion bbox vertices
export const getVerticesWithMargin = (vertices: Vertex[]): Vertex[] => {
  const tableBboxMargin = 2;
  const topLeft = boxPositionValuesUtilV2.getTopLeftCornerVertices(vertices);
  const topRight = boxPositionValuesUtilV2.getTopRightCornerVertices(vertices);
  const bottomRight =
    boxPositionValuesUtilV2.getBottomRightCornerVertices(vertices);
  const bottomLeft =
    boxPositionValuesUtilV2.getBottomLeftCornerVertices(vertices);

  return [
    Vertex.create({
      x: topLeft.x! + tableBboxMargin,
      y: topLeft.y! + tableBboxMargin,
    }),
    Vertex.create({
      x: topRight.x! - tableBboxMargin,
      y: topRight.y! + tableBboxMargin,
    }),
    Vertex.create({
      x: bottomRight.x! - tableBboxMargin,
      y: bottomRight.y! - tableBboxMargin,
    }),
    Vertex.create({
      x: bottomLeft.x! + tableBboxMargin,
      y: bottomLeft.y! - tableBboxMargin,
    }),
  ];
};

// Returns predicted table entities in the form of map based on the last annotated entity
export const getSuggestionAnnotationNestedEntities = (
  entityInfo: EntityInfo,
  tokensInDocument: { [page: number]: DocumentPageToken[] },
  task: Task,
  selectedTextSegmentId: string,
  selectedTaskEntityInfo: { [id: string]: EntityInfo },
  tablesSuggested: { [id: string]: { page: number; tableIndex: number }[] },
):
  | {
      [id: string]: EntityInfo;
    }
  | undefined => {
  const selectedTextSegment = entityInfo.textSegments[selectedTextSegmentId];

  // If no segment is present for entity or if entity is no nested entity, no smart annotation should trigger
  if (!selectedTextSegment || !entityInfo.parentEntityType) return;

  // Get all SiblingChildEntities that share same parent except Notes entities
  const nestedEntities = Object.values(selectedTaskEntityInfo).filter(
    (entity) => {
      return (
        entity?.parentEntityId === entityInfo.parentEntityId &&
        entity.normalizedEntityType !== EntityDataType.ENTITY_TYPE_ANNOTATION
      );
    },
  );

  // Check whether any entity is still not reviewed, since we will show suggestion once
  // all entities are reviewed by the user
  const hasUnreviewedNestedEntities = nestedEntities.some((e) => !e.isReviewed);
  if (hasUnreviewedNestedEntities) {
    return;
  }

  // Checking whether the last updated entity belong to any table and annotation should trigger or not
  const document = getSelectedTaskDocument(task)?.documents?.[0];
  const pagesOfNestedEntitiesSegments: number[] =
    getPagesOfEntitiesSegments(nestedEntities);
  const tablesForAllNestedEntities: DocumentPageTable[] = [];
  pagesOfNestedEntitiesSegments.forEach((page: number) => {
    tablesForAllNestedEntities.push(...(document?.pages?.[page]?.tables || []));
  });
  const tables: DocumentPageTable[] =
    document!.pages![selectedTextSegment.modifiedPage].tables!;

  // Return if there are no tables present for the entity that has been confirmed and
  // for all the entities that shares the same parent (sibling entity)
  if (!tables?.length && !tablesForAllNestedEntities.length) return;

  const collidingTableIndex: number = tables?.findIndex((table) => {
    return boxPositionValuesUtilV2.isBoxContainingTheCenterOfAnotherBox(
      table.layout?.boundingPoly?.vertices as Vertex[],
      selectedTextSegment.pageCorrespondingVertices,
    );
  });

  // If no such table found, return
  if (collidingTableIndex < 0) return;

  const collidingTable = tables[collidingTableIndex];

  // If this table for a particular parent entity type has already been suggested to user, we do not suggest it again
  if (tablesSuggested[entityInfo.parentEntityType]) {
    if (
      tablesSuggested[entityInfo.parentEntityType].some(
        (info) =>
          info.page === selectedTextSegment?.modifiedPage &&
          info.tableIndex === collidingTableIndex,
      )
    ) {
      return;
    } else {
      tablesSuggested[entityInfo.parentEntityType].push({
        page: selectedTextSegment?.modifiedPage,
        tableIndex: collidingTableIndex,
      });
    }
  } else {
    tablesSuggested[entityInfo.parentEntityType] = [
      {
        page: selectedTextSegment?.modifiedPage,
        tableIndex: collidingTableIndex,
      },
    ];
  }

  // If entities outside of a table duplicate with other nested entities and entities inside a
  // table are in the same column with other nested entities, smart annotation should still trigger
  if (!collidingTable) {
    const siblingEntityThatCollidesWithTable: EntityInfo =
      getFirstChildEntityThatCollidesWithTables(nestedEntities, tables || [])!;
    if (siblingEntityThatCollidesWithTable) {
      return getSuggestionAnnotationNestedEntities(
        siblingEntityThatCollidesWithTable,
        tokensInDocument,
        task,
        '0',
        selectedTaskEntityInfo,
        tablesSuggested,
      );
    }
    return;
  }

  // This is the row and cell of the entity reviewed by user
  const tableRowAndCellOfEntity = getTableRowAndCellOfEntity(
    collidingTable,
    selectedTextSegment,
  );
  const areAllEntitiesFullyAnnotated = checkIfFullyAnnotatedEntities(
    nestedEntities,
    tableRowAndCellOfEntity.row,
    selectedTaskEntityInfo,
    document!.text!,
  );

  // If any of the entity is not fully annotated, no smart annotation should trigger
  if (!areAllEntitiesFullyAnnotated) {
    // Remove collidingTable from tablesSuggested
    const tableIndex = tablesSuggested[entityInfo.parentEntityType].findIndex(
      (table) =>
        table.page === selectedTextSegment.modifiedPage &&
        table.tableIndex === collidingTableIndex,
    );
    if (tableIndex >= 0) {
      tablesSuggested[entityInfo.parentEntityType].splice(tableIndex, 1);
    }
    return;
  }

  // Rows other than the row on which the above entity lies
  const remainingRows: DocumentPageTableTableRow[] =
    collidingTable.bodyRows!.filter((r) => {
      return (
        r !== tableRowAndCellOfEntity.row &&
        !doesRowContainsNotesEntity(r, selectedTaskEntityInfo)
      );
    });

  if (remainingRows.length === 0) return;

  // This means that after annotation there are more rows left to be annotated
  // whose annotation suggestion can be displayed on review page
  const suggestionAnnotationNestedEntities: { [id: string]: EntityInfo } = {};
  let rowCounter = 0;
  let cellCounter = 0;
  // Iterated over remaining rows
  for (const row of remainingRows) {
    const parentCopyId =
      entityInfo.parentEntityId + '_copy_' + rowCounter + Date.now();
    rowCounter += 1;
    // For every row we will find the respective cell that has to be assigned
    // to new copy entity based on users' annotated entity
    for (const entity of nestedEntities) {
      // Assuming the entity only contains one textsegment for a cell
      const segment = Object.values(entity.textSegments)[0];
      // If the segment does not exists or it does not have vertices means it's empty
      const isEmpty = !segment || !segment?.pageCorrespondingVertices?.length;

      const insideTable: boolean =
        !isEmpty &&
        boxPositionValuesUtilV2.isBoxContainingTheCenterOfAnotherBox(
          collidingTable.layout!.boundingPoly!.vertices!,
          segment.pageCorrespondingVertices,
        );

      // This will find the cell of the row whose value has to be added for entity
      // which lies in the same column of the table
      const cellData = !isEmpty
        ? row.cells!.find((c) => {
            return boxPositionValuesUtilV2.isBox1CoverBox2CenterX(
              c.layout!.boundingPoly!.vertices!,
              segment.pageCorrespondingVertices,
            );
          })
        : undefined;
      // If the current entity lies outside of the table (no celldata info is found)
      // then there is no information to copy and thus we move to next entities
      if (!cellData && !isEmpty) continue;

      const entityCopyId = entity.id + '_copy_' + cellCounter + Date.now();
      cellCounter += 1;
      const copyEntityInfo: EntityInfo = { ...entity };
      copyEntityInfo.error = undefined;
      copyEntityInfo.id = entityCopyId;
      copyEntityInfo.parentEntityId = parentCopyId;
      copyEntityInfo.isInsideTable = insideTable;
      copyEntityInfo.isSmartAnnotationSuggestionEntity = true;

      if (insideTable) {
        const newVertices = getVerticesWithMargin(
          cellData?.layout?.boundingPoly?.vertices as Vertex[],
        );
        copyEntityInfo.normalizedValue = undefined;
        // Based on the cells' boundingpoly vertices we get the tokens that it contains
        const collidingTokens = boxPositionValuesUtilV2.getCollidingTokens(
          tokensInDocument[segment.modifiedPage],
          newVertices,
        );
        // This is to get the text of the above colliding tokens
        const text = tokenValuesUtil.getTextFromTokens(
          collidingTokens,
          document!.text!,
        )!;
        // Create new text segment with the cell data which was matched above
        copyEntityInfo.textSegments = {
          0: {
            id: '0',
            normalizedVertices: getNormalizedVertices(
              document!.pages![segment.modifiedPage].image!.height!,
              document!.pages![segment.modifiedPage].image!.width!,
              newVertices,
            ),
            entityId: entityCopyId,
            page: segment.modifiedPage,
            vertices: newVertices,
            text,
            textFromTokens: text,
            pageCorrespondingVertices: newVertices,
            modifiedPage: segment.modifiedPage,
            startIndex:
              collidingTokens?.[0]?.layout?.textAnchor?.textSegments?.[0]
                ?.startIndex || 0,
            endIndex:
              collidingTokens?.[collidingTokens.length - 1]?.layout?.textAnchor
                ?.textSegments?.[0]?.endIndex || 0,
          },
        };
        // To update the normalized value of the entity based on captured text
        processNormalizedValue(copyEntityInfo, text);
      }
      suggestionAnnotationNestedEntities[entityCopyId] = copyEntityInfo;
    }
  }
  return suggestionAnnotationNestedEntities;
};

/**
 * Function to get the vertices of cells in a table annotation that collide with the given bounding box.
 *
 * @param boundingBoxVertices - The vertices of the bounding box to check against.
 * @param page - The page number where the table is located in the document.
 * @param document - The document object that contains the page and table information. If undefined, the function returns undefined.
 *
 * @returns An array of vertices for cells that collide with the bounding box, or undefined if no collision is found or if the document is not provided.
 */
export const getCellVerticesForColumnAnnotation = (
  boundingBoxVertices: Vertex[],
  page: number,
  document: Document | undefined,
): Vertex[] | undefined => {
  if (!document) return;
  const tables: DocumentPageTable[] = document?.pages?.[page]?.tables ?? [];
  const collidingTable = tables?.find((table) => {
    return boxPositionValuesUtilV2.isBoxContainingTheCenterOfAnotherBox(
      table.layout!.boundingPoly!.vertices!,
      boundingBoxVertices,
    );
  });

  if (!collidingTable) return;

  const cellsVertices: Vertex[] = [];

  for (const row of [
    ...collidingTable.headerRows!,
    ...collidingTable.bodyRows!,
  ]) {
    for (const cell of row.cells!) {
      // Check if the cell's bounding box collides with the provided bounding box
      const doesCellCollide =
        boxPositionValuesUtilV2.isBoxContainingTheCenterOfAnotherBox(
          boundingBoxVertices,
          cell.layout!.boundingPoly!.vertices!,
        );
      // If the cell collides, add its vertices to the cellsVertices
      if (doesCellCollide) {
        cellsVertices.push(...cell.layout!.boundingPoly!.vertices!);
        break;
      }
    }
  }

  return cellsVertices;
};
