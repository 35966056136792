// OrbyButtonGroup.tsx
import React, { FC, memo } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import '../../styles/global.css';
import OrbyTypography from '../typography/OrbyTypography';
import OrbyColorPalette from '../colors/ColorPalette';

export interface OrbyButtonGroupProps {
  /**
   * Tabs
   */
  tabs: Array<{
    label: string;
    id: string;
  }>;
  /**
   * Selected Tab
   */
  selectedTab: number;
  /**
   * Set Selected Tab
   */
  setSelectedTab: (tabIndex: number) => void;
}

const OrbyButtonGroup: FC<OrbyButtonGroupProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
}) => {
  const handleChange = (newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <ButtonGroup
      variant='outlined'
      aria-label='orby button group'
      sx={{
        height: '34px',
        width: '376px',
        borderRadius: '22px',
        textTransform: 'none',
        color: OrbyColorPalette['grey-700'],
        border: `1px solid ${OrbyColorPalette['grey-200']}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px',
      }}
    >
      {tabs.map((tab, index) => (
        <Button
          key={tab.id}
          onClick={() => handleChange(index)}
          sx={{
            width: '100%',
            border: 'none',
            backgroundColor:
              selectedTab === index
                ? OrbyColorPalette['grey-700']
                : 'transparent',
            borderRadius: '18px !important',
            height: selectedTab === index ? '26px' : 'auto',
            marginRight: index !== tabs.length - 1 ? '4px' : '0px',
            '&:hover': {
              backgroundColor:
                selectedTab === index
                  ? OrbyColorPalette['grey-700']
                  : OrbyColorPalette['grey-200'],
              border: OrbyColorPalette['grey-200'],
              borderRadius: '18px',
              height: '26px',
            },
          }}
        >
          <OrbyTypography
            size='xs'
            weight='medium'
            color={
              selectedTab === index ? '#fff' : OrbyColorPalette['grey-700']
            }
          >
            {tab.label}
          </OrbyTypography>
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default memo(OrbyButtonGroup);
