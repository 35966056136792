// OrbyTabs.tsx
import React, { FC, memo } from 'react';
import { Tabs, Tab } from '@mui/material';
import '../../styles/global.css';
import OrbyTypography from '../typography/OrbyTypography';
import OrbyColorPalette from '../colors/ColorPalette';

export interface OrbyTabsProps {
  /**
   * Tabs
   */
  tabs: Array<{
    label: string;
    id: string;
  }>;
  /**
   * Selected Tab
   */
  selectedTab: number;
  /**
   * Set Selected Tab
   */
  setSelectedTab: (tabIndex: number) => void;
  /**
   * Tab Typography Size
   */
  typographySize?: 'sm' | 'md' | 'lg';
  /**
   * Tab Typography Weight
   */
  typographyWeight?: 'regular' | 'medium' | 'semibold' | 'bold';
}

const OrbyTabs: FC<OrbyTabsProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
  typographySize = 'md',
  typographyWeight = 'medium',
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs
        TabIndicatorProps={{
          style: {
            backgroundColor: OrbyColorPalette['blue-700'],
          },
        }}
        value={selectedTab}
        onChange={handleChange}
      >
        {tabs.map((tab, index) => (
          <Tab
            sx={{
              textTransform: 'none',
              padding: '14px 24px 10px 24px',
              cursor: 'pointer',
            }}
            tabIndex={index}
            title={tab.label}
            aria-label={tab.label}
            key={tab.id}
            label={
              <OrbyTypography
                size={typographySize}
                weight={typographyWeight}
                color={OrbyColorPalette['grey-900']}
              >
                {tab.label}
              </OrbyTypography>
            }
            id={`tab-${tab.id}`}
          />
        ))}
      </Tabs>
    </>
  );
};

export default memo(OrbyTabs);
