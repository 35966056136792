import produce from 'immer';
import { Reducer } from 'redux';
import { Token } from '../../services/StorageService';
import { AuthActionType } from '../actions/actions.constants';
import { User } from 'protos/pb/v1alpha1/user';

export interface AuthState {
  loading: boolean;
  error?: string;
  user?: User;
  token?: Token;
  loginErrorMessage?: string;
  unauthenticatedError?: string;
}

const initialState: AuthState = {
  loading: false,
};

export const authReducer: Reducer<AuthState> = (
  state: AuthState = initialState,
  action: any,
) =>
  produce(state, (draft: AuthState) => {
    switch (action.type) {
      case AuthActionType.LOGIN:
      case AuthActionType.GOOGLE_LOGIN:
      case AuthActionType.MICROSOFT_LOGIN:
      case AuthActionType.SAML_LOGIN:
      case AuthActionType.SIGN_UP: {
        draft.loading = true;
        draft.error = undefined;
        break;
      }
      // TODO: Refactor this
      case AuthActionType.LOGIN_COMPLETED: {
        draft.token = action.payload;
        draft.error = undefined;
        draft.loading = false;
        draft.unauthenticatedError = undefined;
        break;
      }
      case AuthActionType.SAML_LOGIN_COMPLETED: {
        draft.token = action.payload;
        draft.error = undefined;
        draft.loading = false;
        draft.unauthenticatedError = undefined;
        break;
      }
      case AuthActionType.GOOGLE_LOGIN_COMPLETED: {
        draft.token = action.payload;
        draft.error = undefined;
        draft.loading = false;
        draft.unauthenticatedError = undefined;
        break;
      }
      case AuthActionType.MICROSOFT_LOGIN_COMPLETED: {
        draft.token = action.payload;
        draft.error = undefined;
        draft.loading = false;
        draft.unauthenticatedError = undefined;
        break;
      }
      case AuthActionType.SIGN_UP_COMPLETED: {
        draft.user = action.payload;
        draft.loading = false;
        draft.error = undefined;
        draft.unauthenticatedError = undefined;
        break;
      }
      case AuthActionType.LOGIN_ERROR:
      case AuthActionType.SAML_LOGIN_ERROR:
      case AuthActionType.SIGN_UP_ERROR: {
        draft.loading = false;
        draft.error = action.payload;
        break;
      }
      case AuthActionType.LOGOUT_COMPLETED:
        draft.token = undefined;
        draft.user = undefined;
        draft.unauthenticatedError = undefined;
        break;
      case AuthActionType.GOOGLE_LOGIN_ERROR:
        draft.loginErrorMessage = action.payload;
        draft.unauthenticatedError = undefined;
        break;
      case AuthActionType.MICROSOFT_LOGIN_ERROR:
        draft.loginErrorMessage = action.payload;
        draft.unauthenticatedError = undefined;
        break;
      case AuthActionType.UNAUTHENTICATED_ERROR:
        draft.unauthenticatedError = action.payload;
        break;
      case AuthActionType.LOGOUT:
      case AuthActionType.LOGOUT_ERROR:
      case AuthActionType.GOOGLE_LOGOUT_ERROR:
      default:
        break;
    }
  });
