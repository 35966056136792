import OrbyColorPalette from '../colors/ColorPalette';

export type DataRow<T> = {
  id: string;
  title: string;
  row: T;
  cells: Array<JSX.Element | JSX.Element[]>;
  customBackgroundColor?: string;
  isCheckBoxDisabled?: boolean;
  isClickable?: boolean;
  isNoMatch?: boolean;
  tooltipTitle?: string;
};

export const ROWS_PER_PAGE = [5, 10, 15, 20];

export const getRowBackgroundColor = <T>(
  dataRow: DataRow<T>,
  isRowSelected?: (row: T) => boolean,
) => {
  if (dataRow && dataRow.customBackgroundColor) {
    return dataRow.customBackgroundColor;
  }

  if (dataRow && dataRow.isNoMatch) return OrbyColorPalette['yellow-25'];

  return isRowSelected?.(dataRow.row)
    ? OrbyColorPalette['grey-50']
    : OrbyColorPalette['white-0'];
};

export const DEFAULT_ROWS_PER_PAGE = 20;
export const DEFAULT_FIRST_PAGE = 1;
