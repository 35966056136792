import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { SignupForm } from './components/SignUpForm';
import { Link } from 'react-router-dom';
import drawerLog from '../../static/orby-logo-main.svg';
import './LoginPage.css';
//---------------------------------------------------------------

export default function SignUp() {
  return (
    <Box paddingTop={'10px'}>
      <title>&quot;Login | Orby-UI&quot;</title>
      <Container
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'start'}
          marginBottom={4}
          sx={{ width: '100%' }}
        >
          <img src={drawerLog} alt='Orby Logo' />
          <Typography marginTop={4}>
            Already have an account?{' '}
            <Link to={'/login'} className='focused-link'>
              <Typography component={'span'} color={'#1669F7'} fontWeight={600}>
                Login
              </Typography>
            </Link>
          </Typography>
        </Box>
        <Box sx={{ width: '45%' }}>
          <Typography
            fontSize={36}
            fontWeight={700}
            color={'#000000A6'}
            gutterBottom
            sx={{ marginBottom: '70px' }}
          >
            Join now or sign in
          </Typography>
          <Box>
            <SignupForm />
          </Box>

          <Box display={'flex'} alignItems={'center'}>
            <Typography marginTop={1}>
              By registering, I agree to Orby AI
              <Typography
                component={'span'}
                marginLeft={'4px'}
                marginRight={'4px'}
                color={'#1669F7'}
                fontWeight={600}
              >
                Terms of Service
              </Typography>
              and
              <Typography
                marginLeft={'4px'}
                component={'span'}
                color={'#1669F7'}
                fontWeight={600}
              >
                Privacy Policy.
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
