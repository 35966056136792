import { Box, Checkbox } from '@mui/material';
import React, { FC, memo, useState } from 'react';
import orbyLogo from '../static/orby-logo-main.svg';
import CustomTypography, {
  TypographyType,
} from '../components/CustomTypography';
import LaunchIcon from '@mui/icons-material/Launch';
import CustomComboButtons from '../components/CustomComboButtons';
import { UpdateRequest } from 'protos/pb/v1alpha1/users_service';
import { useDispatch, useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
  updateUserLoadingSelector,
} from '../redux/selectors/user.selectors';
import { updateUserAction } from '../redux/actions/user.action';
import { User } from 'protos/pb/v1alpha1/user';
import useLogoutHandler from '../hooks/useLogoutHandler';

const PolicyTermsPage: FC = () => {
  const dispatch = useDispatch();
  const updatingUser = useSelector(updateUserLoadingSelector);
  const [checked, setChecked] = useState(false);
  const user = useSelector(loggedInUserSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const { handleLogout } = useLogoutHandler();
  const handleSecondryClick = () => {
    if (checked) {
      const parsedUser = User.create(user);
      const req = UpdateRequest.create({});
      const u = parsedUser;
      if (u) {
        u.email = parsedUser.email;

        const policies = parsedUser?.prerequisite?.policiesToReview?.map(
          (p) => {
            const policy = p;
            // const date = Date.now()
            // const seconds = Math.floor(date / 1000)
            p.acceptedAt = new Date();
            return policy;
          },
        );
        if (u.prerequisite) {
          u.prerequisite.policiesToReview = policies;
        }
        req.user = u;
        if (selectedOrgInfo) {
          req.orgResourceName = selectedOrgInfo.orgResourceName;
        }
        req.fieldMask = ['prerequisite.policies_to_review'];
        dispatch(updateUserAction(req));
      }
    }
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      paddingTop={'30px'}
      alignItems={'center'}
      height={'100%'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        textAlign={'center'}
      >
        <Box>
          <img
            src={orbyLogo}
            width={'150px'}
            style={{ marginBottom: '68px' }}
            alt='Orby AI Logo'
          />
        </Box>
        <CustomTypography
          component={'h1'}
          typographyType={TypographyType.Bolder}
          sx={{ textAlign: 'center', marginBottom: '36px' }}
        >
          We&apos;ve updated our policies
        </CustomTypography>
        <CustomTypography typographyType={TypographyType.MediumPara}>
          Please review and accept the following terms of service to sign in to
          your account.
        </CustomTypography>
        <Box
          display={'flex'}
          marginTop={'24px'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Checkbox
            inputProps={{ 'aria-label': 'Accept Terms' }}
            checked={checked}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setChecked(!checked);
              }
            }}
            onChange={(e) => {
              const checked = e.target.checked;
              setChecked(checked);
            }}
          />
          <CustomTypography
            sx={{ display: 'inline', marginRight: '4px' }}
            weight={800}
          >
            I agree to the
          </CustomTypography>
          {user?.prerequisite?.policiesToReview?.map((p, index) => (
            <Box key={p.policyUrl}>
              <Box component={'a'} href={p.policyUrl} target='_blank'>
                <CustomTypography
                  weight={800}
                  sx={{
                    display: 'inline',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  color='#3278C3'
                >
                  {p.policyName}
                  <LaunchIcon
                    sx={{ width: '15px', height: '15px', marginBottom: '-3px' }}
                  />
                </CustomTypography>
              </Box>
              {index ===
              (user?.prerequisite?.policiesToReview?.length as number) - 2 ? (
                <CustomTypography
                  sx={{ display: 'inline', marginX: '4px' }}
                  weight={800}
                >
                  and
                </CustomTypography>
              ) : index <
                (user?.prerequisite?.policiesToReview?.length as number) - 2 ? (
                <CustomTypography
                  sx={{ display: 'inline', marginRight: '4px' }}
                  weight={800}
                >
                  ,
                </CustomTypography>
              ) : (
                ''
              )}
            </Box>
          ))}
        </Box>
        <CustomComboButtons
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2.5,
            marginTop: '63px',
            justifyContent: 'center',
          }}
          primarySx={{
            color: '#555555',
            fontWeight: 800,
            border: '1px solid black',
            margin: 0,
            borderRadius: '5px',
          }}
          secondaryLoading={updatingUser}
          secondaryDisabled={updatingUser || !checked}
          primaryDisabled={updatingUser}
          onPrimaryClick={handleLogout}
          onSecondaryClick={handleSecondryClick}
          secondarySx={{
            borderRadius: '5px',
            fontWeight: 800,
          }}
          primaryLabel={'Cancel'}
          secondaryLabel={'Continue'}
          size={'small'}
          reverse={true}
        />
      </Box>
    </Box>
  );
};

export default memo(PolicyTermsPage);
