import { OrbyBodyLabel } from 'orby-ui/src';
import {
  Schedule,
  ScheduleConfigRecurrencePattern,
} from 'protos/pb/v1alpha1/schedule';
import React from 'react';
import { formatDate } from '../../../utils/helpers';
import { TimeOfDay } from 'protos/google/type/timeofday';
import deleteIcon from '../../../static/icons/delete-outlined.svg';
import editIcon from '../../../static/icons/edit-blue.svg';

export const getScheduleCell = (schedule: { title: string }): JSX.Element => {
  return (
    <OrbyBodyLabel
      label={schedule.title}
      color={'#000'}
      fontWeight={'regular'}
      fontSize={'sm'}
    />
  );
};

export const getScheduleDateRangeCell = (schedule: Schedule): JSX.Element => {
  return (
    <OrbyBodyLabel
      label={`${formatDate(schedule.startTime!, 'MM/DD/YYYY')}${schedule.endTime ? ` - ${formatDate(schedule.endTime!, 'MM/DD/YYYY')}` : ''}`}
      color={'#000'}
      fontWeight={'regular'}
      fontSize={'sm'}
    />
  );
};

export const getRecurrencePattern = (
  recurrencePattern: ScheduleConfigRecurrencePattern | undefined,
): string => {
  if (recurrencePattern?.noRecurrence) {
    return 'No Recurrence';
  }
  if (recurrencePattern?.dailyRecurrence) {
    return 'Daily';
  }
  if (recurrencePattern?.weeklyRecurrence) {
    return 'Weekly';
  }
  if (recurrencePattern?.monthlyRecurrence) {
    return 'Monthly';
  }
  return '';
};

export const getScheduleRecurrenceCell = (schedule: Schedule): JSX.Element => {
  return (
    <OrbyBodyLabel
      label={`${getRecurrencePattern(schedule.config?.schedulePattern?.recurrencePattern)}`}
      color={'#000'}
      fontWeight={'regular'}
      fontSize={'sm'}
    />
  );
};

const getTime = (time: TimeOfDay): string => {
  return `${time.hours?.toString().padStart(2, '0')}:${time.minutes?.toString().padStart(2, '0')}:${time.seconds?.toString().padStart(2, '0')}`;
};

export const getScheduleTimeCell = (schedule: Schedule): JSX.Element => {
  return (
    <OrbyBodyLabel
      label={`${schedule.config?.schedulePattern?.runTimes
        ?.map((time) => getTime(time))
        .join(', ')}`}
      color={'#000'}
      fontWeight={'regular'}
      fontSize={'sm'}
    />
  );
};

export const getScheduleDeleteCell = (): JSX.Element => {
  return <img src={deleteIcon} alt='Delete' />;
};

export const getScheduleEditCell = (): JSX.Element => {
  return <img src={editIcon} alt='Edit' />;
};
