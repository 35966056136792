import { User } from 'protos/pb/v1alpha1/user';
import { GetFiltersActionType } from './actions.constants';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';

export const getUserFiltersAction = (orgResourceName: string) => ({
  type: GetFiltersActionType.GET_USER_FILTERS,
  payload: orgResourceName,
});

export const getUserFiltersCompletedAction = (payload: User[]) => ({
  type: GetFiltersActionType.GET_USER_FILTERS_COMPLETED,
  payload,
});

export const getUserFiltersErrorAction = (error?: Error) => ({
  type: GetFiltersActionType.GET_USER_FILTERS_ERROR,
  payload: error,
});

export const getWorkflowFiltersAction = (
  orgResourceName: string,
  isUnifiedWorkflowsViewEnabled?: boolean,
) => ({
  type: GetFiltersActionType.GET_WORKFLOW_FILTERS,
  payload: {
    orgResourceName,
    isUnifiedWorkflowsViewEnabled,
  },
});

export const getWorkflowFiltersCompletedAction = (workflows: Workflow[]) => ({
  type: GetFiltersActionType.GET_WORKFLOW_FILTERS_COMPLETED,
  payload: workflows,
});

export const getWorkflowFiltersErrorAction = (error?: Error) => ({
  type: GetFiltersActionType.GET_WORKFLOW_FILTERS_ERROR,
  payload: error,
});

export const getOrbotWorkflowFiltersAction = (orgResourceName: string) => ({
  type: GetFiltersActionType.GET_ORBOT_WORKFLOW_FILTERS,
  payload: orgResourceName,
});

export const getOrbotWorkflowFiltersCompletedAction = (
  workflows: Workflow[],
) => ({
  type: GetFiltersActionType.GET_ORBOT_WORKFLOW_FILTERS_COMPLETED,
  payload: workflows,
});

export const getOrbotWorkflowFiltersErrorAction = (error?: Error) => ({
  type: GetFiltersActionType.GET_ORBOT_WORKFLOW_FILTERS_ERROR,
  payload: error,
});
