import { WorkflowSecretActionType } from './actions.constants';
import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';
import { WorkflowSecretStore } from '../reducers/workflow_secrets.reducer';
import {
  SecretBlock,
  UpdateSecretBlockRequest,
} from 'protos/pb/v1alpha1/secret_manager';
import { createAction } from '@reduxjs/toolkit';

export const fetchSecretsFromWorkflowAction = createAction<{
  workflow: Workflow;
}>(WorkflowSecretActionType.FETCH_SECRETS_FROM_WORKFLOW);

export const setSecretsInfoFromWorkflowAction = createAction<{
  workflowSecretStore: WorkflowSecretStore;
}>(WorkflowSecretActionType.SET_SECRETS_FROM_WORKFLOW);

export const createSecretBlockAction = createAction<{
  secretBlock: SecretBlock;
}>(WorkflowSecretActionType.CREATE_SECRET_BLOCK);

export const createSecretBlockCompletedAction = createAction<{
  secretBlock: SecretBlock;
}>(WorkflowSecretActionType.CREATE_SECRET_BLOCK_COMPLETED);

export const createSecretBlockErrorAction = createAction<{ error: Error }>(
  WorkflowSecretActionType.CREATE_SECRET_BLOCK_ERROR,
);

export const updateSecretBlockAction = createAction<{
  req: UpdateSecretBlockRequest;
}>(WorkflowSecretActionType.UPDATE_SECRET_BLOCK);

export const updateSecretBlockCompletedAction = createAction<{
  secretBlock: SecretBlock;
}>(WorkflowSecretActionType.UPDATE_SECRET_BLOCK_COMPLETED);

export const updateSecretBlockErrorAction = createAction<{ error: Error }>(
  WorkflowSecretActionType.UPDATE_SECRET_BLOCK_ERROR,
);

export const deleteSecretBlockAction = createAction<{
  secretBlock: SecretBlock;
}>(WorkflowSecretActionType.DELETE_SECRET_BLOCK);
