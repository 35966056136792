import { GmailLabel } from 'protos/automation_mining/ontology/data_models';
import { UtilityActionType } from './actions.constants';
import {
  GetEmailLabelsRequest,
  GetScheduleTasksRequest,
  ScheduleTask,
} from 'protos/pb/v1alpha2/utility_service';

export const getGmailLabelsAction = (req: GetEmailLabelsRequest) => ({
  type: UtilityActionType.GET_GMAIL_LABELS,
  payload: req,
});

export const getGmailLabelsErrorAction = (error?: Error) => ({
  type: UtilityActionType.GET_GMAIL_LABELS_ERROR,
  payload: error,
});

export const getGmailLabelsCompletedAction = (labels: GmailLabel[]) => ({
  type: UtilityActionType.GET_GMAIL_LABELS_COMPLETED,
  payload: labels,
});

export const getScheduledTasksAction = (req: GetScheduleTasksRequest) => ({
  type: UtilityActionType.GET_SCHEDULED_TASKS,
  payload: req,
});

export const getScheduledTasksErrorAction = (error?: Error) => ({
  type: UtilityActionType.GET_SCHEDULED_TASKS_ERROR,
  payload: error,
});

export const getScheduledTasksCompletedAction = (res: ScheduleTask[]) => ({
  type: UtilityActionType.GET_SCHEDULED_TASKS_COMPLETED,
  payload: res,
});
