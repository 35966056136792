import { DocumentPageToken } from 'protos/google/cloud/documentai/v1/document';

// Gets an array of start indices of the searched text found in
// the given parent string
// e.g if string is I want to register the wanted user which was once wanted by police
// and search text is want
// the array will be [2, 23, 50] since want is found at these indices in the above text
// documentText should be passed after converting in lowercase since we support case insensitivity matching
export const getStartIndicesOfSearchedText = (
  documentText: string,
  searchText: string,
) => {
  const positions: number[] = [];
  let index = documentText.indexOf(searchText);

  while (index !== -1) {
    positions.push(index);
    index = documentText.indexOf(searchText, index + 1);
  }

  return positions;
};

// For any given start index and end index, it checks whether any token with its start index
// and end index lies within it or not
export const doesTokenIndicesLiesWithinGivenIndices = (
  token: DocumentPageToken,
  startIndex: number,
  endIndex: number,
) => {
  const tokenStartIndex = token.layout?.textAnchor?.textSegments?.[0]
    .startIndex as number;
  const tokenEndIndex = token.layout?.textAnchor?.textSegments?.[0]
    .endIndex as number;

  if (endIndex <= tokenStartIndex || tokenEndIndex <= startIndex) {
    return false;
  }

  return true;
};

// Checks whether given two tokens are same or not
export const isSameToken = (
  firstToken: DocumentPageToken,
  secondToken: DocumentPageToken,
) => {
  const firstTokenStartIndex =
    firstToken.layout?.textAnchor?.textSegments?.[0].startIndex;
  const firstTokenEndIndex =
    firstToken.layout?.textAnchor?.textSegments?.[0].endIndex;
  const secondTokenStartIndex =
    secondToken.layout?.textAnchor?.textSegments?.[0].startIndex;
  const secondTokenEndIndex =
    secondToken.layout?.textAnchor?.textSegments?.[0].endIndex;

  return (
    firstTokenStartIndex === secondTokenStartIndex &&
    firstTokenEndIndex === secondTokenEndIndex
  );
};

// Function that split a string into two substring from specified index
export const splitStringAtIndex = (inputString: string, index: number) => {
  if (index >= 0 && index < inputString.length) {
    const firstPart = inputString.substring(0, index);
    const secondPart = inputString.substring(index);

    return [firstPart, secondPart];
  } else {
    return [inputString];
  }
};

// Gets an array of string split into substring to show it as highlighted in the UI
// The substring which matches with the splitter text is highlighted
// token is the token for which the split string array is calculated
// tokenText is the text that the token contains
// startIndex is the index of the matched splitter result found in the document text string at that index
// endIndex is the index of the matched splitter result found in the document text + length of the splitter string
// e.g In the sentence "Where are you", if splitter is "ere", the startIndex becomes 2 since match is found at that
// index, endIndex will be 2 + 3 (length of string) = 5, token will be the object which has the info of "where" word
// token text is "where", with this function, we will get ["Wh", "ere"] as the output and then in the UI "ere" will
// be displayed in the highlighted section
export const splitTokenText = (
  token: DocumentPageToken,
  tokenText: string,
  splitterStartIndex: number,
  splitterEndIndex: number,
) => {
  if (splitterEndIndex <= splitterStartIndex) {
    return [];
  }

  const tokenStartIndex = token.layout?.textAnchor?.textSegments?.[0]
    .startIndex as number;
  const tokenEndIndex = token.layout?.textAnchor?.textSegments?.[0]
    .endIndex as number;

  // This difference would be calculated w.r.t 0 since token end index is half open
  const differenceOfEndIndex = splitterEndIndex - tokenEndIndex;

  // [] for token range and () for splitter range

  if (differenceOfEndIndex >= 0) {
    if (splitterStartIndex <= tokenStartIndex) {
      // Means the tokentext lies completely in splitter indices range
      // i.e. ( [token] )
      return [tokenText];
    } else {
      // Means end suffix of the tokentext lies in splitter indices range
      // i.e. [ (suffix] )
      return splitStringAtIndex(
        tokenText,
        splitterStartIndex - tokenStartIndex,
      );
    }
  } else {
    if (splitterStartIndex <= tokenStartIndex) {
      // Means starting prefix of the tokentext lies in splitter indices range
      // i.e. ( [prefix) ]
      return splitStringAtIndex(tokenText, splitterEndIndex - tokenStartIndex);
    } else {
      // Means middle segment of token text lies in splitter indices range
      // i.e. [ (middleString) ]
      const firstSplit = splitStringAtIndex(
        tokenText,
        splitterStartIndex - tokenStartIndex,
      );
      if (firstSplit.length > 1) {
        const secondSplit = splitStringAtIndex(
          firstSplit[1],
          splitterEndIndex - splitterStartIndex,
        );
        return [firstSplit[0], ...secondSplit];
      } else {
        return firstSplit;
      }
    }
  }
};

// Checks if the given start index is in the right (greater than) of end index of a given token.
export const isStartIndexInRightOfToken = (
  token: DocumentPageToken,
  startIndex: number,
) => {
  const tokenEndIndex = token.layout?.textAnchor?.textSegments?.[0]
    .endIndex as number;
  if (tokenEndIndex <= startIndex) {
    return true;
  }
  return false;
};

// Searches for tokens within a given range using binary search and identifies additional occurrences
// to the left and right of the matching token.
export const searchTokensWithBinarySearch = (
  tokens: DocumentPageToken[],
  startIndex: number,
  searchText: string,
) => {
  const searchedTokens: DocumentPageToken[] = [];
  let left = 0;
  let right = tokens.length - 1;
  while (left <= right) {
    // Find the middle index and token
    const mid = Math.floor((left + right) / 2);
    const midToken = tokens[mid];
    // Check if the middle token indices lies within given indices
    if (
      doesTokenIndicesLiesWithinGivenIndices(
        midToken,
        startIndex,
        startIndex + searchText.length,
      )
    ) {
      // Checks if this token is already added or not
      if (!searchedTokens.find((t) => isSameToken(tokens[mid], t))) {
        searchedTokens.push(tokens[mid]);
      }
      // Check for additional occurrences to the left
      let endOfFirstHalfArray = mid - 1;
      while (
        endOfFirstHalfArray >= 0 &&
        doesTokenIndicesLiesWithinGivenIndices(
          tokens[endOfFirstHalfArray],
          startIndex,
          startIndex + searchText.length,
        )
      ) {
        if (
          !searchedTokens.find((t) =>
            isSameToken(tokens[endOfFirstHalfArray], t),
          )
        ) {
          searchedTokens.push(tokens[endOfFirstHalfArray]);
        }
        endOfFirstHalfArray--;
      }
      // Check for additional occurrences to the right
      let startOfSecondHalfArray = mid + 1;
      while (
        startOfSecondHalfArray < tokens.length &&
        doesTokenIndicesLiesWithinGivenIndices(
          tokens[startOfSecondHalfArray],
          startIndex,
          startIndex + searchText.length,
        )
      ) {
        if (
          !searchedTokens.find((t) =>
            isSameToken(tokens[startOfSecondHalfArray], t),
          )
        ) {
          searchedTokens.push(tokens[startOfSecondHalfArray]);
        }
        startOfSecondHalfArray++;
      }
      break;
    } else if (isStartIndexInRightOfToken(midToken, startIndex)) {
      left = mid + 1; // Target may be in the right half
    } else {
      right = mid - 1; // Target may be in the left half
    }
  }
  return searchedTokens;
};
