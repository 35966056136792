import { workflowDetailsSelector } from './app.selectors';
import { WorkflowDetailsState } from '../reducers/workflow_detail.reducer';
import { createSelector } from '@reduxjs/toolkit';

export const loadingSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.loading,
);

export const workflowAllDetailsSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails,
);

export const workflowHasUnsavedChangesSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.hasUnsavedChanges,
);

export const getWorkflowSchedulesSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.schedules,
);

export const createWorkflowScheduleLoadingSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    workflowDetails.createWorkflowScheduleLoading,
);

export const createWorkflowScheduleErrorSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    workflowDetails.createWorkflowScheduleError,
);

export const workflowSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.workflow,
);

export const deleteWorkflowScheduleErrorSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    workflowDetails.deleteWorkflowScheduleError,
);

export const getWorkflowScheduleSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.schedule,
);

export const getSchedulesLoadingSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.schedulesLoading,
);

export const getSchedulesErrorSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.schedulesError,
);

export const getInvalidChangesSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.invalidateChanges,
);
