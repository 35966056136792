import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AutomationMiningActionType } from '../actions/actions.constants';

import {
  generateActivityGraphActionCompletedAction,
  generateActivityGraphActionErrorAction,
} from '../actions/automation_mining.action';
import { automationMiningService } from '../../services/AutomationMiningService';
import {
  GenerateActivityGraphRequest,
  GenerateActivityGraphResponse,
} from 'protos/pb/v1alpha1/automation_mining_service';

function* generateActivityGraphSaga(data: {
  type: AutomationMiningActionType;
  payload: {
    start: string;
    end: string;
  };
}) {
  try {
    const generateActivityGraphRequest = {} as GenerateActivityGraphRequest;
    const startTime = new Date(data.payload.start);
    const endTime = new Date(data.payload.end);
    generateActivityGraphRequest.start = startTime;
    generateActivityGraphRequest.end = endTime;
    const response: GenerateActivityGraphResponse = yield call(
      automationMiningService.generateActivityGraph,
      generateActivityGraphRequest,
    );
    const decoder = new TextDecoder('utf-8');
    yield put(
      generateActivityGraphActionCompletedAction(
        response.mimeType!,
        decoder.decode(response.activityGraph),
      ),
    );
  } catch (e: any) {
    yield put(
      generateActivityGraphActionErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

function* suggestionSaga() {
  yield all([
    takeLatest(
      AutomationMiningActionType.GENERATE_ACTIVITY_GRAPH,
      generateActivityGraphSaga,
    ),
  ]);
}

export default suggestionSaga;
