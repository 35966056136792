const GOOGLE_STORAGE_CLOUD_BASE_URL = 'https://storage.googleapis.com';
class GcsService {
  private static instance: GcsService;
  public static getInstance(): GcsService {
    if (!this.instance) {
      this.instance = new GcsService();
    }
    return this.instance;
  }

  async getUriContent(
    uri: string,
  ): Promise<{ response?: Uint8Array; error?: Error }> {
    // We modify the uri of format gs:// into Public URLs
    if (uri.startsWith('gs://')) {
      uri = `${GOOGLE_STORAGE_CLOUD_BASE_URL}/${uri.slice(5)}`;
    }
    return new Promise<{ response?: Uint8Array; error?: Error }>((resolve) => {
      const request = new XMLHttpRequest();
      request.open('GET', uri, true);
      request.responseType = 'arraybuffer';
      request.send(null);

      request.onreadystatechange = function () {
        if (request.readyState === 4 && request.status === 200) {
          resolve({ response: new Uint8Array(request.response) });
        } else if (request.status != 200) {
          resolve({
            error: new Error(`failed to fetch content for uri: ${uri}`),
          });
        }
      };
    });
  }
}

export const gcsService = GcsService.getInstance();
