import { storageService, Token } from '../services/StorageService';

// There are some cases that token can be '{}'.
export function isEmptyToken(token: Token | undefined): token is undefined {
  return (
    token === null || token === undefined || Object.keys(token).length === 0
  );
}

// We are finding if there is no token when the cookie feature flag is disabled for the user
// When it is enabled, the token shall pass automatically in the requests
export function isEmptyTokenAsPerCookieFeatureFlag(
  token: Token | undefined,
  isLoginCookieEnabled: boolean,
): boolean {
  return (
    !isLoginCookieEnabled &&
    (token === null || token === undefined || Object.keys(token).length === 0)
  );
}

export async function fetchSessionId(refreshToken = true) {
  let sessionId = await storageService.getSessionId();
  if (!sessionId) {
    const token = await storageService.getStoredToken(refreshToken);
    sessionId = token?.sessionId || null;
  }
  return sessionId;
}
