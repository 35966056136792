import produce from 'immer';
import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';
import { Reducer } from 'redux';
import {
  OrbotWorkflowActionType,
  WorkflowTaskActionType,
} from '../actions/actions.constants';

export interface OrbotState {
  workflows: Workflow[];
  nextPageToken?: string;
  totalSize?: number;
  loadingWorkflows: boolean;
  loadedWorkflows: boolean;
  listWorkflowError?: Error;
  processingWorkflow: boolean;
  processWorkflowError?: Error;
  updatedWorkflow?: Workflow;

  // Cancelling Execution / child Executions
  cancelExecutionLoading: boolean;
  cancelExecutionError?: string;
  cancelExecutionSuccess: boolean;
}

const initialState: OrbotState = {
  workflows: [],
  loadingWorkflows: false,
  loadedWorkflows: false,
  processingWorkflow: false,
  cancelExecutionSuccess: false,
  cancelExecutionLoading: false,
};

export const orbotReducer: Reducer<OrbotState> = (
  state: OrbotState = initialState,
  action: any,
) =>
  produce(state, (draft: OrbotState) => {
    switch (action.type) {
      case OrbotWorkflowActionType.LIST_WORKFLOWS:
        draft.loadingWorkflows = true;
        break;
      case OrbotWorkflowActionType.LIST_WORKFLOWS_COMPLETED: {
        if (action.refresh) {
          draft.workflows = action.payload;
        } else {
          draft.workflows.push(...action.payload);
        }
        draft.nextPageToken = action.nextPageToken;
        draft.totalSize = action.totalSize;
        draft.loadingWorkflows = false;
        draft.loadedWorkflows = true;
        break;
      }
      case OrbotWorkflowActionType.LIST_WORKFLOWS_ERROR: {
        draft.loadingWorkflows = false;
        draft.listWorkflowError = action.payload;
        break;
      }
      case OrbotWorkflowActionType.DELETE_WORKFLOW: {
        draft.processingWorkflow = true;
        break;
      }
      case OrbotWorkflowActionType.DELETE_WORKFLOW_COMPLETED: {
        const updatedWorkflow = draft.workflows.find(
          (w) => w.id === action.payload,
        );
        draft.workflows = draft.workflows.filter(
          (w) => w.id !== action.payload,
        );
        draft.totalSize = (draft.totalSize ?? 0) - 1;
        draft.updatedWorkflow = updatedWorkflow;
        draft.processingWorkflow = false;
        break;
      }
      case OrbotWorkflowActionType.DELETE_WORKFLOW_ERROR: {
        draft.processingWorkflow = false;
        draft.processWorkflowError = action.payload;
        break;
      }
      case WorkflowTaskActionType.CANCEL_EXECUTION:
      case WorkflowTaskActionType.CANCEL_CHILD_EXECUTIONS: {
        draft.cancelExecutionLoading = true;
        break;
      }
      case WorkflowTaskActionType.CANCEL_EXECUTION_COMPLETED:
      case WorkflowTaskActionType.CANCEL_CHILD_EXECUTIONS_COMPLETED: {
        draft.cancelExecutionLoading = false;
        draft.cancelExecutionSuccess = true;
        break;
      }
      case WorkflowTaskActionType.CANCEL_EXECUTION_ERROR:
      case WorkflowTaskActionType.CANCEL_CHILD_EXECUTIONS_ERROR: {
        draft.cancelExecutionLoading = false;
        draft.cancelExecutionError = action.payload.message;
        break;
      }
      case WorkflowTaskActionType.CLEAR_CANCELLING_EXECUTION: {
        draft.cancelExecutionLoading = false;
        draft.cancelExecutionSuccess = false;
        draft.cancelExecutionError = undefined;
        break;
      }
      default:
        break;
    }
  });
