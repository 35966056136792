import { createSelector } from 'reselect';
import { userSelector } from './app.selectors';

export const loggedInUserSelector = createSelector(
  [userSelector],
  (userState) => userState.loggedInUser,
);

export const fetchingLoggedInUserSelector = createSelector(
  [userSelector],
  (userState) => userState.fetchingLoggedInUser,
);

export const updatingPasswordSelector = createSelector(
  [userSelector],
  (userState) => userState.updatingPassword,
);

export const usersListSelector = createSelector(
  [userSelector],
  (userState) => userState.users,
);

export const loadingUsersSelector = createSelector(
  [userSelector],
  (userState) => userState.loadingUsers,
);

export const loadedUsersSelector = createSelector(
  [userSelector],
  (userState) => userState.loadedUsers,
);

export const usersNextPageTokenSelector = createSelector(
  [userSelector],
  (userState) => userState.nextPageToken,
);

export const usersTotalSizeSelector = createSelector(
  [userSelector],
  (userState) => userState.totalSize,
);

export const listUsersErrorSelector = createSelector(
  [userSelector],
  (userState) => userState.listUsersError,
);

export const addUserErrorSelector = createSelector(
  [userSelector],
  (userState) => userState.addUserError,
);

export const addedUserSelector = createSelector(
  [userSelector],
  (userState) => userState.addedUser,
);

export const updateUserErrorSelector = createSelector(
  [userSelector],
  (userState) => userState.updateUserError,
);

export const updatedUserSelector = createSelector(
  [userSelector],
  (userState) => userState.updatedUser,
);

export const updateUserLoadingSelector = createSelector(
  [userSelector],
  (userState) => userState.updateLoading,
);

export const createUserLoadingSelector = createSelector(
  [userSelector],
  (userState) => userState.createLoading,
);

export const selectedOrgInfoSelector = createSelector(
  [userSelector],
  (userState) => userState.selectedOrgInfo,
);

export const updateGoogleTokenCompletedSelector = createSelector(
  [userSelector],
  (userState) => userState.updateGoogleTokenCompleted,
);

export const updateGoogleTokenErrorSelector = createSelector(
  [userSelector],
  (userState) => userState.updateGoogleTokenError,
);

export const userPermissionsSelector = createSelector(
  [userSelector],
  (userState) => userState.userPermissions,
);
