import React from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// material

import { TextField, Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// --------------------------------------------------------------------

export const ForgotForm: React.FC = () => {
  const ForgotSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ForgotSchema,
    onSubmit: (values) => {
      // eslint-disable-next-line no-console
      console.log('mail to send password', values.email);
    },
  });

  const navigate = useNavigate();

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Box>
          <Typography
            align='center'
            color={'#808080'}
            fontSize={20}
            marginBottom={4}
          >
            Please enter the email address associated with your account and We
            will email you a link to reset your password.
          </Typography>
          <TextField
            fullWidth
            sx={{
              marginBottom: 3,
            }}
            autoComplete='username'
            type='email'
            label='Email address'
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Box>
        <Button
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          sx={{ marginBottom: 2 }}
        >
          Send request
        </Button>
        <Button
          onClick={() => navigate(-1)}
          variant='text'
          fullWidth
          size='large'
          type='button'
          sx={{ marginBottom: 2 }}
        >
          Back
        </Button>
      </Form>
    </FormikProvider>
  );
};
