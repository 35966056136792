import React from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import {
  ListWorkflowsRequest,
  Workflow,
  WorkflowMode,
} from 'protos/pb/v1alpha2/workflows_service';

import {
  OrbyBadge,
  OrbyBodyLabel,
  OrbyColorPalette,
  OrbyTypography,
} from 'orby-ui/src';
import UserCard from '../../../../components/UserCard';
import {
  checkActions,
  checkTriggers,
  formatDateTime,
  getActionIndex,
  getDateRange,
} from '../../../../utils/helpers';
import { NavigateFunction, generatePath } from 'react-router-dom';
import {
  DISPLAY_NAME_PREFIX,
  DateOptionValues,
  DateRange,
  ELLIPSIS_STYLE,
  END_DATE,
  NAME_PREFIX,
  PERIOD,
  SELECTED_TAB,
  START_DATE,
  USERNAMES,
  WORKFLOW_APPS,
  WORKFLOW_MODES,
  WORKFLOW_RESOURCE_NAMES,
} from '../../../../utils/constants';
import { getLogoByName } from '../../../../components/workflow-table/helper';
import { ApplicationName } from '../../../../utils/protos/enums';
import { parseWorkflowProtoToJson } from '../../../../utils/jsonToYamlParser';
import {
  createSftpClassificationWorkflowObject,
  createSftpExtractionWorkflowObject,
} from '../../../../utils/yamlToJsonParser';
import { User } from 'protos/pb/v1alpha1/user';
import { setSelectedWorkflowAction } from '../../../../redux/actions/workflow.action';
import { getIdFromResourceName } from '../../../../utils/WorkflowUtils';
import { toastService } from '../../../../services/ToastService';
import {
  getUsersJoinedByColon,
  getWorkflowIdsJoinedByDashV2,
} from '../../../Utils/taskV2Utils';
import { DEFAULT_FIRST_PAGE } from 'orby-ui/src/components/table/table-utils';
import { OpenInNew } from '@mui/icons-material';
import { APP_ROUTES } from '../../../ProtectedRoutes/Routes';

export const buildRequest = ({
  status,
  navigate,
  displayNamePrefix,
  orgResourceName,
  selectedUsers,
  selectedApps,
  selectedModes,
  selectedTime,
  customRange,
  isWorkflowAdmin /* ONLY LIST WORKFLOWS THAT USER IS ADMIN OF */,
}: {
  status: string;
  navigate: NavigateFunction;
  displayNamePrefix: string;
  orgResourceName: string;
  selectedApps: string[];
  selectedUsers: string[];
  selectedModes: string[];
  selectedTime: string;
  customRange: DateRange;
  isWorkflowAdmin: boolean;
}) => {
  const req: ListWorkflowsRequest = ListWorkflowsRequest.create({
    filter: getFilters(
      navigate,
      status,
      displayNamePrefix,
      selectedApps,
      selectedUsers,
      selectedModes,
      selectedTime,
      customRange,
      isWorkflowAdmin,
    ),
    orgResourceName: orgResourceName,
  });
  return req;
};

export const getFilters = (
  navigate: NavigateFunction,
  status: string,
  displayNamePrefix: string,
  selectedApps: string[],
  selectedUsers: string[],
  selectedModes: string[],
  selectedTime: string,
  customRange: DateRange,
  isWorkflowAdmin: boolean,
) => {
  let filter = status;
  const searchParams = new URLSearchParams();
  const existingParams = new URLSearchParams(location.search);

  // SEARCH FILTER
  if (displayNamePrefix) {
    filter += `,${NAME_PREFIX}=${displayNamePrefix}`;
    searchParams.append(DISPLAY_NAME_PREFIX, displayNamePrefix);
  }

  // APPS FILTER
  if (selectedApps.length) {
    filter += `,application=${selectedApps.join('-')}`;
    searchParams.append(WORKFLOW_APPS, selectedApps.join('-'));
  }

  // MODES FILTER
  if (selectedModes.length) {
    filter += `,mode=${selectedModes.join('-')}`;
    searchParams.append(WORKFLOW_MODES, selectedModes.join('-'));
  }

  // TIME FILTER
  if (selectedTime) {
    const dateRange = getDateRange(selectedTime, customRange);
    if (dateRange) {
      filter += `,last_update_time_lt=${dateRange.endDate},last_update_time_gt=${dateRange.startDate}`;
      searchParams.append(PERIOD, selectedTime);
    }

    if (selectedTime === DateOptionValues.CUSTOM_RANGE) {
      searchParams.append(
        START_DATE,
        customRange.startDate.format('MM/DD/YYYY'),
      );
      searchParams.append(END_DATE, customRange.endDate.format('MM/DD/YYYY'));
    }
  }

  // CREATORS FILTER
  if (selectedUsers.length) {
    filter += `,creator=${getUsersJoinedByColon(selectedUsers)}`;
    searchParams.append(
      USERNAMES,
      getWorkflowIdsJoinedByDashV2(selectedUsers) || '',
    );
  }

  // ONLY LIST WORKFLOWS THAT USER IS ADMIN OF
  if (isWorkflowAdmin) {
    filter += `,is_workflow_admin=true`;
  }

  // GET THE TAB
  const selectedTab = existingParams.get(SELECTED_TAB) || 0;
  navigate(
    {
      search: `?${searchParams.toString()}&${SELECTED_TAB}=${selectedTab}`,
    },
    {
      replace: true,
    },
  );
  return filter;
};

const BlockedWorkflowTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      placement='top-start'
      disableFocusListener
      disableHoverListener
      {...props}
      classes={{ popper: className }}
    />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: '12px',
    borderRadius: '12px',
    maxWidth: 320,
  },
}));

export const getWorkflowNameCell = (
  workflow: Workflow,
  displayNamePrefix: string,
  isBlocked: boolean,
  handleReviewTasksClick: (workflow: Workflow) => void,
) => {
  return (
    <WorkflowNameCell
      workflow={workflow}
      displayNamePrefix={displayNamePrefix}
      isBlocked={isBlocked}
      handleReviewTasksClick={handleReviewTasksClick}
    />
  );
};

interface WorkflowNameCellProps {
  workflow: Workflow;
  displayNamePrefix: string;
  isBlocked: boolean;
  handleReviewTasksClick: (workflow: Workflow) => void;
}

const WorkflowNameCell: React.FC<WorkflowNameCellProps> = ({
  workflow,
  displayNamePrefix,
  isBlocked,
  handleReviewTasksClick,
}) => {
  const name = workflow.displayName || '';
  const startIndex = name
    .toLowerCase()
    .indexOf(displayNamePrefix?.toLowerCase());
  const endIndex = startIndex + displayNamePrefix?.length;

  const [openTooltip, setOpenTooltip] = React.useState(false);
  const handleTooltipOpen = (event: any) => {
    event.stopPropagation();
    setOpenTooltip(!openTooltip);
  };
  return (
    <Box display={'flex'} flexWrap='wrap' gap={'8px'}>
      <OrbyBodyLabel
        title={name}
        key={name}
        label={
          <span>
            <span
              style={{ color: OrbyColorPalette['blue-700'], fontWeight: '700' }}
            >
              {name.substring(startIndex, endIndex)}
            </span>
            {name.substring(endIndex)}
          </span>
        }
        fontSize='sm'
        fontWeight='medium'
        color={OrbyColorPalette['grey-900']}
      />
      {isBlocked && (
        <BlockedWorkflowTooltip
          onClose={() => setOpenTooltip(false)}
          open={openTooltip}
          title={
            <Box
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Box marginBottom={'8px'}>
                Workflow needs attention
                <CloseOutlinedIcon
                  sx={{
                    fontSize: '18px',
                    color: OrbyColorPalette['grey-400'],
                    cursor: 'pointer',
                    float: 'right',
                  }}
                  onClick={handleTooltipOpen}
                />
              </Box>
              <Box
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: OrbyColorPalette['grey-500'],
                }}
              >
                This workflow needs your attention due to the{' '}
                <a
                  style={{ textDecoration: 'underline', color: 'inherit' }}
                  href='https://docs.orby.ai/docs/quota'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Initial learning or quota
                </a>{' '}
                limitations. Please review the pending tasks to unblock
                executions.
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '10px',
                  paddingBottom: '0px',
                }}
              >
                <OrbyTypography
                  sx={{
                    cursor: 'pointer',
                    color: OrbyColorPalette['blue-700'],
                    fontWeight: '600',
                  }}
                  onClick={() => {
                    handleReviewTasksClick(workflow);
                  }}
                >
                  Review tasks
                </OrbyTypography>
              </Box>
            </Box>
          }
        >
          <Box
            display={'flex'}
            flexWrap='wrap'
            justifyContent={'center'}
            alignItems={'center'}
            gap={'4px'}
          >
            <ErrorOutlineIcon
              sx={{
                fontSize: '14px',
                color: OrbyColorPalette['warning-500'],
              }}
            />
            <OrbyTypography
              size='xs'
              weight='medium'
              color={OrbyColorPalette['warning-700']}
              onClick={handleTooltipOpen}
            >
              <u>Needs Attention</u>
            </OrbyTypography>
          </Box>
        </BlockedWorkflowTooltip>
      )}
    </Box>
  );
};

const AccuracyCellTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    display: 'none',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '350px',
    borderRadius: '8px',
    padding: '8px 12px',
    backgroundColor: theme.palette.common.black,
    marginBottom: '0px !important',
    marginLeft: '-145px',
  },
}));

export const getWorkflowAccuracyCell = (workflow: Workflow) => {
  const handleOpenFieldsPage = () => {
    const params = new URLSearchParams({
      [WORKFLOW_RESOURCE_NAMES]: workflow.name!,
    });

    const isClassificationWorkflow =
      getActionIndex(workflow, ApplicationName.DocumentClassification)
        .stepIndex !== undefined;
    if (isClassificationWorkflow) {
      params.append('type', 'classification');
    }

    window.open(`${APP_ROUTES.FIELDS}?${params.toString()}`, undefined);
  };

  const formatAccuracy = (accuracy?: number) => {
    if (accuracy === null || accuracy === undefined || accuracy === -1) {
      return '-';
    }
    return `${(accuracy * 100).toFixed(0)}%`;
  };

  return (
    <AccuracyCellTooltip
      placement='top-start'
      title={
        <Box display={'flex'} alignItems={'center'} gap={'6px'}>
          <OpenInNew sx={{ fontSize: '16px' }} />
          <OrbyTypography
            size='xs'
            weight='semibold'
            color={OrbyColorPalette['white-0']}
          >
            Click to see accuracy per field for this workflow
          </OrbyTypography>
        </Box>
      }
    >
      <Box
        role='button'
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            e.stopPropagation();
            handleOpenFieldsPage();
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleOpenFieldsPage();
        }}
      >
        <OrbyTypography weight='bold' color={OrbyColorPalette['blue-700']}>
          {formatAccuracy(workflow.accuracy)}
        </OrbyTypography>
      </Box>
    </AccuracyCellTooltip>
  );
};

export const getWorkflowAppsCell = (
  applicationNames: string[],
  width = '24px',
  imageWidth = '12.8px',
): JSX.Element => {
  return (
    <Box display={'flex'} flexWrap='wrap'>
      {applicationNames.map((app: string, index) => {
        const logo = getLogoByName(app);
        if (logo) {
          return (
            <React.Fragment key={app}>
              {/* IF INDEX IS ODD (1,3,5 etc) THEN PRINT BELOW*/}
              {index % 2 === 0 && index > 1 && (
                <Box
                  marginLeft={'13px'}
                  marginRight={'15px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <ArrowForwardIosRoundedIcon
                    sx={{
                      color: OrbyColorPalette['grey-400'],
                      fontSize: '10px',
                    }}
                  />
                </Box>
              )}

              <Box
                width={width}
                height={width}
                key={logo}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  border: `1px solid ${OrbyColorPalette['grey-200']}`,
                  borderRadius: '100px',
                }}
              >
                <img
                  src={logo}
                  title={app}
                  alt={app}
                  width={imageWidth}
                  height={imageWidth}
                />
              </Box>
            </React.Fragment>
          );
        }
      })}
    </Box>
  );
};

export const getWorkflowModeName = (mode: WorkflowMode) => {
  if (mode === WorkflowMode.DEFAULT) {
    return 'Active Review';
  } else if (mode === WorkflowMode.AUTOPILOT) {
    return 'Auto-pilot';
  } else if (mode === WorkflowMode.ASSISTED) {
    return 'Assisted';
  }
};

export const getWorkflowModeCell = (workflow: Workflow): JSX.Element => {
  return (
    <OrbyBadge
      key={workflow.name}
      backgroundColor={OrbyColorPalette['purple-50']}
      textColor={OrbyColorPalette['purple-700']}
      badgeName={getWorkflowModeName(workflow.mode!)}
      size='small'
      textSx={ELLIPSIS_STYLE}
    />
  );
};

export const getWorkflowCreatorCell = (workflow: Workflow): JSX.Element => {
  return (
    <UserCard
      email={workflow?.creatorEmail ?? ''}
      fullName={workflow.creator?.fullName}
      imageUrl={workflow.creator?.imageUrl}
    />
  );
};

export const getWorkflowTimeCell = (workflow: Workflow): JSX.Element => {
  if (workflow.lastModifiedTime || workflow.createTime) {
    const { date, time } = formatDateTime(
      workflow.lastModifiedTime ?? workflow.createTime,
    );
    return (
      <Box key={workflow.name}>
        <OrbyBodyLabel
          label={date}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-900']}
        />
        <OrbyBodyLabel
          label={time}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-600']}
        />
      </Box>
    );
  } else {
    return (
      <OrbyBodyLabel
        key={workflow.name}
        label={'--'}
        fontSize='sm'
        fontWeight='regular'
        color={OrbyColorPalette['grey-900']}
      />
    );
  }
};

export const getWorkflowActionMenuCell = (
  workflow: Workflow,
  handleActionMenuClick: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    workflow: Workflow,
  ) => void,
): JSX.Element => {
  return (
    <IconButton
      title='Menu'
      aria-label='Menu'
      tabIndex={0}
      key={workflow.name}
      onClick={(
        event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
      ) => {
        event.stopPropagation();
        handleActionMenuClick(event, workflow);
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      <MoreVertOutlinedIcon sx={{ color: OrbyColorPalette['grey-400'] }} />
    </IconButton>
  );
};

export const WORKFLOW_ACTION_MENU_ITEM = {
  DELETE: 'Delete',
  EDIT: 'Edit',
  COPY: 'Copy',
  UPLOAD: 'Upload',
  DOWNLOAD: 'Download',
  SHARE: 'Share',
};

export const isSFTPToJsonWorkflow = (workflow: Workflow) => {
  const sftpIndex = checkTriggers(workflow, [ApplicationName.SftpServer]);
  const generateOutputIndex = checkActions(workflow, [
    ApplicationName.GenerateOutput,
    ApplicationName.EntityExtraction,
    ApplicationName.DocumentClassification,
  ]);
  if (sftpIndex && generateOutputIndex) {
    return true;
  }
  return false;
};

export const getWorkflowActionItem = (
  actionMenuAnchorEl: HTMLElement | null,
  open: boolean,
  isShowYamlConfig: boolean,
  isShowShareWorkflow: boolean,
  handleActionMenuClose: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type: string,
  ) => void,
) => {
  return (
    <Menu
      id='basic-menu'
      anchorEl={actionMenuAnchorEl}
      open={open}
      onClose={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        handleActionMenuClose(e, '');
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
          overflow: 'visible',
          borderRadius: '8px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {/* EDIT */}
      <MenuItem
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => {
          handleActionMenuClose(e, WORKFLOW_ACTION_MENU_ITEM.EDIT);
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          Edit
        </OrbyTypography>
      </MenuItem>
      {/* COPY */}
      <MenuItem
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => {
          handleActionMenuClose(e, WORKFLOW_ACTION_MENU_ITEM.COPY);
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          Copy tasks
        </OrbyTypography>
      </MenuItem>
      {isShowYamlConfig && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={(e) => {
            handleActionMenuClose(e, WORKFLOW_ACTION_MENU_ITEM.DOWNLOAD);
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            Download configuration
          </OrbyTypography>
        </MenuItem>
      )}
      {isShowYamlConfig && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={(e) => {
            handleActionMenuClose(e, WORKFLOW_ACTION_MENU_ITEM.UPLOAD);
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            Upload configuration
          </OrbyTypography>
        </MenuItem>
      )}
      {isShowShareWorkflow && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={(e) => {
            handleActionMenuClose(e, WORKFLOW_ACTION_MENU_ITEM.SHARE);
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            Edit Workflow Admin
          </OrbyTypography>
        </MenuItem>
      )}
      {/* DELETE */}
      <MenuItem
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => {
          handleActionMenuClose(e, WORKFLOW_ACTION_MENU_ITEM.DELETE);
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['error-700']}
        >
          Delete
        </OrbyTypography>
      </MenuItem>
    </Menu>
  );
};

/**
 * Handle Workflow Page Change
 */
export const handleWorkflowPageChange = ({
  pageNumber,
  rowsPerPage,
  setPage,
  listWorkflows,
}: {
  pageNumber: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  listWorkflows: (pageNumber: number, rowsPerPage: number) => void;
}) => {
  setPage(pageNumber);
  listWorkflows(pageNumber, rowsPerPage);
};

/**
 *  HANDLE ROWS SELECTION CHANGE
 */
export const handleWorkflowsRowSelectionChange = ({
  rowsNumber,
  setPage,
  setRowsPerPage,
  listWorkflows,
}: {
  rowsNumber: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  listWorkflows: (pageNumber: number, rowsPerPage: number) => void;
}) => {
  setPage(DEFAULT_FIRST_PAGE);
  // Refresh is needed when rows per page is changes to fetch fresh data
  setRowsPerPage(rowsNumber);
  listWorkflows(DEFAULT_FIRST_PAGE, rowsNumber);
};

export const getWorkflowNameColumnWidth = (
  tableWidth: number,
  minWidth?: number,
) => {
  const width =
    tableWidth -
    110 -
    174 -
    142 -
    217 -
    getWorkflowTimeWidth(tableWidth, 80) -
    69 -
    10; // Subtract 10 pixels to add avoid overflowX auto on normal screen sizes (like desktop)
  if (minWidth) {
    return Math.max(width, minWidth);
  }
  return width;
};

export const getWorkflowTimeWidth = (tableWidth: number, minWidth?: number) => {
  const width = tableWidth >= 1272 ? 182 : 139;
  if (minWidth) {
    return Math.max(width, minWidth);
  }
  return width;
};

/**
 * Download the workflow as a YAML file
 */
export const downloadYamlFile = (workflow: Workflow) => {
  const yamlString = parseWorkflowProtoToJson(workflow);
  const element = document.createElement('a');
  const file = new Blob([yamlString], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = workflow.displayName + '.yaml';
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  element.remove();
};

/**
 * HANDLE YAML EDIT
 */
export const handleYamlEditWorkflow = ({
  jsonData,
  selectedWorkflow,
  setYamlFileError,
  user,
  dispatch,
  navigate,
}: {
  jsonData: any;
  selectedWorkflow: Workflow;
  setYamlFileError: React.Dispatch<React.SetStateAction<string | null>>;
  user: User;
  dispatch: React.Dispatch<any>;
  navigate: NavigateFunction;
}) => {
  let parsedWorkflow = Workflow.create(selectedWorkflow);
  // Check if the workflow template name in the YAML file matches the selected workflow template name
  // If the template name doesn't match, then the user is trying to edit a different workflow
  if (jsonData.templateResourceName != selectedWorkflow.templateResourceName) {
    setYamlFileError("Workflow template doesn't match");
    return;
  }
  // Check if the workflow display name in the YAML file matches the selected workflow display name
  if (jsonData?.displayName != selectedWorkflow?.displayName) {
    setYamlFileError("Workflow display name doesn't match");
    return;
  }
  const isSftpTrigger = checkTriggers(selectedWorkflow, [
    ApplicationName.SftpServer,
  ]);
  const isClassificationGenerateOutputAction = checkActions(selectedWorkflow, [
    ApplicationName.DocumentClassification,
    ApplicationName.GenerateOutput,
  ]);
  const isExtractionGenerateOutputAction = checkActions(selectedWorkflow, [
    ApplicationName.EntityExtraction,
    ApplicationName.GenerateOutput,
  ]);
  // Check if the workflow is a classification workflow or an extraction workflow
  if (
    isSftpTrigger &&
    (isClassificationGenerateOutputAction || isExtractionGenerateOutputAction)
  ) {
    try {
      if (isClassificationGenerateOutputAction) {
        parsedWorkflow = createSftpClassificationWorkflowObject(
          parsedWorkflow,
          jsonData,
          user,
        );
      } else {
        parsedWorkflow = createSftpExtractionWorkflowObject(
          parsedWorkflow,
          jsonData,
          user,
        );
      }
    } catch (error: any) {
      setYamlFileError(`${error.message}`);
      return;
    }
    // Navigate to the update workflow page with the parsed workflow
    dispatch(setSelectedWorkflowAction(parsedWorkflow as Workflow));
    const id = getIdFromResourceName(parsedWorkflow?.name as string);
    if (id) {
      navigate(generatePath(APP_ROUTES.WORKFLOW_UPDATE, { workflow_id: id! }), {
        state: { isYamlWorkflow: true },
      });
    } else {
      toastService.showError('Something went wrong', {
        position: 'top-right',
      });
    }
  } else {
    // Handle the case where the YAML file is invalid
    setYamlFileError('Invalid YAML file');
    return;
  }
};
