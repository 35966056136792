import { createSelector } from 'reselect';
import { orbotSelector } from './app.selectors';

export const orbotWorkflowListSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.workflows,
);

export const orbotWorkflowsLoadingSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.loadingWorkflows,
);

export const orbotWorkflowsLoadedSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.loadedWorkflows,
);

export const orbotWorkflowsNextPageTokenSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.nextPageToken,
);

export const orbotWorkflowsTotalSizeSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.totalSize,
);

export const listOrbotWorkflowErrorSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.listWorkflowError,
);

export const processOrbotWorkflowErrorSelector = createSelector(
  [orbotSelector],
  (workflowState) => workflowState.processWorkflowError,
);

export const processingOrbotWorkflowSelector = createSelector(
  [orbotSelector],
  (workflowState) => workflowState.processingWorkflow,
);

export const updatedOrbotWorkflowSelector = createSelector(
  [orbotSelector],
  (workflowState) => workflowState.updatedWorkflow,
);

export const cancelExecutionLoadingSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.cancelExecutionLoading,
);

export const cancelExecutionErrorSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.cancelExecutionError,
);

export const cancelExecutionSuccessSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.cancelExecutionSuccess,
);
