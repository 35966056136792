import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { FC, ReactElement, memo, useState } from 'react';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import {
  getFontSize,
  getFontWeight,
} from 'orby-ui/src/components/typography/typography-utils';

export interface DrawerTabType {
  title: string;
  route: string;
  icon: ReactElement;
  hoverIcon: ReactElement;
  description?: string;
}

type DrawerTabProps = {
  index: number;
  data: DrawerTabType;
  isSelected: boolean;
  onClick: (arg: string) => void;
  collapse: boolean;
};

const DrawerTab: FC<DrawerTabProps> = ({
  index,
  data,
  isSelected,
  onClick,
  collapse,
}) => {
  const { icon, route, title, description, hoverIcon } = data;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip
      title={title}
      arrow
      placement='right-end'
      enterNextDelay={collapse ? 0 : 1000000} // A Hack to not show tooltip when not collapsed
      enterDelay={collapse ? 0 : 1000000} // A Hack to not show tooltip when not collapsed
      PopperProps={{
        sx: {
          '& .MuiTooltip-arrow': { color: OrbyColorPalette['white-0'] },
          '& .MuiTooltip-tooltip': {
            backgroundColor: OrbyColorPalette['white-0'],
            borderRadius: '8px',
            padding: '8px 12px',
            color: OrbyColorPalette['grey-700'],
            fontSize: getFontSize('xs'),
            fontWeight: getFontWeight('semibold'),
            boxShadow:
              '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
          },
        },
      }}
    >
      <Box
        key={index}
        padding={'8px 12px'}
        id={route}
        tabIndex={0}
        role={'tab'}
        aria-selected={true}
        display='flex'
        alignItems='center'
        gap={'8px'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick(route);
        }}
        onKeyUp={(event) => {
          event.stopPropagation();
          if (event.key === 'Enter') {
            onClick(route);
          }
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        marginBottom='16px'
        bgcolor={
          isSelected
            ? 'rgba(255, 255, 255, 0.10)'
            : OrbyColorPalette['grey-900']
        }
        width={'100%'}
        height='40px'
        borderRadius='5px'
        sx={{
          outlineColor: 'red',
          cursor: isSelected ? 'default' : 'pointer',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.10)',
          },
        }}
      >
        <Box
          title={title}
          alignItems={'center'}
          display={'flex'}
          sx={{
            color: OrbyColorPalette['white-0'],
          }}
        >
          {isHovered || isSelected ? hoverIcon : icon}
        </Box>
        <Box
          display={collapse ? 'none' : 'flex'}
          flexDirection='column'
          sx={{
            transition: 'all 0.1s ease',
            opacity: collapse ? 0 : 1,
            pointerEvents: collapse ? 'none' : 'unset',
          }}
        >
          <OrbyTypography
            color={OrbyColorPalette['white-0']}
            sx={{
              textWrap: 'nowrap',
            }}
          >
            {title}
          </OrbyTypography>
          {description && (
            <OrbyTypography
              size='xs'
              color={OrbyColorPalette['grey-500']}
              sx={{
                textWrap: 'nowrap',
              }}
            >
              {description}
            </OrbyTypography>
          )}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default memo(DrawerTab);
