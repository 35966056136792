import { createSelector } from 'reselect';
import { authSelector } from './app.selectors';

export const loadingSelector = createSelector(
  [authSelector],
  (authState) => authState.loading,
);

export const userObjectSelector = createSelector(
  [authSelector],
  (authState) => authState.user,
);

export const tokenSelector = createSelector(
  [authSelector],
  (authState) => authState.token,
);

export const authErrorSelector = createSelector(
  [authSelector],
  (authState) => authState.error,
);

export const loginErrorMessageSelector = createSelector(
  [authSelector],
  (authState) => authState.loginErrorMessage,
);

export const unauthenticatedErrorSelector = createSelector(
  [authSelector],
  (authState) => authState.unauthenticatedError,
);
