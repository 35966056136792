import { all, call, put, takeLatest } from 'redux-saga/effects';
import { suggestionService } from '../../services/SuggestionService';
import { SuggestionActionType } from '../actions/actions.constants';
import {
  batchUpdateSuggestionCompletedAction,
  batchUpdateSuggestionErrorAction,
  executeSuggestionStepAction,
  getNextStepAction,
  getNextStepCompletedAction,
  getNextStepErrorAction,
  getSuggestionByNameCompletedAction,
  getSuggestionByNameErrorAction,
  getSuggestionSummaryCompletedAction,
  getSuggestionSummaryErrorAction,
  listCompletedSuggestionAction,
  listCompletedSuggestionCompletedAction,
  listCompletedSuggestionErrorAction,
  listReadySuggestionAction,
  listReadySuggestionCompletedAction,
  listReadySuggestionErrorAction,
  updateSuggestionCompletedAction,
  updateSuggestionErrorAction,
} from '../actions/suggestion.action';

import { Activity } from 'protos/automation_mining/automation_mining';
import { Suggestion, SuggestionSTATUS } from 'protos/pb/v1alpha1/suggestion';
import {
  BatchUpdateSuggestionsRequest,
  BatchUpdateSuggestionsResponse,
  ListSuggestionsRequest,
  ListSuggestionsResponse,
  SummarizeSuggestionsRequest,
  SummarizeSuggestionsResponse,
  UpdateSuggestionRequest,
  UpdateSuggestionResponse,
} from 'protos/pb/v1alpha1/suggestions_service';
import { getEnumName } from '../../utils/protos/enums';
import { SuggestionStep } from 'protos/pb/v1alpha1/suggestion_step';
import { Document } from 'protos/google/cloud/documentai/v1/document';

function* listReadySuggestionSaga(data: {
  type: SuggestionActionType;
  payload: ListSuggestionsRequest;
  fetchRegularly: boolean;
  fetchOnStart: boolean;
}) {
  if (!data.fetchOnStart && data.fetchRegularly) {
    yield call(waitForDuration);
  }
  try {
    const response: ListSuggestionsResponse = yield call(
      suggestionService.getSuggestions,
      data.payload,
    );
    yield put(
      listReadySuggestionCompletedAction(
        response.suggestions as Suggestion[],
        response.nextPageToken as string,
        response.totalSize as number,
      ),
    );
    if (data.fetchRegularly) {
      yield put(
        listReadySuggestionAction(data.payload, data.fetchRegularly, false),
      );
    }
  } catch (e: any) {
    yield put(
      listReadySuggestionErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
    if (data.fetchRegularly) {
      yield put(
        listReadySuggestionAction(data.payload, data.fetchRegularly, false),
      );
    }
  }
}

function* listCompletedSuggestionSaga(data: {
  type: SuggestionActionType;
  payload: ListSuggestionsRequest;
}) {
  try {
    const response: ListSuggestionsResponse = yield call(
      suggestionService.getSuggestions,
      data.payload,
    );
    yield put(
      listCompletedSuggestionCompletedAction(
        response.suggestions as Suggestion[],
        response.nextPageToken as string,
        response.totalSize as number,
      ),
    );
  } catch (e: any) {
    yield put(
      listCompletedSuggestionErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

function* updateSuggestionSaga(data: {
  type: SuggestionActionType;
  payload: UpdateSuggestionRequest;
}) {
  try {
    const response: UpdateSuggestionResponse = yield call(
      suggestionService.updateSuggestion,
      data.payload,
    );
    yield put(
      updateSuggestionCompletedAction(response.suggestion as Suggestion),
    );
  } catch (e: any) {
    yield put(
      updateSuggestionErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

function* batchUpdateSuggestionSaga(data: {
  type: SuggestionActionType;
  payload: BatchUpdateSuggestionsRequest;
}) {
  try {
    const response: BatchUpdateSuggestionsResponse = yield call(
      suggestionService.batchUpdateSuggestion,
      data.payload,
    );
    yield put(
      batchUpdateSuggestionCompletedAction(
        response.suggestions as Suggestion[],
      ),
    );
  } catch (e: any) {
    yield put(
      batchUpdateSuggestionErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

function* getSuggestionSummarySaga(data: {
  type: SuggestionActionType;
  payload: SummarizeSuggestionsRequest;
}): any {
  try {
    const summary: SummarizeSuggestionsResponse = yield call(
      suggestionService.getSuggestionSummary,
      data.payload,
    );
    yield put(getSuggestionSummaryCompletedAction(summary));
  } catch (e: any) {
    yield put(
      getSuggestionSummaryErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

function* getNextStepSaga(data: {
  type: SuggestionActionType;
  suggestion: Suggestion;
}): any {
  try {
    const response: Suggestion = yield call(
      suggestionService.getNextStep,
      data.suggestion,
    );
    response?.steps?.unshift(...(data.suggestion.steps! as SuggestionStep[]));
    yield put(getNextStepCompletedAction(response));
    yield put(executeSuggestionStepAction(response));
  } catch (e: any) {
    yield put(
      getNextStepErrorAction((e?.errors && e.errors[0]?.message) || e?.message),
    );
  }
}

function* getSuggestionByNameSaga(data: {
  type: SuggestionActionType;
  suggestionName: string;
  parent: string;
  loadList: boolean;
}): any {
  try {
    const response = yield call(
      suggestionService.getSuggestionByName,
      data.suggestionName,
    );
    if (data.loadList) {
      const req: ListSuggestionsRequest = {};
      req.parent = data.parent;
      req.filter = `status=${getEnumName(SuggestionSTATUS, response.status)}`;
      if (response.status === SuggestionSTATUS.READY) {
        yield put(listReadySuggestionAction(req));
      } else {
        yield put(listCompletedSuggestionAction(req));
      }
    }
    yield put(getSuggestionByNameCompletedAction(response));
  } catch (e: any) {
    yield put(
      getSuggestionByNameErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

function* executeStepSaga(data: {
  type: SuggestionActionType;
  payload: Suggestion;
}): any {
  let suggestion: Suggestion = data.payload;
  if (suggestion?.steps?.length == 0) {
    suggestion = yield call(
      // @ts-ignore
      suggestionService.getSuggestionByName,
      suggestion.name,
    );
  }
  const step: SuggestionStep = suggestion?.steps?.find(
    (s: SuggestionStep) =>
      s.mode && !s.mode.complete && !s.mode.preliminaryExecution,
  ) as SuggestionStep;
  if (step && step.mode && step.documents) {
    switch (step.activity) {
      case Activity.COMPREHEND_DOCUMENT: {
        step.mode.complete = true;
        for (let i = 0; i < step.documents.length; i++) {
          if (step.documents[i].uri && step.documents![i].uri!.length > 0) {
            const doc: Document = {};
            doc.entities = [...step.documents![i].entities!];
            doc.uri = step.documents[i].uri;
            step.documents[i] = doc;
          }
        }

        for (let i = 0; i < step.originalDocuments!.length; i++) {
          if (
            step.originalDocuments![i].uri &&
            step.originalDocuments![i].uri!.length > 0
          ) {
            const doc: Document = {};
            doc.entities = [...step.originalDocuments![i].entities!];
            doc.uri = step.documents[i].uri;
            step.documents[i] = doc;
          }
        }
        yield put(executeSuggestionStepAction(suggestion));
        break;
      }
      case Activity.ADD_SHEET_ROW: {
        step.mode.complete = true;
        yield put(executeSuggestionStepAction(suggestion));
        break;
      }
      case Activity.CREATE_INVOICE: {
        step.mode.complete = true;
        yield put(executeSuggestionStepAction(suggestion));
        break;
      }
      default: {
        throw new Error(`unknown activity: ${step.activity}`);
      }
    }
  } else {
    // all tasks are complete and get next step
    if (
      suggestion.steps!.length > 0 &&
      suggestion.steps![suggestion.steps!.length - 1].activity != Activity.END
    ) {
      yield put(getNextStepAction(suggestion));
    } else if (
      suggestion.steps!.length > 0 &&
      suggestion.steps![suggestion.steps!.length - 1].activity == Activity.END
    ) {
      yield put(updateSuggestionCompletedAction(suggestion));
    }
  }
}

const waitForDuration = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 2000);
  });
};

function* suggestionSaga() {
  yield all([
    takeLatest(
      SuggestionActionType.LIST_READY_SUGGESTIONS,
      listReadySuggestionSaga,
    ),
    takeLatest(
      SuggestionActionType.LIST_COMPLETED_SUGGESTIONS,
      listCompletedSuggestionSaga,
    ),
    takeLatest(SuggestionActionType.UPDATE_SUGGESTION, updateSuggestionSaga),
    takeLatest(
      SuggestionActionType.BATCH_UPDATE_SUGGESTION,
      batchUpdateSuggestionSaga,
    ),
    takeLatest(
      SuggestionActionType.GET_SUGGESTION_SUMMARY,
      getSuggestionSummarySaga,
    ),
    takeLatest(SuggestionActionType.GET_NEXT_STEP, getNextStepSaga),
    takeLatest(
      SuggestionActionType.GET_SUGGESTION_BY_NAME,
      getSuggestionByNameSaga,
    ),
    takeLatest(SuggestionActionType.EXECUTE_SUGGESTION_STEP, executeStepSaga),
  ]);
}

export default suggestionSaga;
