import React from 'react';

import { Container, Typography, Box } from '@mui/material';
// components

import { ForgotForm } from './components/ForgotPasswordForm';
import drawerLog from '../../static/orby-logo-main.svg';
// ----------------------------------------------------------------------

export default function ForgotPassword() {
  return (
    <Box paddingTop={'10px'}>
      <Container
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box marginBottom={4} sx={{ width: '100%' }}>
          <img src={drawerLog} alt='Orby Logo' />
        </Box>
        <Box sx={{ width: '45%' }}>
          <Typography
            align='center'
            fontSize={36}
            fontWeight={700}
            color={'#000000A6'}
            gutterBottom
            sx={{ marginBottom: '70px' }}
          >
            Forgot Password
          </Typography>
          <ForgotForm />
        </Box>
      </Container>
    </Box>
  );
}
