import { OrbyColorPalette } from 'orby-ui/src';
import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { getInfoIcon, getSuccessIcon } from '../components/ToastIcons';

class ToastService {
  private static _instance: ToastService;

  static getInstance(): ToastService {
    if (!this._instance) {
      this._instance = new ToastService();
    }
    return this._instance;
  }

  showSuccess(content: ToastContent, config?: ToastOptions) {
    toast.dismiss();
    toast(content, {
      ...config,
      type: 'success',
      position: config?.position || 'top-right',
      autoClose: config?.autoClose ?? 2000,
      closeOnClick: false,
      draggable: false,
      style: { backgroundColor: OrbyColorPalette['green-50'] },
      icon: getSuccessIcon(),
      bodyStyle: { alignItems: 'flex-start' },
    });
  }

  showWarning(content: ToastContent, config?: ToastOptions) {
    toast.dismiss();
    toast(content, {
      ...config,
      type: 'warning',
      position: config?.position || 'top-right',
      autoClose: config?.autoClose ?? false, // By default warning toast messages wont auto close
      closeOnClick: false,
      draggable: false,
      style: { backgroundColor: '#FEFBE8' },
    });
  }

  showError(content: ToastContent, config?: ToastOptions) {
    toast.dismiss();
    toast(content, {
      ...config,
      type: 'error',
      position: config?.position || 'top-right',
      autoClose: config?.autoClose ?? false, // By default error toast messages wont auto close
      closeOnClick: false,
      draggable: false,
      style: { backgroundColor: OrbyColorPalette['error-50'] },
    });
  }

  showInfo(content: ToastContent, config?: ToastOptions) {
    toast.dismiss();
    toast(content, {
      ...config,
      type: 'info',
      position: config?.position || 'top-right',
      autoClose: config?.autoClose ?? 1000,
      closeOnClick: false,
      draggable: false,
      style: { backgroundColor: OrbyColorPalette['blue-50'] },
      icon: getInfoIcon(),
      bodyStyle: { alignItems: 'flex-start' },
    });
  }

  dismiss(toastRef?: any) {
    toast.dismiss(toastRef);
  }
}

export const toastService = ToastService.getInstance();
