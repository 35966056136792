import produce from 'immer';
import { Reducer } from 'redux';
import { ArchivedResource } from 'protos/pb/v1alpha2/archived_resources_service';
import { ArchivedResourceActionType } from '../actions/actions.constants';

export interface ArchivedResourceState {
  list: ArchivedResource[];
  listLoading: boolean;
  listLoaded: boolean;
  listingError?: Error;
  restoring: boolean;
  restored: boolean;
  restoringError?: Error;
  totalSize?: number;
}

const initialState: ArchivedResourceState = {
  list: [],
  listLoading: false,
  listLoaded: false,
  restoring: false,
  restored: false,
};

export const archivedResourcesReducer: Reducer<ArchivedResourceState> = (
  state: ArchivedResourceState = initialState,
  action: any,
) =>
  produce(state, (draft: ArchivedResourceState) => {
    switch (action.type) {
      case ArchivedResourceActionType.LIST_ARCHIVED_RESOURCES:
        draft.listLoading = true;
        break;
      case ArchivedResourceActionType.LIST_ARCHIVED_RESOURCES_COMPLETED: {
        if (action.refresh) {
          draft.list = action.payload;
        } else {
          draft.list.push(...action.payload);
        }
        draft.totalSize = action.totalSize;
        draft.listLoading = false;
        draft.listLoaded = true;
        break;
      }
      case ArchivedResourceActionType.LIST_ARCHIVED_RESOURCES_ERROR: {
        draft.listLoading = false;
        draft.listingError = action.payload;
        break;
      }
      case ArchivedResourceActionType.RESTORE_ARCHIVED_RESOURCES:
        draft.restoring = true;
        draft.restored = false;
        break;
      case ArchivedResourceActionType.RESTORE_ARCHIVED_RESOURCES_COMPLETED: {
        draft.list = draft.list.filter((t) => {
          if (t.task) {
            return !action.payload.includes(t.task.name);
          }
          if (t.workflow) {
            return !action.payload.includes(t.workflow.name);
          }
          if (t.connector) {
            return !action.payload.includes(t.connector.name);
          }
          return true;
        });
        draft.totalSize = (draft.totalSize as number) - action.payload.length;
        draft.restoring = false;
        draft.restored = true;
        break;
      }
      case ArchivedResourceActionType.RESTORE_ARCHIVED_RESOURCES_ERROR: {
        draft.restoring = false;
        draft.restoringError = action.payload;
        break;
      }
      default:
        break;
    }
  });
