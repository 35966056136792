import { TaskPERMISSION } from 'protos/pb/v1alpha2/tasks_service';
import { Task } from 'protos/pb/v1alpha2/tasks_service';

export interface UserPermissions {
  // user can manage teams page
  updateUser: boolean;
  // user can list workflows
  listWorkflows: boolean;
  // user can create workflow
  createWorkflow: boolean;
  // user can manage connectors
  listConnectors: boolean;
  // user can access deleted items page
  listArchivedResources: boolean;
}

export function createUserPermissions(permissions: string[]): UserPermissions {
  const defaults: UserPermissions = {
    updateUser: false,
    listWorkflows: false,
    createWorkflow: false,
    listConnectors: false,
    listArchivedResources: false,
  };

  const mapping: { [key: string]: keyof UserPermissions } = {
    update_user: 'updateUser',
    list_workflows: 'listWorkflows',
    create_workflow: 'createWorkflow',
    list_connectors: 'listConnectors',
    list_archived_resources: 'listArchivedResources',
  };

  permissions.forEach((key) => {
    if (mapping[key]) {
      defaults[mapping[key]] = true;
    }
  });

  return defaults;
}

export function isTaskViewable(task: Task): boolean {
  return !!task.permissions?.includes(TaskPERMISSION.PERMISSION_VIEW);
}

export function isTaskDeletable(task: Task): boolean {
  return !!task.permissions?.includes(TaskPERMISSION.PERMISSION_DELETE);
}

export function isTaskAssignable(task: Task): boolean {
  return !!task.permissions?.includes(TaskPERMISSION.PERMISSION_ASSIGN);
}
