import {
  DeleteConnectorRequest,
  ListConnectorRequest,
  UpdateConnectorRequest,
} from 'protos/pb/v1alpha2/connector_service';
import { ConnectorActionType } from './actions.constants';
import { Connector } from 'protos/pb/v1alpha2/connector';

export const listConnectorAction = (
  req: ListConnectorRequest,
  refresh = false,
) => ({
  type: ConnectorActionType.LIST_CONNECTORS,
  req,
  refresh,
});

export const listConnectorCompletedAction = (
  Connectors: Connector[],
  nextPageToken: string,
  totalSize: number,
  refresh: boolean,
) => ({
  type: ConnectorActionType.LIST_CONNECTORS_COMPLETED,
  payload: Connectors,
  nextPageToken,
  totalSize,
  refresh,
});

export const listConnectorErrorAction = (error?: Error) => ({
  type: ConnectorActionType.LIST_CONNECTORS_ERROR,
  payload: error,
});

export const createConnectorAction = (connector: Connector) => ({
  type: ConnectorActionType.CREATE_CONNECTOR,
  payload: connector,
});

export const createConnectorCompletedAction = (connector: Connector) => ({
  type: ConnectorActionType.CREATE_CONNECTOR_COMPLETED,
  payload: connector,
});

export const createConnectorErrorAction = (error: Error | undefined) => ({
  type: ConnectorActionType.CREATE_CONNECTOR_ERROR,
  payload: error,
});

export const updateConnectorAction = (data: UpdateConnectorRequest) => ({
  type: ConnectorActionType.UPDATE_CONNECTOR,
  payload: data,
});

export const updateConnectorCompletedAction = (connector: Connector) => ({
  type: ConnectorActionType.UPDATE_CONNECTOR_COMPLETED,
  payload: connector,
});

export const updateConnectorErrorAction = (error: Error) => ({
  type: ConnectorActionType.UPDATE_CONNECTOR_ERROR,
  payload: error,
});

export const deleteConnectorAction = (payload: DeleteConnectorRequest) => ({
  type: ConnectorActionType.DELETE_CONNECTOR,
  payload,
});

export const deleteConnectorCompletedAction = (name: string) => ({
  type: ConnectorActionType.DELETE_CONNECTOR_COMPLETED,
  payload: name,
});

export const deleteConnectorErrorAction = (error: string) => ({
  type: ConnectorActionType.DELETE_CONNECTOR_ERROR,
  payload: error,
});

export const setSelectedConnectorAction = (
  connector: Connector | undefined,
) => ({
  type: ConnectorActionType.SET_SELECTED_CONNECTOR,
  payload: connector,
});

export const getConnectorAction = (connectorName: string) => ({
  type: ConnectorActionType.GET_CONNECTOR,
  payload: connectorName,
});

export const getConnectorCompletedAction = (connector: Connector) => ({
  type: ConnectorActionType.GET_CONNECTOR_COMPLETED,
  payload: connector,
});

export const getConnectorErrorAction = (error?: Error) => ({
  type: ConnectorActionType.GET_CONNECTOR_ERROR,
  payload: error,
});
