/**
 * This file centralizes all routing-related logic.
 * Keeping route handling in one place ensures consistent navigation behavior
 * and makes maintenance/updates easier across the application.
 */

import { TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import { APP_ROUTES } from './Routes';
import { ArchivedResourceType, TASKS_TAB_INDEX } from '../../utils/constants';
import { WORKFLOW_PAGE } from '../MyWorkflows';
import { generatePath } from 'react-router-dom';

const COLLAPSIBLE_PATH_PATTERNS = [
  /^\/executions\//,
  /^\/workflow\//,
  /^\/workflows\//,
  /^\/dev-testing\/document\//,
];

export const shouldCollapseSideDrawer = (pathname: string) => {
  // Check if the pathname matches any dynamic pattern using regular expressions
  return COLLAPSIBLE_PATH_PATTERNS.some((pattern) => pattern.test(pathname));
};

const getPathAccToTaskStatus = (status: TaskSTATUS): string => {
  if (
    [
      TaskSTATUS.REJECTED_INCORRECT,
      TaskSTATUS.REJECTED_ALREADY_COMPLETED,
      TaskSTATUS.OBSOLETE, // System Declined
    ].includes(status)
  ) {
    return `${APP_ROUTES.TASKS}?tab=${TASKS_TAB_INDEX.DECLINED_TAB}`;
  } else if ([TaskSTATUS.ACCEPTED, TaskSTATUS.COMPLETED].includes(status)) {
    return `${APP_ROUTES.TASKS}?tab=${TASKS_TAB_INDEX.COMPLETED_TAB}`;
  } else if ([TaskSTATUS.READY, TaskSTATUS.CREATED].includes(status)) {
    return `${APP_ROUTES.TASKS}?tab=${TASKS_TAB_INDEX.PENDING_TAB}`;
  }
  return '';
};

export const getPathAccToResource = (
  resourceType: ArchivedResourceType,
  status?: TaskSTATUS,
) => {
  switch (resourceType) {
    case ArchivedResourceType.TASK: {
      return status && TaskSTATUS[status] ? getPathAccToTaskStatus(status) : '';
    }
    case ArchivedResourceType.CONNECTOR: {
      return `${APP_ROUTES.WORKFLOW}?tab=${WORKFLOW_PAGE.CONNECTOR_TAB_INDEX}`;
    }
    default: {
      return `${APP_ROUTES.WORKFLOW}?tab=${WORKFLOW_PAGE.API_AUTOMATION_TAB_INDEX}`;
    }
  }
};

export const getPathForTaskDetailsPage = (
  taskResourceName: string,
  isOrbotTask = false,
) => {
  const [, workflowId, , taskId] = taskResourceName.split('/');
  return generatePath(
    isOrbotTask ? APP_ROUTES.HITL_DETAILS : APP_ROUTES.TASK_REVIEW,
    {
      task_id: taskId,
      workflow_id: workflowId,
    },
  );
};
