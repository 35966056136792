import React from 'react';
import {
  OrbySelect,
  OrbyMenuItem,
  OrbyTypography,
  OrbyColorPalette,
} from 'orby-ui/src';
import { ELLIPSIS_STYLE, Filter } from '../../../utils/constants';
import { Box } from '@mui/material';

export interface Props {
  selectedModes: string[];
  setSelectedModes: (data: string[]) => void;
  width?: string;
  menuWidth?: string;
  modesAvailable: Array<Filter>;
}

const ModesFilter: React.FC<Props> = ({
  selectedModes,
  setSelectedModes,
  width = '200px',
  menuWidth = '200px',
  modesAvailable = [],
}) => {
  const DEFAULT_MODES = {
    label: 'All modes',
    value: 'all-modes',
  };

  /**
   * HANDLE RENDER VALUE
   */
  const handleRenderValue = () => {
    if (selectedModes.length === 0) {
      return (
        <Box overflow={'hidden'} title={DEFAULT_MODES.label}>
          {renderLabel(DEFAULT_MODES.label)}
        </Box>
      );
    } else if (selectedModes.length === 1) {
      const value = modesAvailable.find(
        (mode) => mode.value === selectedModes[0],
      )?.label;
      if (value) {
        return (
          <Box overflow={'hidden'} title={value}>
            {renderLabel(value)}
          </Box>
        );
      }
    } else {
      const value = (
        <Box
          overflow={'hidden'}
          display={'flex'}
          gap={'4px'}
          alignItems={'center'}
        >
          <OrbyTypography
            size='sm'
            weight='semibold'
            color={OrbyColorPalette['blue-700']}
          >
            {selectedModes.length}
          </OrbyTypography>
          <OrbyTypography
            size='sm'
            weight='medium'
            color={OrbyColorPalette['grey-700']}
            sx={{
              paddingRight: '10px',
              ...ELLIPSIS_STYLE,
            }}
          >
            selected modes
          </OrbyTypography>
        </Box>
      );
      return (
        <Box
          overflow={'hidden'}
          title={`${selectedModes.length} modes selected`}
        >
          {value}
        </Box>
      );
    }
  };

  const renderLabel = (label: string, isMenu = false) => {
    return (
      <OrbyTypography
        size='sm'
        color={OrbyColorPalette['grey-900']}
        weight={'regular'}
        sx={{
          paddingRight: isMenu ? '0px' : '10px',
          ...ELLIPSIS_STYLE,
        }}
      >
        {label}
      </OrbyTypography>
    );
  };

  return (
    <>
      <OrbySelect
        value={selectedModes}
        multiple={true}
        renderValue={handleRenderValue}
        width={width}
        onChange={(event) => {
          const value = event.target.value as Array<string>;
          if (value) {
            if (
              value[value.length - 1] === DEFAULT_MODES.value ||
              value.length === 0
            ) {
              setSelectedModes([]);
            } else {
              // REMOVE THE DEFAULT VALUE
              const filteredSelectedModes = value.filter(
                (o) => o !== DEFAULT_MODES.value,
              );
              setSelectedModes(filteredSelectedModes);
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <OrbyMenuItem
          width={menuWidth}
          key={DEFAULT_MODES.value}
          title={DEFAULT_MODES.label}
          value={DEFAULT_MODES.value}
          isSelected={selectedModes.length === 0}
        />
        {modesAvailable.map((mode) => {
          return (
            <OrbyMenuItem
              width={menuWidth}
              key={mode.value}
              title={mode.label}
              value={mode.value}
              isSelected={selectedModes.includes(mode.value)}
            />
          );
        })}
      </OrbySelect>
    </>
  );
};

export default React.memo(ModesFilter);
