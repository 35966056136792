import { DocumentEntityNormalizedValue } from 'protos/google/cloud/documentai/v1/document';
import { DateMessage } from 'protos/google/type/date';
import {
  NormalizedVertex,
  Vertex,
} from 'protos/google/cloud/documentai/v1/geometry';
import { DateTime } from 'protos/google/type/datetime';
import { Money } from 'protos/google/type/money';
import { PostalAddress } from 'protos/google/type/postal_address';
import {
  EntityInfo,
  TextSegmentInfo,
} from '../redux/reducers/review_task.reducer';
import { cloneDeep as deepClone } from 'lodash';
import { NormalizedValue } from 'protos/pb/v1alpha1/field';

export const copyProperties = <T extends object>(copyFrom: T, copyTo: T) => {
  Object.keys(copyFrom).map(
    (key) => (copyTo[key as keyof T] = copyFrom[key as keyof T]),
  );
};

export const deepCloneNormalizedValueObject = (
  normalizedValue?: DocumentEntityNormalizedValue,
) => {
  const normalizedValueCopy: DocumentEntityNormalizedValue = {};
  if (!normalizedValue) {
    return normalizedValueCopy;
  }

  if (normalizedValue.dateValue) {
    const dateToCopy = normalizedValue.dateValue;
    const date: DateMessage = {};
    copyProperties(dateToCopy, date);
    normalizedValueCopy.dateValue = date;
  } else if (normalizedValue.addressValue) {
    const addressToCopy = normalizedValue.addressValue;
    const address: PostalAddress = {};
    copyProperties(addressToCopy, address);
    normalizedValueCopy.addressValue = address;
  } else if (normalizedValue.booleanValue) {
    normalizedValueCopy.booleanValue = normalizedValue.booleanValue;
  } else if (normalizedValue.datetimeValue) {
    const dateTimeToCopy = normalizedValue.datetimeValue;
    const dateTime: DateTime = {};
    copyProperties(dateTimeToCopy, dateTime);
    normalizedValueCopy.datetimeValue = dateTime;
  } else if (!isNaN(normalizedValue.floatValue!)) {
    normalizedValueCopy.floatValue = normalizedValue.floatValue;
  } else if (normalizedValue.moneyValue) {
    const moneyToCopy = normalizedValue.moneyValue;
    const money: Money = {};
    copyProperties(moneyToCopy, money);
    normalizedValueCopy.moneyValue = money;
  } else if (!isNaN(normalizedValue.integerValue!)) {
    normalizedValueCopy.integerValue = normalizedValue.integerValue;
  }

  /*  Since text could exist along with different structs `if` conditional should be used rather than 
     `else if` to make sure we always consider setting `text` if it exists
  */
  if (normalizedValue.text) {
    normalizedValueCopy.text = normalizedValue.text;
  }
  return normalizedValueCopy;
};

export const deepCloneVertexObject = (
  vertexToClone: Vertex | NormalizedVertex,
) => {
  if (vertexToClone) {
    const vertex: Vertex = {};
    copyProperties(vertexToClone, vertex);
    return vertex;
  }
  const vertex: NormalizedValue = {};
  return vertex;
};

export const deepCloneTextSegmentInfoObject = (
  textSegmentInfo: TextSegmentInfo,
) => {
  // cloneDeep method fails to clone proto objects
  const textSegmentInfoCopy = deepClone(textSegmentInfo);

  // manually clone proto objects
  textSegmentInfoCopy.vertices = textSegmentInfo?.vertices?.map((vertex) => {
    return deepCloneVertexObject(vertex) as Vertex;
  });
  textSegmentInfoCopy.pageCorrespondingVertices =
    textSegmentInfo?.pageCorrespondingVertices?.map((vertex) => {
      return deepCloneVertexObject(vertex) as Vertex;
    });
  textSegmentInfoCopy.normalizedVertices =
    textSegmentInfo?.normalizedVertices?.map((vertex) => {
      return deepCloneVertexObject(vertex) as NormalizedVertex;
    });
  return textSegmentInfoCopy;
};

export const deepCloneEntityInfoObject = (entityInfo: EntityInfo) => {
  // cloneDeep method fails to clone proto objects
  const entityInfoDeepCopy = deepClone(entityInfo);

  // manually clone proto objects
  entityInfoDeepCopy.normalizedValue = deepCloneNormalizedValueObject(
    entityInfo.normalizedValue,
  );
  const textSegmentsToCopy = entityInfo?.textSegments;
  const keys = Object.keys(textSegmentsToCopy ?? {});
  if (keys.length > 0) {
    keys.forEach((key) => {
      const textSegmentToCopy = textSegmentsToCopy[key];
      if (textSegmentToCopy) {
        entityInfoDeepCopy.textSegments[key] =
          deepCloneTextSegmentInfoObject(textSegmentToCopy);
      }
    });
  }
  return entityInfoDeepCopy;
};
