import { Box, Grid } from '@mui/material';
import CustomTypography from '../../../components/CustomTypography';
import React, { useRef, FC, useState } from 'react';
import UploaderIcon from '../../../static/icons/uploader_icon.svg';
import CheckCircle from '../../../static/icons/check-circle.svg';
import CustomModal from '../../CustomModal';

interface Props {
  yamlFileError: string | null; // This is used to show the error message if the file is not a YAML file
  open: boolean; // This is used to show the modal or not (open/close)
  isEdit: boolean; // This is used to show the correct text in the modal (create vs modify)
  setOpen: (open: boolean) => void; // This is used to set the open state of the modal (open/close)
  setYamlFileError: (error: string | null) => void; // This is used to set the error message to null in case of file change
  handleFileChange: (file: File | undefined) => void; // This is used to handle the file change (upload) and pass the file to the parent component (workflow-table)
}

const UploadYamlComponent: FC<Props> = ({
  handleFileChange,
  open,
  setOpen,
  isEdit,
  yamlFileError,
  setYamlFileError,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | undefined>();

  const [isHovered, setIsHovered] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(true);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(true);
  };

  const handleDragLeave = () => {
    setIsHovered(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(false);

    const files = e.dataTransfer.files;
    if (files.length > 1) {
      // Multiple files detected, show an error message or handle it as needed
      alert('Please drop only one file.');
      return;
    }
    if (files.length > 0) {
      setYamlFileError(null);
      const file = files[0];

      // Check if the file type is YAML
      // REF - https://github.com/orby-ai-engineering/orby-web-app/pull/682/files/973d69f338e4e9a3cc55ec295f9d1f8f7cb2a2b6#r1379422769
      // REF - https://www.iana.org/assignments/media-types/media-types.xhtml
      if (
        [
          'application/yaml',
          'text/yaml',
          'text/x-yaml',
          'application/x-yaml',
        ].includes(file.type)
      ) {
        // File is a YAML file, you can set it
        setFile(file);
      } else {
        // File is not a YAML file, show an error message or handle it as needed
        alert('Please drop a YAML file.');
      }
    }
  };

  return (
    <CustomModal
      open={open}
      closable={false}
      heading={`Upload to ${isEdit ? 'modify' : 'create'} a workflow`}
      containerSx={{ maxWidth: '400px' }}
      primaryLabel={'Upload File'}
      secondaryLabel={'Cancel'}
      handleClose={() => {
        setOpen(false);
        setFile(undefined);
      }}
      onPrimaryClick={() => {
        handleFileChange(file);
      }}
      primaryButtonSx={{ maxWidth: '50%' }}
      content={
        <Box>
          <Box>
            <input
              type='file'
              accept='.yaml'
              ref={inputRef}
              tabIndex={-1} // disables focus for this element since this element is off screen
              style={{ display: 'none' }}
              onChange={(event) => {
                const file = event.target.files?.[0];
                setFile(file);
                setYamlFileError(null);
                if (inputRef.current) {
                  inputRef.current.value = '';
                }
              }}
            />
            <CustomTypography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: 400,
                color: '#667085',
                marginBottom: '20px',
              }}
            >
              {isEdit
                ? 'Upload a configuration YAML file to modify a workflow, the settings of this workflow will be overwritten by the contents of the YAML file'
                : 'Upload a configuration YAML file to create a workflow with existing settings'}
            </CustomTypography>
          </Box>
          <Box
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.click();
              }
            }}
            height={'189px'}
            sx={{
              cursor: 'pointer',
            }}
            border={'2px dashed #F8F8FF'}
            bgcolor={isHovered ? 'rgb(56 78 183 / 30%)' : 'rgb(56 78 183 / 9%)'}
            padding={'30px'}
            textAlign={'center'}
            id='drop-zone'
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <img
              alt='Uploader Icon'
              style={{ height: '54px' }}
              src={UploaderIcon}
            />

            <CustomTypography
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 700,
                color: '#0F0F0F',
                marginTop: '20px',
              }}
            >
              Drag & drop a file or
              <span
                style={{
                  color: '#483EA8',
                  cursor: 'pointer',
                  marginLeft: '4px',
                  borderBottom: '1px solid #483EA8',
                }}
              >
                Browse
              </span>
            </CustomTypography>

            <CustomTypography
              sx={{
                fontSize: '12px',
                lineHeight: '18px',
                fontWeight: 400,
                color: '#676767',
                marginTop: '20px',
              }}
            >
              Supported format: YAML
            </CustomTypography>
          </Box>

          {file && (
            <Box
              paddingTop={'20px'}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <CustomTypography
                sx={{
                  overflowWrap: 'anywhere',
                }}
              >
                {file?.name}
              </CustomTypography>
              <img
                alt='Check Icon'
                style={{ height: '18px' }}
                src={CheckCircle}
              />
            </Box>
          )}

          {yamlFileError && (
            <Grid paddingY={'10px'}>
              <CustomTypography
                component={'span'}
                sx={{ color: '#EB0000', fontSize: '12px', display: 'block' }}
              >
                {yamlFileError}
              </CustomTypography>
            </Grid>
          )}
        </Box>
      }
    />
  );
};

export default UploadYamlComponent;
