import { NavigateFunction } from 'react-router-dom';
import {
  ADMIN_TASK_FILTER,
  CREATOR_IDS,
  DISPLAY_NAME_PREFIX,
  ORBY_UNASSIGNED,
  PAGE_NO,
  SELECTED_TAB,
  TASKS_TAB_INDEX,
  USERNAMES,
  USER_IDS,
  USER_TASK_FILTER,
  WORKFLOW_RESOURCE_NAMES,
} from '../../utils/constants';
import { User } from 'protos/pb/v1alpha1/user';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import { DEFAULT_WORKFLOW_VALUE } from '../Tasks/TaskHelpers';
import {
  FieldGroupMatch,
  Item,
  ReconcileItems,
  ReconcileItemsResult,
} from 'protos/pb/v1alpha1/actionprocessing';
import { Field } from 'protos/pb/v1alpha1/field';
import { ExecutionStep } from 'protos/pb/v1alpha2/execution_steps';
import { DEFAULT_FIRST_PAGE } from 'orby-ui/src/components/table/table-utils';

// This function extracts the workflow ids from params to get all the applied
// workflow filters and adds them in an array and returns it
export const getWorkflowIdsFromParamString = (
  idsParam?: string,
  defaultValue = DEFAULT_WORKFLOW_VALUE.value,
) => {
  if (idsParam) {
    // Split the value to get individual IDs
    const ids = idsParam.split('-');
    return ids;
  }
  // Return a default value
  return [defaultValue];
};

export const getUsersFromParamStringV2 = (
  urlSearchParams: URLSearchParams,
): Array<string> => {
  const userNameParamString = urlSearchParams.get(USERNAMES);
  let usernames: string[] = userNameParamString
    ? userNameParamString.split('-')
    : [];
  usernames = usernames?.filter((name) => name !== ORBY_UNASSIGNED);
  if (usernames.length === 0) {
    return [];
  }
  return usernames;
};

export const getUserIdsFromParamStringV2 = (
  urlSearchParams: URLSearchParams,
): Array<string> => {
  const userNameParamString = urlSearchParams.get(USER_IDS);
  let usernames: string[] = userNameParamString
    ? userNameParamString.split('-')
    : [];
  usernames = usernames?.filter((name) => name !== ORBY_UNASSIGNED);
  if (usernames.length === 0) {
    return [];
  }
  return usernames;
};

export const getCreatorIdsFromParamStringV2 = (
  urlSearchParams: URLSearchParams,
): Array<string> => {
  const creatorIdsParamString = urlSearchParams.get(CREATOR_IDS);
  let creatorIds: string[] = creatorIdsParamString
    ? creatorIdsParamString.split('-')
    : [];
  creatorIds = creatorIds?.filter((id) => id !== ORBY_UNASSIGNED);
  if (creatorIds.length === 0) {
    return [];
  }
  return creatorIds;
};

export const getWorkflowFromParamString = (idsParam?: string) => {
  if (idsParam) {
    // Split the value to get individual IDs
    const ids = idsParam.split('-');
    return ids;
  }
  // Return a default value
  return [];
};

export const getApiExecutionStatusesFromParamString = (idsParam?: string) => {
  if (idsParam) {
    // Split the value to get individual IDs
    const ids = idsParam.split('-');
    return ids;
  }
  // Return a default value
  return [];
};

export const getExecutionStatusesFJoinedByDash = (
  selectedWorkflows: string[],
) => {
  const ids = selectedWorkflows.join('-');
  return ids;
};

// This function takes the array of workflow names and returns a string
// with all the workflow names joined by `-`
export const getWorkflowIdsJoinedByDash = (
  selectedWorkflows: string[],
  defaultValue = DEFAULT_WORKFLOW_VALUE.value,
) => {
  if (selectedWorkflows.find((w) => w === defaultValue)) {
    return undefined;
  }
  const ids = selectedWorkflows.join('-');
  return ids;
};

export const composeTaskFiltersAndUpdateNetworkURL = (
  status: string,
  email: string,
  isAdminView: boolean,
  navigate: NavigateFunction,
  selectedWorkflows: string[],
  displayNamePrefix: string,
  selectedReviewers: string[] = [], // only for pending tasks
  selectedTaskFilter?: string, // only for pending tasks
): string => {
  const searchParams = new URLSearchParams();

  // Convert selected workflows to a dash-separated string
  const ids: string | undefined = getWorkflowIdsJoinedByDash(selectedWorkflows);

  // If workflows are selected, append to status string
  if (ids) {
    status += `,${WORKFLOW_RESOURCE_NAMES}=${ids}`;
    searchParams.append(WORKFLOW_RESOURCE_NAMES, ids);
  }

  // If display name prefix is not empty, append to status string
  if (displayNamePrefix) {
    status += `,${DISPLAY_NAME_PREFIX}=${displayNamePrefix}`;
    searchParams.append(DISPLAY_NAME_PREFIX, displayNamePrefix);
  }
  if (selectedTaskFilter) {
    if (!isAdminView) {
      let usernames = '';
      if (
        [USER_TASK_FILTER.ALL_TASKS, USER_TASK_FILTER.UNASSIGNED_ONLY].includes(
          selectedTaskFilter as USER_TASK_FILTER,
        )
      ) {
        usernames += ORBY_UNASSIGNED;
      }
      if (
        [USER_TASK_FILTER.ALL_TASKS, USER_TASK_FILTER.ASSIGNED_TO_ME].includes(
          selectedTaskFilter as USER_TASK_FILTER,
        )
      ) {
        usernames += (usernames.includes(ORBY_UNASSIGNED) ? ';' : '') + email;
      }
      if (USER_TASK_FILTER.ALL_TASKS !== selectedTaskFilter) {
        searchParams.append(USERNAMES, usernames);
      }
      status += `,${USERNAMES}=${usernames}`;
    } else {
      if (selectedTaskFilter !== ADMIN_TASK_FILTER.ALL_TASKS) {
        let usernames = '';
        if (
          [
            ADMIN_TASK_FILTER.UNASSIGNED_ONLY,
            ADMIN_TASK_FILTER.UNASSIGNED_WITH_REVIEWERS,
          ].includes(selectedTaskFilter as ADMIN_TASK_FILTER)
        ) {
          usernames += ORBY_UNASSIGNED;
        }
        if (
          [
            ADMIN_TASK_FILTER.REVIEWERS_ONLY,
            ADMIN_TASK_FILTER.UNASSIGNED_WITH_REVIEWERS,
          ].includes(selectedTaskFilter as ADMIN_TASK_FILTER)
        ) {
          usernames +=
            (usernames.includes(ORBY_UNASSIGNED) ? ';' : '') +
            selectedReviewers.join(';');
        }
        searchParams.append(USERNAMES, usernames);
        status += `,${USERNAMES}=${usernames}`;
      }
    }
  } else {
    if (!isAdminView) {
      status += `,${USERNAMES}=${email}`;
    }
  }
  // Update URL search parameters for workflow and display name
  navigate({ search: `?${searchParams.toString()}` });

  // Return the updated status string
  return status.length && status[0] === ',' ? status.substring(1) : status;
};

export const getFilterValuesFromUrl = (
  isAdminView: boolean,
  urlSearchParams: URLSearchParams,
  user: User,
): { filterOption: string; selectedUsers: Set<string> } => {
  const userNameParamString = urlSearchParams.get(USERNAMES);
  let usernames: string[] = userNameParamString
    ? userNameParamString.split(';')
    : [];
  const isUnassigned = !!usernames?.find((name) => name === ORBY_UNASSIGNED);
  usernames = usernames?.filter((name) => name !== ORBY_UNASSIGNED);
  if (isAdminView) {
    const hasReviewers = usernames.length > 0;
    if (isUnassigned && hasReviewers) {
      return {
        filterOption: ADMIN_TASK_FILTER.UNASSIGNED_WITH_REVIEWERS,
        selectedUsers: new Set(usernames),
      };
    }
    if (isUnassigned) {
      return {
        filterOption: ADMIN_TASK_FILTER.UNASSIGNED_ONLY,
        selectedUsers: new Set(),
      };
    }
    if (hasReviewers) {
      return {
        filterOption: ADMIN_TASK_FILTER.REVIEWERS_ONLY,
        selectedUsers: new Set(usernames),
      };
    }
    return {
      filterOption: ADMIN_TASK_FILTER.ALL_TASKS,
      selectedUsers: new Set(),
    };
  }
  const includesOwnEmail = usernames.find((u) => u === user?.email);
  if (isUnassigned && includesOwnEmail) {
    return {
      filterOption: USER_TASK_FILTER.ALL_TASKS,
      selectedUsers: new Set(),
    };
  }
  if (isUnassigned) {
    return {
      filterOption: USER_TASK_FILTER.UNASSIGNED_ONLY,
      selectedUsers: new Set(),
    };
  }
  if (includesOwnEmail) {
    return {
      filterOption: USER_TASK_FILTER.ASSIGNED_TO_ME,
      selectedUsers: new Set(),
    };
  }
  return {
    filterOption: USER_TASK_FILTER.ALL_TASKS,
    selectedUsers: new Set(),
  };
};

export const getFilterValuesFromUrlV2 = (
  urlSearchParams: URLSearchParams,
): Array<string> => {
  const userNameParamString = urlSearchParams.get(USERNAMES);
  const usernames: string[] = userNameParamString
    ? userNameParamString.split(';')
    : [];
  if (usernames.length === 0) {
    return [];
  }
  return usernames;
};

// This function takes the array of workflow names and returns a string
// with all the workflow names joined by `-`
export const getWorkflowIdsJoinedByDashV2 = (
  selectedWorkflows: string[],
  defaultValue = DEFAULT_WORKFLOW_VALUE.value,
) => {
  if (selectedWorkflows.find((w) => w === defaultValue)) {
    return undefined;
  }
  const ids = selectedWorkflows.join('-');
  return ids;
};

// This function takes the array of strings and returns a string
// with all the workflow names joined by `:`
export const getUsersJoinedByColon = (data: string[]) => {
  const ids = data.join(':');
  return ids;
};

export const composeTaskFiltersAndUpdateNetworkURLV2 = (
  status: string,
  email: string,
  isAdminView: boolean,
  navigate: NavigateFunction,
  selectedWorkflows: string[],
  displayNamePrefix: string,
  selectedReviewers: string[] = [], // only for pending tasks
  pageNumber: number = DEFAULT_FIRST_PAGE,
): string => {
  const searchParams = new URLSearchParams();
  const existingParams = new URLSearchParams(location.search);

  // Convert selected workflows to a dash-separated string
  const ids: string | undefined =
    getWorkflowIdsJoinedByDashV2(selectedWorkflows);

  // If workflows are selected, append to status string
  if (ids) {
    status += `,${WORKFLOW_RESOURCE_NAMES}=${ids}`;
    searchParams.append(WORKFLOW_RESOURCE_NAMES, ids);
  }

  // If display name prefix is not empty, append to status string
  if (displayNamePrefix) {
    status += `,${DISPLAY_NAME_PREFIX}=${displayNamePrefix}`;
    searchParams.append(DISPLAY_NAME_PREFIX, displayNamePrefix);
  }

  if (!isAdminView) {
    if (selectedReviewers.length > 0) {
      const usernames = selectedReviewers.join(';');
      searchParams.append(USERNAMES, usernames);
      status += `,${USERNAMES}=${usernames}`;
    } else {
      status += `,${USERNAMES}=${email};${ORBY_UNASSIGNED}`;
    }
  } else if (selectedReviewers.length) {
    const usernames = selectedReviewers.join(';');
    searchParams.append(USERNAMES, usernames);
    status += `,${USERNAMES}=${usernames}`;
  }

  // GET THE TAB
  const selectedTab =
    existingParams.get(SELECTED_TAB) || TASKS_TAB_INDEX.PENDING_TAB;

  searchParams.append(SELECTED_TAB, selectedTab.toString());
  searchParams.append(PAGE_NO, pageNumber.toString());
  navigate(
    {
      search: `?${searchParams.toString()}`,
    },
    { replace: true },
  );

  // Return the updated status string
  return status.length && status[0] === ',' ? status.substring(1) : status;
};

export type FieldsUpdate = {
  fieldGroupIndex: number;
  fields: Field[];
};

export function calculateUpdatedItem(item: Item, updates: FieldsUpdate[]) {
  const newItem = structuredClone(item);
  updates.forEach((update) => {
    update.fields.forEach((field) => {
      newItem.fieldGroups![update.fieldGroupIndex].fields?.forEach((f) => {
        if (f.name === field.name) {
          f.value = field.value;
        }
      });
    });
  });
  return newItem;
}

export function mergeRespAndTargetItem(
  resp: ReconcileItemsResult,
  item: Item | undefined,
): Item {
  if (!item) {
    throw new Error('Item is undefined');
  }
  const newItem = structuredClone(item);
  // caaps
  resp.targetExtractedFields?.forEach((fieldGroupFields) => {
    fieldGroupFields.fields?.forEach((field) => {
      if (
        field.name === 'line item/description' ||
        field.name === 'line item/date'
      ) {
        newItem.fieldGroups![fieldGroupFields.fieldGroupIndex!].fields?.unshift(
          field,
        );
      }
    });
  });
  return newItem;
}

/**
 * Grouping that allows a source to be mapped to multiple targets.
 */
interface Grouping {
  sourceIndex?: number;
  targetIndices: number[];
}

/**
 * NOTE: this implementation assume a target can only be matched to a single
 * source, while a source can be matched to multiple targets.
 */
export function calculateGroupings(matches: FieldGroupMatch[]): Grouping[] {
  const groupings: Grouping[] = [];
  // map from sourceIndex to the index in the groupings array
  const sourceGroupingIndices = new Map<number, number>();

  for (const fg of matches) {
    if (
      fg.match &&
      fg.match.sourceIndex !== undefined &&
      fg.match.targetIndex !== undefined
    ) {
      const groupingIndex = sourceGroupingIndices.get(fg.match.sourceIndex);
      if (groupingIndex === undefined) {
        sourceGroupingIndices.set(fg.match.sourceIndex, groupings.length);
        groupings.push({
          sourceIndex: fg.match.sourceIndex,
          targetIndices: [fg.match.targetIndex],
        });
      } else {
        groupings[groupingIndex]!.targetIndices.push(fg.match.targetIndex);
      }
    } else if (fg.unmatchedSource && fg.unmatchedSource.index !== undefined) {
      groupings.push({
        sourceIndex: fg.unmatchedSource.index,
        targetIndices: [],
      });
    } else if (fg.unmatchedTarget && fg.unmatchedTarget.index !== undefined) {
      groupings.push({
        sourceIndex: undefined,
        targetIndices: [fg.unmatchedTarget.index],
      });
    }
  }
  return groupings;
}

export interface ListElement {
  css: string;
  values: Array<{ key?: string; value: string }>;
}

interface ReconcileData {
  source: Item;
  target: Item;
  reconcileResult: ReconcileItemsResult;
  stepIndex: number;
}

export const getReconcileData = (
  taskData?: Task,
): ReconcileData | undefined => {
  if (!taskData || !taskData.executionSteps) {
    return;
  }

  let stepIndex = -1;
  let step: ExecutionStep | undefined;
  taskData?.executionSteps?.forEach((es, index) => {
    if (
      es?.result?.smartActionResult?.correctedSmartActionResult
        ?.reconcileLineItemsResult ||
      es?.result?.smartActionResult?.smartActionResult?.reconcileLineItemsResult
    ) {
      stepIndex = index;
      step = es;
    }
  });
  if (!step) {
    throw new Error('No reconcileLineItems step found in task');
  }

  const reconcileLineItemsResult =
    step.result?.smartActionResult?.correctedSmartActionResult
      ?.reconcileLineItemsResult ||
    step.result?.smartActionResult?.smartActionResult?.reconcileLineItemsResult;

  if (!reconcileLineItemsResult) {
    throw new Error('No reconcileLineItems step found in task');
  }

  const option = step.options?.find(
    (option) => option.smartAction?.reconcileLineItems,
  );
  if (!option) {
    throw new Error('No reconcileLineItems option found in step');
  }

  const targetItem = option.smartAction?.reconcileLineItems?.target;
  const mergedTargetItem = mergeRespAndTargetItem(
    reconcileLineItemsResult!,
    targetItem,
  );
  const sourceItem = option.smartAction!.reconcileLineItems!.source!;
  return {
    source: sourceItem,
    target: mergedTargetItem,
    reconcileResult: reconcileLineItemsResult,
    stepIndex,
  };
};

export const getReconcileItemsFromTask = (
  task: Task,
): ReconcileItems | undefined => {
  let reconcileItems: ReconcileItems | undefined;
  task?.executionSteps?.forEach((es) => {
    es?.options?.forEach((option) => {
      if (option?.smartAction?.reconcileLineItems) {
        reconcileItems = option?.smartAction.reconcileLineItems;
      }
    });
  });

  return reconcileItems;
};
