/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Empty } from "../../google/protobuf/empty";
import { FieldMask } from "../../google/protobuf/field_mask";
import { ApiKey } from "./api_key";
import { Organization } from "./organization";

export const protobufPackage = "pb.v1alpha1";

export interface DeleteOrganizationRequest {
  name?: string | undefined;
}

export interface CreateOrganizationRequest {
  organization?:
    | Organization
    | undefined;
  /**
   * User to be created as password user, must not be existing user and
   * needs to be an admin within the organization
   */
  passwordUser?: OrganizationPasswordUser | undefined;
}

export interface GetOrganizationRequest {
  name?: string | undefined;
}

export interface OrganizationPasswordUser {
  /** The email provided here must be part of admin array in workflow */
  email?:
    | string
    | undefined;
  /** Password cannot be empty */
  password?: string | undefined;
}

export interface UpdateOrganizationRequest {
  organization?:
    | Organization
    | undefined;
  /**
   * The fields that can be updated are: users, admins, creators, display_name,
   * workflow_template_types, saml_config, saml_config.is_activated,
   * saml_config.sign_request, saml_config.idp_metadata.signing_certificates,
   * saml_config.idp_metadata.sso_url, saml_config.idp_metadata.entity_id,
   * saml_config.idp_metadata.logout_url.
   *
   * The fields that can be updated from internal app are:  workflow_template_types,
   * hyperparameter_resource_name.
   *
   * We provide a FieldMask to allow partial updates for idp_metadata because
   * the client won't be able to send the complete existing data for idp_metadata
   * since certificates are not sent to the client. We have a certificate check
   * on the server side to validate the certificates inside idp_metadata.
   */
  fieldMask?: string[] | undefined;
}

export interface CreateApiKeyRequest {
  apiKeyName?: string | undefined;
}

export interface CreateApiKeyResponse {
  orgId?: string | undefined;
  apiKey?: ApiKey | undefined;
}

export interface ListApiKeysRequest {
}

export interface ListApiKeysResponse {
  orgId?: string | undefined;
  apiKeys?: ApiKey[] | undefined;
}

export interface DeleteApiKeyRequest {
  apiKeyId?: string | undefined;
}

function createBaseDeleteOrganizationRequest(): DeleteOrganizationRequest {
  return { name: "" };
}

export const DeleteOrganizationRequest = {
  encode(message: DeleteOrganizationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteOrganizationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteOrganizationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteOrganizationRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteOrganizationRequest): unknown {
    const obj: any = {};
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteOrganizationRequest>, I>>(base?: I): DeleteOrganizationRequest {
    return DeleteOrganizationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteOrganizationRequest>, I>>(object: I): DeleteOrganizationRequest {
    const message = createBaseDeleteOrganizationRequest();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreateOrganizationRequest(): CreateOrganizationRequest {
  return { organization: undefined, passwordUser: undefined };
}

export const CreateOrganizationRequest = {
  encode(message: CreateOrganizationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      Organization.encode(message.organization, writer.uint32(10).fork()).ldelim();
    }
    if (message.passwordUser !== undefined) {
      OrganizationPasswordUser.encode(message.passwordUser, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrganizationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrganizationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = Organization.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.passwordUser = OrganizationPasswordUser.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrganizationRequest {
    return {
      organization: isSet(object.organization) ? Organization.fromJSON(object.organization) : undefined,
      passwordUser: isSet(object.passwordUser) ? OrganizationPasswordUser.fromJSON(object.passwordUser) : undefined,
    };
  },

  toJSON(message: CreateOrganizationRequest): unknown {
    const obj: any = {};
    if (message.organization !== undefined) {
      obj.organization = Organization.toJSON(message.organization);
    }
    if (message.passwordUser !== undefined) {
      obj.passwordUser = OrganizationPasswordUser.toJSON(message.passwordUser);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrganizationRequest>, I>>(base?: I): CreateOrganizationRequest {
    return CreateOrganizationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOrganizationRequest>, I>>(object: I): CreateOrganizationRequest {
    const message = createBaseCreateOrganizationRequest();
    message.organization = (object.organization !== undefined && object.organization !== null)
      ? Organization.fromPartial(object.organization)
      : undefined;
    message.passwordUser = (object.passwordUser !== undefined && object.passwordUser !== null)
      ? OrganizationPasswordUser.fromPartial(object.passwordUser)
      : undefined;
    return message;
  },
};

function createBaseGetOrganizationRequest(): GetOrganizationRequest {
  return { name: "" };
}

export const GetOrganizationRequest = {
  encode(message: GetOrganizationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrganizationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrganizationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrganizationRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetOrganizationRequest): unknown {
    const obj: any = {};
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrganizationRequest>, I>>(base?: I): GetOrganizationRequest {
    return GetOrganizationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOrganizationRequest>, I>>(object: I): GetOrganizationRequest {
    const message = createBaseGetOrganizationRequest();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseOrganizationPasswordUser(): OrganizationPasswordUser {
  return { email: "", password: "" };
}

export const OrganizationPasswordUser = {
  encode(message: OrganizationPasswordUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== undefined && message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.password !== undefined && message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationPasswordUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationPasswordUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrganizationPasswordUser {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
    };
  },

  toJSON(message: OrganizationPasswordUser): unknown {
    const obj: any = {};
    if (message.email !== undefined && message.email !== "") {
      obj.email = message.email;
    }
    if (message.password !== undefined && message.password !== "") {
      obj.password = message.password;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrganizationPasswordUser>, I>>(base?: I): OrganizationPasswordUser {
    return OrganizationPasswordUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrganizationPasswordUser>, I>>(object: I): OrganizationPasswordUser {
    const message = createBaseOrganizationPasswordUser();
    message.email = object.email ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseUpdateOrganizationRequest(): UpdateOrganizationRequest {
  return { organization: undefined, fieldMask: undefined };
}

export const UpdateOrganizationRequest = {
  encode(message: UpdateOrganizationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      Organization.encode(message.organization, writer.uint32(10).fork()).ldelim();
    }
    if (message.fieldMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.fieldMask), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOrganizationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOrganizationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = Organization.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateOrganizationRequest {
    return {
      organization: isSet(object.organization) ? Organization.fromJSON(object.organization) : undefined,
      fieldMask: isSet(object.fieldMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.fieldMask)) : undefined,
    };
  },

  toJSON(message: UpdateOrganizationRequest): unknown {
    const obj: any = {};
    if (message.organization !== undefined) {
      obj.organization = Organization.toJSON(message.organization);
    }
    if (message.fieldMask !== undefined) {
      obj.fieldMask = FieldMask.toJSON(FieldMask.wrap(message.fieldMask));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateOrganizationRequest>, I>>(base?: I): UpdateOrganizationRequest {
    return UpdateOrganizationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateOrganizationRequest>, I>>(object: I): UpdateOrganizationRequest {
    const message = createBaseUpdateOrganizationRequest();
    message.organization = (object.organization !== undefined && object.organization !== null)
      ? Organization.fromPartial(object.organization)
      : undefined;
    message.fieldMask = object.fieldMask ?? undefined;
    return message;
  },
};

function createBaseCreateApiKeyRequest(): CreateApiKeyRequest {
  return { apiKeyName: "" };
}

export const CreateApiKeyRequest = {
  encode(message: CreateApiKeyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.apiKeyName !== undefined && message.apiKeyName !== "") {
      writer.uint32(10).string(message.apiKeyName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateApiKeyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateApiKeyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.apiKeyName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateApiKeyRequest {
    return { apiKeyName: isSet(object.apiKeyName) ? globalThis.String(object.apiKeyName) : "" };
  },

  toJSON(message: CreateApiKeyRequest): unknown {
    const obj: any = {};
    if (message.apiKeyName !== undefined && message.apiKeyName !== "") {
      obj.apiKeyName = message.apiKeyName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateApiKeyRequest>, I>>(base?: I): CreateApiKeyRequest {
    return CreateApiKeyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateApiKeyRequest>, I>>(object: I): CreateApiKeyRequest {
    const message = createBaseCreateApiKeyRequest();
    message.apiKeyName = object.apiKeyName ?? "";
    return message;
  },
};

function createBaseCreateApiKeyResponse(): CreateApiKeyResponse {
  return { orgId: "", apiKey: undefined };
}

export const CreateApiKeyResponse = {
  encode(message: CreateApiKeyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== undefined && message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.apiKey !== undefined) {
      ApiKey.encode(message.apiKey, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateApiKeyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateApiKeyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.apiKey = ApiKey.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateApiKeyResponse {
    return {
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      apiKey: isSet(object.apiKey) ? ApiKey.fromJSON(object.apiKey) : undefined,
    };
  },

  toJSON(message: CreateApiKeyResponse): unknown {
    const obj: any = {};
    if (message.orgId !== undefined && message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.apiKey !== undefined) {
      obj.apiKey = ApiKey.toJSON(message.apiKey);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateApiKeyResponse>, I>>(base?: I): CreateApiKeyResponse {
    return CreateApiKeyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateApiKeyResponse>, I>>(object: I): CreateApiKeyResponse {
    const message = createBaseCreateApiKeyResponse();
    message.orgId = object.orgId ?? "";
    message.apiKey = (object.apiKey !== undefined && object.apiKey !== null)
      ? ApiKey.fromPartial(object.apiKey)
      : undefined;
    return message;
  },
};

function createBaseListApiKeysRequest(): ListApiKeysRequest {
  return {};
}

export const ListApiKeysRequest = {
  encode(_: ListApiKeysRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListApiKeysRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListApiKeysRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListApiKeysRequest {
    return {};
  },

  toJSON(_: ListApiKeysRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListApiKeysRequest>, I>>(base?: I): ListApiKeysRequest {
    return ListApiKeysRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListApiKeysRequest>, I>>(_: I): ListApiKeysRequest {
    const message = createBaseListApiKeysRequest();
    return message;
  },
};

function createBaseListApiKeysResponse(): ListApiKeysResponse {
  return { orgId: "", apiKeys: [] };
}

export const ListApiKeysResponse = {
  encode(message: ListApiKeysResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== undefined && message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.apiKeys !== undefined && message.apiKeys.length !== 0) {
      for (const v of message.apiKeys) {
        ApiKey.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListApiKeysResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListApiKeysResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.apiKeys!.push(ApiKey.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListApiKeysResponse {
    return {
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      apiKeys: globalThis.Array.isArray(object?.apiKeys) ? object.apiKeys.map((e: any) => ApiKey.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListApiKeysResponse): unknown {
    const obj: any = {};
    if (message.orgId !== undefined && message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.apiKeys?.length) {
      obj.apiKeys = message.apiKeys.map((e) => ApiKey.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListApiKeysResponse>, I>>(base?: I): ListApiKeysResponse {
    return ListApiKeysResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListApiKeysResponse>, I>>(object: I): ListApiKeysResponse {
    const message = createBaseListApiKeysResponse();
    message.orgId = object.orgId ?? "";
    message.apiKeys = object.apiKeys?.map((e) => ApiKey.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteApiKeyRequest(): DeleteApiKeyRequest {
  return { apiKeyId: "" };
}

export const DeleteApiKeyRequest = {
  encode(message: DeleteApiKeyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.apiKeyId !== undefined && message.apiKeyId !== "") {
      writer.uint32(10).string(message.apiKeyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteApiKeyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteApiKeyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.apiKeyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteApiKeyRequest {
    return { apiKeyId: isSet(object.apiKeyId) ? globalThis.String(object.apiKeyId) : "" };
  },

  toJSON(message: DeleteApiKeyRequest): unknown {
    const obj: any = {};
    if (message.apiKeyId !== undefined && message.apiKeyId !== "") {
      obj.apiKeyId = message.apiKeyId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteApiKeyRequest>, I>>(base?: I): DeleteApiKeyRequest {
    return DeleteApiKeyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteApiKeyRequest>, I>>(object: I): DeleteApiKeyRequest {
    const message = createBaseDeleteApiKeyRequest();
    message.apiKeyId = object.apiKeyId ?? "";
    return message;
  },
};

export interface Organizations {
  /** Allows user to register an organization */
  CreateOrganization(request: DeepPartial<CreateOrganizationRequest>, metadata?: grpc.Metadata): Promise<Organization>;
  /** Returns information about an organization */
  GetOrganization(request: DeepPartial<GetOrganizationRequest>, metadata?: grpc.Metadata): Promise<Organization>;
  /** Updates the organization information */
  UpdateOrganization(request: DeepPartial<UpdateOrganizationRequest>, metadata?: grpc.Metadata): Promise<Organization>;
  /** Delete the organization */
  DeleteOrganization(request: DeepPartial<DeleteOrganizationRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  CreateApiKey(request: DeepPartial<CreateApiKeyRequest>, metadata?: grpc.Metadata): Promise<CreateApiKeyResponse>;
  ListApiKeys(request: DeepPartial<ListApiKeysRequest>, metadata?: grpc.Metadata): Promise<ListApiKeysResponse>;
  DeleteApiKey(request: DeepPartial<DeleteApiKeyRequest>, metadata?: grpc.Metadata): Promise<Empty>;
}

export class OrganizationsClientImpl implements Organizations {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateOrganization = this.CreateOrganization.bind(this);
    this.GetOrganization = this.GetOrganization.bind(this);
    this.UpdateOrganization = this.UpdateOrganization.bind(this);
    this.DeleteOrganization = this.DeleteOrganization.bind(this);
    this.CreateApiKey = this.CreateApiKey.bind(this);
    this.ListApiKeys = this.ListApiKeys.bind(this);
    this.DeleteApiKey = this.DeleteApiKey.bind(this);
  }

  CreateOrganization(request: DeepPartial<CreateOrganizationRequest>, metadata?: grpc.Metadata): Promise<Organization> {
    return this.rpc.unary(
      OrganizationsCreateOrganizationDesc,
      CreateOrganizationRequest.fromPartial(request),
      metadata,
    );
  }

  GetOrganization(request: DeepPartial<GetOrganizationRequest>, metadata?: grpc.Metadata): Promise<Organization> {
    return this.rpc.unary(OrganizationsGetOrganizationDesc, GetOrganizationRequest.fromPartial(request), metadata);
  }

  UpdateOrganization(request: DeepPartial<UpdateOrganizationRequest>, metadata?: grpc.Metadata): Promise<Organization> {
    return this.rpc.unary(
      OrganizationsUpdateOrganizationDesc,
      UpdateOrganizationRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteOrganization(request: DeepPartial<DeleteOrganizationRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(
      OrganizationsDeleteOrganizationDesc,
      DeleteOrganizationRequest.fromPartial(request),
      metadata,
    );
  }

  CreateApiKey(request: DeepPartial<CreateApiKeyRequest>, metadata?: grpc.Metadata): Promise<CreateApiKeyResponse> {
    return this.rpc.unary(OrganizationsCreateApiKeyDesc, CreateApiKeyRequest.fromPartial(request), metadata);
  }

  ListApiKeys(request: DeepPartial<ListApiKeysRequest>, metadata?: grpc.Metadata): Promise<ListApiKeysResponse> {
    return this.rpc.unary(OrganizationsListApiKeysDesc, ListApiKeysRequest.fromPartial(request), metadata);
  }

  DeleteApiKey(request: DeepPartial<DeleteApiKeyRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(OrganizationsDeleteApiKeyDesc, DeleteApiKeyRequest.fromPartial(request), metadata);
  }
}

export const OrganizationsDesc = { serviceName: "pb.v1alpha1.Organizations" };

export const OrganizationsCreateOrganizationDesc: UnaryMethodDefinitionish = {
  methodName: "CreateOrganization",
  service: OrganizationsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateOrganizationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Organization.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrganizationsGetOrganizationDesc: UnaryMethodDefinitionish = {
  methodName: "GetOrganization",
  service: OrganizationsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetOrganizationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Organization.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrganizationsUpdateOrganizationDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateOrganization",
  service: OrganizationsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateOrganizationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Organization.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrganizationsDeleteOrganizationDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteOrganization",
  service: OrganizationsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteOrganizationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrganizationsCreateApiKeyDesc: UnaryMethodDefinitionish = {
  methodName: "CreateApiKey",
  service: OrganizationsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateApiKeyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateApiKeyResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrganizationsListApiKeysDesc: UnaryMethodDefinitionish = {
  methodName: "ListApiKeys",
  service: OrganizationsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListApiKeysRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListApiKeysResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrganizationsDeleteApiKeyDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteApiKey",
  service: OrganizationsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteApiKeyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
