import {
  CreateSecretBlockRequest,
  CreateSecretBlockResponse,
  DeleteSecretBlockRequest,
  GetSecretBlockRequest,
  GetSecretBlockResponse,
  ListSecretBlocksRequest,
  ListSecretBlocksResponse,
  SecretBlock,
  SecretManagerClientImpl,
  UpdateSecretBlockRequest,
  UpdateSecretBlockResponse,
  CreateSecretRequest,
  CreateSecretResponse,
  GetSecretRequest,
  GetSecretResponse,
  UpdateSecretRequest,
  UpdateSecretResponse,
  DeleteSecretRequest,
  Secret,
} from 'protos/pb/v1alpha1/secret_manager';
import { storageService } from './StorageService';
import {
  getMetaData,
  RpcError,
  rpcWithErrorHandling,
} from '../utils/RpcUtills';
import { Empty } from 'protos/google/protobuf/empty';

export class SecretsService {
  private static instance: SecretsService;
  private static client = new SecretManagerClientImpl(rpcWithErrorHandling);

  public static getInstance(): SecretsService {
    if (!this.instance) {
      this.instance = new SecretsService();
    }
    return this.instance;
  }

  // SecretBlock methods

  async listSecretBlocks(
    req: ListSecretBlocksRequest,
  ): Promise<{ response?: ListSecretBlocksResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await SecretsService.client.ListSecretBlocks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async createSecretBlock(
    secretBlock: SecretBlock,
  ): Promise<{ response?: CreateSecretBlockResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    const req: CreateSecretBlockRequest = { secretBlock };
    try {
      const response = await SecretsService.client.CreateSecretBlock(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateSecretBlock(
    req: UpdateSecretBlockRequest,
  ): Promise<{ response?: UpdateSecretBlockResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await SecretsService.client.UpdateSecretBlock(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getSecretBlock(
    req: GetSecretBlockRequest,
  ): Promise<{ response?: GetSecretBlockResponse; error?: RpcError }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await SecretsService.client.GetSecretBlock(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as RpcError };
    }
  }

  async deleteSecretBlock(
    req: DeleteSecretBlockRequest,
  ): Promise<{ response?: Empty; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await SecretsService.client.DeleteSecretBlock(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  // Individual Secret methods

  async createSecret(
    secret: Secret,
  ): Promise<{ response?: CreateSecretResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    const req: CreateSecretRequest = { secret };
    try {
      const response = await SecretsService.client.CreateSecret(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getSecret(
    req: GetSecretRequest,
  ): Promise<{ response?: GetSecretResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await SecretsService.client.GetSecret(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateSecret(
    req: UpdateSecretRequest,
  ): Promise<{ response?: UpdateSecretResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await SecretsService.client.UpdateSecret(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async deleteSecret(
    req: DeleteSecretRequest,
  ): Promise<{ response?: Empty; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await SecretsService.client.DeleteSecret(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const secretsService = SecretsService.getInstance();
